<template>
  <section class="flex flex-col flex-grow" :class="scrollable ? 'h-full' : ''">
    <div
      class="inline-flex px-6 border-b bordered"
      :class="
        (smallPadding ? 'py-3' : 'py-5') +
        ' ' +
        (darkened ? 'darkened' : '') +
        ' ' +
        additionalHeaderClass
      "
    >
      <h2 class="text-base font-medium text-gray-900">
        {{ title }}
      </h2>
      <span class="mx-1 text-base text-gray-900">•</span
      ><span class="text-base text-gray-900">{{
        loading ? "..." : length || (contributors && contributors.length)
      }}</span>

      <slot name="header" />
    </div>
    <display-more-list
      :button-title="$filters.i18n('View more contributors')"
      :entries="orderedContributors"
      :limit="limit"
      :class="scrollable ? 'flex-grow overflow-x-hidden overflow-y-auto' : ''"
      @scroll="$emit('scroll', $event)"
    >
      <template #default="{ entry }">
        <light-contributor-card
          :name="entry.name"
          :related-contributions-count="entry.relatedContributions.count"
          :link-strength="
            entry.relatedContributions.averageStrength != null &&
            entry.relatedContributions.averageStrength &&
            typeof entry.relatedContributions.averageStrength === 'number'
              ? entry.relatedContributions.averageStrength
              : null
          "
          :datas="entry"
          :contribution-hint="customContributionHint"
          v-if="lightCards"
          @click="passDetailsToModal(entry)"
        />

        <small-contributor-card
          :name="entry.name"
          :related-contributions-count="entry.relatedContributions.count"
          :link-strength="
            entry.relatedContributions.averageStrength != null &&
            entry.relatedContributions.averageStrength &&
            typeof entry.relatedContributions.averageStrength === 'number'
              ? entry.relatedContributions.averageStrength
              : null
          "
          :datas="entry.datas"
          :contribution-hint="customContributionHint"
          :small="smallCards"
          v-else
          @click="passDetailsToModal(entry)"
        />
      </template>
    </display-more-list>
  </section>
</template>

<script>
import DisplayMoreList from "@/components/Lists/DisplayMoreList";
import SmallContributorCard from "@/components/Contributors/SmallContributorCard";
import _ from "lodash";
import LightContributorCard from "@/components/Contributors/LightContributorCard";
import { useContributorsModal } from "@/logic/use-modals";
export default {
  name: "SmallContributorsList",
  components: { LightContributorCard, SmallContributorCard, DisplayMoreList },
  props: {
    contributors: Array,
    additionalHeaderClass: { type: String, default: "" },
    modalsInstanceId: {
      type: String,
    },
    limit: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "Related contributors",
    },
    darkened: {
      type: Boolean,
      default: true,
    },
    smallPadding: Boolean,
    customContributionHint: String,
    scrollable: Boolean,
    smallCards: Boolean,
    length: Number,
    lightCards: Boolean,
    loading: Boolean,
  },
  setup(props) {
    let passDetailsToModal = () => {};
    if (props.modalsInstanceId != null)
      passDetailsToModal = useContributorsModal(props.modalsInstanceId)
        .passDetailsToModal;

    return { passDetailsToModal };
  },
  computed: {
    orderedContributors() {
      return _.orderBy(
        this.contributors,
        "relatedContributions.averageStrength",
        "desc"
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
