<template>
  <div>
    <span class="block mb-1 font-medium text-gray-500">{{ title }}</span>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "DetailsGridSubCol",
  props: {
    title: String,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
