<template>
  <transition
    enter-active-class="transition ease-out duration-100"
    leave-active-class="transition ease-in duration-75"
    enter-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div
      class="overflow-hidden absolute right-0 z-[10001] mt-2 w-56 bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-1"
      v-if="visible"
      v-click-away="closeDropdown"
    >
      <slot name="content" />
    </div>
  </transition>
</template>

<script>
export default {
  name: "Dropdown",
  metaInfo: {
    title: "",
  },
  props: {
    visible: Boolean,
  },
  mounted() {
    this.initialized = true;
  },
  data() {
    return {
      initialized: false,
    };
  },
  methods: {
    closeDropdown() {
      if (this.initialized) {
        console.log("Click outside");
        this.$emit("close-dropdown");
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
