import Shepherd from "shepherd.js";
import { ref, watch } from "vue";

const generateStep = (
  text,
  title = "Découverte du dashboard",
  element,
  i18n,
  tour,
  // eslint-disable-next-line no-unused-vars
  direction = "top",
  beforeShowPromise = null,
  buttons = null,
  additionalParameters = {}
) => {
  buttons = {
    buttons: buttons || [
      {
        text: i18n("Previous"),
        action: tour.back,
      },
      {
        text: i18n("Next"),
        action: tour.next,
      },
    ],
  };

  return {
    text: i18n(text),
    title: i18n(title),
    attachTo: {
      element: element,
      on: direction,
    },
    ...buttons,
    ...(beforeShowPromise && { beforeShowPromise }),
    ...additionalParameters,
  };
};

const getStepsForPage = (page, tour, i18n, additionalFunctions = {}) => {
  const stepsByPages = {
    Insight: [
      generateStep(
        "step_one_insight",
        "header_step_one",
        "#step-1-insight",
        i18n,
        tour,
        "top",
        () => {},
        [
          {
            text: i18n("btn_end_tutorial"),
            action() {
              // Dismiss the tour when the finish button is clicked. Do not show the tour on next page reload.
              storeTourCompletedForPageyInLS(page);
              return this.complete();
            },
          },

          {
            text: i18n("Next"),
            action: tour.next,
          },
        ]
      ),
      generateStep(
        "step_two_insight",
        "header_step_two",
        "#step-2-insight",
        i18n,
        tour
      ),
      generateStep(
        "step_three_insight",
        "header_step_three",
        "#step-3-insight",
        i18n,
        tour,
        "right",
        () => {
          return new Promise(function (resolve) {
            const toggleFirstContributionModal =
              additionalFunctions["toggleFirstContributionModal"];
            toggleFirstContributionModal(false).then(() => resolve());
          });
        },
        null
      ),
      generateStep(
        "step_four_insight",
        "header_step_four",
        "#step-4-insight",
        i18n,
        tour,
        "bottom",
        () => {
          return new Promise(function (resolve) {
            const toggleFirstContributionModal =
              additionalFunctions["toggleFirstContributionModal"];
            toggleFirstContributionModal(true).then(() => resolve());
          });
        },
        null
      ),
      generateStep(
        "step_five_insight",
        "header_step_five",
        "#step-5-insight",
        i18n,
        tour,
        "right",
        () => {
          return new Promise(function (resolve) {
            const toggleFirstContributionModal =
              additionalFunctions["toggleFirstContributionModal"];
            toggleFirstContributionModal(true).then(() => resolve());
          });
        },
        null
      ),
      {
        text: i18n("step_six_insight"),
        title: i18n("header_step_six"),
        attachTo: {
          element: "#step-6-insight",
          on: "right",
        },
        buttons: [
          {
            text: i18n("Previous"),
            action: tour.back,
          },
          {
            text: i18n("Next"),
            action: tour.next,
          },
        ],
        beforeShowPromise: () => {
          console.log("Step 6 promise");
          return new Promise(function (resolve) {
            const toggleFirstContributionModal =
              additionalFunctions["toggleFirstContributionModal"];
            toggleFirstContributionModal(false).then(() => resolve());
          });
        },
      },

      generateStep(
        "step_seven_insight",
        "header_step_seven",
        "#step-7-insight",
        i18n,
        tour,
        "left"
      ),
      generateStep(
        "step_height_insight",
        "header_step_eight",
        "#step-8-insight",
        i18n,
        tour
      ),
      generateStep(
        "step_nine_insight",
        "header_step_nine",
        "#dashboard_exploration_switch",
        i18n,
        tour,
        "left"
      ),
    ],
    InsightDashboard: [
      generateStep(
        "step_one_dashboard",
        "header_step_ten",
        "#step-1-dashboard",
        i18n,
        tour,
        "top",
        () => {},
        [
          {
            text: i18n("Next"),
            action: tour.next,
          },
        ]
      ),
      generateStep(
        "step_two_dashboard",
        "header_step_eleven",
        "#step-2-dashboard",
        i18n,
        tour
      ),

      generateStep(
        "step_three_dashboard",
        "header_step_twelve",
        "#dashboard_exploration_switch",
        i18n,
        tour,
        "bottom",
        () => {},
        [
          {
            text: i18n("end_of_stages"),
            action() {
              // Dismiss the tour when the finish button is clicked. Do not show the tour on next page reload.
              storeTourCompletedForPageyInLS(page);
              return this.complete();
            },
          },
        ]
      ),
    ],
  };

  return stepsByPages[page];
};

export const setupTour = (
  i18n,
  page,
  additionalFunctions = {},
  tourVisibilityRef = ref(false)
) => {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
      modalOverlayOpeningPadding: 15,
      modalOverlayOpeningRadius: 10,
    },
    useModalOverlay: true,
  });

  const steps = getStepsForPage(page, tour, i18n, additionalFunctions);

  if (steps) {
    tour.addSteps(steps);
  }

  tour.on("show", () => (tourVisibilityRef.value = true));
  tour.on("cancel", () => {
    tourVisibilityRef.value = false;
    storeTourCompletedForPageyInLS(page);
  });
  tour.on("complete", () => {
    tourVisibilityRef.value = false;
    storeTourCompletedForPageyInLS(page);
  });

  watch(tourVisibilityRef, (visibility) => {
    if (visibility) {
      tour.start();
    } else {
      if (tour.isActive()) tour.cancel();
    }
  });

  if (tourVisibilityRef.value) {
    tour.start();
  }

  return tour;
};

const storeTourCompletedForPageyInLS = (page) => {
  const key = `shepherd-tour-complete-${page}`;
  if (!localStorage.getItem(key)) {
    localStorage.setItem(key, true);
  }
};

// eslint-disable-next-line
export const isTourCompletedForPageInLS = (page) => {
  const key = `shepherd-tour-complete-${page}`;
  return localStorage.getItem(key);
};
