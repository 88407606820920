<template>
  <Menu as="div" class="text-left">
    <MenuItems
      static
      class="flex overflow-hidden relative z-[1000] flex-col mt-2 w-56 max-h-[300px] bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none"
      as="div"
    >
      <div class="py-3 px-4 bg-white border-b border-gray-100">
        <div class="flex justify-between items-center w-full">
          <slot name="header">
            <p class="text-sm">Title</p>
            <p class="text-sm font-medium text-gray-900 truncate">Subtitle</p>
          </slot>
          <button
            class="p-1 rounded-full interactive button button-primary-dark"
            @click="$emit('close')"
          >
            <XIcon class="w-3 h-3" />
          </button>
        </div>
      </div>
      <div class="overflow-y-auto flex-1 py-1 h-0">
        <MenuItem v-for="entry in entries" :key="entry.key" as="div">
          <button
            :class="[
              'hover:bg-gray-100 hover:text-gray-900 text-gray-700',
              'block px-4 py-2 text-sm w-full text-left',
              entry.active ? '!bg-primary-dark !text-white' : '',
            ]"
            @click="$emit('entry-clicked', entry.key)"
          >
            {{ entry.title }}
          </button>
        </MenuItem>
      </div>
    </MenuItems>
  </Menu>
</template>

<script>
import { Menu, MenuItem, MenuItems } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
  name: "DropdownList",
  emits: ["entry-clicked", "close"],
  components: {
    Menu,
    MenuItem,
    MenuItems,
    XIcon,
  },
  props: {
    entries: Array,
  },
};
</script>
