<template>
  <!--  <TransitionRoot as="div" :show="visible" appear>-->
  <Dialog
    as="div"
    static
    class="overflow-hidden absolute inset-0 z-50"
    @close="disableCloseOnOutsideClick ? null : close()"
    :open="visible"
  >
    <div
      class="flex justify-center items-center px-4 pt-4 pb-20 min-h-screen sm:p-0"
    >
      <!--      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >-->
      <DialogOverlay
        class="fixed inset-0 bg-black bg-opacity-30 transition-opacity cursor-pointer"
        v-if="!disableCloseOnOutsideClick"
      />

      <div
        v-else
        class="fixed inset-0 bg-black bg-opacity-30 transition-opacity pointer-events-none"
      ></div>
      <!--      </TransitionChild>-->

      <!--      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 translate-y-0 sm:scale-100"
        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >-->
      <section
        class="flex overflow-hidden absolute inset-1 z-[10000] flex-col max-h-screen bg-white shadow sm:rounded-lg md:inset-auto md:max-w-[90vw] md:max-h-[95vh] lg:min-w-[50vw] 2xl:max-w-screen-lg"
      >
        <slot name="content"></slot>
      </section>
      <!--      </TransitionChild>-->
    </div>
  </Dialog>
  <!--  </TransitionRoot>-->
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  /*  TransitionChild,
  TransitionRoot,*/
} from "@headlessui/vue";

export default {
  name: "Modal",
  props: {
    visible: Boolean,
    disableCloseOnOutsideClick: Boolean,
  },
  components: {
    Dialog,
    DialogOverlay,
    /*    TransitionChild,
    TransitionRoot,*/
  },
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
