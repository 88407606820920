import * as simplifiedQueries from "../graphql/simplifiedQueries";
import * as simplifiedSubscriptions from "../graphql/simplifiedSubscriptions";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { inject, ref, watch, computed, unref } from "vue";
import _ from "lodash";
import { useStore } from "vuex";
import gql from "graphql-tag";
import {
  deleteClusters,
  deleteSession,
  mergeClusters,
  renameClusterMutation,
} from "@/graphql/simplifiedMutations";
import { updateIdeaClusterMutation } from "../graphql/simplifiedMutations";
export const useApiFetch = {
  getBatch: (id) => {
    const loadedBatch = ref(false);
    let nextToken = ref(null);
    const authorsCollection = ref([]);

    let queryVars = {};

    try {
      queryVars = useQuery(simplifiedQueries.getSessionPaginatingItems, {
        id: id,
        nextToken: null,
      });
    } catch (e) {
      console.error(e);
    }

    const { result, loading, error, onResult } = queryVars;

    onResult((queryResult) => {
      if (queryResult?.data?.getSession?.authors?.nextToken != null) {
        nextToken.value = queryResult?.data?.getSession?.authors?.nextToken;
      }
    });

    const paginationQueryParameters = computed(() => ({
      enabled: nextToken.value != null,
    }));

    const { onResult: onPaginatedResult } = useQuery(
      simplifiedQueries.getSessionPaginatingItemsAuthors,
      {
        id: id,
        nextToken: nextToken,
      },
      paginationQueryParameters
    );

    onPaginatedResult((queryResult) => {
      authorsCollection.value = [
        ...authorsCollection.value,
        ...(queryResult?.data?.getSession?.authors?.items || []),
      ];

      if (queryResult?.data?.getSession?.authors?.nextToken != null) {
        nextToken.value = queryResult?.data?.getSession?.authors?.nextToken;
      } else {
        nextToken.value = null;
      }
    });

    const getSessionWithoutPaginatedAuthors = useResult(
      result,
      null,
      (data) => ({
        ...data.getSession,
        ...(nextToken.value == null && {
          authors: {
            ...data.getSession.authors,
            items: _.uniqBy(data.getSession.authors.items, "id"),
          },
        }),
      })
    );

    const getSession = computed(() => {
      if (getSessionWithoutPaginatedAuthors.value != null) {
        return {
          ...getSessionWithoutPaginatedAuthors.value,
          authors: {
            ...(getSessionWithoutPaginatedAuthors?.value?.authors || {}),
            items: _.uniqBy(
              [
                ...getSessionWithoutPaginatedAuthors?.value?.authors?.items,
                ...authorsCollection.value,
              ],
              "id"
            ),
          },
        };
      }

      return {};
    });

    const clusters = useResult(
      result,
      [],
      (data) => data.getSession.clusters.items
    );

    const contributors = computed(
      () => (loadedBatch.value && getSession.value?.authors?.items) || []
    );

    watch(
      [loading, nextToken],
      () => {
        if (!loading.value && nextToken.value == null) {
          loadedBatch.value = true;
        }
      },
      { immediate: true }
    );

    return {
      nextToken,
      clusters,
      contributors,
      getSession,
      loading,
      error,
      loadedBatch,
    };
  },
  getBatches: () => {
    const store = useStore();

    const owner = store.state.user && store.state.user.username;
    const { result, loading, error, refetch } = useQuery(
      gql(`query GetBatches($owner: String!) {
                    listSessions(filter: {owner: {eq: $owner}}) {
                        items {
                          title
                          createdAt
                          id
                        }
                      }
                    }`),
      {
        owner: owner,
      }
    );

    const batches = useResult(result, null, (data) =>
      data.listSessions?.items.map((b) => {
        return {
          title: b.title,
          createdAt: new Date(b.createdAt),
          id: b.id,
        };
      })
    );

    return { batches, loading, error, refetch };
  },
  pollSessionAnalysedState: (id, pollingEnabledRef) => {
    const { result, error, loading } = useQuery(
      gql(`query GetSession($id: ID!) {
                    getSession(id: $id) {
                      id
                      analysed
                    }
                  }`),
      {
        id: id,
        page: 0,
        pageSize: 1,
      },
      () => ({
        pollInterval: 5000,
        enabled: pollingEnabledRef,
      })
    );

    return {
      result:
        useResult(result, null, (data) => data.getSession?.analysed) || false,
      sessionAnalysedStateError: error,
      sessionAnalysedStateLoading: loading,
    };
  },
};

export function useWatchNewBatch(callback) {
  const appsyncClient = inject("appsyncClient");
  const store = useStore();

  const owner = store.state.user && store.state.user.username;
  appsyncClient
    .hydrated()
    .then((appsyncClient) => {
      console.log("appsync client hydrated");
      // Now subscribe to results
      const newBatchSubscriptionObserver = appsyncClient
        .subscribe({
          query: simplifiedSubscriptions.onCreateSession,
          variables: { owner },
        })
        .subscribe({
          next: (d) => callback(d, newBatchSubscriptionObserver),
          complete: console.log,
          error: console.log,
        });
    })
    .catch(console.error);
}

export function useDeleteBatch(batchId) {
  const { mutate: deleteBatch, onDone, onError, loading } = useMutation(
    deleteSession,
    {
      fetchPolicy: "no-cache",
      variables: { input: { id: batchId } },
    }
  );

  return { deleteBatch, onDone, onError, loading };
}

export function useDeleteClusters(clusterIds) {
  const {
    mutate: deleteMultipleClusters,
    onDone,
    onError,
    loading,
  } = useMutation(deleteClusters, {
    fetchPolicy: "no-cache",
    variables: { ids: unref(clusterIds) },
  });

  return { deleteMultipleClusters, onDone, onError, loading };
}

export function useRenameCluster(clusterId) {
  const newTitle = ref("NDef");

  const { mutate: renameCluster, onDone, onError, loading } = useMutation(
    renameClusterMutation,
    () => ({
      fetchPolicy: "no-cache",
      variables: {
        id: clusterId,
        title: newTitle.value,
      },
    })
  );

  return { renameCluster, onDone, onError, loading, newTitle };
}

export function useMergeClusters() {
  const newTitle = ref("NDef");
  const clusterIds = ref([]);

  const {
    mutate: mergeMultipleClusters,
    onDone,
    onError,
    loading,
  } = useMutation(mergeClusters, () => ({
    fetchPolicy: "no-cache",
    variables: { ids: clusterIds.value, newTitle: newTitle.value },
  }));

  return {
    mergeMultipleClusters,
    onDone,
    onError,
    loading,
    newTitle,
    clusterIds,
  };
}

export function useUpdateContributionCluster() {
  const clusterID = ref(null);
  const contributionID = ref(null);

  const {
    mutate: updateContributionCluster,
    onDone,
    onError,
    loading,
  } = useMutation(updateIdeaClusterMutation, () => {
    if (contributionID.value == null) {
      throw "No contribution ID defined";
    }

    return {
      fetchPolicy: "no-cache",
      variables: {
        contributionId: contributionID.value,
        clusterId: clusterID.value,
      },
    };
  });

  return {
    updateContributionCluster,
    onDone,
    onError,
    loading,
    contributionID,
    clusterID,
  };
}
