<template>
  <main
    class="flex flex-col space-y-4 w-full md:flex-row md:space-y-0 md:space-x-6"
  >
    <div class="md:flex-1" v-if="multipleSheets || fromCsv">
      <stacked-radio-cards
        v-if="multipleSheets"
        :modelValue="pickedSheet"
        @update:modelValue="$emit('update:pickedSheet', $event)"
        :options="sheetsTitles"
      >
        <h3 class="block font-medium text-gray-700">
          {{ $filters.i18n("Data sheet to use") }}
        </h3>
      </stacked-radio-cards>

      <text-input
        :modelValue="csvSeparator"
        @update:modelValue="$emit('update:csvSeparator', $event)"
        v-else-if="fromCsv"
        >{{
          $filters.i18n(
            "If necessary, you can edit the column delimiter of your CSV"
          )
        }}</text-input
      >
    </div>
    <div
      :class="[
        multipleSheets || fromCsv ? 'md:2/3 lg:w-3/4' : 'w-full',
        'space-y-5',
      ]"
    >
      <h3 class="block font-medium text-gray-700">
        {{
          $filters.i18n(
            "Pick the document columns with the data to use by Phedone"
          )
        }}*
      </h3>

      <rows-pickers
        v-if="pickedSheet && json[pickedSheet]"
        :entries="pickedSheet && json[pickedSheet]"
        class="mt-4"
        v-bind="$attrs"
      />
    </div>
  </main>
</template>

<script>
import RowsPickers from "../SheetsComputing/RowsPickers";
import objects from "lodash/object";
import StackedRadioCards from "../DesignSystem/Radios/StackedRadioCards";
import TextInput from "../DesignSystem/Inputs/TextInput";

export default {
  name: "InsertingStep2",
  // eslint-disable-next-line vue/no-unused-components
  components: { TextInput, StackedRadioCards, RowsPickers },

  data() {
    return {
      rows: null,
    };
  },
  props: {
    json: { default: null },
    fileName: { type: String },
    csvSeparator: { type: String },
    pickedKeyByColumns: { type: Object },
    pickedSheet: { type: String },
  },
  methods: {
    updateProgression(progression) {
      this.$emit("step-updated", progression);
    },
    goBack() {
      this.$emit("step-updated", 1);
    },
  },
  mounted() {
    this.$emit("update:pickedSheet", this.sheetsTitles && this.sheetsTitles[0]);
  },
  watch: {
    sheetsTitles() {
      if (!this.pickedSheet) {
        this.$emit(
          "update:pickedSheet",
          this.sheetsTitles && this.sheetsTitles[0]
        );
      }
    },
  },
  computed: {
    sheetsTitles() {
      return this.json && Object.keys(this.json);
    },
    multipleSheets() {
      return this.sheetsTitles && this.sheetsTitles.length > 1;
    },
    fromCsv() {
      return (
        this.sheetsTitles &&
        this.sheetsTitles[0] &&
        this.sheetsTitles[0] === "fromCsv"
      );
    },
    keys() {
      if (this.json && this.json[0]) {
        return objects.keys(this.json[0]).map((key) => key.trim());
      }

      return [];
    },
  },
};
</script>

<style scoped lang="scss"></style>
