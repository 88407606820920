<template>
  <main class="flex flex-col space-y-4 w-full">
    <h3 class="block font-medium text-gray-700">
      {{
        $filters.i18n(
          "The following data will be securely sent to Phedone's servers, after being encrypted in your browser"
        )
      }}<template v-if="encryptionKey"
        >,
        {{
          $filters.i18n(
            "the results will only be decipherable with the subsequent private key : "
          )
        }}
        <b>{{
          encryptionKey.substr(encryptionKey.lastIndexOf("Key Title : ") + 12)
        }}</b></template
      ><template v-else
        >.<b class="block text-primary-dark">{{
          $filters.i18n(
            "As you didn't pick an encryption key, the results won't be encrypted !"
          )
        }}</b></template
      >
    </h3>
    <table-view
      :entries="jsonWithAdditionalColumsAsColumns"
      class="w-full"
      :columns-titles="columnsTitles"
    />
  </main>
</template>

<script>
import TableView from "../Lists/TableView";

export default {
  name: "InsertingStep3",
  components: { TableView },
  data() {
    return {
      hasDates: false,
      hasDescriptions: false,
    };
  },
  props: {
    json: { type: Array },
    encryptionKey: { type: String },
    additionalColumsTitles: { type: Array },
  },
  computed: {
    jsonWithAdditionalColumsAsColumns() {
      return this.json.map((r) => {
        const keys = Object.keys(r);
        if (keys.includes("date")) {
          this.hasDates = true;
        }

        if (keys.includes("description")) {
          this.hasDescriptions = true;
        }

        // eslint-disable-next-line no-unused-vars
        const { ["additionalColumns"]: remove, ...rest } = r;

        let parsedAdditionalColumns = [];

        try {
          // eslint-disable-next-line no-unused-vars
          const { ["phedone_columnsBindings"]: remove, ...additionalColumns } =
            JSON.parse(r.additionalColumns) || {};
          parsedAdditionalColumns = additionalColumns;
        } catch (e) {
          console.error(e);
        }

        return { ...rest, ...parsedAdditionalColumns };
      });
    },
    columnsTitles() {
      let titles = [
        this.$filters.i18n("Title"),
        this.$filters.i18n("Contributor"),
      ];

      if (this.hasDescriptions) titles.push(this.$filters.i18n("Description"));

      if (this.hasDates) titles.push(this.$filters.i18n("Date"));

      return [...titles, ...this.additionalColumsTitles];
    },
  },
};
</script>
