<template>
  <div>
    <HistogramSlider
      :key="`filterableHistogram_${$store.state.locale}_${resetKey}`"
      style="margin: 200px auto"
      width="100%"
      :bar-height="100"
      :data="sortedEntries"
      :prettify="prettifyDate"
      :drag-interval="true"
      :force-edges="false"
      :label-color="colors.sky['500']"
      :primary-color="colors.sky['500']"
      :colors="[colors.sky['500'], colors.sky['400']]"
      :min="firstEntry.valueOf()"
      :max="lastEntry.valueOf()"
      resettable
    >
      <button
        class="absolute top-3 right-3 interactive button button-small button-soft-visible"
        @click="resetKey = resetKey + 1"
      >
        {{ $filters.i18n("filterable_histogram_reset") }}
      </button>
    </HistogramSlider>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { last } from "lodash";
import { computed, ref } from "vue";
import HistogramSlider from "@/components/Graphs/Custom/HistogramSlider";

const { useTailwindColors } = require("@/logic/use-tailwind-colors");
export default {
  name: "FilterableHistogram",
  components: { HistogramSlider },
  props: {
    entries: Array,
  },
  setup(props) {
    const colors = useTailwindColors.asObject;
    const resetKey = ref(1);
    const store = useStore();

    const sortedEntries = computed(() =>
      [...props.entries].sort((a, b) => a - b)
    );

    const prettifyDate = function (timestamp) {
      return new Date(timestamp).toLocaleDateString(store.state.locale, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    };

    const firstEntry = computed(() => sortedEntries.value[0]);
    const lastEntry = computed(() => last(sortedEntries.value));

    return {
      prettifyDate,
      colors,
      sortedEntries,
      firstEntry,
      lastEntry,
      resetKey,
    };
  },
};
</script>

<style scoped lang="scss"></style>
