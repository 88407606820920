<template>
  <div class="block p-6 hover:bg-gray-50" :class="small ? 'py-3' : ''">
    <div class="flex flex-wrap items-center md:space-x-4">
      <div class="flex-1 min-w-full md:min-w-0">
        <p class="text-sm font-medium text-gray-900 truncate">
          {{ name }}
        </p>
        <div class="flex">
          <p class="text-sm text-gray-500 truncate">
            {{ relatedContributionsCount
            }}{{
              " " + (contributionHint || $filters.i18n("related contributions"))
            }}
          </p>
          <link-strength-badge
            v-if="linkStrength && typeof linkStrength === 'number'"
            :strength="linkStrength"
          />
        </div>
      </div>
      <div
        class="flex mt-0"
        :class="small ? 'flex-col space-y-2 space-y-0' : 'flex-row space-x-2'"
      >
        <button
          class="button button-small"
          :class="selected ? `button-primary` : `button-white`"
          @click="selected = !selected"
          v-if="
            false // TODO set it available when ready
          "
        >
          {{ $filters.i18n(selected ? "Unselect" : "Select") }}
        </button>
        <button
          @click="$emit('click')"
          class="button button-small button-white"
        >
          {{ $filters.i18n("View") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LinkStrengthBadge from "@/components/DesignSystem/Badges/LinkStrenthBadge";

export default {
  name: "SmallContributorCard",
  components: { LinkStrengthBadge },
  emits: ["click"],
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    linkStrengthText() {
      if (this.linkStrength >= 0.67) {
        return "Strong link";
      }

      if (this.linkStrength >= 0.33) {
        return "Medium link";
      }

      return "Weak link";
    },
    linkStrengthColor() {
      if (this.linkStrength >= 0.67) {
        return "green";
      }

      if (this.linkStrength >= 0.33) {
        return "cyan";
      }

      return "red";
    },
  },
  props: {
    name: String,
    datas: Object,
    relatedContributionsCount: Number,
    linkStrength: Number,
    contributionHint: String,
    small: Boolean,
  },
};
</script>

<style scoped lang="scss"></style>
