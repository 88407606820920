<template>
  <div class="flex items-center space-x-3 text-sm">
    <b><slot /></b>
    <span class="flex relative z-0 rounded-md shadow-sm">
      <button
        type="button"
        v-for="(entry, i) in entries"
        :key="entry.title"
        :class="[
          'interactive button button-white focus:z-10 !text-sm !py-2 !px-3',
          i > 0 ? '-ml-px rounded-l-none' : '',
          i === entries.length - 1
            ? 'rounded-r-md rounded-l-none'
            : '!rounded-r-none',
          entry.key === modelValue.key
            ? '!bg-primary text-white !border-primary-dark hover:!opacity-[95%]'
            : '',
        ]"
        @click="$emit('update:modelValue', entry)"
      >
        {{ entry.title }}
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: "HorizontalRadioGroup",
  props: {
    entries: { type: Array, default: () => [] },
    modelValue: { type: Object },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
