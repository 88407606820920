<template>
  <div class="inline-block relative text-left" v-if="user">
    <button
      type="button"
      class="flex text-sm bg-white rounded-full focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:outline-none"
      id="menu-1"
      aria-expanded="false"
      aria-haspopup="true"
      @click="userDropdownVisible = true"
    >
      <span class="sr-only">{{ $filters.i18n("Open user menu") }}</span>
      <user-avatar :user-name="userIdentify" />
    </button>
    <user-dropdown
      :visible="userDropdownVisible"
      @close-dropdown="closeDropdown()"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserAvatar from "@/components/User/UserAvatar";
import UserDropdown from "@/components/Header/UserDropdown";

export default {
  name: "UserPopover",
  components: { UserDropdown, UserAvatar },
  data() {
    return {
      userDropdownVisible: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    userIdentify() {
      if (this.user) {
        return this.user.attributes.name
          ? this.user.attributes.name
          : this.user.attributes.email;
      }
      return "Phedone User";
    },
  },
  methods: {
    closeDropdown() {
      this.userDropdownVisible = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
