<template>
  <div class="flex overflow-hidden carded">
    <div id="step-7-insight" class="block">
      <div class="h-full border-r border-gray-200">
        <nav
          class="flex flex-col -mb-px h-full divide-y divide-gray-200"
          aria-label="Tabs"
        >
          <button
            aria-current="page"
            v-for="(tab, i) in tabs"
            :key="tab.url"
            class="group flex overflow-hidden relative focus:z-10 flex-1 justify-center items-center py-3 px-4 min-w-0 text-sm font-medium text-center outline-none focus:outline-none"
            :class="
              tab.active
                ? 'bg-primary bg-opacity-10 text-primary'
                : 'bg-white text-gray-500 hover:text-gray-700 hover:bg-primary hover:bg-opacity-5 focus:bg-primary focus:bg-opacity-10'
            "
            @click="$emit('update:activeTab', i)"
          >
            <span class="sr-only">{{ tab.title }}</span>
            <svg v-html="tab.icon" class="w-7 h-7" fill="currentColor" />
            <span
              aria-hidden="true"
              class="absolute inset-y-0 right-0 w-0.5"
              :class="tab.active ? 'bg-primary' : 'bg-transparent'"
            ></span>
          </button>
        </nav>
      </div>
    </div>
    <div class="relative flex-1">
      <slot name="tabsContent" />
    </div>
  </div>
</template>

<script>
export default {
  name: "VerticalTabs",
  props: {
    tabs: Array,
    activeTab: Number,
  },
};
</script>

<style scoped lang="scss"></style>
