<template>
  <div class="w-full max-w-full h-full max-h-full">
    <vue3-chart-js
      :data="stackedBarGraph.data"
      :type="stackedBarGraph.type"
      :plugins="stackedBarGraph.plugins"
      :options="stackedBarGraph.options"
      ref="chartRef"
      class="!w-full !max-w-full !h-full !max-h-full"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import _, { sumBy, uniqueId } from "lodash";
import { onMounted, ref, watch } from "vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTailwindColors } from "@/logic/use-tailwind-colors";

export default {
  name: "HorizontalStackedBarChart",
  data() {
    return {};
  },
  props: {
    dataSets: Array,
    labels: Array,
  },
  components: {
    Vue3ChartJs,
  },
  setup(props) {
    const chartRef = ref(`${uniqueId("horizontal_stacked_bar_chart")}`);

    const stackedBarGraph = {
      type: "bar",
      data: {
        labels: [],
        datasets: [],
      },
      plugins: [ChartDataLabels],
      options: {
        devicePixelRatio: 2,
        responsive: true,

        indexAxis: "y",
        plugins: {
          datalabels: {
            color: "white",
            textShadowColor: useTailwindColors.asObject.gray["700"],
            textShadowBlur: 1,
            display: function (context) {
              return context.dataset.data[context.dataIndex] > 5;
            },
            font: {
              weight: "bold",
            },
            formatter: (d, context) => {
              const entryInDataSet = (props.dataSets || [])[
                context.datasetIndex
              ].entries[context.dataIndex];

              return (entryInDataSet && entryInDataSet.absoluteValue) || d;
            },
          },
          legend: {
            labels: {
              boxWidth: 20,
              padding: 10,
              font: {
                size: 12,
                lineHeight: 1.2,
                family:
                  'Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: true,
              autoSkip: false,
            },
          },
        },
        layout: {
          padding: 15,
        },
      },
    };

    onMounted(() => {
      watch(
        props,
        () => {
          const dataSets = props.dataSets || [];

          stackedBarGraph.data.labels = props.labels || [];

          const dataSetEntries = dataSets.map((d) => d.entries);

          const barIndexes = _.chain(dataSetEntries)
            .map((category) => Object.keys(category))
            .flattenDeep()
            .uniq()
            .value();

          // eslint-disable-next-line no-unused-vars
          const valuesByBarIndex = barIndexes.map((barIndex) => {
            let entries = [];

            for (const columnData of dataSetEntries) {
              entries.push(columnData[barIndex]);
            }

            return entries;
          });

          stackedBarGraph.data.datasets = dataSets.map((dataset) => ({
            axis: "y",
            label: dataset.title,
            data: dataset.entries.map((c) => c.value),
            backgroundColor: dataset.color,
            hoverBackgroundColor: dataset.color,
            borderColor: "white",
            borderWidth: 1,
            borderSkipped: (context) => {
              const column = context.dataIndex;
              const row = context.datasetIndex;

              const barValues = valuesByBarIndex[column];

              const i = row;

              const previousEntriesInSameBar = barValues.filter(
                (d, sentimentIndex) => sentimentIndex < i
              );
              const nextEntriesInSameBar = barValues.filter(
                (d, sentimentIndex) => sentimentIndex > i
              );

              const previousEntriesInSameBarAreNotEmpty =
                sumBy(
                  previousEntriesInSameBar &&
                    previousEntriesInSameBar.length > 0
                    ? previousEntriesInSameBar
                    : [{ value: 0 }],
                  "value"
                ) > 0;
              const nextEntriesInSameBarAreNotEmpty =
                sumBy(
                  nextEntriesInSameBar && nextEntriesInSameBar.length > 0
                    ? nextEntriesInSameBar
                    : [{ value: 0 }],
                  "value"
                ) > 0;

              let skipStart = previousEntriesInSameBarAreNotEmpty;
              let skipEnd = nextEntriesInSameBarAreNotEmpty;

              let skippingBorder = false;

              if (skipStart && skipEnd) {
                return true;
              }

              if (!(skipStart && skipEnd) && skipStart) {
                skippingBorder = "start";
              }

              if (!(skipStart && skipEnd) && skipEnd) {
                skippingBorder = "end";
              }

              return skippingBorder;
            },
            barThickness: 15,
            borderRadius: (context, options) =>
              options.borderSkipped !== true
                ? {
                    topLeft: Number.MAX_VALUE,
                    topRight: Number.MAX_VALUE,
                    bottomLeft: Number.MAX_VALUE,
                    bottomRight: Number.MAX_VALUE,
                  }
                : 0,
          }));
          chartRef.value.update(`${uniqueId("stacked_bar_chart")}`);
        },

        {
          immediate: true,
        }
      );
    });

    return { chartRef, stackedBarGraph };
  },
};
</script>

<style scoped lang="scss"></style>
