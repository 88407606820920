<template>
  <section class="flex flex-wrap items-center space-x-4">
    <div class="flex-1 min-w-0">
      <p class="text-sm font-medium text-gray-900 truncate">
        {{ name }}
      </p>
      <div class="flex">
        <p class="text-sm text-gray-500 truncate">
          {{ contributionsCount }} {{ $filters.i18n(" contributions") }}
        </p>
      </div>
    </div>
    <div>
      <button @click="$emit('click')" class="button button-small button-white">
        {{ $filters.i18n("View") }}
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "LargeContributorCard",
  data() {
    return {
      selected: false,
    };
  },
  props: {
    name: String,
    datas: Object,
    contributionsCount: Number,
  },
};
</script>

<style scoped lang="scss"></style>
