<template>
  <dl class="sm:divide-y sm:divide-gray-200">
    <slot name="rows"></slot>
  </dl>
</template>

<script>
export default {
  name: "DetailsGrid",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
