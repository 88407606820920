<template>
  <span class="inline-flex relative justify-center items-center w-full h-full">
    <span :class="loading ? 'opacity-0' : 'opacity-100'"><slot /></span>
    <svg
      class="flex absolute inset-0 z-30 justify-center items-center m-auto w-[1.1rem] h-[1.1rem] animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      :class="[
        loading ? 'opacity-100' : 'opacity-0',
        white ? 'text-white' : 'text-current',
      ]"
    >
      <circle
        class="opacity-50"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-80"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </span>
</template>

<script>
export default {
  name: "TogglableLoadingState",
  props: {
    loading: Boolean,
    white: { type: Boolean, default: true },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
