<template>
  <page-body>
    <template #preBody> </template>
    <template #mainBody>
      <div class="flex relative flex-col mx-6 mb-6 space-y-5 carded">
        <section class="flex flex-col p-4 space-y-5 max-h-full sm:p-6">
          <header class="flex sticky z-20 flex-col space-y-5 bg-white">
            <div class="flex justify-between items-center w-full">
              <h2 class="text-2xl font-medium leading-6 text-black">
                {{ $filters.i18n("yourAnalysis_hp_title") }}
              </h2>
              <router-link to="/onboarding" class="button button-soft-visible">
                <PlusIcon class="mr-2 -ml-1 w-4 h-4" />
                {{ $filters.i18n("new_feminine") }}
              </router-link>
            </div>

            <div class="relative">
              <search-icon
                id="step-2"
                class="absolute top-1/2 left-3 w-5 h-5 text-gray-400 transform -translate-y-1/2"
              />
              <sort-and-filter-input
                name="batchesFilter"
                :label="$filters.i18n('yourAnalysis_hp_searchHint')"
                v-model:searchTerms="searchTerms"
                v-model:sortOrder="sortOrder"
                :sort-options="sortOptions"
                large-select
                @click="showFilters = true"
              >
                <template #icon>
                  <SearchIcon class="w-5 h-5 text-gray-400" />
                </template>
              </sort-and-filter-input>
            </div>
          </header>

          <ul
            class="grid flex-1 grid-cols-1 gap-5 h-0 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
            :key="orderedBatches && orderedBatches.length"
          >
            <li id="step-1" class="flex h-full min-h-[100px] rounded-lg">
              <router-link
                to="/onboarding"
                class="flex justify-center items-center py-4 w-full text-lg font-medium text-primary-dark hover:text-primary-dark text-opacity-80 bg-primary hover:bg-primary hover:bg-opacity-10 rounded-lg border border-primary-dark hover:border-transparent border-opacity-20 shadow-none hover:shadow-lg transition duration-300 bg-opacity-[2%]"
              >
                {{ $filters.i18n("createNewBatch") }}
              </router-link>
            </li>
            <li
              v-for="batch in orderedBatches"
              :key="`${batch.id}_${batch.createdAt}`"
              class="block relative h-full"
            >
              <div
                class="group block relative p-6 h-full hover:bg-primary rounded-lg border border-gray-200 hover:border-transparent hover:shadow-lg cursor-pointer interactive"
              >
                <insight-delete-modal
                  :session-id="batch.id"
                  @on-done="waitAndRefetch"
                  :ref="`modal_${batch.id}`"
                >
                  <template #default="{ clickCallback, loading }">
                    <button
                      type="button"
                      class="hidden group-hover:inline-flex absolute right-2 bottom-2 z-20 items-center p-1.5 text-white bg-white rounded-lg border border-primary shadow-sm opacity-80 focus:opacity-100 focus:outline-none hover:opacity-[95%]"
                      @click="clickCallback"
                      :disabled="loading"
                    >
                      <TrashIcon
                        class="w-5 h-5 text-primary-dark"
                        aria-hidden="true"
                      />
                    </button>
                  </template>
                </insight-delete-modal>
                <router-link
                  :to="`/insights/${batch.id}`"
                  class="absolute inset-0 z-10"
                />
                <dl
                  class="grid grid-cols-2 grid-rows-2 sm:block lg:block lg:space-y-0.5"
                >
                  <div>
                    <dt class="sr-only">Title</dt>
                    <dd
                      class="text-lg font-medium leading-6 text-black group-hover:text-white truncate"
                    >
                      {{ batch.title }}
                    </dd>
                  </div>
                  <div>
                    <dt class="sr-only">Date of creation</dt>
                    <dd class="text-sm font-light group-hover:text-white">
                      <b class="text-primary group-hover:text-white">{{
                        dateToFormatRelative(batch.createdAt)
                      }}</b>
                    </dd>
                  </div>
                </dl>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </template>
  </page-body>
</template>

<script>
import PageBody from "@/components/Layout/PageBody";
import { useApiFetch } from "@/logic/use-api-fetch";
import { capitalize } from "lodash";
import { computed, ref, watchEffect } from "vue";
import InsightDeleteModal from "@/components/Insights/InsightDeleteModal";

import { TrashIcon, SearchIcon, PlusIcon } from "@heroicons/vue/outline";
import { useFuseSearch, useSetupFuseSearch } from "@/logic/use-fuse-search";
import { useOrderList } from "@/logic/use-order-list";
import SortAndFilterInput from "@/components/DesignSystem/Inputs/SortAndFilterInput";
import { formatRelative } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { useStore } from "vuex";

export default {
  name: "Home",
  metaInfo() {
    return {
      title: this.$filters.i18n("Accueil"),
    };
  },
  setup() {
    const { getBatches } = useApiFetch;
    const store = useStore();

    let { batches, loading, error, refetch } = getBatches();

    const searchTerms = ref("");
    const searchableKeys = ["title"];

    const sortOrder = ref(1);
    const sortOptions = ref([
      "Creation Date, Asc.",
      "Creation Date, Desc.",
      "Title, A to Z",
      "Title, Z to A",
    ]);
    const orderableKeys = ["createdAt", "createdAt", "title", "title"];

    const fuse = computed(() =>
      useSetupFuseSearch(batches.value, searchableKeys)
    );

    const results = computed(() => {
      return useFuseSearch(fuse.value, searchTerms.value);
    });
    const orderedBatches = ref([]);
    watchEffect(() => {
      orderedBatches.value = useOrderList(
        searchTerms.value.length > 0
          ? results.value.map((r) => r.item)
          : batches.value,
        orderableKeys,
        sortOrder.value
      );
    });

    const waitAndRefetch = ({ close }) => {
      close();
      return setTimeout(refetch, 50);
    };

    const dateToFormatRelative = (date) => {
      try {
        const formatteDate =
          formatRelative(new Date(date), new Date(), {
            locale: store.state.locale === "en" ? enUS : fr,
          }) || "";

        return capitalize(formatteDate);
      } catch (e) {
        console.error(e);
      }
      return null;
    };

    return {
      orderedBatches,
      loading,
      error,
      waitAndRefetch,
      sortOrder,
      searchTerms,
      sortOptions,
      dateToFormatRelative,
    };
  },
  components: {
    SortAndFilterInput,
    InsightDeleteModal,
    PageBody,
    TrashIcon,
    SearchIcon,
    PlusIcon,
  },
};
</script>

<style scoped lang="scss"></style>
