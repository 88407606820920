<template>
  <main class="flex flex-col">
    <keywords-manager
      class="-mb-3"
      :modelValue="blackList"
      @update:modelValue="$emit('update:blackList', $event)"
    />
  </main>
</template>

<script>
import KeywordsManager from "@/components/keyword/KeywordsManager";
export default {
  name: "InsertingStepBlackList",
  components: { KeywordsManager },
  props: {
    blackList: Array,
  },
  metaInfo() {
    return {
      title: this.$filters.i18n("step_2.5"),
    };
  },
};
</script>

<style scoped lang="scss"></style>
