<template>
  <label class="block font-medium text-gray-700" :class="containerClass">
    <slot />

    <input
      :type="type"
      class="block py-4 mt-2 w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary shadow-sm sm:text-sm"
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: "TextInput",
  inheritAttrs: false,
  props: {
    modelValue: { type: String },
    containerClass: { type: String }, // This is related to "inheritAttrs: false", we want to pass every attrs to the input, as it's convenient from the parent, while being still able to update the container's class
    type: {
      type: String,
      default: "text",
    },
  },
};
</script>

<style scoped lang="scss"></style>
