<template>
  <div class="py-3 px-3 my-9 space-y-1 space-x-1 w-3/6 h-72 rounded-lg shadow">
    <span
      v-for="(keyword, i) in keywords"
      :key="i"
      class="inline-flex items-center py-0.5 pr-1 pl-2.5 text-sm font-medium text-primary-dark bg-primary-middle rounded-lg"
    >
      {{ keyword }}
      <button
        @click="removeKeywordFromList(keyword)"
        value="keyword"
        class="inline-flex flex-shrink-0 justify-center items-center ml-0.5 w-4 h-4 text-primary-dark hover:bg-primary-lighter rounded-full focus:outline-none"
      >
        <span class="sr-only"></span>
        <svg
          class="w-2 h-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path
            stroke-linecap="round"
            stroke-width="1.5"
            d="M1 1l6 6m0-6L1 7"
          />
        </svg>
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: "KeywordsList",
  props: { keywords: Array },

  methods: {
    removeKeywordFromList(keyword) {
      this.$emit(
        "update:keywords",
        this.keywords.filter((item) => item !== keyword)
      );
    },
  },
};
</script>

<style scoped></style>
