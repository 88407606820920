<template>
  <slide-panel
    :title="$filters.i18n(`Contributor's details`)"
    :visible="details != null"
    @close="close()"
    :key="ownModalInstanceId"
  >
    <template v-slot:header>
      <div class="border-b bordered" v-if="details">
        <div class="pb-5 sm:pb-6">
          <div class="px-4 sm:flex sm:items-end sm:px-6">
            <div class="sm:flex-1">
              <div>
                <div class="flex items-center">
                  <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">
                    {{ details.name }}
                  </h3>
                </div>
              </div>
              <div
                class="flex flex-wrap mt-4 space-y-3 sm:mt-5 sm:space-y-0 sm:space-x-3"
              >
                <togglable-state-button
                  :title="$filters.i18n('Add to selection')"
                  :toggle-title="$filters.i18n('Remove from selection')"
                  :toggled="selected"
                  color-class="button-primary"
                  class="flex-1"
                  v-if="
                    false // TODO set it available when ready
                  "
                >
                  <template v-slot:icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      v-if="!selected"
                      class="mr-2 -ml-1 w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                      />
                    </svg>
                  </template>
                </togglable-state-button>
                <add-to-favorites-button
                  :toggled="bookmarked"
                  @toggled="bookmarked = !bookmarked"
                  class="flex-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <small-contributions-list
        :limit="3"
        :contributions="contributions"
        v-if="contributions.length > 0"
        :modals-instance-id="ownModalInstanceId"
      />

      <small-contributors-list
        :limit="3"
        :contributors="formattedRelatedContributors"
        :title="$filters.i18n('Related contributors')"
        class="border-t border-b"
        v-if="formattedRelatedContributors.length > 0"
        :modals-instance-id="ownModalInstanceId"
      />

      <!--            <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
              <dl class="space-y-8 px-4 sm:px-6 sm:space-y-6">
                <div>
                  <dt
                    class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0"
                  >
                    Location
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                    New York, NY, USA
                  </dd>
                </div>
              </dl>
            </div>-->

      <contribution-modal
        :visible="contributionModal != null"
        :external-contributors="externalContributors"
        :external-contributions="externalContributions"
        :details="contributionModal"
        :modal-instance-id="ownModalInstanceId"
        v-if="contributionModal"
      />

      <contributor-slide-panel
        :external-contributors="externalContributors"
        :external-contributions="externalContributions"
        :external-clusters="externalClusters"
        :details="ContributorSlidePanel"
        :modal-instance-id="ownModalInstanceId"
        v-if="ContributorSlidePanel"
      />
    </template>
  </slide-panel>
</template>

<script>
import SmallContributionsList from "@/components/Contributions/SmallContributionsList";
import SmallContributorsList from "@/components/Contributors/SmallContributorsList";
import SlidePanel from "@/components/Layout/SidePanel";
import _ from "lodash";
import AddToFavoritesButton from "@/components/DesignSystem/Buttons/AddToFavoritesButton";
import TogglableStateButton from "@/components/DesignSystem/Buttons/TogglableStateButton";
import { computed } from "vue";
import { useInjectModals, useProvideModals } from "@/logic/use-modals";
import ContributionModal from "@/components/Contributions/ContributionModal";
export default {
  name: "ContributorSlidePanel",
  components: {
    ContributionModal,
    TogglableStateButton,
    AddToFavoritesButton,
    SlidePanel,
    SmallContributorsList,
    SmallContributionsList,
  },
  props: {
    externalContributions: Array,
    externalContributors: Array,
    externalClusters: Array,
    details: Object,
    modalInstanceId: String,
  },
  setup(props) {
    const ownModalInstanceId = computed(
      () => `contributor_${props.details?.id}`
    );

    const { contributionModal, ContributorSlidePanel } = useProvideModals(
      ownModalInstanceId.value
    );

    let { updateContributorSlidePanel: close } = useInjectModals(
      props.modalInstanceId,
      false,
      true,
      false
    );

    const contributions = computed(() => {
      if (!props.details) {
        return [];
      }

      return _.chain(props.externalContributions)
        .filter((contribution) => contribution.author?.id === props.details?.id)
        .map((contribution) => ({
          ..._.pick(contribution, ["id", "title", "relativeness"]),
          cluster: _.find(props.externalClusters, (cluster) => {
            return cluster?.id === contribution?.cluster?.id;
          }),
          author: contribution.author,
          description: contribution.description,
        }))
        .value();
    });
    const relatedContributions = computed(() => {
      let relatedContributions = [];
      for (const c of contributions.value) {
        let relativeContributions = JSON.parse(c.relativeness);
        for (const contribution of Object.values(relativeContributions || {})) {
          let contributionsCompletedInformations = _.find(
            props.externalContributions,
            (idea) => idea.id === contribution?.id
          );

          relatedContributions.push({
            ...contribution,
            ...contributionsCompletedInformations,
          });
        }
      }

      return relatedContributions;
    });

    const relatedContributorsWithContributions = computed(() => {
      return _.chain(relatedContributions.value)
        .filter((c) => c.score > 0.15 && c.author?.id != props.details?.id)
        .groupBy("author.id")
        .map((contributor) => {
          return {
            contributor: contributor[0] && contributor[0].author,
            contributions: contributor,
          };
        })
        .value();
    });
    const formattedRelatedContributors = computed(() => {
      return _.map(
        relatedContributorsWithContributions.value,
        (relatedContributorWithContributions) => ({
          id: relatedContributorWithContributions?.contributor?.id,
          name: relatedContributorWithContributions?.contributor?.name,
          datas: relatedContributorWithContributions?.contributor,
          relatedContributions: {
            count: relatedContributorWithContributions?.contributions?.length,
            averageStrength: _.meanBy(
              relatedContributorWithContributions?.contributions,
              "score"
            ),
          },
        })
      );
    });

    return {
      ownModalInstanceId,
      contributionModal,
      ContributorSlidePanel,
      close,
      contributions,
      relatedContributions,
      relatedContributorsWithContributions,
      formattedRelatedContributors,
    };
  },
  data() {
    return {
      bookmarked: false,
      selected: false,
    };
  },
};
</script>

<style scoped lang="scss"></style>
