<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto w-full">
      <div class="inline-block px-1 pb-1 w-full min-w-full align-middle">
        <div class="overflow-hidden rounded-lg border-b border-gray-200 shadow">
          <div class="overflow-x-auto w-full" v-if="headerRows">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    :class="[
                      colIndex === 0 ? 'pl-6' : '',
                      colIndex === headerRows.length - 1 ? 'pr-6' : '',
                      'px-3 py-3 pt-4 w-[220px] text-left',
                    ]"
                    v-for="(colTitle, colIndex) in headerRows"
                    :key="colIndex"
                  >
                    <span
                      class="text-xs font-medium tracking-wider text-gray-500 uppercase truncate"
                      >{{
                        columnsTitles
                          ? columnsTitles[colIndex]
                          : translateEntries
                          ? $filters.i18n(colTitle)
                          : colTitle
                      }}</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, rowId) in entries.slice(0, limit)"
                  :key="rowId"
                  :class="rowId % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    :class="[
                      i === 0 ? 'pl-6' : '',
                      i === Object.values(row).length - 1 ? 'pr-6' : '',
                      'px-3 py-4 whitespace-nowrap text-sm text-gray-500 truncate max-w-[80px]',
                    ]"
                    v-for="(val, i) in Object.values(row)"
                    :key="i"
                    :title="val"
                  >
                    {{ val.slice(0, 30) }}{{ val.length > 30 ? "..." : "" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="flex justify-center py-4 px-6 w-full text-sm text-center text-gray-500 truncate whitespace-nowrap border-t border-gray-200"
          >
            <p>
              + {{ entries.length - limit }}
              {{ $filters.i18n("more rows") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableView",
  props: {
    entries: { type: Array },
    translateEntries: { type: Boolean },
    columnsTitles: { type: Array },
    limit: { type: Number, default: 4 },
  },
  computed: {
    headerRows() {
      return this.entries && this.entries[0] && Object.keys(this.entries[0]);
    },
  },
};
</script>
