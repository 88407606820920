<template>
  <div>
    <dismissable-alert v-if="error" v-model:visible="error">{{
      $filters.i18n("An error occured during the merge")
    }}</dismissable-alert>

    <slot :clickCallback="() => (modalVisible = true)" :loading="loading" />

    <dialog-modal
      :show="modalVisible"
      @action-clicked="mergeMultipleClusters()"
      @close="modalVisible = false"
      type="action"
      full-width-content
      :close-on-outside-click="false"
      :action-disabled="newTitle.length < 1 || pickedClusters.length < 2"
      overflow-visible
    >
      <template #action>
        <togglable-loading-state :loading="loading">
          {{ $filters.i18n("Merge clusters") }}
        </togglable-loading-state>
      </template>

      <template #title>{{ $filters.i18n("mergeClusterHint_title") }}</template>
      <template #content>
        <div class="flex flex-col space-y-4 w-full">
          <label class="block font-medium text-gray-700">
            <span class="block mb-2"
              >{{ $filters.i18n("mergeCluster_clusters_label") }} *</span
            >
            <VueMultiselect
              v-model="pickedClusters"
              :options="availableFormattedClusters"
              :multiple="true"
              :allow-empty="false"
              :selectLabel="$filters.i18n('mergeCluster_select_label')"
              :selectedLabel="$filters.i18n('mergeCluster_selected_label')"
              :deselectLabel="$filters.i18n('mergeCluster_deselect_label')"
              :close-on-select="false"
              :show-no-results="false"
              :placeholder="$filters.i18n('mergeCluster_clusters_placeholder')"
              label="title"
              track-by="id"
            />
          </label>

          <text-input v-model="newTitle" required class="w-full"
            >{{ $filters.i18n("mergeClusterHint_label") }} *</text-input
          >
        </div>
      </template>
    </dialog-modal>
  </div>
</template>

<script>
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";
import DialogModal from "@/components/DesignSystem/Dialog/DialogModal";
import { inject, ref, watchEffect, computed } from "vue";
import { useMergeClusters } from "@/logic/use-api-fetch";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import TextInput from "@/components/DesignSystem/Inputs/TextInput";
import VueMultiselect from "vue-multiselect";

export default {
  name: "ClusterMergeModal",
  components: {
    TextInput,
    TogglableLoadingState,
    DialogModal,
    DismissableAlert,
    VueMultiselect,
  },
  inheritAttrs: false,
  setup(props, { emit }) {
    const error = ref(null);
    const modalVisible = ref(false);

    const pickedClusters = ref([]);

    const availableClusters = inject("availableClusters");

    const availableFormattedClusters = computed(() =>
      availableClusters.value.map((c) => ({ title: c.name, id: c.id }))
    );

    pickedClusters.value.push(
      availableClusters.value.find((c) => c.id === props.clusterId)
    );

    const {
      mergeMultipleClusters,
      onDone,
      onError,
      loading,
      newTitle,
      clusterIds,
    } = useMergeClusters([props.clusterId]);

    watchEffect(() => {
      clusterIds.value = pickedClusters.value.map((c) => c.id);
    });

    newTitle.value = (
      (pickedClusters.value &&
        pickedClusters.value[0] &&
        pickedClusters.value[0].name) ||
      ""
    ).replace("\n", "");

    const close = () => {
      modalVisible.value = false;
    };

    onDone(() => {
      emit("on-done");
      close();
    });

    onError((err) => {
      error.value = err;
    });

    return {
      mergeMultipleClusters,
      loading,
      error,
      modalVisible,
      newTitle,
      availableFormattedClusters,
      pickedClusters,
    };
  },
  props: {
    clusterId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="css">
.multiselect__tag {
  @apply bg-primary-dark !important;
}

.multiselect__tag-icon:after {
  @apply text-white !important;
}

.multiselect__option--highlight {
  @apply bg-primary !important;
}

.multiselect__option--highlight:after {
  @apply bg-primary !important;
}

.multiselect__option--selected {
  @apply bg-primary-dark text-white !important;
}
.multiselect__option--selected:after {
  @apply bg-primary-dark text-white !important;
}

.multiselect__tags,
.multiselect__input {
  @apply focus:ring-primary focus:border-primary shadow-sm sm:text-sm border-gray-300 rounded-md !important;
}
</style>
