import { inject, provide, ref, readonly } from "vue";

export function useProvideModals(instanceId) {
  const contributionModal = ref(null);
  const ContributorSlidePanel = ref(null);
  const clusterModal = ref(null);

  const updateContributionModal = (newData = null) =>
    (contributionModal.value = newData);

  const updateContributorSlidePanel = (newData = null) =>
    (ContributorSlidePanel.value = newData);

  const updateClusterModal = (newData = null) => (clusterModal.value = newData);

  provide(`contributionModal_${instanceId}`, readonly(contributionModal));
  provide(`updateContributionModal_${instanceId}`, updateContributionModal);

  provide(
    `ContributorSlidePanel_${instanceId}`,
    readonly(ContributorSlidePanel)
  );
  provide(
    `updateContributorSlidePanel_${instanceId}`,
    updateContributorSlidePanel
  );

  provide(`clusterModal_${instanceId}`, readonly(clusterModal));
  provide(`updateClusterModal_${instanceId}`, updateClusterModal);

  return {
    contributionModal,
    ContributorSlidePanel,
    clusterModal,
    updateContributionModal,
  };
}

export function useInjectModals(
  instanceId,
  contributions = true,
  contributors = true,
  clusters = true
) {
  console.log({ instanceId, contributions, contributors, clusters });

  let contributionModal,
    updateContributionModal,
    ContributorSlidePanel,
    updateContributorSlidePanel,
    clusterModal,
    updateClusterModal;

  if (contributions) {
    contributionModal = inject(`contributionModal_${instanceId}`);
    updateContributionModal = inject(`updateContributionModal_${instanceId}`);
  }

  if (contributors) {
    ContributorSlidePanel = inject(`ContributorSlidePanel_${instanceId}`);
    updateContributorSlidePanel = inject(
      `updateContributorSlidePanel_${instanceId}`
    );
  }

  if (clusters) {
    clusterModal = inject(`clusterModal_${instanceId}`);
    updateClusterModal = inject(`updateClusterModal_${instanceId}`);
  }

  return {
    contributionModal,
    ContributorSlidePanel,
    clusterModal,
    updateContributionModal,
    updateContributorSlidePanel,
    updateClusterModal,
  };
}

export function useContributionsModals(modalInstanceId) {
  const { updateContributionModal } = useInjectModals(
    modalInstanceId,
    true,
    false,
    false
  );

  console.log(updateContributionModal);

  const passDetailsToModal = (contribution) => {
    updateContributionModal({
      ...contribution,
      details: contribution,
    });
  };

  return { passDetailsToModal };
}
export function useClustersModals(modalInstanceId) {
  const { updateClusterModal } = useInjectModals(
    modalInstanceId,
    false,
    false,
    true
  );

  const passDetailsToModal = (clusterId) => {
    console.log(clusterId);
    updateClusterModal(clusterId);
  };

  return { passDetailsToModal };
}

export function useContributorsModal(modalInstanceId) {
  const { updateContributorSlidePanel } = useInjectModals(
    modalInstanceId,
    false,
    true,
    false
  );

  const passDetailsToModal = (contributor) =>
    updateContributorSlidePanel(contributor);

  return { passDetailsToModal };
}

export function useReadyToUseSingleClusterModal(modalInstanceId) {
  const { clusterModal } = useProvideModals(modalInstanceId.value);
  const [availableContributors, availableContributions, availableClusters] = [
    inject("availableContributors"),
    inject("availableContributions"),
    inject("availableClusters"),
  ];

  return {
    clusterModal,
    availableContributors,
    availableContributions,
    availableClusters,
  };
}
