<template>
  <div class="w-full max-w-full h-full max-h-full">
    <vue3-chart-js
      :data="donutGraph.data"
      :type="donutGraph.type"
      :plugins="donutGraph.plugins"
      :options="donutGraph.options"
      ref="chartRef"
      class="!w-full !max-w-full !h-full !max-h-full"
    />
  </div>
</template>

<script>
import { sum, uniqueId } from "lodash";
import { onMounted, ref, watch } from "vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "DonutChart",
  data() {
    return {};
  },
  props: {
    dataSets: Array,
    labels: Array,
    colors: Array,
    roundedLabels: Boolean,
    noAutoSort: Boolean,
  },
  components: {
    Vue3ChartJs,
  },
  setup(props) {
    const chartRef = ref(`${uniqueId("donut_chart")}`);

    const donutGraph = {
      type: "doughnut",
      data: {
        labels: [],
        datasets: [],
      },
      plugins: [ChartDataLabels],
      options: {
        devicePixelRatio: 2,
        responsive: true,

        cutout: "60%",
        radius: "85%",
        rotation: -90,
        layout: {
          padding: 15,
        },
        plugins: {
          datalabels: {
            borderColor: "white",
            borderRadius: Number.MAX_VALUE,
            color: "white",
            display: function (context) {
              let dataset = context.dataset;
              let count = sum(dataset.data);
              let value = dataset.data[context.dataIndex];
              return value > count / 18;
            },
            formatter: function (value, context) {
              // TODO Update the calculation for a computed value of visible dataset entry

              let visibleData = [];
              for (let i = 0; i < context.dataset.data.length; i++) {
                if (context.chart.getDataVisibility(i)) {
                  visibleData.push(context.dataset.data[i]);
                }
              }

              let count = sum(visibleData);
              return `${Math.round((value / count) * 100)}%`;
            },
            font: {
              weight: "bold",
            },
            padding: props.roundedLabels ? 6 : 3,
          },
          legend: {
            labels: {
              sort: function (a, b) {
                return a.text.localeCompare(b.text);
              },
              boxWidth: 20,
              padding: 8,
              font: {
                size: 12,
                lineHeight: 1.2,
                family:
                  'Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              },
            },
          },
        },
      },
    };

    onMounted(() => {
      watch(
        props,
        () => {
          let dataSets = props.dataSets || [];
          dataSets = dataSets.map((c) =>
            typeof c === "string" ? parseFloat(c.replace("v_", "")) : c
          );

          if (!props.noAutoSort) dataSets.sort((a, b) => b - a);

          donutGraph.data.labels = props.labels || [];
          donutGraph.data.datasets = [
            {
              data: dataSets,
              backgroundColor: props.colors,
              barThickness: 10,
              datalabels: {
                anchor: props.roundedLabels ? "start" : "center",
                backgroundColor: function (context) {
                  return context.dataset.backgroundColor;
                },
                borderWidth: props.roundedLabels ? 2 : 0,
              },
            },
          ];
          chartRef.value.update(`${uniqueId("donut_chart")}`);
        },
        {
          immediate: true,
        }
      );
    });

    return { chartRef, donutGraph };
  },
};
</script>

<style scoped lang="scss"></style>
