<template>
  <nav
    aria-label="Sidebar"
    class="hidden z-30 md:flex md:overflow-y-auto md:flex-col md:flex-shrink-0 md:justify-between md:items-center md:bg-gray-800"
  >
    <div class="flex relative flex-col p-3 space-y-3 w-20">
      <component
        :is="menuItem.route != null ? 'router-link' : 'button'"
        :to="menuItem.route"
        v-for="menuItem in menuItems"
        :key="menuItem.route"
        :title="menuItem.title"
        class="inline-flex flex-shrink-0 justify-center items-center w-14 h-14 rounded-lg transition interactive"
        @click="menuItem.route != null ? null : openWorkInProgressModal()"
        :class="
          menuItem.active
            ? 'bg-gray-900 text-white'
            : 'text-gray-400 hover:bg-gray-700'
        "
      >
        <span class="sr-only">{{ $filters.i18n(menuItem.title) }}</span>
        <div v-html="menuItem.icon" />
      </component>
    </div>

    <!--    <div
      class="relative w-20 flex flex-col p-3 space-y-3 bg-gray-100 border-gray-800 border-r-.5 border-opacity-10"
    >
      <a
        href="#"
        v-for="menuItem in bottomMenuItems"
        :key="menuItem.route"
        :title="menuItem.title"
        class="flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg transition interactive"
        :class="
          menuItem.active
            ? 'bg-gray-900 text-white'
            : 'text-gray-600 hover:bg-gray-600 hover:text-gray-100'
        "
        @click.prevent="openWorkInProgressModal()"
      >
        <span class="sr-only">{{ menuItem.title }}</span>
        <div v-html="menuItem.icon" />
      </a>
    </div>-->
  </nav>
</template>

<script>
export default {
  name: "Sidebar",
  inject: ["openWorkInProgressModal"],
  data() {
    return {
      menuItems: [
        {
          title: "Analysis",
          route: "/",
          icon: `<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>`,
          active: true,
        },
        /*    {
          title: "Contributions",
          route: null,
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" /></svg>`,
        },
        {
          title: "Contributors",
          route: null,
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>`,
        },
        {
          title: "Clusters",
          route: null,
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" /></svg>`,
        },*/
      ],
      bottomMenuItems: [
        {
          title: "Credentials",
          route: null,
          icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" class="h-6 w-6" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" /></svg>`,
        },
        {
          title: "Settings",
          route: null,
          icon: `<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>`,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss"></style>
