<template>
  <page-body>
    <template #preBody>
      <insight-information-bar
        :title="getSession && getSession.title"
        :results-available="getSession && getSession.analysed"
        :date="getSession && getSession.createdAt"
        :sessionId="id"
        :can-delete="!isUserAPilot"
        @export-clusters-to-xlsx="
          exportClustersToXlsx(
            formattedClusters,
            (contributions && contributions.length) || 0,
            getSession.title,
            $filters.i18n
          )
        "
        @export-contributions-to-xlsx="
          exportContributionsToXlsx(
            contributionsWithFormattedClusters,
            getSession.title,
            $filters.i18n
          )
        "
      />

      <dismissable-alert
        type="success"
        :visible="globalReloadProposed"
        @update:visible="globalReloadProposed = null"
        v-if="globalReloadProposed"
      >
        <template #default>
          <span class="block mr-3">{{
            $filters.i18n("Some data has been updated")
          }}</span></template
        >

        <template #actions>
          <button
            type="button"
            class="flex items-center py-1.5 px-2 text-sm font-medium text-green-800 bg-green-50 hover:bg-green-100 rounded-md focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50 focus:outline-none interactive"
            @click="refreshWindow"
          >
            <RefreshIcon class="mr-1.5 w-4 h-4" />
            <span>{{ $filters.i18n("Reload the analysis") }}</span>
          </button>
        </template>
      </dismissable-alert>
    </template>
    <template #leftBodyPane>
      <searchable-contributions-list
        :loading="!loadedBatch"
        :contributions="
          filteredContributionsWithFocusedClusterAndFocusedContribution &&
          filteredContributionsWithFocusedClusterAndFocusedContribution.length >
            0
            ? filteredContributionsWithFocusedClusterAndFocusedContribution
            : contributionsWithFormattedClusters
        "
        :additional-columns="contributionsAdditionalColumns"
        :data-ready-for-charts="dataReadyForCharts"
        :analyse-done="getSession && getSession.analysed"
      />
    </template>
    <template #mainBody>
      <div class="flex relative flex-col space-y-6 h-full">
        <insight-metrics
          class="flex-grow-0"
          :ideas-nb="contributions && contributions.length"
          :clusters-nb="formattedClusters.length || 0"
          :main-theme="
            (formattedClusters.length &&
              formattedClusters.filter((c) => c.title !== 'Other')[0]) ||
            {}
          "
          :authors-length="formattedContributors.length || 0"
          :loading="!loadedBatch"
        />

        <insight-loader
          :id="(getSession && getSession.id) || id"
          v-if="!loadedBatch || (loadedBatch && sessionAnalysedState === false)"
          :loading="!loadedBatch"
        />

        <insight-error v-if="error" :error="error" />

        <insight-pane
          class="flex-1"
          :clusters="formattedClusters"
          :contributors="formattedContributors"
          :contributions="contributionsWithFormattedClusters"
          :key="id"
          v-if="
            loadedBatch &&
            sessionAnalysedState === true &&
            formattedClusters &&
            formattedClusters.length > 0 &&
            formattedContributors &&
            formattedContributors.length > 0 &&
            contributionsWithFormattedClusters &&
            contributionsWithFormattedClusters.length > 0
          "
        />
      </div>
    </template>
    <template #rightBodyPane>
      <searchable-contributors-list
        :loading="!loadedBatch"
        :contributors="formattedContributors"
        :data-ready-for-charts="dataReadyForCharts"
      />
    </template>

    <template #endOfBody>
      <insight-modals />
    </template>
  </page-body>
</template>
<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import InsightPane from "../components/Insights/InsightPane";
import InsightMetrics from "../components/Insights/InsightMetrics";
import PageBody from "@/components/Layout/PageBody";
import InsightInformationBar from "@/components/Insights/InsightInformationBar";
import SearchableContributionsList from "@/components/Contributions/SearchableContributionsList";
import SearchableContributorsList from "@/components/Contributors/SearchableContributorsList";

import { computed, inject, provide, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { useFormatBatchData } from "../logic/use-format-batch-data";
import { useCsvExport } from "../logic/use-csv-export";
import { useApiFetch } from "@/logic/use-api-fetch";
import InsightLoader from "@/components/Insights/InsightLoader";
import { useDesktopNotifications } from "@/logic/use-desktop-notifications";
//import { useProvideModals } from "@/logic/use-modals";
import InsightModals from "@/components/Insights/InsightModals";
import { useRouter } from "vue-router";
import InsightError from "@/components/Insights/InsightError";
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";
import { RefreshIcon } from "@heroicons/vue/outline";
import { useProvideModals } from "@/logic/use-modals";
import { isTourCompletedForPageInLS } from "@/logic/use-tour";

export default {
  name: "Insight",
  components: {
    DismissableAlert,
    InsightError,
    InsightModals,
    InsightLoader,
    InsightMetrics,
    InsightPane,
    PageBody,
    InsightInformationBar,
    SearchableContributionsList,
    SearchableContributorsList,
    RefreshIcon,
  },
  mounted() {
    this.canContinue = false;
    this.latestSessionWithFetchedAuthors = null;
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const i18n = inject("i18n");

    const globalReloadProposed = ref(false);
    provide("globalReloadProposed", globalReloadProposed);

    const { updateContributionModal } = useProvideModals("insight");

    const batchWasNotReadyAtFirst = ref(false);

    const {
      filterContributionsWithFocusedClusterAndFocusedContribution,
      formatClusters,
      formatContributorsWithContributions,
      formatContributionsWithFormattedClusters,
      extractContributionsFromContributors,
    } = useFormatBatchData();

    const { getBatch, pollSessionAnalysedState } = useApiFetch;

    const {
      notifyDesktop,
      requestNotificationsPermissions,
    } = useDesktopNotifications;

    const { exportContributionsToXlsx, exportClustersToXlsx } = useCsvExport;

    const sessionAnalysedStatePollingEnabled = ref(true);
    const {
      result: sessionAnalysedState,
      sessionAnalysedStateError,
      sessionAnalysedStateLoading,
    } = pollSessionAnalysedState(props.id, sessionAnalysedStatePollingEnabled);

    let {
      loading,
      error,
      getSession,
      nextToken,
      clusters,
      contributors,
      loadedBatch,
    } = getBatch(props.id);

    watch(
      sessionAnalysedState,
      (analysed) => {
        if (analysed) {
          sessionAnalysedStatePollingEnabled.value = false; // Stop polling
          ({
            loading,
            error,
            getSession,
            nextToken,
            clusters,
            contributors,
          } = getBatch(props.id));

          if (batchWasNotReadyAtFirst.value === true) {
            notifyDesktop(
              `Phedone : ${i18n("Results available")}`,
              i18n("Click here to access to the results")
            );

            document.location.reload();
          }
        } else {
          if (analysed === undefined) {
            router.push("/");
          }

          batchWasNotReadyAtFirst.value = true;
          requestNotificationsPermissions();
        }
      },
      {
        immediate: false,
      }
    );

    const contributions = computed(() =>
      extractContributionsFromContributors(contributors.value)
    );

    const formattedClusters = computed(() =>
      formatClusters(clusters.value, contributions.value)
    );

    const formattedContributors = computed(() =>
      formatContributorsWithContributions(contributors.value)
    );

    const contributionsWithFormattedClusters = computed(() =>
      formatContributionsWithFormattedClusters(
        formattedClusters.value,
        contributions.value
      )
    );

    const filteredContributionsWithFocusedClusterAndFocusedContribution = computed(
      () =>
        filterContributionsWithFocusedClusterAndFocusedContribution(
          contributionsWithFormattedClusters.value,
          store.state.focusedClusterIds,
          store.state.focusedIdeaId
        )
    );

    const contributionsAdditionalColumns = ref({});

    watchEffect(() => {
      contributions.value.forEach((c) => {
        contributionsAdditionalColumns.value = {};

        c.additionalColumns.forEach((column, i) => {
          contributionsAdditionalColumns.value[column.key] = {
            title: column.title,
            key: column.key,
            index: i,
          };
        });
      });
    });

    provide("availableContributors", formattedContributors);
    provide("availableContributions", contributionsWithFormattedClusters);
    provide("availableClusters", formattedClusters);

    const refreshWindow = () => location.reload();

    const toggleFirstContributionModal = async (open = true) => {
      let firstContribution = null;

      if (open) {
        firstContribution = contributions.value[0];
      }
      //
      open
        ? updateContributionModal({
            ...firstContribution,
            details: firstContribution,
          })
        : updateContributionModal();

      await new Promise((r) => setTimeout(r, 750));
      return true;
    };

    provide("toggleFirstContributionModal", toggleFirstContributionModal);
    const setTutorialVisible = inject("setTutorialVisible");
    const setupTourOnComponent = inject("setupTourOnComponent");
    const isReadyForTutorial = computed(
      () => loadedBatch.value && sessionAnalysedState.value
    );

    setupTourOnComponent(i18n, "Insight", { toggleFirstContributionModal });
    if (!isTourCompletedForPageInLS("Insight")) {
      watch(isReadyForTutorial, () => setTutorialVisible());
    }

    return {
      getSession,
      loading,
      error,
      nextToken,
      clusters,
      contributors,
      formattedContributors,
      formattedClusters,
      contributions,
      contributionsWithFormattedClusters,
      filteredContributionsWithFocusedClusterAndFocusedContribution,
      exportContributionsToXlsx,
      exportClustersToXlsx,
      notifyDesktop,
      requestNotificationsPermissions,
      sessionAnalysedState,
      sessionAnalysedStateError,
      sessionAnalysedStateLoading,
      loadedBatch,
      globalReloadProposed,
      refreshWindow,
      contributionsAdditionalColumns,
      toggleFirstContributionModal,
    };
  },
  methods: {
    ...mapMutations(["setPageTitle"]),
  },
  props: {
    id: { type: String, required: true },
  },
  metaInfo() {
    return {
      title:
        (this.getSession.value && this.getSession.value.title) ||
        this.$filters.i18n("Loading..."),
    };
  },
  data() {
    return {
      placeholding: true,
      fullPlaceholdedIdeas: [],
      canContinue: false,
      latestSessionWithFetchedAuthors: null,
      pollingAuthorsForNonAnalysedSession: null,
      dataReadyForCharts: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isUserAPilot"]),
  },
  watch: {
    getSession: {
      handler: function (session) {
        session = session.value;

        if (!session) return;

        this.setPageTitle(session.title);

        if (
          this.latestSessionWithFetchedAuthors !== session.id &&
          !this.dataReadyForCharts
        ) {
          this.latestSessionWithFetchedAuthors = session.id;

          if (this.nextToken) {
            // TODO Need to paginate here !
            // this.getNextThousandAuthorsOrRefresh();
          }
        }

        if (
          !session.analysed &&
          this.dataReadyForCharts &&
          session.authors &&
          session.authors.items &&
          session.authors.items.length < 500 &&
          session.authors.items.length > 0
        ) {
          // TODO need to implement this the new way :)
          // this.pollAuthorsForNonAnalysedSession(session.id, true);
        }
      },
      deep: false,
    },
    nextToken(nextToken) {
      this.dataReadyForCharts = nextToken == null || nextToken == undefined;
    },
  },
};
</script>

<style scoped lang="scss"></style>
