<template>
  <section>
    <nav aria-label="Progress">
      <ol class="space-y-4 md:flex md:space-y-0 md:space-x-8">
        <li class="md:flex-1" v-for="(step, i) in items" :key="i">
          <button
            class="flex flex-col py-2 pl-4 w-full outline-none focus:outline-none md:pt-4 md:pb-0 md:pl-0"
            :class="
              i + 1 < modelValue
                ? 'group border-l-4 border-primary hover:border-primary-dark  md:border-l-0 md:border-t-4'
                : i + 1 === modelValue
                ? 'border-l-4 border-primary md:border-l-0 md:border-t-4'
                : 'group flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:border-l-0 md:border-t-4'
            "
            :aria-current="i + 1 === modelValue && 'step'"
            :disabled="!editable"
            @click="$emit('step-clicked', i + 1)"
          >
            <span
              class="text-xs font-semibold tracking-wide uppercase"
              :class="
                i + 1 < modelValue
                  ? 'text-primary group-hover:text-primary-dark'
                  : i + 1 === modelValue
                  ? ' text-primary'
                  : 'text-gray-500 group-hover:text-gray-700'
              "
              >{{ $filters.i18n("Step") }} {{ i + 1 }}</span
            >
            <span class="text-sm font-medium text-left">{{ step }}</span>
          </button>
        </li>
      </ol>
    </nav>
  </section>
</template>

<script>
export default {
  name: "HorizontalSteps",
  props: {
    modelValue: { type: Number },
    items: { type: Array },
    editable: { type: Boolean, default: true },
  },
};
</script>

<style scoped lang="scss"></style>
