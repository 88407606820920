<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">{{
        $filters.i18n("Select a language")
      }}</label>

      <select
        id="tabs"
        name="tabs"
        class="block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary"
        v-model="locale"
      >
        <option
          class="uppercase"
          v-for="(availableLocale, i) in locales"
          :key="i"
          :selected="availableLocale === locale"
        >
          {{ availableLocale }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav
        class="flex space-x-1"
        :aria-label="$filters.i18n('Select a language')"
      >
        <button
          v-for="(availableLocale, i) in locales"
          :key="i"
          @click="locale = availableLocale"
          :class="[
            availableLocale === locale
              ? 'bg-primary-lighter text-gray-800 text-primary-dark'
              : 'text-gray-600 hover:text-gray-800 hover:bg-gray-100',
            'px-3 py-2 font-medium text-xs rounded-md interactive',
          ]"
          :aria-current="availableLocale === locale ? 'page' : undefined"
        >
          {{ availableLocale }}
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "LanguagePicker",
  data() {
    return {
      locales: ["en", "fr"],
    };
  },
  computed: {
    locale: {
      set(locale) {
        this.setLocale(locale);
      },
      get() {
        return this.$store.state.locale;
      },
    },
  },
  methods: {
    ...mapMutations(["setLocale"]),
  },
};
</script>

<style scoped lang="scss"></style>
