<template>
  <div class="flex flex-col divide-y divide-gray-200 carded">
    <div class="flex py-5 px-4 space-x-3 sm:px-6">
      <div class="flex-1 min-w-0">
        <p class="text-sm font-medium text-gray-900" v-if="$slots.title">
          <slot name="title" />
        </p>
        <p class="text-sm text-gray-500" v-if="$slots.subtitle">
          <slot name="subtitle" />
        </p>
      </div>
      <div
        class="flex flex-shrink-0 self-center"
        v-if="
          (actions && Array.isArray(actions) && actions.length > 0) || !actions
        "
      >
        <dropdown-button icon large :actions="actions || defaultActions"
          >Chart actions</dropdown-button
        >
      </div>
    </div>
    <div
      class="flex relative flex-1 justify-center items-center py-5 px-4 h-0 sm:p-6"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import DropdownButton from "@/components/DesignSystem/Buttons/DropdownButton";

export default {
  name: "DashboardCard",
  components: {
    DropdownButton,
  },
  props: {
    actions: Array,
  },
  data() {
    return {
      defaultActions: [
        {
          title: this.$filters.i18n("dashboard_card_export_picture"),
          callback: () => this.$emit("export-to-picture-clicked"),
        },
        /*        {
          title: this.$filters.i18n("dashboard_card_about"),
          callback: () => this.$emit("about-clicked"),
        },*/
      ],
    };
  },
};
</script>

<style scoped lang="scss"></style>
