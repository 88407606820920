<template>
  <div class="bg-white border-t border-gray-200">
    <div class="px-4 sm:px-6 lg:mx-auto">
      <div class="pt-4 pb-5 md:flex md:justify-between md:items-center">
        <div class="flex-1 min-w-0">
          <div class="flex items-center">
            <div class="flex flex-col md:flex-row md:items-center md:space-x-6">
              <div class="flex items-center">
                <h1
                  class="text-xl font-bold leading-7 text-gray-900 sm:leading-9"
                >
                  {{ title }}
                </h1>
              </div>
              <dl
                class="flex flex-col mt-6 sm:flex-row sm:flex-wrap md:mt-0 md:-mb-1"
              >
                <slot name="infos" />
              </dl>
            </div>
          </div>
        </div>
        <div class="flex mt-6 space-x-3 md:mt-0">
          <slot name="actions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformationsBar",
  props: {
    title: String,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
