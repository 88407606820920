<template>
  <div class="overflow-hidden h-full carded">
    <small-contributions-list
      :contributions="slicedVisibleItems"
      :scrollable="true"
      additional-header-class="flex flex-wrap"
      :length="orderedResults.length"
      @scroll="scrollHandler"
      :modals-instance-id="modalsInstanceId"
      :loading="loading"
      clusters-actions
      id="step-1-insight"
    >
      <template #header>
        <div class="block mt-4 w-full">
          <sort-and-filter-input
            name="contributorsFilters"
            :label="$filters.i18n('Start typing...')"
            v-model:searchTerms="searchTerms"
            v-model:sortOrder="sortOrder"
            :sort-options="sortOptions"
            @click="showFilters = true"
          />
        </div>
      </template>
    </small-contributions-list>
  </div>
</template>

<script>
import { useInfiniteScroll } from "../../logic/use-infinite-scroll";
import SmallContributionsList from "@/components/Contributions/SmallContributionsList";
import SortAndFilterInput from "@/components/DesignSystem/Inputs/SortAndFilterInput";
import { computed, ref, toRefs } from "vue";
import { useOrderList } from "../../logic/use-order-list";
import { useFuseSearch, useSetupFuseSearch } from "../../logic/use-fuse-search";
export default {
  name: "SearchableContributionsList",
  components: {
    SortAndFilterInput,
    SmallContributionsList,
    // InfiniteLoading
  },
  props: {
    contributions: Array,
    dataReadyForCharts: Boolean,
    modalsInstanceId: { type: String, default: "insight" },
    loading: Boolean,
    additionalColumns: Object,
  },
  setup(props) {
    const searchTerms = ref("");
    const { additionalColumns } = toRefs(props);
    const additionalColumnsArray = computed(() =>
      Object.values(additionalColumns.value)
    );

    const searchableKeys = computed(() => {
      const defaultKeys = [
        "title",
        "description",
        "details.keywords",
        "author.name",
        "clusters.name",
        "clusters.themes.title",
      ];

      const additionalKeys = additionalColumnsArray.value.map(
        (additionalColumn) => additionalColumn.key
      );
      // Add logic for additional keys here

      return [...defaultKeys, ...additionalKeys];
    });

    const sortOrder = ref(0);
    const sortOptions = computed(() => {
      const defaultSortOptions = [
        "Title, A to Z",
        "Title, Z to A",
        "Originality, Asc.",
        "Originality, Desc.",
        "Polarity, Asc.",
        "Polarity, Desc.",
        "Theme title, A to Z",
        "Theme title, Z to A",
      ];
      let additionalSortOptions = [];

      additionalColumnsArray.value.forEach((additionalColumn) => {
        additionalSortOptions.push(additionalColumn.title + ", Asc.");
        additionalSortOptions.push(additionalColumn.title + ", Desc.");
      });
      // Add logic for additional sort options here

      return [...defaultSortOptions, ...additionalSortOptions];
    });

    const orderableKeys = computed(() => {
      const defaultOrderableKeys = [
        "title",
        "title",
        "originality",
        "originality",
        "polarity",
        "polarity",
        "cluster.title",
        "cluster.title",
      ];

      // Add logic for additional orderable here
      let additionalOrderableKeys = [];

      additionalColumnsArray.value.forEach((additionalColumn) => {
        additionalOrderableKeys.push(additionalColumn.key);
        additionalOrderableKeys.push(additionalColumn.key);
      });

      return [
        ...defaultOrderableKeys,
        ...additionalOrderableKeys,
        // additionalOrderableKeys
      ];
    });

    const fuse = computed(() =>
      useSetupFuseSearch(props.contributions, searchableKeys.value)
    );

    const results = computed(() => {
      return useFuseSearch(fuse.value, searchTerms.value);
    });

    const orderedResults = computed(() =>
      useOrderList(
        searchTerms.value.length > 0
          ? results.value.map((r) => r.item)
          : props.contributions,
        orderableKeys.value,
        sortOrder.value
      )
    );

    const infiniteScroll = useInfiniteScroll(orderedResults);

    const {
      scrollHandler,
      slicedVisibleItems,
      maxVisibleItems,
    } = infiniteScroll;

    return {
      orderedResults,
      maxVisibleItems,
      slicedVisibleItems,
      sortOrder,
      searchTerms,
      scrollHandler,
      sortOptions,
      additionalColumnsArray,
      searchableKeys,
    };
  },
};
</script>

<style scoped lang="scss"></style>
