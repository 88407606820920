<template>
  <div>
    <div id="step-2-insight" class="flex rounded-md shadow-sm">
      <div class="flex relative focus-within:z-10 flex-grow items-stretch">
        <div
          class="absolute top-1/2 left-3 text-gray-400 transform -translate-y-1/2"
        >
          <slot name="icon" v-if="$slots.icon" />
          <DocumentSearchIcon v-else class="w-5 h-5 text-gray-400" />
        </div>
        <input
          type="text"
          :name="name"
          class="block py-2 pl-10 text-sm placeholder-gray-500 text-black rounded-md rounded-r-none border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary focus:outline-none"
          :class="inputClass"
          :placeholder="label"
          :modelValue="searchTerms"
          @input="$emit('update:searchTerms', $event.target.value)"
        />
      </div>

      <Select
        :items="sortOptions.map((s) => $filters.i18n(s))"
        :modelValue="sortOrder"
        @update:modelValue="$emit('update:sortOrder', $event)"
        v-slot="{ open }"
      >
        <button
          class="inline-flex relative items-center py-2 px-4 -ml-px space-x-2 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 rounded-r-md border border-gray-300 focus:border-primary border-opacity-70 focus:ring-1 focus:ring-primary focus:outline-none"
          :class="largeSelect ? 'lg:min-w-[110px]' : ''"
          @click="open"
        >
          <svg
            class="w-5 h-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"
            />
          </svg>
          <span>{{ $filters.i18n("Sort") }}</span>
        </button>
      </Select>
    </div>
  </div>
</template>

<script>
import Select from "@/components/DesignSystem/Inputs/Select";

import { DocumentSearchIcon } from "@heroicons/vue/solid";

export default {
  name: "SortAndFilterInput",
  components: { Select, DocumentSearchIcon },
  props: {
    name: String,
    sortOptions: Array,
    label: String,
    searchTerms: String,
    sortOrder: Number,
    largeSelect: Boolean,
    inputClass: { type: String, default: "w-full" },
  },
  data() {
    return {
      showFilters: false,
    };
  },
};
</script>

<style scoped lang="scss"></style>
