<template>
  <section class="space-y-5">
    <div
      v-bind="getRootProps()"
      class="flex flex-wrap justify-center items-center px-6 pt-5 pb-6 min-h-[80px] hover:bg-primary hover:bg-opacity-5 rounded-md border focus-visible:border-2 border-gray-300 hover:border-primary focus-visible:border-primary hover:border-opacity-20 border-dashed cursor-pointer focus:outline-none md:min-h-[220px]"
    >
      <input v-bind="getInputProps()" />
      <p v-if="isDragActive" class="text-sm text-primary">
        {{ $filters.i18n("Drop the files here ...") }}
      </p>
      <div
        class="space-y-1 text-center"
        v-if="acceptedFiles && acceptedFiles.length === 1"
      >
        <div class="flex justify-center text-sm text-gray-600">
          <b
            class="relative font-medium text-primary hover:text-primary-dark rounded-md focus-within:ring-2 focus-within:ring-primary-dark focus-within:ring-offset-2 cursor-pointer focus-within:outline-none"
          >
            <span>{{ $filters.i18n("You picked the following file :") }}</span>
          </b>
        </div>
        <p class="text-xs text-gray-500">
          {{ acceptedFiles[0] && acceptedFiles[0].name }}
        </p>
      </div>
      <div v-else>
        <div class="space-y-1 text-center">
          <div
            id="step-1-onboarding"
            class="flex flex-col text-sm text-gray-600 md:flex-row"
          >
            <b
              class="relative font-medium text-primary hover:text-primary-dark rounded-md focus-within:ring-2 focus-within:ring-primary-dark focus-within:ring-offset-2 cursor-pointer focus-within:outline-none"
            >
              <span>{{ $filters.i18n("Upload a file") }}</span>
            </b>
            <p class="pl-1">
              {{ $filters.i18n("or drag and drop") }}
            </p>
          </div>
          <p class="text-xs text-gray-500">
            {{ $filters.i18n("XLSX, CSV up to 100MB") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { excel2json } from "js2excel";
import { ref, watch, toRefs } from "vue";

const csv = require("csvtojson");

export default {
  name: "XlsxImporter",
  setup(props, { emit }) {
    const csvSeparator = toRefs(props).csvSeparator;

    const json = ref(null);
    const fileName = ref(null);

    const convertExcelToJson = (f) => {
      excel2json(f, (data) => {
        json.value = data;
      });
    };

    const convertCsvToJson = async (f) => {
      const reader = new FileReader();
      reader.onload = async function (event) {
        json.value = {
          fromCsv: await csv({
            delimiter: csvSeparator.value || ";",
          }).fromString(event.target.result),
        };
      };
      reader.readAsText(f);
    };

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      acceptedFiles,
    } = useDropzone({
      multiple: false,
      maxFiles: 1,
      minSize: 0,
      maxSize: 100000000,
    });

    watch(
      [acceptedFiles, csvSeparator],
      () => {
        const file = acceptedFiles.value && acceptedFiles.value[0];

        if (!file) {
          json.value = null;
          return;
        }

        const extension = file.name.split(".").slice(-1)[0];

        if (["xlsx", "xls"].includes(extension)) {
          convertExcelToJson([file]);
          fileName.value = file.name;
        } else if (extension === "csv") {
          convertCsvToJson(file);
          fileName.value = file.name;
        }
      },
      { deep: true }
    );

    watch([json], ([newJsonVal]) => {
      emit("user-file-input-changed", {
        json: newJsonVal,
        name: fileName.value,
      });
    });

    return {
      getRootProps,
      getInputProps,
      isDragActive,
      acceptedFiles,
      open,
      json,
    };
  },
  data() {
    return {
      selectedSheet: null,
      sheetName: null,
      sheets: [],
      collection: [],
    };
  },
  props: {
    csvSeparator: String,
  },
  methods: {
    fileChanged(file) {
      this.file = file;
    },
    parsed(json) {
      this.json = json;
      this.$emit("sheet-picked", { json: json, file: this.file });
    },
  },
};
</script>
