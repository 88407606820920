<template>
  <section class="carded">
    <div class="py-5 px-4 sm:p-6 md:flex md:justify-between md:items-center">
      <div>
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{
            $filters.i18n(
              "If you would like to analyse more than 100 ideas, contact us"
            )
          }}
        </h3>
        <!--
        <div class="mt-2 max-w-xl text-sm text-gray-500" >
          <p></p>
        </div>-->
      </div>
      <div class="mt-5 md:mt-0">
        <a
          :href="
            'mailto:contact@phedone.com?subject=' +
            $filters.i18n(`Increase maximum of ideas request`)
          "
          class="bg-primary bg-opacity-10 hover:!bg-opacity-20 button button-soft"
        >
          {{ $filters.i18n("Get in touch") }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InsertingLimitedAlert",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
