<template>
  <div class="w-3/6">
    <label for="comment" class="block mb-3 font-medium text-gray-700">{{
      $filters.i18n("blacklist_title")
    }}</label>
    <div class="mt-1">
      <div class="flex flex-col">
        <textarea
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          rows="8"
          name="comment"
          id="comment"
          class="overflow-auto py-3 px-3 w-full h-72 rounded-md border-gray-300 focus:border-primary focus:ring-primary shadow resize-none sm:text-sm"
        ></textarea>
        <span class="mt-1 text-sm text-red-700">
          {{ $filters.i18n("blacklist_message_bottom") }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KeywordsInput",
  props: {
    modelValue: { type: String },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
