import { ref, watchEffect, watch } from "vue";

export const useInfiniteScroll = (
  items,
  pagingLength = 15,
  loadMoreHandler = null
) => {
  const isLoading = ref(false);
  const maxVisibleItems = ref(pagingLength);

  const loadMore = async () => {
    isLoading.value = true;

    if (loadMoreHandler) {
      await loadMoreHandler();
    } else {
      if (maxVisibleItems.value < (items.value && items.value.length)) {
        maxVisibleItems.value += pagingLength;
      }
    }
    isLoading.value = false;
  };

  const scrollHandler = async ($event) => {
    const { scrollTop, clientHeight, scrollHeight } = $event.target;

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!isLoading.value) {
        await loadMore();
      }
    }
  };

  const slicedVisibleItems = ref([]);

  watch(items, () => (maxVisibleItems.value = pagingLength));

  watchEffect(() => {
    slicedVisibleItems.value =
      items.value && Array.isArray(items.value)
        ? items.value.slice(0, maxVisibleItems.value)
        : [];
  });

  return {
    isLoading,
    scrollHandler,
    loadMore,
    maxVisibleItems,
    slicedVisibleItems,
  };
};
