<template>
  <main class="flex flex-col -mx-6 md:flex-row md:space-y-0 md:space-x-6">
    <div class="-my-5 -mb-6 w-full">
      <div class="py-5 px-4 sm:px-6">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{ $filters.i18n("The keys were successfully generated !") }}
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          {{
            $filters.i18n(
              "You must keep the key in a safe place : if you were to lose it, your encrypted data in Phedone would be unrecoverable !"
            )
          }}
        </p>
      </div>
      <div class="py-5 px-4 border-t border-gray-100 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-100">
          <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              {{ $filters.i18n("Generated private key") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <textarea
                readonly
                class="overflow-auto p-3 w-full !text-xs bg-primary-lighter rounded !border-none !ring-0 !outline-none resize-none"
                rows="13"
                :value="privateKey"
              />
            </dd>
          </div>
          <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt class="flex items-center text-sm font-medium text-gray-500">
              <span>{{ $filters.i18n("Actions") }}</span>
            </dt>
            <dd
              class="flex items-center mt-1 space-x-3 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
            >
              <button
                class="interactive button button-soft-visible"
                @click="copyPrivateKeyToDevice"
              >
                <ClipboardCopyIcon class="mr-2 w-4 h-4" />
                {{ $filters.i18n("encryption_privateKey_actions_copy") }}
              </button>

              <button
                class="interactive button button-primary"
                @click="downloadPrivateKeyToDevice"
              >
                <DownloadIcon class="mr-2 w-4 h-4" />{{
                  $filters.i18n("encryption_privateKey_actions_download")
                }}
              </button>

              <dismissable-alert
                type="success"
                v-if="showCopiedMessage"
                v-model:visible="showCopiedMessage"
                >{{
                  $filters.i18n("encryption_privateKey_actions_copy_callback")
                }}</dismissable-alert
              >
            </dd>
          </div>
          <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt class="flex items-center text-sm font-medium text-gray-500">
              <span>{{
                $filters.i18n("encryption_privateKey_liability_title")
              }}</span>
            </dt>
            <dd
              class="flex items-center mt-1 space-x-3 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
            >
              <Checkbox
                :model-value="userApproval"
                @update:modelValue="$emit('update:userApproval', $event)"
                >{{
                  $filters.i18n("encryption_privateKey_liability_hint")
                }}</Checkbox
              >
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </main>
  <!--  <div class="pb-1">
    <v-card class="mb-6" elevation="0">
      <v-row>
        <v-col class="pb-0"
          ><h2>
            {{ $filters.i18n("The keys were successfully generated !") }}
          </h2></v-col
        ></v-row
      >
      <v-row
        ><v-col
          ><h4>
            {{
              $filters.i18n(
                "You must keep the key in a safe place : if you were to lose it, your encrypted data in Phedone would be unrecoverable !"
              )
            }}
          </h4></v-col
        ></v-row
      >
      <v-row
        ><v-col class="pb-2"
          ><p>
            {{ $filters.i18n("You can") }}
            <b class="primary&#45;&#45;text">{{
              $filters.i18n("copy it from below")
            }}</b>
          </p></v-col
        ></v-row
      >
      <v-row>
        <v-col class="pt-0">
          <v-textarea
            outlined
            name="input-7-4"
            :label="$filters.i18n('Generated private key')"
            :value="privateKey"
            hide-details
            readonly
            class="privateKeyOutput"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row
        ><v-col class="pt-0"
          ><b class="primary&#45;&#45;text">{{ $filters.i18n("Or") }}</b></v-col
        ></v-row
      >
      <v-row
        ><v-col class="pt-0"
          ><v-btn color="secondary" @click="downloadPrivateKeyToDevice()">
            <v-icon class="mr-2 ml-n1">mdi-download</v-icon>
            {{
              $filters.i18n("Download the generated private key to your device")
            }}</v-btn
          ></v-col
        ></v-row
      >
      <v-row>
        <v-col class="mb-n4">
          <v-checkbox v-model="agreeToContinue">
            <template v-slot:label>{{
              $filters.i18n("I stored the private key in a safe place")
            }}</template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-card>
    <v-btn
      color="primary"
      :disabled="!agreeToContinue"
      @click="agreeToContinue ? goNext() : false"
    >
      <v-icon class="mr-2 ml-n1">mdi-upload-lock</v-icon>
      {{ $filters.i18n("Upload the generated public key to Phedone") }}
    </v-btn>
    &lt;!&ndash;
    <v-btn text>
      {{ $filters.i18n("Cancel") }}
    </v-btn>
    &ndash;&gt;
  </div>-->
</template>

<script>
import { kebabCase } from "lodash";
import copy from "copy-to-clipboard";
import { ClipboardCopyIcon } from "@heroicons/vue/outline";
import { DownloadIcon } from "@heroicons/vue/solid";
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";
import Checkbox from "@/components/DesignSystem/Inputs/Checkbox";

export default {
  name: "KeysPairGenerationPrivateKeyOutput",
  components: {
    Checkbox,
    DismissableAlert,
    DownloadIcon,
    ClipboardCopyIcon,
  },
  data() {
    return {
      showCopiedMessage: false,
    };
  },
  methods: {
    copyPrivateKeyToDevice() {
      copy(this.privateKey, { onCopy: () => (this.showCopiedMessage = true) });
    },
    downloadPrivateKeyToDevice() {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:charset=utf-8," + encodeURIComponent(this.privateKey)
      );
      element.setAttribute(
        "download",
        kebabCase(this.title) + "_private_key.rsa"
      );

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    goNext() {
      this.$emit("upload-to-phedone");
    },
  },
  props: {
    privateKey: String,
    title: String,
    userApproval: Boolean,
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.privateKeyOutput {
  font-size: 10px;
}
</style>
