<template>
  <main
    class="flex flex-col space-y-5 w-full md:flex-row md:space-y-0 md:space-x-6"
  >
    <div class="space-y-4 w-full">
      <text-input
        :model-value="title"
        @update:modelValue="$emit('update:title', $event)"
        required
        autocomplete="off"
        >{{ $filters.i18n("encryption_inputs_keyTitle_label") }}*</text-input
      >
      <div class="flex items-center space-x-5">
        <simple-select
          :items="passPhraseChoices"
          :modelValue="passPhraseEnabled"
          large
          @update:modelValue="passPhraseEnabled = $event"
          :class="passPhraseEnabled.key === false ? 'flex-1' : ''"
          >{{
            $filters.i18n("encryption_inputs_addPassword_label")
          }}</simple-select
        >
        <text-input
          :model-value="passPhrase"
          autocomplete="off"
          @update:modelValue="$emit('update:passPhrase', $event)"
          required
          :type="passFieldType"
          container-class="flex-1"
          @focus="handleType"
          @blur="handleType"
          v-if="passPhraseEnabled.key === true"
          >{{ $filters.i18n("Pass phrase") }}*</text-input
        >
      </div>
    </div>
  </main>
</template>

<script>
import TextInput from "@/components/DesignSystem/Inputs/TextInput";
import SimpleSelect from "@/components/DesignSystem/Inputs/SimpleSelect";
import { inject, ref, watch } from "vue";

export default {
  name: "KeysPairGenerationInputs",
  components: { SimpleSelect, TextInput },
  setup(props, { emit }) {
    const i18n = inject("i18n");
    const passFieldType = ref("text");
    const passPhraseChoices = [
      {
        title: i18n("No"),
        key: false,
      },
      {
        title: i18n("Yes"),
        key: true,
      },
    ];

    const passPhraseEnabled = ref(passPhraseChoices[0]);

    watch(passPhraseEnabled, (state) => {
      if (state.key === true && props.passPhrase === null) {
        emit("update:passPhrase", "");
      }

      if (state.key === false && props.passPhrase === "") {
        emit("update:passPhrase", null);
      }
    });

    const handleType = (event) => {
      const { srcElement, type } = event;
      const { value } = srcElement;

      if (type === "blur" && !value) {
        passFieldType.value = "text";
      } else {
        passFieldType.value = "password";
      }
    };

    return { passPhraseEnabled, passPhraseChoices, handleType, passFieldType };
  },
  props: {
    title: String,
    passPhrase: String,
  },
};
</script>

<style scoped lang="scss"></style>
