<template>
  <page-body>
    <template #mainBody>
      <dismissable-alert
        type="success"
        v-model:visible="confirmationMessage"
        v-if="confirmationMessage"
      >
        {{ $filters.i18n("register_confirmation_notice") }}
      </dismissable-alert>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="mx-auto w-auto h-12"
          src="../../assets/logo.png"
          alt="Workflow"
        />
        <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          {{ $filters.i18n("Reset password") }}
        </h2>
        <p class="mt-2 text-sm text-center text-gray-600">
          {{ $filters.i18n("Or") }}
          <router-link
            to="/auth/login"
            class="font-medium text-primary hover:text-primary-light"
          >
            {{ $filters.i18n("auth_reset_subtitle") }}
          </router-link>
        </p>
        <form @submit.prevent="newPassword">
          <div class="py-8 px-10 mt-8 space-y-5 carded">
            <text-input
              v-model="code"
              type="number"
              :placeholder="$filters.i18n('Enter your code')"
              class="py-3 px-3 bg-white"
              >{{
                $filters.i18n("auth_password_label_verification")
              }}</text-input
            >
            <text-input
              type="password"
              autocomplete="new-password"
              v-model="password"
              :disabled="requestInProgress"
              :placeholder="$filters.i18n('Enter new password')"
              class="py-3 px-3 bg-white"
              >{{ $filters.i18n("New Password") }}</text-input
            >
            <button type="submit" class="w-full button button-primary">
              <togglable-loading-state :loading="requestInProgress">
                {{ $filters.i18n("Submit") }}
              </togglable-loading-state>
            </button>
          </div>
        </form>
      </div>
    </template>
  </page-body>
</template>

<script>
import PageBody from "@/components/Layout/PageBody";
import TextInput from "@/components/DesignSystem/Inputs/TextInput";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import { Auth } from "aws-amplify";
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";

export default {
  name: "NewPassword",
  components: { DismissableAlert, TogglableLoadingState, TextInput, PageBody },
  props: {
    email: String,
  },
  mounted() {
    this.username = this.email || "";
  },
  data() {
    return {
      requestInProgress: false,
      username: "",
      code: "",
      password: "",
      confirmationMessage: true,
    };
  },
  methods: {
    async newPassword() {
      try {
        const result = await Auth.forgotPasswordSubmit(
          this.username,
          this.code,
          this.password
        );
        console.log(result);
        await this.$router.push({
          path: "/auth/login",
          query: { resetSuccessful: true },
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
