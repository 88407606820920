<template>
  <div
    class="flex overflow-hidden flex-col flex-1 justify-between items-center pt-8 carded"
  >
    <div class="hidden lg:block"></div>

    <div class="flex flex-col justify-center items-center w-full max-w-[500px]">
      <loading-spinner
        :hint="
          $filters.i18n(
            loading
              ? 'insightLoading_title_loading'
              : 'insightLoading_title_processing'
          ) + '...'
        "
      />
      <small
        class="block mt-6 max-w-[400px] italic font-light text-center text-gray-700 whitespace-pre-wrap"
        v-if="!loading"
      >
        {{ $filters.i18n("batchLoadingHint") }}
      </small>
    </div>

    <div
      class="flex justify-center items-center p-8 space-x-4 w-full bg-gray-50 bg-opacity-10 border-t border-gray-100 2xl:p-4 2xl:space-x-6"
    >
      <h3 class="block flex-1 text-sm text-gray-500">
        {{
          $filters.i18n(
            "If the process is taking way longer than usual or you think their might be an issue, please contact us !"
          )
        }}
      </h3>
      <a
        class="!py-1.5 px-3 text-xs button button-white"
        :href="`mailto:support@phedone.com?subject=${encodeURIComponent(
          $filters.i18n('The analysis proccess seems to be stuck') + ' – ' + id
        )}&body=${encodeURIComponent(
          $filters.i18n('Additional informations : ')
        )}%0D%0A`"
        >{{ $filters.i18n("Report an error") }}</a
      >
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/DesignSystem/Progress/LoadingSpinner";
export default {
  name: "InsightLoader",
  components: { LoadingSpinner },
  props: {
    id: String,
    loading: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
