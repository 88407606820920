<template>
  <div class="relative w-full max-w-full h-full max-h-full">
    <button
      class="absolute -top-3 -right-3 interactive button button-small button-soft-visible"
      @click="chartRef.chartJSState.chart.resetZoom()"
    >
      {{ $filters.i18n("filterable_histogram_reset") }}
    </button>
    <vue3-chart-js
      :data="stackedBarGraph.data"
      :type="stackedBarGraph.type"
      :plugins="stackedBarGraph.plugins"
      :options="stackedBarGraph.options"
      ref="chartRef"
      class="!w-full !max-w-full !h-full !max-h-full"
    />
  </div>
</template>

<script>
import { uniqueId } from "lodash";
import { onMounted, ref, watch } from "vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { useTailwindColors } from "@/logic/use-tailwind-colors";
import zoomPlugin from "chartjs-plugin-zoom";

export default {
  name: "StackedBarChart",
  data() {
    return {};
  },
  props: {
    dataSets: Array,
    labels: Array,
  },
  components: {
    Vue3ChartJs,
  },
  setup(props) {
    const chartRef = ref(`${uniqueId("stacked_bar_chart")}`);

    const stackedBarGraph = {
      type: "bar",
      data: {
        labels: [],
        datasets: [],
      },
      plugins: [zoomPlugin],
      options: {
        devicePixelRatio: 2,
        responsive: true,

        plugins: {
          zoom: {
            pan: {
              enabled: true,
              modifierKey: "shift",
              threshold: 10,
            },
            zoom: {
              limits: {
                y: { min: 5, minRange: 5 },
              },
              drag: {
                enabled: true,
                threshold: 2,
              },
              wheel: {
                enabled: false,
              },
              pinch: true,
              mode: "x",
            },
          },
          legend: {
            labels: {
              boxWidth: 20,
              padding: 8,
              font: {
                size: 12,
                lineHeight: 1.2,
                family:
                  'Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            type: "category",
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: true,
              autoSkipPadding: 50,
              maxRotation: 0,
              precision: 0,
            },
          },
          y: {
            stacked: true,
            grace: "5%",
            min: 0,
            grid: {
              drawBorder: false,
              color: useTailwindColors.asObject.gray["100"],
            },
          },
        },
        layout: {
          padding: 15,
        },
      },
    };

    onMounted(() => {
      watch(
        props,
        () => {
          const dataSets = props.dataSets || [];

          stackedBarGraph.data.labels = props.labels || [];
          stackedBarGraph.data.datasets = dataSets.map((dataset) => ({
            label: dataset.title,
            data: dataset.entries,
            backgroundColor: dataset.color,
            borderRadius: Number.MAX_VALUE,
            barThickness: 10,
          }));
          chartRef.value.update(`${uniqueId("stacked_bar_chart")}`);
        },
        {
          immediate: true,
        }
      );
    });

    return { chartRef, stackedBarGraph };
  },
};
</script>

<style scoped lang="scss"></style>
