<template>
  <page-body>
    <template #preBody>
      <dismissable-alert
        type="success"
        v-model:visible="confirmNotice"
        v-if="confirmNotice"
      >
        {{ $filters.i18n("register_confirmation_notice") }}
      </dismissable-alert>
    </template>
    <template #mainBody>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="mx-auto w-auto h-12"
          src="../../assets/logo.png"
          alt="Workflow"
        />
        <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          {{ $filters.i18n("Confirm Sign Up") }}
        </h2>
        <p class="mt-2 text-sm text-center text-gray-600">
          {{ $filters.i18n("Or") }}
          <router-link
            to="/auth/login"
            class="font-medium text-primary hover:text-primary-light"
          >
            {{ $filters.i18n("auth_reset_subtitle") }}
          </router-link>
        </p>
        <form @submit.prevent="confirmSignUp">
          <div class="py-8 px-10 mt-8 space-y-5 carded">
            <text-input
              v-model="username"
              type="email"
              class="py-3 px-3 bg-white"
              >{{ $filters.i18n("Email Address") }}</text-input
            >
            <text-input
              v-model="code"
              type="number"
              :placeholder="$filters.i18n('Enter your code')"
              class="py-3 px-3"
              >{{ $filters.i18n("Confirmation Code") }}</text-input
            >
            <div class="flex justify-end">
              <div class="text-sm text-right">
                <p class="mt-2 text-sm text-center text-gray-600">
                  {{ $filters.i18n("Lost your code?") }}
                  <button
                    @click="resendConfirmationCode()"
                    class="font-medium text-primary hover:text-primary-light"
                  >
                    {{ $filters.i18n("Resend Code") }}
                  </button>
                </p>
              </div>
            </div>
            <button
              type="submit"
              :disabled="requestInProgress"
              class="w-full button button-primary"
            >
              <togglable-loading-state :loading="requestInProgress">{{
                $filters.i18n("Confirm")
              }}</togglable-loading-state>
            </button>
          </div>
        </form>
      </div>
    </template>
  </page-body>
</template>

<script>
import PageBody from "@/components/Layout/PageBody";
import TextInput from "@/components/DesignSystem/Inputs/TextInput";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import { Auth } from "aws-amplify";
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";
export default {
  name: "ConfirmAccount",
  components: { DismissableAlert, TogglableLoadingState, TextInput, PageBody },
  props: {
    email: String,
  },
  mounted() {
    this.username = this.email || "";
  },
  data() {
    return {
      requestInProgress: false,
      username: "",
      code: "",
      confirmNotice: true,
    };
  },
  methods: {
    async confirmSignUp() {
      this.requestInProgress = true;
      try {
        const result = await Auth.confirmSignUp(this.username, this.code);
        if (result === "SUCCESS")
          await this.$router.push({
            path: "/auth/login",
            query: { email: this.username, confirmationSuccessful: true },
          });
      } catch (error) {
        console.log("error confirming sign up", error);
      }
      this.requestInProgress = false;
    },

    async resendConfirmationCode() {
      try {
        await Auth.resendSignUp(this.username);
        console.log("code resent successfully");
      } catch (err) {
        console.log("error resending code: ", err);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
