<template>
  <div>
    <h2 class="text-lg">
      {{ $filters.i18n("blacklist_text") }}
    </h2>
    <div class="flex pt-5 space-x-4 w-full">
      <keywords-input v-model="text" />
      <chevron-double-right-icon class="my-auto w-8 h-8 text-primary-dark" />
      <keywords-list @update:keywords="updateKeywords" :keywords="modelValue" />
    </div>
  </div>
</template>

<script>
import KeywordsInput from "@/components/keyword/KeywordsInput";
import KeywordsList from "@/components/keyword/KeywordsList";
import { ChevronDoubleRightIcon } from "@heroicons/vue/solid";

export default {
  name: "KeywordsManager",
  components: { KeywordsList, KeywordsInput, ChevronDoubleRightIcon },
  props: {
    modelValue: Array,
  },
  data() {
    return {
      text: "",
    };
  },
  //array to text
  methods: {
    updateKeywords(keywords) {
      this.$emit("update:modelValue", keywords);
      this.text = keywords.join(",") + ",";
    },
  },
  computed: {
    keywordsFromText() {
      return this.text
        .split(",")
        .map((keyword) => keyword.trim())
        .filter((keyword) => keyword.length > 0);
    },
  },
  watch: {
    keywordsFromText: function (newKeywordsFromText) {
      this.$emit("update:modelValue", newKeywordsFromText);
    },
  },
};
</script>

<style scoped></style>
