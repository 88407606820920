import chroma from "chroma-js";

const colorScale = chroma.scale(["B71C1C", "C9B9BC", "4CAF50"]);

const percentageFromScore = (score) => 0.5 + score;

const scoreToColor = (score, inputIsPercentage = false) => {
  return colorScale(
    inputIsPercentage ? score : percentageFromScore(score)
  ).hex();
};

const scoreToTextColor = (score, inputIsPercentage = false) => {
  let perc = inputIsPercentage ? score : percentageFromScore(score);
  if (perc >= 0.35 && perc <= 0.65) {
    return "#000000";
  }

  return "#ffffff";
};

const scoreOnFive = (score) => {
  return Math.round(2.5 + score * 2.5);
};

export const useClustersScoreCalculations = {
  scoreToColor: scoreToColor,
  scoreToTextColor: scoreToTextColor,
  scoreOnFive: scoreOnFive,
};
