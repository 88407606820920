<template>
  <button
    type="button"
    class="button button-white"
    :class="toggled ? toggledColorClass : colorClass"
    @click="toggle()"
  >
    <slot name="icon"></slot>
    <span>{{ toggled ? toggleTitle : title }}</span>
  </button>
</template>

<script>
export default {
  name: "TogglableStateButton",
  data() {
    return {};
  },
  props: {
    toggled: Boolean,
    title: String,
    toggleTitle: String,
    colorClass: { type: String, default: "button-white" },
    toggledColorClass: { type: String, default: "button-light-red" },
  },
  methods: {
    toggle() {
      this.$emit("toggle");
    },
  },
};
</script>

<style scoped lang="scss"></style>
