<template>
  <div
    class="flex flex-col justify-between items-center w-full max-w-full h-full max-h-full"
  >
    <div class="flex relative flex-col flex-1 items-end w-full h-0 chartParent">
      <horizontal-radio-group
        @update:model-value="$emit('update:unitKey', $event.key)"
        :model-value="timeRangeType"
        :entries="timeRangeTypes"
        class="-mt-2 mb-2"
        >{{ $filters.i18n("time_unit") }} :
      </horizontal-radio-group>

      <!--      <button
        class="button button-small button-soft-visible"
        @click="chartRef.chartJSState.chart.resetZoom()"
      >
        {{ $filters.i18n("filterable_histogram_reset") }}
      </button>-->

      <div class="relative flex-1 w-full h-0">
        <vue3-chart-js
          :data="matrixGraph.data"
          :type="matrixGraph.type"
          :plugins="matrixGraph.plugins"
          :options="matrixGraph.options"
          ref="chartRef"
          class="flex-1 !w-full !max-w-full !h-full !max-h-full"
          :class="!isMonth && 'cursor-[ew-resize]'"
        />
      </div>
    </div>

    <div class="flex justify-center items-center mt-4 space-x-2">
      <small class="inline-block">0</small>
      <div class="inline-flex overflow-hidden items-center rounded">
        <span class="inline-block w-4 h-4 bg-sky-500/[0.05]"></span>
        <span
          v-for="i in 9"
          class="inline-block w-4 h-4 bg-sky-500"
          :style="`opacity: ${i * 0.1}`"
          :key="i"
        ></span>
      </div>
      <small class="inline-block">{{ maxNumberOfEntries }}</small>
    </div>
  </div>
</template>

<script>
import { uniqueId } from "lodash";
import zoomPlugin from "chartjs-plugin-zoom";

import { onMounted, ref, watch, computed, inject } from "vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

import { MatrixElement, MatrixController } from "chartjs-chart-matrix";
import "chartjs-adapter-luxon";

import { useStore } from "vuex";
import HorizontalRadioGroup from "@/components/DesignSystem/Radios/HorizontalRadioGroup";

export default {
  name: "MatrixChart",
  data() {
    return {};
  },
  props: {
    days: Array,
    months: Array,
    verticalLabels: Array,
    horizontalLabels: Array,
    horizontalLabelsMonths: Array,
    unitKey: String,
    labels: Array,
  },
  components: {
    HorizontalRadioGroup,
    Vue3ChartJs,
  },
  setup(props) {
    const i18n = inject("i18n");
    const chartRef = ref(`${uniqueId("matrix_chart")}`);
    // eslint-disable-next-line no-unused-vars
    const store = useStore();
    const visibleCols = ref(31);
    Vue3ChartJs.registerGlobalPlugins([MatrixController, MatrixElement]);

    const timeRangeTypes = [
      {
        title: "period_days",
        key: "day",
      },
      {
        title: "period_months",
        key: "month",
      },
    ].map((r) => ({ ...r, title: i18n(r.title) }));

    const timeRangeType = computed(() =>
      timeRangeTypes.find((t) => t.key === props.unitKey)
    );

    const timeUnit = computed(() => timeRangeType.value.key);

    const isMonth = computed(() => timeUnit.value === "month");

    const matrixGraph = {
      type: "matrix",
      data: {
        labels: [],
        datasets: [],
      },
      plugins: [zoomPlugin],
      options: {
        responsive: true,

        devicePixelRatio: 2,
        scales: {
          x: {
            min: (isMonth.value
              ? props.horizontalLabelsMonths
              : props.horizontalLabels || [])[0],
            max: (isMonth.value
              ? props.horizontalLabelsMonths
              : props.horizontalLabels || [])[visibleCols.value - 1],
            type: isMonth.value ? "category" : "time",
            ...(!isMonth.value && {
              time: {
                unit: timeUnit.value || "month",
              },
              adapters: {
                date: {
                  locale: store.state.locale,
                },
              },
            }),
            ...(isMonth.value && { labels: props.horizontalLabels }),
            ticks: {
              display: true,
              autoSkip: false,
              autoSkipPadding: isMonth.value ? 10 : 50,
              ...(!isMonth.value && { maxRotation: 0 }),
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            align: "center",
            type: "category",
            z: 101,
            labels: props.verticalLabels,
            offset: true,
            ticks: {
              display: "top",
              padding: 0,
              backdropColor: "#fff",
              backdropPadding: 5,
              showLabelBackdrop: true,
              z: 100,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          zoom: {
            pan: {
              enabled: !isMonth.value,
              mode: "x",
              threshold: 2,
            },
            zoom: {
              drag: {
                enabled: false,
              },
              wheel: {
                enabled: false,
              },
              pinch: false,
              mode: "x",
            },
          },
          tooltip: {
            callbacks: {
              title() {
                return "";
              },
              label(context) {
                const v = context.dataset.data[context.dataIndex];
                return [
                  "Date: " + isMonth.value
                    ? new Date(v.formattedDate).toLocaleString(
                        store.state.locale,
                        {
                          month: "long",
                          year: "numeric",
                        }
                      )
                    : new Date(v.formattedDate).toLocaleString(
                        store.state.locale,
                        {
                          day: "numeric",
                          month: "long",
                          weekday: "long",
                          year: "numeric",
                        }
                      ),
                  "Theme: " + v.y,
                  "Contributions : " + v.value,
                ];
              },
            },
          },
        },
      },
    };

    const maxNumberOfEntries = ref(1);

    onMounted(() => {
      watch(
        props,
        () => {
          const dataset =
            props[`${(timeUnit.value && timeUnit.value) || "month"}s`] || [];

          maxNumberOfEntries.value = Math.max(
            ...dataset.map((o) => o.value),
            1
          );

          if (isMonth.value) {
            matrixGraph.data.labels = props.horizontalLabelsMonths || [];
          }

          matrixGraph.data.datasets = [
            {
              clip: 0,
              data: dataset,
              backgroundColor(context) {
                const numberOfEntries =
                  context.dataset.data[context.dataIndex].value;

                const alpha = Math.max(
                  numberOfEntries / (maxNumberOfEntries.value * 0.75),
                  0.05
                );

                if (!isMonth.value) {
                  let point = context.dataset.data[context.dataIndex];
                  let xAxis = context.chart.scales.x;

                  return point.x >= xAxis.min && point.x <= xAxis.max
                    ? `rgba(14, 165, 233, ${alpha})`
                    : "transparent";
                }

                return `rgba(14, 165, 233, ${alpha})`;
              },
              hoverBackgroundColor(context) {
                const numberOfEntries =
                  context.dataset.data[context.dataIndex].value;
                const alpha = Math.max(
                  numberOfEntries / (maxNumberOfEntries.value * 0.75),
                  0.05
                );

                return `rgba(14, 165, 233, ${alpha})`;
              },
              width: ({ chart }) =>
                Math.max(
                  (chart.chartArea || {}).width / visibleCols.value,
                  40
                ) - (isMonth.value ? 1 : 4),
              height: ({ chart }) =>
                (chart.chartArea || {}).height / props.verticalLabels.length -
                4,
              categoryPercentage: 1.0,
              barPercentage: 1.0,
            },
          ];

          chartRef.value.update(`${uniqueId("matrix_chart")}`);
        },
        {
          immediate: true,
        }
      );
    });

    return {
      chartRef,
      matrixGraph,
      maxNumberOfEntries,
      timeRangeType,
      timeRangeTypes,
      timeUnit,
      isMonth,
    };
  },
};
</script>

<style scoped lang="scss"></style>
