<template>
  <page-body>
    <template #mainBody>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="mx-auto w-auto h-12"
          src="../../assets/logo.png"
          alt="Workflow"
        />
        <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          {{ $filters.i18n("Reset password") }}
        </h2>
        <p class="mt-2 text-sm text-center text-gray-600">
          {{ $filters.i18n("Or") }}
          <router-link
            to="/auth/login"
            class="font-medium text-primary hover:text-primary-light"
          >
            {{ $filters.i18n("auth_reset_subtitle") }}
          </router-link>
        </p>
        <form @submit.prevent="forgot">
          <div class="py-8 px-10 mt-8 space-y-5 carded">
            <text-input
              type="email"
              v-model="username"
              :disabled="requestInProgress"
              :placeholder="$filters.i18n('Enter your email address')"
              class="py-3 px-3 bg-white"
              >{{ $filters.i18n("Email*") }}</text-input
            >
            <button type="submit" class="w-full button button-primary">
              <togglable-loading-state :loading="requestInProgress">
                {{ $filters.i18n("Send Code") }}
              </togglable-loading-state>
            </button>
          </div>
        </form>
      </div>
    </template>
  </page-body>
</template>

<script>
import PageBody from "@/components/Layout/PageBody";
import TextInput from "@/components/DesignSystem/Inputs/TextInput";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import { Auth } from "aws-amplify";

export default {
  name: "ResetPassword",
  components: { TogglableLoadingState, TextInput, PageBody },

  data() {
    return {
      requestInProgress: false,
      username: "",
    };
  },
  methods: {
    async forgot() {
      this.requestInProgress = true;
      try {
        await Auth.forgotPassword(this.username);
        await this.$router.push({
          path: "/auth/confirm/password",
          query: { email: this.username },
        });
      } catch (error) {
        console.log(error);
      }
      this.requestInProgress = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
