import { createStore } from "vuex";
import _ from "lodash";
import LogRocket from "logrocket";
import { I18n } from "@aws-amplify/core";
import createPersistedState from "vuex-persistedstate";

export const store = createStore({
  plugins: [
    createPersistedState({
      paths: ["locale"],
    }),
  ],
  state: {
    user: null,
    userRoles: [],
    userFetched: false,
    signedIn: false,
    pageTitle: I18n.get("Loading..."),
    focusedClusterIds: [],
    focusedIdeaId: null,
    locale: "fr",
  },
  mutations: {
    setUser(state, user) {
      state.user = user;

      if (user != null) {
        state.signedIn = true;

        LogRocket.identify(user.username, {
          email: user.attributes.email,
          // Add your own custom user variables here, ie:
          subscriptionType:
            state.userRoles.length > 0 ? state.userRoles[0] : "default",
        });
      }
    },
    setUserRoles(state, roles) {
      state.userRoles = roles;
    },
    setSignedIn(state, signedIn) {
      state.signedIn = signedIn;
    },
    setPageTitle(state, pageTitle) {
      state.pageTitle = pageTitle;
    },
    setFocusedClusterId(state, id) {
      state.focusedClusterId = id;
    },
    addFocusedClusterId(state, id) {
      state.focusedClusterIds.push(id);
    },
    removeFocusedClusterId(state, id) {
      _.pull(state.focusedClusterIds, id);
      state.focusedClusterIds = [...state.focusedClusterIds];
    },
    setFocusedIdeaId(state, id) {
      state.focusedIdeaId = id;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
  },
  getters: {
    isUserAPilot: (state) => {
      return state.userRoles.includes("pilots");
    },
    isUserASelfSubscribed: (state) => {
      return state.userRoles.includes("selfsubscribed");
    },
  },
  actions: {},
  modules: {},
});
