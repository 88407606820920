<template>
  <div class="relative" v-click-away="close">
    <slot :open="open">
      <button
        type="button"
        class="relative py-2 pr-10 pl-3 w-full text-left bg-white rounded-md border border-gray-300 focus:border-primary focus:ring-1 focus:ring-primary shadow-sm cursor-default focus:outline-none sm:text-sm"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        @click="active = true"
      >
        <span class="block truncate">{{ selectedItem }}</span>
        <span
          class="flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none"
          ><svg
            class="w-5 h-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>
    </slot>

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ul
        class="overflow-auto overflow-y-auto absolute right-0 z-50 py-1 mt-1 max-h-[300px] text-base bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg focus:outline-none sm:text-sm"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        v-if="active"
      >
        <li
          class="relative py-2 pr-9 pl-3 cursor-pointer select-none"
          :class="
            i === modelValue
              ? 'text-white bg-primary-dark'
              : 'text-gray-900 hover:bg-primary-dark hover:bg-opacity-10'
          "
          id="listbox-option-0"
          role="option"
          v-for="(item, i) in items"
          :key="i"
          @click="$emit('update:modelValue', i)"
        >
          <span
            class="block truncate"
            :class="i === modelValue ? 'font-semibold' : 'font-normal'"
          >
            {{ item }}
          </span>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Select",
  data() {
    return {
      active: false,
    };
  },
  props: {
    modelValue: { required: true },
    items: { type: Array, required: true },
  },
  methods: {
    open() {
      this.active = true;
    },
    close() {
      this.active = false;
    },
  },
  computed: {
    selectedItem() {
      return this.items[this.modelValue];
    },
  },
};
</script>

<style scoped lang="scss"></style>
