<template>
  <section class="flex overflow-y-auto flex-col w-full">
    <header>
      <dismissable-alert
        :visible="globalError != null"
        @update:visible="globalError = null"
        v-if="globalError"
      >
        {{ globalError }}
      </dismissable-alert>
      <slot name="preBody" />
    </header>
    <main
      class="flex-1 flex-grow w-full border-t border-gray-200 lg:flex"
      style="max-height: calc(100% - 4.75rem)"
    >
      <aside
        class="lg:flex-shrink-0 lg:max-w-md xl:max-w-[400px]"
        v-if="!!this.$slots.leftBodyPane"
      >
        <div class="py-6 h-full sm:px-6">
          <div class="relative h-full">
            <slot name="leftBodyPane" />
          </div>
        </div>
      </aside>

      <section class="flex-1 min-w-0 bg-transparent xl:flex">
        <div class="flex flex-col bg-gray-100 lg:flex-1 lg:min-w-0 lg:h-full">
          <div class="flex-1 py-6 h-full">
            <div class="relative h-full">
              <slot name="mainBody" />
            </div>
          </div>
        </div>
      </section>

      <aside
        class="lg:flex-shrink-0 lg:max-w-sm xl:max-w-[350px]"
        v-if="!!this.$slots.rightBodyPane"
      >
        <div class="py-6 h-full sm:px-6">
          <div class="relative h-full">
            <slot name="rightBodyPane" />
          </div>
        </div>
      </aside>
    </main>

    <slot name="endOfBody" />
  </section>
</template>

<script>
import { provide, ref } from "vue";
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";

export default {
  name: "PageBody",
  components: { DismissableAlert },
  setup() {
    const globalError = ref(null);
    provide("globalError", globalError);

    return { globalError };
  },
};
</script>

<style scoped lang="scss"></style>
