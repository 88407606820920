<template>
  <div class="relative w-full h-full">
    <LoadingChartOverlay
      v-if="!chartReady"
      :hint="$filters.i18n(`Authors connections loading in progress`)"
    />
    <div
      id="connectionsLinks"
      ref="chartElementRef"
      class="absolute inset-5 lg:inset-y-16"
    />
    <slider-links-precision
      class="absolute right-6 bottom-8"
      v-model:percentage="linksPrecision"
    />
  </div>
</template>

<script>
import LoadingChartOverlay from "./LoadingChartOverlay";
import SliderLinksPrecision from "./SliderLinksPrecision";
import { debounce } from "lodash";
import {
  nextTick,
  onMounted,
  ref,
  watchEffect,
  computed,
  onBeforeUnmount,
  watch,
} from "vue";
import { useConnectionsLinksChart } from "@/components/DataVizualisation/use-connections-links-chart";

export default {
  name: "ConnectionsLinks",
  components: { SliderLinksPrecision, LoadingChartOverlay },
  setup(props) {
    const chartElementRef = ref(null);
    const chart = ref(null);

    const {
      setupChartWithActualPrecision,
      startGeneratingLinksBetweenContributorsByPrecision,
      chartReady,
    } = useConnectionsLinksChart(chart, chartElementRef);

    const defaultLinkPrecision =
      props.contributors.length < 50
        ? 0.15
        : props.contributors.length < 100
        ? 0.2
        : props.contributors.length < 200
        ? 0.25
        : 0.3;

    const linksPrecision = ref(defaultLinkPrecision);

    const contributionsWithContributorName = computed(() => {
      let contributionsWithContributorName = {};
      for (const contribution of props.contributions) {
        contributionsWithContributorName[contribution.id] =
          contribution.author.name;
      }

      return contributionsWithContributorName;
    });

    watchEffect(() => {
      if (props.visible && chart.value) {
        setTimeout(async () => {
          await nextTick();
          try {
            chart.value.svgContainer.autoResize = false;
          } catch (e) {
            console.error(e);
          }
        }, 1);
      }
    });

    watch(
      linksPrecision,
      debounce(function (precision) {
        setupChartWithActualPrecision(precision);
      }, 220)
    );

    onMounted(() => {
      try {
        startGeneratingLinksBetweenContributorsByPrecision(
          linksPrecision.value,
          props.contributors,
          props.contributions,
          contributionsWithContributorName.value
        );
      } catch (e) {
        console.error(e);
      }
    });

    onBeforeUnmount(() => {
      if (chart.value) {
        chart.value.dispose();
      }
    });

    return {
      linksPrecision,
      chartElementRef,
      chartReady,
    };
  },
  props: {
    contributors: { type: Array, default: null, required: false },
    contributions: { type: Array, default: null, required: false },
    visible: Boolean,
  },
};
</script>

<style scoped lang="scss"></style>
