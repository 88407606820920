<template>
  <badge
    :title="`${$filters.i18n('Polarity')} : ${textFromScore}`"
    :color="!noColor ? colorFromScore : null"
  >
    <slot />{{ textFromScore }}
  </badge>
</template>

<script>
import Badge from "@/components/DesignSystem/Badges/Badge";
export default {
  name: "TonalityBadge",
  components: { Badge },
  data() {
    return {};
  },
  computed: {
    scoreOnFive() {
      return Math.round(2.5 + this.score * 2.5);
    },
    colorFromScore() {
      return ["red", "amber", "gray", "teal", "lime"][
        Math.max(0, this.scoreOnFive - 1)
      ];
    },
    textFromScore() {
      return this.$filters.i18n(
        (this.emojis
          ? ["1/5", "2/5", "3/5", "4/5", "5/5"]
          : [
              "negative",
              "middly negative",
              "neutral",
              "middly positive",
              "positive",
            ])[Math.max(0, this.scoreOnFive - 1)]
      );
    },
  },
  props: {
    score: Number,
    emojis: Boolean,
    noColor: Boolean,
  },
};
</script>

<style scoped lang="scss"></style>
