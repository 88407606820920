export const useDesktopNotifications = {
  requestNotificationsPermissions: () => {
    if (Notification.permission !== "granted") Notification.requestPermission();
  },
  notifyDesktop: (
    title,
    body,
    focusTabOnClick = false // As we reload the analysis on results available (80/20 quick workaround), the focus feature just open a new windows, as the previous one is not exactly the same after reload
  ) => {
    try {
      let notification = new Notification(title, {
        icon: "/phedone_full_color_192x192.png",
        body: body,
        requireInteraction: focusTabOnClick,
      });
      if (focusTabOnClick) {
        notification.onclick = function () {
          window.focus();
          this.close();
        };
      }
    } catch (e) {
      console.error(e);
    }
  },
};
