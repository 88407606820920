<template>
  <div class="flex overflow-hidden flex-col h-screen bg-gray-100">
    <Header />
    <main class="flex overflow-hidden flex-1 min-h-0">
      <sidebar />
      <router-view />
    </main>
    <work-in-progress-modal v-model:visible="workInProgressModalVisible" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "@/components/Layout/Sidebar";
import Header from "@/components/Layout/Header";
import WorkInProgressModal from "@/components/Layout/WorkInProgressModal";
import { provide, ref, nextTick, watchEffect } from "vue";
import { setupTour } from "@/logic/use-tour";

export default {
  name: "App",
  metaInfo() {
    return this.storeMetaInfos;
  },
  setup() {
    const workInProgressModalVisible = ref(false);
    const tutorialVisibility = ref(false);
    const displayTutorialButton = ref(false);

    const openWorkInProgressModal = () => {
      workInProgressModalVisible.value = true;
    };

    const setTutorialVisible = () => {
      tutorialVisibility.value = true;
    };

    const setTutorialInvisible = () => {
      tutorialVisibility.value = false;
    };

    const toggleTutorialVisibiliy = () => {
      tutorialVisibility.value = !tutorialVisibility.value;
    };

    provide("setTutorialVisible", setTutorialVisible);
    provide("setTutorialInvisible", setTutorialInvisible);
    provide("toggleTutorialVisibiliy", toggleTutorialVisibiliy);

    const tour = ref(null);

    const setupTourOnComponent = (i18n, page, additionalFunctions = {}) => {
      console.log("Setting up tour on component");
      nextTick(async () => {
        await new Promise((r) => setTimeout(r, 4000));

        tour.value = setupTour(
          i18n,
          page,
          additionalFunctions,
          tutorialVisibility
        );
      });
    };

    provide("setupTourOnComponent", setupTourOnComponent);
    provide("tour", tour);

    provide("openWorkInProgressModal", openWorkInProgressModal);

    provide("tutorialVisibility", tutorialVisibility);

    provide("displayTutorialButton", displayTutorialButton);

    watchEffect(() => {
      console.log(tutorialVisibility.value);
    });

    return { workInProgressModalVisible };
  },
  components: {
    WorkInProgressModal,
    Header,
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
  },
  computed: {
    ...mapState(["pageTitle", "signedIn"]),
    storeMetaInfos() {
      return {
        title: this.pageTitle || this.$filters.i18n("Loading..."),
        titleTemplate: "%s — Phedone",
      };
    },
  },
  watch: {
    pageTitle(title) {
      console.log(title);
    },
    async signedIn(signedIn) {
      console.log("signed in state", signedIn);
      if (signedIn) {
        try {
          // await this.$.provides.apolloProvider.defaultClient.hydrated();
        } catch (e) {
          console.error(e);
        }
      }

      this.appReady = true;
    },
  },
  data: () => ({
    appReady: false,
  }),
  methods: {},
  props: { displayLoginBox: { type: Boolean, default: true } },
};
</script>
