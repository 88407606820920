<template>
  <div @scroll="throttledScrollEvent">
    <ul :class="['divide-y divide-gray-200', listClass]">
      <li
        v-for="entry in entriesToDisplay"
        :key="entry[keyToUse]"
        class="relative"
      >
        <slot :entry="entry"></slot>
      </li>
    </ul>
    <slot name="endOfList" />
    <div
      :class="buttonParentClass || 'mt-3 mb-6 mx-6'"
      v-if="shouldDisplayViewMore"
    >
      <button
        @click="showMore()"
        class="flex justify-center items-center w-full button button-white"
      >
        {{ buttonTitle }}
      </button>
    </div>
  </div>
</template>

<script>
import throttle from "lodash";
export default {
  name: "DisplayMoreList",
  components: {},
  data() {
    return {
      displaying: 0,
    };
  },
  props: {
    listClass: String,
    buttonParentClass: String,
    limit: {
      type: Number,
      default: 0,
    },
    keyToUse: { type: String, default: "id" },
    entries: { type: Array, default: () => [] },
    buttonTitle: String,
  },
  mounted() {
    if (this.limit > 0) {
      this.displaying = this.limit;
    }
  },
  methods: {
    throttledScrollEvent($event) {
      return throttle(this.emitScrollEvent($event), 200);
    },
    emitScrollEvent($event) {
      return this.$emit("scroll", $event);
    },
    showMore() {
      this.displaying += this.limit;
    },
  },
  computed: {
    shouldDisplayViewMore() {
      return (
        this.limit !== 0 &&
        this.entries &&
        this.entries.length &&
        this.displaying < this.entries.length
      );
    },
    entriesToDisplay() {
      if (this.entries && this.entries.length > 0) {
        if (this.displaying != 0) {
          return this.entries.slice(0, this.displaying);
        } else {
          return this.entries;
        }
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
