<template>
  <informations-bar :title="title || $filters.i18n('Loading...')">
    <template #infos>
      <template v-if="date">
        <dt class="sr-only">{{ $filters.i18n("Creation date") }}</dt>
        <dd class="flex items-center text-sm font-medium text-gray-500 sm:mr-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="flex-shrink-0 mr-1.5 w-5 h-5 text-gray-400"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            />
          </svg>
          {{ formattedDate }}
        </dd>

        <dt class="sr-only">Results availability</dt>
        <dd
          class="flex items-center mt-3 text-sm font-medium text-gray-500 sm:mt-0 sm:mr-6"
        >
          <template v-if="resultsAvailable">
            <svg
              class="flex-shrink-0 mr-1.5 w-5 h-5 text-green-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            {{ $filters.i18n("Results available") }}
          </template>
          <template v-else>
            <svg
              class="flex-shrink-0 mr-1.5 w-5 h-5 text-primary"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                clip-rule="evenodd"
              />
            </svg>
            {{ $filters.i18n("Processing") }}
          </template>
        </dd>
      </template>
    </template>
    <template #actions>
      <insight-delete-button :session-id="sessionId" v-if="canDelete" />

      <dropdown-button
        v-if="canExport"
        :actions="[
          {
            title: $filters.i18n('export_clusters'),
            callback: () => $emit('export-clusters-to-xlsx'),
          },
          {
            title: $filters.i18n('export_contributions'),
            callback: () => $emit('export-contributions-to-xlsx'),
          },
        ]"
      >
        {{ $filters.i18n("exportVerb") }}
      </dropdown-button>

      <a
        :href="`/insights/${sessionId}${isDashboard ? '' : '/dashboard'}`"
        class="interactive button button-soft-visible"
        id="dashboard_exploration_switch"
        >{{
          $filters.i18n(
            isDashboard ? "insight_exploration_link" : "insight_dashboard_link"
          )
        }}</a
      >
    </template>
  </informations-bar>
</template>

<script>
import InformationsBar from "@/components/DesignSystem/Bars/InformationsBar";
import _ from "lodash";
import InsightDeleteButton from "@/components/Insights/InsightDeleteButton";
import DropdownButton from "@/components/DesignSystem/Buttons/DropdownButton";

export default {
  name: "InsightInformationBar",
  components: { DropdownButton, InsightDeleteButton, InformationsBar },
  props: {
    title: String,
    resultsAvailable: Boolean,
    date: String,
    canDelete: Boolean,
    canExport: { type: Boolean, default: true },
    sessionId: String,
    isDashboard: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    formattedDate() {
      if (this.date) {
        let date = new Date(this.date);

        return _.capitalize(
          date.toLocaleDateString(this.$filters.i18n("phedoneLocale"), {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        );
      }

      return null;
    },
  },
};
</script>

<style scoped lang="scss"></style>
