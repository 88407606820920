<template>
  <TransitionRoot as="template" :show="show">
    <Dialog
      as="div"
      static
      class="overflow-y-auto fixed inset-0 z-[50]"
      @close="closeOnOutsideClick ? $emit('close') : null"
      :open="show"
    >
      <div
        class="flex justify-center items-end px-4 pt-4 pb-20 min-h-screen text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-black bg-opacity-40 transition-opacity cursor-pointer"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block px-4 pt-5 pb-4 text-left align-bottom bg-white rounded-lg shadow-xl transition-all transform sm:p-6 sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
            :class="overflowVisible ? '' : 'overflow-hidden'"
          >
            <div class="hidden absolute top-0 right-0 pt-4 pr-4 sm:block">
              <button
                type="button"
                class="text-gray-400 hover:text-gray-500 bg-white rounded-md focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:outline-none"
                @click="$emit('close')"
              >
                <span class="sr-only">{{ $filters.i18n("Close") }}</span>
                <XIcon class="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div
                class="flex flex-shrink-0 justify-center items-center mx-auto w-12 h-12 bg-red-100 rounded-full sm:mx-0 sm:w-10 sm:h-10"
              >
                <component
                  :is="type === 'alert' ? 'ExclamationIcon' : 'PencilIcon'"
                  class="w-6 h-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                :class="fullWidthContent ? 'w-full' : ''"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  <slot name="title" />
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500"><slot name="content" /></p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
              <button
                type="button"
                class="inline-flex justify-center py-2 px-4 w-full text-base font-medium text-white bg-red-600 hover:bg-red-700 rounded-md border border-transparent focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-sm focus:outline-none sm:ml-3 sm:w-auto sm:text-sm button interactive"
                @click="$emit('action-clicked')"
                :disabled="actionDisabled"
              >
                <slot name="action" />
              </button>
              <button
                type="button"
                class="inline-flex justify-center py-2 px-4 mt-3 w-full text-base font-medium text-gray-700 hover:text-gray-500 bg-white rounded-md border border-gray-300 focus:ring-2 focus:ring-primary focus:ring-offset-2 shadow-sm focus:outline-none sm:mt-0 sm:w-auto sm:text-sm button interactive"
                @click="$emit('close')"
              >
                {{ cancel || $filters.i18n("Cancel") }}
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { ExclamationIcon, XIcon, PencilIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    XIcon,
    PencilIcon,
  },
  emits: ["action-clicked", "close"],
  props: {
    show: Boolean,
    cancel: { type: String, default: null },
    fullWidthContent: { type: Boolean, default: false },
    actionDisabled: { type: Boolean, default: false },
    overflowVisible: { type: Boolean, default: false },
    closeOnOutsideClick: { type: Boolean, default: true },
    type: { type: String, default: "alert" }, // ['alert', 'action']
  },
};
</script>
