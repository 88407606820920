const frenchDateStringToGlobalDate = (frenchDate = null) => {
  if (!frenchDate) {
    return null;
  }

  let parts = frenchDate.split("/");
  return new Date(
    parseInt(parts[2], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[0], 10)
  );
};

const frenchDateStringToTimeStamp = (frenchDate = null) => {
  if (!frenchDate) {
    return null;
  }

  let parts = frenchDate.split("/");
  return +new Date(
    parseInt(parts[2], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[0], 10)
  );
};

const dateToTimestamp = (date) => new Date(date).getTime();

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

function getDatesInRange(startDate, stopDate) {
  let dateArray = new Array();
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}

export const useDateHelpers = {
  frenchDateStringToGlobalDate: frenchDateStringToGlobalDate,
  frenchDateStringToTimeStamp: frenchDateStringToTimeStamp,
  getDatesInRange: getDatesInRange,
  dateToTimestamp: dateToTimestamp,
};
