<template>
  <div
    class="group inline-flex max-w-full rounded-md shadow-sm transition cursor-pointer"
  >
    <div
      class="flex flex-shrink-0 justify-center items-center w-16 text-sm font-medium text-white rounded-l-md"
      :class="`bg-${color}-600`"
      :style="
        colorHex ? `background: ${colorHex}; color: ${titleColorHex}` : ''
      "
    >
      {{ initiales }}
    </div>
    <div
      class="flex flex-1 justify-between items-center truncate bg-white group-hover:bg-gray-50 rounded-r-md border-t border-r border-b transition bordered"
    >
      <div class="flex-1 py-2 px-4 text-sm truncate">
        <div
          class="font-medium text-gray-900 hover:text-gray-600 truncate"
          :title="capitalizedTitle"
        >
          {{ capitalizedTitle }}
        </div>
        <div class="flex space-x-3">
          <p class="text-gray-500">
            {{ contributionsCount }} {{ $filters.i18n("contributions") }}
          </p>
          <tonality-badge
            :score="polarity"
            v-if="polarity !== null && polarity !== undefined"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import TonalityBadge from "@/components/DesignSystem/Badges/TonalityBadge";

export default {
  name: "SmallClusterCard",
  components: { TonalityBadge },
  data() {
    return {};
  },
  props: {
    title: String,
    polarity: Number,
    color: { type: String, default: "purple" },
    colorHex: { type: String },
    titleColorHex: { type: String },
    contributionsCount: Number,
  },
  computed: {
    initiales() {
      return _.upperCase(this.title).slice(0, 2);
    },
    capitalizedTitle() {
      return _.capitalize(this.title);
    },
  },
};
</script>

<style scoped lang="scss"></style>
