<template>
  <dropdown-list
    class="absolute top-full left-6 -mt-4"
    :entries="entries"
    @entry-clicked="callContributionClusterUpdate"
  >
    <template #header>
      <togglable-loading-state
        class="absolute inset-0 !justify-start bg-white bg-opacity-20"
        :loading="loading"
        :white="false"
      >
        <b class="text-sm font-medium text-gray-900 truncate">
          {{ $filters.i18n(editing ? "Update the theme" : "Attach a theme") }}
        </b>
        <p class="text-xs" v-if="editing">
          {{ $filters.i18n("It will replace the current one") }}
        </p>
      </togglable-loading-state>
    </template>
  </dropdown-list>
</template>

<script>
import DropdownList from "@/components/DesignSystem/Dropdown/DropdownList";
import { inject, toRefs } from "vue";
import { useUpdateContributionCluster } from "../../logic/use-api-fetch";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";

export default {
  name: "ContributionUpdateClusterDropdown",
  components: { TogglableLoadingState, DropdownList },
  props: {
    entries: Array,
    contributionId: String,
    editing: Boolean,
  },
  setup(props, { emit }) {
    const globalReloadProposed = inject("globalReloadProposed");
    const globalError = inject("globalError");
    let { contributionId } = toRefs(props);

    const {
      updateContributionCluster,
      onDone,
      onError,
      loading,
      contributionID,
      clusterID,
    } = useUpdateContributionCluster();

    onDone(() => {
      globalReloadProposed.value = true;
      emit("done", contributionID.value);
    });

    onError((err) => {
      globalError.value = err;
    });

    const callContributionClusterUpdate = async (newClusterID) => {
      clusterID.value = newClusterID;
      contributionID.value = contributionId.value;
      await updateContributionCluster();
    };

    return {
      loading,
      callContributionClusterUpdate,
    };
  },
};
</script>

<style scoped lang="scss"></style>
