<template>
  <div id="step-8-insight" class="overflow-hidden h-full carded">
    <small-contributors-list
      :contributors="slicedVisibleItems"
      :scrollable="true"
      :small-cards="true"
      :light-cards="true"
      :title="$filters.i18n('Contributors')"
      additional-header-class="flex flex-wrap"
      :custom-contribution-hint="$filters.i18n('contribution(s)')"
      :length="orderedResults.length"
      @scroll="scrollHandler"
      :modals-instance-id="modalsInstanceId"
      :loading="loading"
    >
      <template #header>
        <button
          class="ml-auto button button-small"
          :class="allSelected ? 'button-primary' : 'button-white'"
          @click="allSelected = !allSelected"
          v-if="
            false // TODO REMOVE THAT WHEN ITS READY
          "
        >
          {{ $filters.i18n(allSelected ? "Unselect all" : "Select all") }}
        </button>

        <div class="block mt-4 w-full">
          <sort-and-filter-input
            name="contributorsFilters"
            :label="$filters.i18n('Start typing...')"
            v-model:searchTerms="searchTerms"
            v-model:sortOrder="sortOrder"
            input-class="w-24 flex-1"
            :sort-options="sortOptions"
            @click="showFilters = true"
          >
            <template #icon>
              <UserIcon class="w-5 h-5 text-gray-400" />
            </template>
          </sort-and-filter-input>
        </div>
      </template>
    </small-contributors-list>
  </div>
</template>

<script>
import SmallContributorsList from "@/components/Contributors/SmallContributorsList";
import SortAndFilterInput from "@/components/DesignSystem/Inputs/SortAndFilterInput";
import { computed, ref } from "vue";

// import InfiniteLoading from "vue-infinite-loading";
import { UserIcon } from "@heroicons/vue/solid";
import { useOrderList } from "@/logic/use-order-list";
import { useFuseSearch, useSetupFuseSearch } from "@/logic/use-fuse-search";
import { useInfiniteScroll } from "@/logic/use-infinite-scroll";

export default {
  name: "SearchableContributorsList",
  components: {
    SortAndFilterInput,
    SmallContributorsList,
    // InfiniteLoading,
    UserIcon,
  },
  props: {
    contributors: Array,
    dataReadyForCharts: Boolean,
    modalsInstanceId: { type: String, default: "insight" },
    loading: Boolean,
  },
  setup(props) {
    const searchTerms = ref("");
    const searchableKeys = ["name", "relatedContributions.entries.title"];

    const sortOrder = ref(0);
    const orderableKeys = [
      "name",
      "name",
      "relatedContributions.count",
      "relatedContributions.count",
    ];

    const fuse = computed(() =>
      useSetupFuseSearch(props.contributors, searchableKeys)
    );

    const results = computed(() => {
      return useFuseSearch(fuse.value, searchTerms.value);
    });

    const orderedResults = computed(() =>
      useOrderList(
        searchTerms.value.length > 0
          ? results.value.map((r) => r.item)
          : props.contributors,
        orderableKeys,
        sortOrder.value
      )
    );

    const infiniteScroll = useInfiniteScroll(orderedResults);

    const {
      scrollHandler,
      slicedVisibleItems,
      maxVisibleItems,
    } = infiniteScroll;

    return {
      orderedResults,
      maxVisibleItems,
      slicedVisibleItems,
      sortOrder,
      searchTerms,
      scrollHandler,
    };
  },
  data() {
    return {
      sortOptions: [
        "Name, A to Z",
        "Name, Z to A",
        "Contributions Nb., Asc.",
        "Contributions Nb., Desc.",
      ],
      allSelected: false,
    };
  },
};
</script>

<style scoped lang="scss"></style>
