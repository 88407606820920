<template>
  <li class="inline mr-2">
    <div
      class="inline-flex relative items-center py-0.5 px-3 hover:bg-gray-50 rounded-full border border-gray-300 hover:shadow-sm transition cursor-pointer"
    >
      <div class="text-xs font-medium text-gray-900">
        {{ title }}
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "Tag",
  data() {
    return {};
  },
  props: {
    title: String,
  },
};
</script>

<style scoped lang="scss"></style>
