<template>
  <div class="relative w-full h-full">
    <loading-chart-overlay
      v-if="!chartReady"
      :hint="$filters.i18n('Clusters loading in progress')"
    />
    <toggle
      class="absolute top-5 left-5 z-40"
      v-model="displayOpinions"
      :label="$filters.i18n('Display opinions')"
      v-if="chartReady"
    />
    <div
      id="collapsibleBlobs"
      ref="collapsibleBlobs"
      class="absolute inset-0"
    />
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import _, { sumBy } from "lodash";

import theme from "@amcharts/amcharts4/themes/material";
import { mapMutations, mapState } from "vuex";
import LoadingChartOverlay from "./LoadingChartOverlay";
import Toggle from "@/components/DesignSystem/Inputs/Toggle";
import { nextTick, computed } from "vue";
import { useClustersScoreCalculations } from "@/logic/use-clusters-score-calculations";

// Apply the themes
am4core.useTheme(theme);

am4core.options.queue = true;

export default {
  name: "CollapsibleBlobs",
  components: { Toggle, LoadingChartOverlay },
  data() {
    return {
      chart: null,
      chartReady: false,
      fullscreen: false,
      displayOpinions: false,
      networkSeries: null,
      changingDisplay: false,
    };
  },
  mounted() {
    this.setupChart();
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  setup(props) {
    const { scoreToColor, scoreToTextColor } = useClustersScoreCalculations;

    const clustersWithLimitedChildren = computed(() => {
      let formattedClustersWithLimitedChildren = [...props.clusters].filter(
        (c) => c.title.toLowerCase() !== "other"
      );

      formattedClustersWithLimitedChildren = formattedClustersWithLimitedChildren.map(
        (cluster) => {
          let children = cluster.children;
          let countChild = null;

          if (children.length > 10) {
            countChild = {
              title: `+${children.length - 10}`,
              value: 4,
              first_word: `+${children.length - 10}`, // 5 first characters + any subsequents without space
              id: null,
              bgColor: children[0].bgColor,
              textColor: children[0].textColor,
            };
          }

          children = children.slice(0, 10);

          if (countChild) children.push(countChild);

          return {
            ...cluster,
            value: cluster.children.length,
            children,
          };
        }
      );

      return formattedClustersWithLimitedChildren;
    });

    // eslint-disable-next-line no-undef
    const datasAccordingToMode = computed(() => {
      return _.map(clustersWithLimitedChildren.value, (data) => ({
        ...data,
        textColorOpinion: scoreToTextColor(data.polarityMean ?? 0),
        name: data.title || data.name,
        backgroundColorOpinion: scoreToColor(data.polarityMean ?? 0),
      }));
    });

    const totalContributionsInClusters = computed(() =>
      sumBy(datasAccordingToMode.value || [], "children.length")
    );

    return { datasAccordingToMode, totalContributionsInClusters };
  },
  computed: {
    ...mapState(["focusedClusterId", "focusedIdeaId"]),
  },
  props: {
    visible: Boolean,
    clusters: {
      type: Array,
    },
  },
  methods: {
    ...mapMutations([
      "addFocusedClusterId",
      "removeFocusedClusterId",
      "setFocusedIdeaId",
    ]),
    setupChart() {
      let that = this;

      let chart = am4core.create(
        this.$refs.collapsibleBlobs,
        am4plugins_forceDirected.ForceDirectedTree
      );

      chart.svgContainer.autoResize = true;

      // chart.legend = new am4charts.Legend();

      let networkSeries = chart.series.push(
        new am4plugins_forceDirected.ForceDirectedSeries()
      );

      networkSeries.showOnInit = false;

      networkSeries.data = this.datasAccordingToMode;

      networkSeries.dataFields.linkWith = "linkWith";
      networkSeries.dataFields.name = "first_word";
      networkSeries.dataFields.id = "id";
      networkSeries.dataFields.value = "value";
      networkSeries.dataFields.children = "children";
      networkSeries.dataFields.color = "backgroundColor";
      networkSeries.dataFields.textColor = "textColor";
      networkSeries.dataFields.configField = {
        textColor: "textColor",
      };

      networkSeries.nodes.template.fillOpacity = 1;
      networkSeries.nodes.template.label.text = "{name}";
      networkSeries.nodes.template.outerCircle.strokeOpacity = 0;
      networkSeries.nodes.template.outerCircle.fillOpacity = 0.3;
      // networkSeries.nodes.template.label.hideOversized = false;
      networkSeries.nodes.template.label.fullWords = true;
      /*      networkSeries.nodes.template.label.fill = "black";
      networkSeries.nodes.template.label.padding(2, 4, 2, 4);
      networkSeries.nodes.template.label.background.fill = am4core.color(
        "#eee"
      );
      networkSeries.nodes.template.label.background.stroke = am4core.color(
        "#555"
      );
      networkSeries.nodes.template.label.background = new am4core.RoundedRectangle();
      networkSeries.nodes.template.label.background.cornerRadius(5, 5, 5, 5);
      networkSeries.nodes.template.label.background.strokeOpacity = 1;*/
      networkSeries.nodes.template.children.tooltipText = "{name}";

      networkSeries.nodes.template.outerCircle.adapter.add(
        "fill",
        function (fill, target) {
          return (
            target.dataItem.dataContext.bgColor ||
            target.dataItem.dataContext.backgroundColor
          );
        }
      );

      networkSeries.nodes.template.label.adapter.add(
        "truncate",
        function (truncate, target) {
          return !(
            target.dataItem.dataContext.children &&
            target.dataItem.dataContext.children.length > 0
          );
        }
      );

      networkSeries.nodes.template.adapter.add("fill", function (fill, target) {
        if (target.dataItem.level == 1) {
          return target.dataItem.dataContext.backgroundColorOpinion != null &&
            that.displayOpinions
            ? target.dataItem.dataContext.backgroundColorOpinion
            : target.dataItem.parent.dataContext.backgroundColor;
        }

        return fill;
      });

      networkSeries.nodes.template.label.adapter.add(
        "wrap",
        function (wrap, target) {
          return (
            !!(
              target.dataItem.dataContext.children &&
              target.dataItem.dataContext.children.length > 0
            ) &&
            target.dataItem.dataContext.title.trim().split(/\s+/).length > 1
          );
        }
      );

      networkSeries.nodes.template.label.adapter.add(
        "maxWidth",
        function (maxWidth, target) {
          if (
            target.dataItem.dataContext.children &&
            target.dataItem.dataContext.children.length > 0
          ) {
            return networkSeries.data.length < 20 ? 60 : 45;
          }

          return maxWidth;
        }
      );

      networkSeries.nodes.template.label.adapter.add(
        "fontSize",
        function (fontSize, target) {
          if (
            target.dataItem.dataContext.children &&
            target.dataItem.dataContext.children.length > 0
          ) {
            return networkSeries.data.length < 20 ? 12 : 11;
          }

          return networkSeries.data.length < 20 ? 10 : 8;
        }
      );

      networkSeries.interpolationDuration = 0;
      networkSeries.rangeChangeDuration = 0;
      networkSeries.maxLevels = 2; // Default toggled level
      networkSeries.maxRadius = am4core.percent(
        networkSeries.data.length < 20 ? 8 : 7.5
      );
      networkSeries.minRadius = am4core.percent(
        networkSeries.data.length < 20 ? 5 : 2.5
      );
      networkSeries.links.template.distance = 1.3;

      networkSeries.animate(
        {
          property: "velocityDecay",
          to: 1,
        },
        0
      );
      networkSeries.centerStrength = 0.4;
      networkSeries.manyBodyStrength = -6;

      networkSeries.nodes.template.events.on("toggled", function (ev) {
        if (
          ev.target.dataItem.children &&
          ev.target.dataItem.children.length > 0
        ) {
          that.clusterToggled(
            ev.target.dataItem.dataContext,
            ev.target.isActive
          );
        }
      });

      networkSeries.nodes.template.events.on("hover", function (ev) {
        chart.seriesContainer.draggable = false;

        if (
          !ev.target.dataItem.children ||
          (ev.target.dataItem.children &&
            ev.target.dataItem.children.length < 1)
        ) {
          that.setFocusedIdeaId(ev.target.dataItem.id);
        }
      });

      networkSeries.nodes.template.events.on("down", function () {
        chart.seriesContainer.draggable = false;
      });

      networkSeries.nodes.template.events.on("up", function (ev) {
        chart.seriesContainer.draggable = true;

        if (
          !ev.target.dataItem.children ||
          (ev.target.dataItem.children &&
            ev.target.dataItem.children.length < 1)
        ) {
          that.setFocusedIdeaId(ev.target.dataItem.null);
        }
      });

      networkSeries.dragFixedNodes = true;

      networkSeries.nodes.template.events.on("dragstart", function () {
        chart.seriesContainer.draggable = false;
      });

      networkSeries.nodes.template.events.on("dragstop", function (event) {
        event.target.dataItem.fixed = true;
        chart.seriesContainer.draggable = true;
      });

      chart.zoomable = true;
      chart.minZoomCount = 0;
      chart.minZoomLevel = 0.2;
      chart.seriesContainer.mouseOptions.sensitivity = 0.25;

      chart.events.on("mouseleave", () => {
        console.log("mouseleave chart");
        chart.seriesContainer.draggable = false;
      });

      chart.events.on("mouseenter", () => {
        console.log("mouseleave chart");
        chart.seriesContainer.draggable = true;
      });

      chart.events.on("ready", () => {
        if (that.changingDisplay) {
          that.changingDisplay = false;
          return;
        }
        networkSeries.animate(
          {
            property: "velocityDecay",
            to: 1,
          },
          0
        );

        _.each(networkSeries.data, (cluster) => {
          let dataItem = networkSeries.getDataItemById(
            networkSeries.dataItems,
            cluster.id
          );
          dataItem.hide();
        });

        setTimeout(() => {
          setTimeout(() => {
            networkSeries.manyBodyStrength = -4;

            networkSeries.animate(
              {
                property: "velocityDecay",
                to: 0.8,
              },
              0
            );

            setTimeout(() => {
              that.chartReady = true;
            }, 600);
          }, 600);
        }, 300);
      });

      chart.events.on("zIndexChanged", () => {
        networkSeries.nodes.template.adapter.remove("fill");
        networkSeries.nodes.template.outerCircle.adapter.remove("fill");
        networkSeries.nodes.template.outerCircle.adapter.remove("stroke");

        for (const target of networkSeries.nodes) {
          if (!that.changingDisplay) {
            return;
          }

          const fill =
            target.dataItem.level === 1
              ? target.dataItem.dataContext.bgColor != null &&
                that.displayOpinions
                ? target.dataItem.dataContext.bgColor
                : target.dataItem.parent.dataContext.backgroundColor
              : that.displayOpinions
              ? target.dataItem.dataContext.backgroundColorOpinion
              : target.dataItem.dataContext.backgroundColor;

          target.fill = fill;
          target.stroke = null;
          target.label.fill = that.displayOpinions
            ? "black"
            : target.dataItem.dataContext.textColor;
          target.outerCircle.fill = fill;
          target.outerCircle.opacity = 1;
          target.outerCircle.strokeOpacity = 0;
          target.outerCircle.fillOpacity = 0.3;
        }
      });

      this.chart = chart;
      this.networkSeries = networkSeries;
    },
    clusterToggled(clusterData, state) {
      if (this.chartReady) {
        if (state) {
          this.addFocusedClusterId(clusterData.id);
        } else {
          this.removeFocusedClusterId(clusterData.id);
        }
      }
    },
  },
  watch: {
    displayOpinions() {
      this.changingDisplay = true;
      this.chart.dispatchImmediately("zIndexChanged"); // It's the trick
    },
    visible: {
      handler() {
        if (this.visible) {
          setTimeout(async () => {
            await nextTick();
            this.chart.svgContainer.autoResize = false;
          }, 1);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
