<template>
  <div class="fixed inset-x-0 bottom-0 z-[20]" v-if="modelValue">
    <div class="bg-purple-600 opacity-[95%]">
      <div class="py-3 px-3 mx-auto w-full sm:px-6 lg:px-8 lg:pl-[100px]">
        <div class="flex flex-wrap justify-between items-center">
          <div class="flex flex-1 items-center w-0">
            <span class="flex p-2 bg-purple-800 rounded-lg">
              <InformationCircleIcon
                class="w-6 h-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p class="ml-3 text-base font-medium text-white truncate">
              <span class="md:inline">
                <slot />
              </span>
            </p>
          </div>
          <!--          <div
            class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
          >
            <a href="#" class="interactive button button-white"> Learn more </a>
          </div>-->
          <div class="flex-shrink-0 order-2 sm:order-3 sm:ml-3">
            <button
              type="button"
              class="flex p-2 -mr-1 hover:bg-purple-500 rounded-md focus-visible:ring-2 focus-visible:ring-white focus-visible:outline-none sm:-mr-2"
              @click="$emit('update:modelValue', false)"
            >
              <span class="sr-only">Dismiss</span>
              <XIcon class="w-6 h-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon, XIcon } from "@heroicons/vue/outline";

export default {
  name: "StickyBanner",
  components: { InformationCircleIcon, XIcon },
  data() {
    return {};
  },
  props: {
    modelValue: Boolean,
  },
};
</script>

<style scoped lang="scss"></style>
