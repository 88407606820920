<template>
  <span
    class="badge"
    :class="[
      color === null
        ? `bg-white text-gray-800 text-opacity-70 border border-gray-800 border-opacity-20 shadow-sm`
        : `bg-${color}-100 text-${color}-800`,
      rounded ? '' : 'rounded',
    ]"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "Badge",
  data() {
    return {};
  },
  props: {
    rounded: { type: Boolean, default: true },
    color: { type: String, default: "white" },
  },
};
</script>

<style scoped lang="scss"></style>
