<template>
  <div>
    <dismissable-alert v-if="error" v-model:visible="error">{{
      $filters.i18n("An error occured during the deletion")
    }}</dismissable-alert>

    <slot :clickCallback="() => (modalVisible = true)" :loading="loading" />

    <dialog-modal
      :show="modalVisible"
      @action-clicked="deleteBatch()"
      @close="modalVisible = false"
    >
      <template #action>
        <togglable-loading-state :loading="loading">
          {{ $filters.i18n("Delete the analysis") }}
        </togglable-loading-state>
      </template>
      <template #title>{{
        $filters.i18n("deleteAnalysisHint_title")
      }}</template>
      <template #content>{{
        $filters.i18n("deleteAnalysisHint_content")
      }}</template>
    </dialog-modal>
  </div>
</template>

<script>
import router from "../../router/router";
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";
import DialogModal from "@/components/DesignSystem/Dialog/DialogModal";
import { ref } from "vue";
import { useDeleteBatch } from "../../logic/use-api-fetch";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";

export default {
  name: "InsightDeleteModal",
  components: { TogglableLoadingState, DialogModal, DismissableAlert },
  setup(props, { emit }) {
    const error = ref(null);
    const modalVisible = ref(false);

    const { deleteBatch, onDone, onError, loading } = useDeleteBatch(
      props.sessionId
    );

    const close = () => {
      modalVisible.value = false;
    };

    onDone(() => {
      emit("on-done", { close });
    });

    onError((err) => {
      error.value = err;
    });

    return {
      deleteBatch,
      loading,
      error,
      modalVisible,
    };
  },
  props: {
    sessionId: {
      type: String,
      required: true,
    },
  },
  methods: {
    quitSession() {
      router.push("/onboarding");
    },
  },
};
</script>
