<template>
  <div
    class="flex flex-wrap items-center pr-1.5 max-w-xl h-12 bg-white rounded-2xl shadow-lg"
  >
    <div
      class="flex overflow-hidden justify-center items-center -my-12 ml-[-0.15rem] bg-white rounded-full"
    >
      <svg style="width: 5rem; height: 5rem" aria-hidden="true">
        <circle
          class="text-gray-300"
          stroke-width="8"
          stroke="currentColor"
          fill="transparent"
          r="30"
          cx="40"
          cy="40"
        />
        <circle
          :class="`text-${color}-600`"
          stroke-width="8"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="
            circumference - ((percentage * 100 - 4.5) / 43.5) * circumference
          "
          stroke-linecap="round"
          stroke="currentColor"
          fill="transparent"
          r="30"
          cx="40"
          cy="40"
        />
      </svg>
      <span class="absolute text-xl" :class="`text-${color}-600`"
        >{{ parseInt(percentage * 100)
        }}<small class="ml-0.5 text-xs">%</small></span
      >
    </div>
    <p class="mx-4 ml-2 font-medium text-gray-600 sm:text-sm">
      {{ $filters.i18n("Similarity threshold") }}
    </p>

    <div
      class="flex relative flex-row mt-1 w-16 h-8 rounded-2xl"
      :title="$filters.i18n('Hold your click to continuously change')"
    >
      <button
        @mousedown="continuousDecrement"
        @mouseup="clearTimeout"
        @mouseleave="clearTimeout"
        class="w-16 h-full bg-opacity-20 hover:bg-opacity-40 rounded-l-2xl cursor-pointer focus:outline-none"
        :class="`text-${colorPrev}-600 bg-${colorPrev}-400`"
      >
        <span class="m-auto text-2xl font-thin leading-none">−</span>
      </button>
      <button
        @mousedown="continuousIncrement"
        @mouseup="clearTimeout"
        @mouseleave="clearTimeout"
        class="w-16 h-full bg-opacity-20 hover:bg-opacity-40 rounded-r-2xl cursor-pointer focus:outline-none"
        :class="`text-${colorNext}-600 bg-${colorNext}-400`"
      >
        <span class="m-auto text-2xl font-thin leading-none">+</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderLinksPrecision",
  props: {
    percentage: { type: Number },
  },
  data: () => ({
    interval: null,
    circumference: 50 * 2 * Math.PI,
    incrementTimer: null,
    decrementTimer: null,
  }),

  computed: {
    color() {
      if (this.percentage < 0.1) return "indigo";
      if (this.percentage < 0.12) return "teal";
      if (this.percentage < 0.2) return "green";
      if (this.percentage < 0.25) return "orange";
      return "red";
    },
    colorNext() {
      if (this.percentage + 0.05 < 0.1) return "indigo";
      if (this.percentage + 0.05 < 0.12) return "teal";
      if (this.percentage + 0.05 < 0.2) return "green";
      if (this.percentage + 0.05 < 0.25) return "orange";
      return "red";
    },
    colorPrev() {
      if (this.percentage - 0.05 < 0.1) return "indigo";
      if (this.percentage - 0.05 < 0.12) return "teal";
      if (this.percentage - 0.05 < 0.2) return "green";
      if (this.percentage - 0.05 < 0.25) return "orange";
      return "red";
    },
  },

  methods: {
    continuousIncrement() {
      this.increment();
      this.incrementTimer = setTimeout(this.continuousIncrement, 200);
    },
    continuousDecrement() {
      this.decrement();
      this.decrementTimer = setTimeout(this.continuousDecrement, 200);
    },
    clearTimeout() {
      clearTimeout(this.incrementTimer);
      clearTimeout(this.decrementTimer);
    },
    decrement() {
      if (this.percentage > 0.05)
        this.$emit(
          "update:percentage",
          parseInt(this.percentage * 100 - 1) / 100
        );
    },
    increment() {
      if (this.percentage < 0.3)
        this.$emit(
          "update:percentage",
          parseInt(this.percentage * 100 + 1) / 100
        );
    },
  },
};
</script>

<style scoped lang="scss"></style>
