<template>
  <div
    class="z-[10001] p-4 rounded-md transition duration-200 hover:opacity-[95%]"
    :class="[
      color,
      relative ? 'relative' : 'fixed inset-x-5 top-5 sm:left-auto',
    ]"
  >
    <div class="flex">
      <div class="flex flex-shrink-0 items-center">
        <svg
          class="w-5 h-5"
          :class="iconColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            v-if="type === 'success'"
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
          <path
            v-if="type === 'danger'"
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-3">
        <p class="text-sm font-medium" :class="textColor">
          <slot />
        </p>
      </div>
      <div class="flex items-center pl-3 ml-auto">
        <div class="-my-1.5 -mx-1.5">
          <button
            v-if="dismissable"
            type="button"
            class="inline-flex p-1.5 hover:bg-opacity-20 rounded-md interactive"
            :class="subTextColor + ' ' + color"
            @click="$emit('update:visible', false)"
          >
            <span class="sr-only">Fermer</span>
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="mt-3 ml-8" v-if="$slots.actions">
      <div class="flex -my-1.5 -mx-2 space-x-3">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DismissableAlert",
  props: {
    type: { type: String, default: "danger" },
    visible: Boolean,
    relative: Boolean,
    dismissable: { type: Boolean, default: true },
    actions: { type: Array },
  },
  data() {
    return {};
  },
  computed: {
    color() {
      return { danger: "bg-red-50", success: "bg-green-50" }[this.type];
    },
    iconColor() {
      return { danger: "text-red-400", success: "text-green-400" }[this.type];
    },
    textColor() {
      return { danger: "text-red-800", success: "text-green-800" }[this.type];
    },
    subTextColor() {
      return { danger: "text-red-500", success: "text-green-500" }[this.type];
    },
  },
};
</script>

<style scoped lang="scss"></style>
