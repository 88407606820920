/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "customerApi",
            "endpoint": "https://lhmafq6oba.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "oauth",
            "endpoint": "https://lhje6vftc1.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "usageSummary",
            "endpoint": "https://t5cate7yf8.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://nm4envoymrdxzpw6xpnj2766q4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:0edf3cdb-e603-4058-a163-853dab1937fa",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_V1v17xhWx",
    "aws_user_pools_web_client_id": "5o6udqn6u8r9ugjnbh1e94v924",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "phedoneb2b-dev-hostingbucket-250-master",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "http://phedoneb2b-dev-hostingbucket-250-master.s3-website.eu-west-2.amazonaws.com",
    "aws_user_files_s3_bucket": "phedoneb2b-temporary-user-json-storage160619-master",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
