<template>
  <fieldset>
    <legend class="block font-medium text-gray-700"><slot /></legend>
    <div class="overflow-hidden relative mt-2 -space-y-px bg-white">
      <label
        class="flex relative p-4 cursor-pointer"
        :class="
          (modelValue === item.value
            ? 'bg-primary bg-opacity-10 border-primary border-opacity-50 z-10 border'
            : 'border-gray-200 border-b border-l border-r') +
          ' ' +
          (i === 0
            ? 'rounded-tl-md rounded-tr-md border-b border-t'
            : i === items.length - 1 && buttonTitle == null
            ? 'rounded-bl-md rounded-br-md border-b border-t-0'
            : '')
        "
        v-for="(item, i) in items"
        :key="i"
      >
        <input
          type="radio"
          :name="name"
          :modelValue="item.value"
          :checked="item.value === modelValue"
          @input="$emit('update:modelValue', item.value)"
          class="mt-0.5 w-4 h-4 text-primary border-gray-300 focus:ring-primary cursor-pointer"
          :aria-labelledby="`${name}-${i}-label`"
        />
        <span class="flex flex-col ml-3">
          <span
            :id="`${name}-${i}-label`"
            class="block text-sm font-medium"
            :class="
              modelValue === item.value ? 'text-primary-dark' : 'text-gray-900'
            "
          >
            {{ item.text }}
          </span>
        </span>
      </label>
      <button
        v-if="buttonTitle"
        @click="$emit('button-clicked')"
        class="block relative z-10 p-4 py-3 w-full text-xs text-white bg-primary rounded-md rounded-t-none border border-primary border-opacity-50 focus:ring-offset-primary-dark cursor-pointer focus:outline-none xl:hover:bg-opacity-90"
      >
        {{ buttonTitle }}
      </button>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "RadiosList",
  props: {
    modelValue: String,
    items: Array,
    name: String,
    buttonTitle: String,
  },
};
</script>

<style scoped lang="scss"></style>
