<template>
  <modal :visible="activeClusterId !== null" @close="closeModal()">
    <template #content v-if="activeClusterId !== null">
      <header
        class="flex flex-wrap justify-between items-center py-5 px-4 -mt-4 -ml-4 sm:flex-nowrap sm:px-6"
      >
        <div class="flex items-center mt-4 ml-4">
          <svg
            class="mr-1.5 -ml-0.5 w-5 h-5 opacity-90"
            :style="`color: ${clusterDetails.backgroundColor}`"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>

          <h3 class="text-lg font-medium leading-6 text-gray-900">
            {{ capitalizedTitle }}
          </h3>
        </div>
        <div
          class="flex flex-wrap flex-shrink-0 mt-4 ml-4 space-y-3 sm:space-y-0 sm:space-x-3"
        >
          <add-to-favorites-button
            :toggled="favorited"
            @toggle="favorited = !favorited"
            class="flex-1"
          />

          <!-- // TODO WHEN READY -->
          <button
            class="inline-flex relative items-center button button-primary"
            v-if="false"
          >
            <pencil-icon class="mr-2 -ml-1 w-5 h-5" />
            <span>{{ $filters.i18n("Edit") }}</span>
          </button>

          <cluster-merge-button
            class="inline-flex relative items-center button button-soft-visible"
            :cluster-id="activeClusterId"
            @on-merged="refreshWindow()"
          >
            <view-grid-add-icon class="mr-2 -ml-1 w-5 h-5" />
          </cluster-merge-button>

          <cluster-rename-button
            class="inline-flex relative items-center button button-soft-visible"
            :cluster-id="activeClusterId"
            :default-title="capitalizedTitle"
            @on-renamed="refreshWindow()"
          >
            <pencil-icon class="mr-2 -ml-1 w-5 h-5" />
          </cluster-rename-button>

          <cluster-delete-button
            class="inline-flex relative items-center button button-primary"
            :cluster-id="activeClusterId"
            @on-deleted="refreshWindow()"
          >
            <trash-icon class="mr-2 -ml-1 w-5 h-5" />
          </cluster-delete-button>

          <span class="flex flex-col justify-center">
            <button class="lg:ml-2 icon-button" @click="closeModal()">
              <span class="sr-only">{{ $filters.i18n("Close") }}</span>
              <svg
                class="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg></button
          ></span>
        </div>
      </header>
      <main class="overflow-y-auto py-5 px-4 h-auto border-t sm:p-0 bordered">
        <details-grid>
          <template #rows>
            <details-grid-row
              :title="$filters.i18n('Indexes')"
              additional-row-class="grid grid-cols-3"
            >
              <template #content>
                <details-grid-sub-col
                  :title="$filters.i18n('Tonality')"
                  v-if="polarity != null && polarity != undefined"
                >
                  <template #content>
                    <tonality-badge :score="polarity" />
                  </template>
                </details-grid-sub-col>
                <details-grid-sub-col
                  :title="$filters.i18n('Density')"
                  v-if="density != null && density != undefined"
                >
                  <template #content>
                    <percentage-badge :percentage="density" />
                  </template>
                </details-grid-sub-col>
                <details-grid-sub-col
                  :title="$filters.i18n('Relevance')"
                  v-if="
                    clusterDetails.accuracy != null &&
                    clusterDetails.accuracy !== undefined
                  "
                >
                  <template #content>
                    <percentage-badge :percentage="0.3" />
                  </template>
                </details-grid-sub-col>
              </template>
            </details-grid-row>
            <details-grid-row :title="$filters.i18n('Keywords')">
              <template #content>
                <tags-list :tags="tags" class="-mt-1" />
              </template>
            </details-grid-row>
            <details-grid-row
              :title="$filters.i18n('clusterModal_sentiment_repartition')"
            >
              <template #content>
                <div>
                  <horizontal-stacked-bar-chart
                    :labels="['']"
                    :data-sets="sentimentSegmentationChartValues"
                    ref="horizontalStackedBarChart"
                    class="-my-4 md:!max-h-[90px]"
                  />
                </div>
              </template>
            </details-grid-row>

            <section class="grid grid-cols-1 lg:grid-cols-2">
              <small-contributions-list
                :title="$filters.i18n('Contributions')"
                :limit="4"
                :contributions="contributions"
                class="border-b md:border-r bordered"
                :darkened="false"
                additional-header-class="justify-center"
                :modals-instance-id="ownModalInstanceId"
              />

              <small-contributors-list
                :title="$filters.i18n('Contributors')"
                :limit="4"
                :contributors="formattedContributors"
                class="border-b bordered"
                :darkened="false"
                additional-header-class="justify-center"
                :modals-instance-id="ownModalInstanceId"
                :custom-contribution-hint="
                  $filters.i18n('contributions in the cluster')
                "
              />
            </section>
          </template>
        </details-grid>

        <ContributorSlidePanel
          :external-contributors="externalContributors"
          :external-contributions="externalContributions"
          :external-clusters="externalClusters"
          :details="ContributorSlidePanel"
          :modal-instance-id="ownModalInstanceId"
          v-if="ContributorSlidePanel"
          :key="`contributor_${ownModalInstanceId}`"
        />

        <ContributionModal
          :visible="contributionModal != null"
          :external-contributors="externalContributors"
          :external-contributions="externalContributions"
          :details="contributionModal"
          :modal-instance-id="ownModalInstanceId"
          :key="`contribution_${ownModalInstanceId}`"
          v-if="contributionModal"
        />
      </main>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Layout/Modal";
import AddToFavoritesButton from "@/components/DesignSystem/Buttons/AddToFavoritesButton";
import DetailsGrid from "@/components/DesignSystem/Layouts/DetailsGrid";
import DetailsGridRow from "@/components/DesignSystem/Layouts/DetailsGridRow";
import _ from "lodash";
import DetailsGridSubCol from "@/components/DesignSystem/Layouts/DetailsGridSubCol";
import PercentageBadge from "@/components/DesignSystem/Badges/PercentageBadge";
import TagsList from "@/components/DesignSystem/Tags/TagsList";
import SmallContributionsList from "@/components/Contributions/SmallContributionsList";
import SmallContributorsList from "@/components/Contributors/SmallContributorsList";
import TonalityBadge from "@/components/DesignSystem/Badges/TonalityBadge";

import { computed, inject, ref } from "vue";
import { useInjectModals, useProvideModals } from "@/logic/use-modals";
import HorizontalStackedBarChart from "@/components/Graphs/HorizontalStackedBarChart";
import { useClustersScoreCalculations } from "@/logic/use-clusters-score-calculations";
import { PencilIcon, TrashIcon, ViewGridAddIcon } from "@heroicons/vue/solid";
import ClusterDeleteButton from "@/components/Clusters/ClusterDeleteButton";
import ClusterRenameButton from "@/components/Clusters/ClusterRenameButton";
import ClusterMergeButton from "@/components/Clusters/ClusterMergeButton";

export default {
  name: "ClusterModal",
  components: {
    ClusterMergeButton,
    ClusterRenameButton,
    ClusterDeleteButton,
    HorizontalStackedBarChart,
    TonalityBadge,
    SmallContributorsList,
    SmallContributionsList,
    TagsList,
    PercentageBadge,
    DetailsGridSubCol,
    DetailsGridRow,
    DetailsGrid,
    AddToFavoritesButton,
    Modal,
    PencilIcon,
    TrashIcon,
    ViewGridAddIcon,
  },
  beforeCreate() {
    this.$options.components.ContributorSlidePanel = require("@/components/Contributors/ContributorSlidePanel.vue").default;
    this.$options.components.ContributionModal = require("@/components/Contributions/ContributionModal.vue").default;
  },
  setup(props) {
    const ownModalInstanceId = computed(
      () => `cluster_${props.activeClusterId}`
    );

    const {
      clusterModal,
      contributionModal,
      ContributorSlidePanel,
    } = useProvideModals(ownModalInstanceId.value);

    const { updateClusterModal: closeModal } = useInjectModals(
      props.modalInstanceId,
      false,
      false,
      true
    );

    const clusterDetails = computed(() => {
      return _.find(
        props.externalClusters,
        (c) => c.id === props.activeClusterId
      );
    });

    const capitalizedTitle = computed(() => {
      return _.capitalize(clusterDetails.value?.name);
    });

    const polarity = computed(() => clusterDetails.value?.polarityMean);
    const density = computed(() => clusterDetails.value?.density);

    const contributions = computed(() => {
      return _.filter(
        props.externalContributions,
        (c) => c.cluster && c.cluster.id === clusterDetails.value?.id
      );
    });

    const contributorsWithContributions = computed(() => {
      return _.chain(contributions.value)
        .groupBy("author.id")
        .map((contributor) => {
          return {
            contributor: contributor[0] && contributor[0].author,
            contributions: contributor,
          };
        })
        .value();
    });

    const formattedContributors = computed(() => {
      return _.map(
        contributorsWithContributions.value,
        (contributorWithContributions) => ({
          id: contributorWithContributions.contributor.id,
          name: contributorWithContributions.contributor.name,
          datas: contributorWithContributions.contributor,
          relatedContributions: {
            count: contributorWithContributions.contributions.length,
          },
        })
      );
    });

    const tags = computed(() => {
      let tags = [];
      if (clusterDetails.value) {
        try {
          tags = _.chain(clusterDetails.value.themes)
            .orderBy("score", "desc")
            .map("title")
            .value();
        } catch (e) {
          console.error(e);
        }
      }
      return tags;
    });

    const i18n = inject("i18n");

    const sentimentsLabels = computed(() =>
      [
        "negative",
        "middly negative",
        "neutral",
        "middly positive",
        "positive",
      ].map((s) => {
        return i18n(s);
      })
    );

    let sentimentSegmentationChartValues = ref([]);

    const contributionsWithSentimentOnFive = contributions.value.map((c) => ({
      c,
      opinion: useClustersScoreCalculations.scoreOnFive(c.polarity),
    }));

    for (let [sentimentIndex, sentiment] of sentimentsLabels.value.entries()) {
      sentimentIndex++;

      const contributionsForSentiment = contributionsWithSentimentOnFive.filter(
        (c) => c.opinion === sentimentIndex
      );

      sentimentSegmentationChartValues.value.push({
        title: sentiment,
        color: useClustersScoreCalculations.scoreToColor(
          sentimentIndex / 5,
          true
        ),
        entries: [
          {
            absoluteValue: contributionsForSentiment.length || 0,
            value:
              ((contributionsForSentiment.length || 0) /
                contributions.value.length) *
              100,
          },
        ],
      });
    }

    const refreshWindow = () => location.reload();

    return {
      ownModalInstanceId,
      closeModal,
      clusterModal,
      contributionModal,
      ContributorSlidePanel,
      capitalizedTitle,
      polarity,
      density,
      contributions,
      contributorsWithContributions,
      formattedContributors,
      tags,
      clusterDetails,
      sentimentSegmentationChartValues,
      refreshWindow,
    };
  },
  data() {
    return {
      favorited: false,
    };
  },
  props: {
    activeClusterId: String,
    externalClusters: Array,
    externalContributors: Array,
    externalContributions: Array,
    modalInstanceId: String,
  },
};
</script>

<style scoped lang="scss"></style>
