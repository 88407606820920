/*eslint no-unused-vars:0*/

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { store } from "@/store/store";
import { Auth, DataStore, Hub } from "aws-amplify";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import PhedoneAuth from "../components/Auth/PhedoneAuth";
import OnBoarding from "../views/OnBoarding";
import Insight from "../views/Insight";
import InsightDashboard from "@/views/InsightDashboard";
import NewKeys from "@/views/NewKeys";
import Login from "@/views/Auth/Login";
import Register from "@/views/Auth/Register";
import ResetPassword from "@/views/Auth/ResetPassword";
import ConfirmAccount from "@/views/Auth/ConfirmAccount";
import NewPassword from "@/views/Auth/NewPassword";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/onboarding",
    name: "OnBoarding",
    component: OnBoarding,
    meta: { requiresAuth: true },
  },
  {
    path: "/keys/new",
    name: "NewKeys",
    component: NewKeys,
    meta: { requiresAuth: true },
  },
  {
    path: "/insights/:id",
    name: "Insight",
    component: Insight,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/insights/:id/dashboard",
    name: "Insight Dashboard",
    component: InsightDashboard,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    props: (route) => ({
      email: route.query.email,
      confirmationSuccessful: route.query.confirmationSuccessful,
      resetSuccessful: route.query.resetSuccessful,
    }),
  },
  {
    path: "/auth/register",
    name: "Register",
    component: Register,
    props: (route) => ({
      presetEmail: route.query.email,
      presetName: route.query.name,
      presetCorporation: route.query.corporation,
    }),
  },
  {
    path: "/auth/reset/password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/auth/confirm/account",
    name: "ConfirmAccount",
    component: ConfirmAccount,
    props: (route) => ({ email: route.query.email }),
  },
  {
    path: "/auth/confirm/password",
    name: "NewPassword",
    component: NewPassword,
    props: (route) => ({ email: route.query.email }),
  },
];

const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve(async (to, from, next) => {
  let nextParameters = null;
  console.log("Route resolved");
  console.log(to.matched.some((record) => record.meta.requiresAuth));
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log("Gonna try to get user");
    try {
      let user = await Auth.currentAuthenticatedUser();

      store.commit("setUser", user);
      store.commit("setUserRoles", await getUserRoles());
      console.log(user);
    } catch (e) {
      console.log("error with no user match", e);

      console.log(to.fullPath);

      nextParameters = {
        path: "/auth/login",
        query: {
          redirect: to.fullPath,
        },
      };
    }
  }

  console.log(nextParameters);

  return next(nextParameters);
});

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        store.commit("setUser", data);
        return data;
      }
    })
    .catch((e) => {
      store.commit("setUser", null);
      return null;
    });
}

async function getUserRoles() {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  let roles = user.signInUserSession.accessToken.payload["cognito:groups"];

  return roles ? roles : [];
}

const listener = (data) => {
  const authState = data?.payload?.event;

  console.log(data.payload);
  if (authState === "signIn") {
    store.commit("setSignedIn", true);
    Auth.currentAuthenticatedUser()
      .then((data) => {
        if (data && data.signInUserSession) {
          store.commit("setUser", data);

          getUserRoles().then((roles) => {
            store.commit("setUserRoles", roles);
          });

          if ("redirect" in router?.currentRoute?.value?.query) {
            router.push({ path: router.currentRoute.value.query.redirect });
          } else if (
            router.currentRoute.meta &&
            router.currentRoute.meta.requiresAuth
          ) {
            // Do nothing, we're on the proper route rn
          } else {
            console.log(router.currentRoute);
            router.push("/onboarding");
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  if (authState === "signOut") {
    store.commit("setSignedIn", false);
    store.commit("setUser", null);
    router.push({ path: "/" });
  }
};

Hub.listen("auth", listener);

export default router;
