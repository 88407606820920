import { render } from "./PhedoneAuth.vue?vue&type=template&id=39138b88&scoped=true"
import script from "./PhedoneAuth.vue?vue&type=script&lang=js"
export * from "./PhedoneAuth.vue?vue&type=script&lang=js"

import "./PhedoneAuth.vue?vue&type=style&index=0&id=39138b88&lang=scss"
import "./PhedoneAuth.vue?vue&type=style&index=1&id=39138b88&scoped=true&lang=scss"
import "./PhedoneAuth.vue?vue&type=style&index=2&id=39138b88&lang=scss"
script.render = render
script.__scopeId = "data-v-39138b88"

export default script