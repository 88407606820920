<template>
  <div>
    <h3 class="text-lg font-medium leading-6 text-gray-900" v-if="title">
      {{ title }}
    </h3>
    <dl
      class="grid overflow-hidden grid-cols-1 divide-y divide-gray-200 xl:grid-cols-3 xl:divide-y-0 xl:divide-x carded"
      :class="title ? 'mt-5' : ''"
    >
      <slot name="metrics" />
    </dl>
  </div>
</template>

<script>
export default {
  name: "SharedBordersMetrics",
  props: {
    title: { type: String },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
