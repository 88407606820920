<template>
  <section class="flex flex-col m-auto space-y-4 w-10/12 max-w-screen-md">
    <div class="overflow-hidden py-6 px-4 space-y-6 sm:p-6 carded">
      <header
        class="p-6 -mx-6 -mt-6 bg-gray-50 bg-opacity-50 border-b border-gray-100"
      >
        <horizontal-steps
          id="insertingSteps"
          v-model="step"
          key="insertingStepsContainer"
          :items="steps"
          @step-clicked="goToStep"
        />
      </header>

      <keys-pair-generation-inputs
        v-model:title="keyTitle"
        v-model:passPhrase="passPhrase"
        v-show="step === 1"
      />

      <keys-pair-generation-summary
        :title="keyTitle"
        :pass-phrase="passPhrase"
        v-if="step === 2"
      />

      <keys-pair-generation-private-key-output
        :title="keyTitle"
        :private-key="privateKey"
        v-model:user-approval="userApproval"
        v-if="step === 3"
      />

      <keys-pair-generation-sending
        v-if="step === 4"
        :sending-error="sendingError"
        :sending="sending"
      />

      <footer class="px-6 pt-6 -m-6 border-t border-gray-100">
        <div class="flex justify-end items-center space-x-3">
          <button
            class="button button-soft"
            :disabled="!canGoPrev"
            v-if="step > 1"
            @click="updateStep(step - 1)"
          >
            {{ $filters.i18n("Previous") }}
          </button>

          <button
            class="button button-primary"
            :disabled="!canGoNext"
            @click="updateStep(step + 1)"
            v-if="step < 4"
          >
            {{ customNextButton || $filters.i18n("Next") }}
          </button>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { mapMutations, mapState } from "vuex";

// eslint-disable-next-line no-unused-vars
import router from "../../router/router";
import HorizontalSteps from "@/components/DesignSystem/Steps/HorizontalSteps";

import gql from "graphql-tag";
import KeysPairGenerationInputs from "@/components/Encryption/KeysPairGenerationInputs";
import KeysPairGenerationSummary from "@/components/Encryption/KeysPairGenerationSummary";
import KeysPairGenerationPrivateKeyOutput from "@/components/Encryption/KeysPairGenerationPrivateKeyOutput";
import KeysPairGenerationSending from "@/components/Encryption/KeysPairGenerationSending";

export default {
  name: "KeysPairGenerationContainer",
  components: {
    KeysPairGenerationSending,
    KeysPairGenerationPrivateKeyOutput,
    KeysPairGenerationSummary,
    KeysPairGenerationInputs,
    HorizontalSteps,
  },
  data() {
    return {
      step: 1,
      keyTitle: null,
      passPhrase: null,
      userApproval: false,
      sending: false,
      privateKey: null,
      publicKey: null,
      sendingError: null,
      steps: [
        "Key settings",
        "Settings review",
        "Private key retrieval",
        "encryption_steps_sending",
      ].map((step) => this.$filters.i18n(step)),
    };
  },
  computed: {
    ...mapState(["user"]),
    editable() {
      return !this.sending && this.step < 2;
    },
    canGoNext() {
      let conditionsForSteps = {
        1:
          this.keyTitle &&
          this.keyTitle.length > 0 &&
          (this.passPhrase === null ||
            (this.passPhrase && this.passPhrase.length > 0)),
        2: this.keyTitle && this.keyTitle.length > 0,
        3: this.keyTitle && this.keyTitle.length > 0 && this.userApproval,
        4: this.keyTitle && this.keyTitle.length > 0 && this.userApproval,
      };

      return conditionsForSteps[this.step];
    },
    canGoPrev() {
      let conditionsForSteps = {
        1: false,
        2: true,
        3: false,
        4: false,
        5: false,
      };

      return conditionsForSteps[this.step];
    },
    customNextButton() {
      let customNextButtonTitle = null;

      switch (this.step) {
        case 2:
          customNextButtonTitle = this.$filters.i18n("Confirm");
          break;

        case 3:
          customNextButtonTitle = this.$filters.i18n(
            "Upload the generated public key to Phedone"
          );
          break;
      }

      return customNextButtonTitle;
    },
  },
  methods: {
    ...mapMutations(["setPageTitle"]),
    updateStep(step) {
      if (
        (this.canGoNext && step === this.step + 1) ||
        (this.canGoPrev && step === this.step - 1)
      ) {
        this.step = step;
        return true;
      }
      return false;
    },
    goToStep(step) {
      if (step > this.step) {
        while (this.step < step) {
          let canTravel = this.updateStep(this.step + 1);
          if (!canTravel) break;
        }
      }

      if (step < this.step) {
        while (this.step > step) {
          let canTravel = this.updateStep(this.step - 1);
          if (!canTravel) break;
        }
      }
    },
    generateKeyPair() {
      let that = this;
      const forge = require("node-forge");
      const rsa = forge.pki.rsa;

      rsa.generateKeyPair({ bits: 2048, workers: 2 }, function (err, keypair) {
        if (err) {
          console.error(err);
          return;
        }

        const pubKey = forge.pki.publicKeyToPem(keypair.publicKey);
        const privKey = that.passPhrase
          ? forge.pki.encryptRsaPrivateKey(keypair.privateKey, that.passPhrase)
          : forge.pki.privateKeyToPem(keypair.privateKey);

        that.publicKey = pubKey;
        that.privateKey = privKey;
      });
    },
    closeView() {
      if (window.opener) {
        window.close();
      } else {
        let routeData = this.$router.resolve({ name: "OnBoarding" });
        window.location.href = routeData.href;
      }
    },
    async uploadPublicKeyToPhedone() {
      try {
        this.sending = true;
        await this.$apollo.mutate({
          fetchPolicy: "no-cache",
          mutation: gql(`
                          mutation createPublicKey($name: String!, $owner:String!, $key: String!) {
                            createPublicKey(input: { name: $name, owner: $owner, key: $key}) {
                              id
                            }
                          }`),
          variables: {
            name: this.title,
            key: this.publicKey,
            owner: this.user && this.user.username,
          },
        });
        setTimeout(() => {
          this.sending = false;
          this.closeView();
        }, 1000);
      } catch (err) {
        console.error(err);
        this.sending = false;
        this.sendingError = JSON.stringify(
          err,
          Object.getOwnPropertyNames(err)
        );
      }
    },
    simulateSendingError: async function () {
      await new Promise((r) => setTimeout(r, 3000));

      this.sending = false;
      this.sendingError =
        "{error : 's3 upload failed because of your network :('}";
    },
  },
  watch: {
    async step(step, previousStep) {
      if (step === 3 && previousStep === 2) {
        this.generateKeyPair();
      }

      if (step === 5) {
        await this.uploadPublicKeyToPhedone();
      }
    },
  },
};
</script>
