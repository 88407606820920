<template>
  <main
    class="flex flex-col space-y-5 w-full md:flex-row md:space-y-0 md:space-x-6"
  >
    <div class="space-y-5 lg:w-2/3">
      <text-input
        v-model="sessionName"
        @update:modelValue="$emit('update:sessionName', $event)"
        required
        >{{ $filters.i18n("Title of your analysis*") }}</text-input
      >

      <div class="block font-medium text-gray-700">
        <span class="block mb-2"
          >{{ $filters.i18n("Your XLSX or CSV file") }}*</span
        >
        <xlsx-importer
          :key="importerKey"
          v-bind="$attrs"
          :csv-separator="csvSeparator"
        />
      </div>
    </div>

    <radios-list
      class="md:flex-1"
      :items="availablePublicKeys"
      v-model="pickedEncryptionKey"
      :key="pickedEncryptionKey && pickedEncryptionKey.value"
      name="encryptionKeys"
      :button-title="$filters.i18n('Add a new key pair')"
      @button-clicked="
        openWorkInProgressModal()
        // showKeyPairGeneration
      "
    >
      {{ $filters.i18n("Encryption key for your analysis") }}
    </radios-list>
  </main>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import XlsxImporter from "../SheetsComputing/XlsxImporter";
import { Auth } from "aws-amplify";
import _ from "lodash";
import Input from "@/components/DesignSystem/Inputs/TextInput";
import TextInput from "@/components/DesignSystem/Inputs/TextInput";
import RadiosTable from "@/components/DesignSystem/Radios/RadiosList";
import RadiosList from "@/components/DesignSystem/Radios/RadiosList";

export default {
  name: "InsertingStep1",
  inject: ["openWorkInProgressModal"],
  components: { RadiosList, RadiosTable, TextInput, Input, XlsxImporter },
  metaInfo() {
    return {
      title: this.$filters.i18n("Data import"),
    };
  },
  props: {
    csvSeparator: String,
  },
  data() {
    return {
      importerKey: 1,
      sessionName: null,
      pickedEncryptionKey: null,
      availablePublicKeys: [
        {
          text: this.$filters.i18n("None"),
          value: null,
        },
      ],
      refreshPublicKeysTimer: null,
    };
  },
  async mounted() {
    this.pickedEncryptionKey = this.availablePublicKeys[0].value;

    await this.refreshPublicKeys();

    this.refreshPublicKeysTimer = setInterval(() => {
      this.refreshPublicKeys();
    }, 3000);
  },
  beforeUnmount() {
    clearInterval(this.refreshPublicKeysTimer);
  },
  methods: {
    reset() {
      this.importerKey++;
    },
    showKeyPairGeneration() {
      this.$emit("show-key-pair-generation");
    },
    async refreshPublicKeys() {
      const currentUserInfo = await Auth.currentUserInfo();

      this.availablePublicKeys = JSON.parse(
        currentUserInfo.attributes["custom:public_keys"] ?? "{}"
      );
      this.availablePublicKeys = [
        {
          text: this.$filters.i18n("None"),
          value: null,
        },
        ..._.map(this.availablePublicKeys, function (value, key) {
          return {
            text: key,
            value: value,
          };
        }),
      ];
    },
  },
  watch: {
    sessionName(sessionName) {
      this.$emit("session-name-updated", sessionName);
    },
    pickedEncryptionKey(encryptionKey) {
      this.$emit("public-key-updated", encryptionKey);
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  background-color: rgba(251, 69, 63, 0.16) !important;
}

.source {
  background: transparent;
  transition: 0.3s;
  border-radius: 8px;
  cursor: not-allowed;
  height: 100%;
  padding: 0;
  opacity: 0.4;

  &:hover {
    background: rgba(black, 0.05);
  }

  &__image {
    width: 47.5px;
    height: 40px;
    object-fit: contain;
  }
}

.inputs {
  &--disabled {
    cursor: not-allowed;

    * {
      pointer-events: none;
    }
  }
}

.encryptionHint {
  max-width: 410px;

  .v-list-item__subtitle {
    -webkit-line-clamp: 5 !important;
  }
}
</style>
