<template>
  <div>
    <dismissable-alert v-if="error" v-model:visible="error">{{
      $filters.i18n("An error occured during the deletion")
    }}</dismissable-alert>

    <slot :clickCallback="() => (modalVisible = true)" :loading="loading" />

    <dialog-modal
      :show="modalVisible"
      @action-clicked="deleteMultipleClusters()"
      @close="modalVisible = false"
    >
      <template #action>
        <togglable-loading-state :loading="loading">
          {{ $filters.i18n("Delete the cluster") }}
        </togglable-loading-state>
      </template>
      <template #title>{{ $filters.i18n("deleteClusterHint_title") }}</template>
      <template #content>{{
        $filters.i18n("deleteClusterHint_content")
      }}</template>
    </dialog-modal>
  </div>
</template>

<script>
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";
import DialogModal from "@/components/DesignSystem/Dialog/DialogModal";
import { ref } from "vue";
import { useDeleteClusters } from "@/logic/use-api-fetch";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";

export default {
  name: "ClusterDeleteModal",
  components: { TogglableLoadingState, DialogModal, DismissableAlert },
  inheritAttrs: false,
  setup(props, { emit }) {
    const error = ref(null);
    const modalVisible = ref(false);

    const {
      deleteMultipleClusters,
      onDone,
      onError,
      loading,
    } = useDeleteClusters([props.clusterId]);

    const close = () => {
      modalVisible.value = false;
    };

    onDone(() => {
      emit("on-done");
      close();
    });

    onError((err) => {
      error.value = err;
    });

    return {
      deleteMultipleClusters,
      loading,
      error,
      modalVisible,
    };
  },
  props: {
    clusterId: {
      type: String,
      required: true,
    },
  },
};
</script>
