<template>
  <div
    class="flex justify-center w-8 h-8 rounded-full"
    :class="'bg-' + (reverseColor ? 'white' : 'primary')"
  >
    <span
      :class="'text-' + (reverseColor ? 'primary' : 'white')"
      class="flex justify-center items-center text-xs tracking-widest uppercase"
      >{{ firstLetters }}</span
    >
  </div>
</template>

<script>
export default {
  name: "UserAvatar",
  data() {
    return {};
  },
  props: {
    userName: { type: String, default: "Phedone User" },
    reverseColor: { type: Boolean, default: false },
  },
  computed: {
    firstLetters() {
      let userName = this.userName;
      let firstLettersOfWords = userName.match(/\b(\w)/g);
      let twoFirstLetters = firstLettersOfWords.splice(0, 2).join("");

      if (twoFirstLetters.length > 1) {
        return twoFirstLetters;
      }

      return userName.substring(0, 2);
    },
  },
};
</script>

<style scoped lang="scss"></style>
