<template>
  <badge :color="colorFromPercentage">{{
    $filters.percentage(percentage)
  }}</badge>
</template>

<script>
import Badge from "@/components/DesignSystem/Badges/Badge";
export default {
  name: "PercentageBadge",
  components: { Badge },
  data() {
    return {};
  },
  computed: {
    colorFromPercentage() {
      if (this.percentage >= 0.67) {
        return "green";
      }

      if (this.percentage >= 0.33) {
        return "cyan";
      }

      return "red";
    },
  },
  props: {
    percentage: Number,
  },
};
</script>

<style scoped lang="scss"></style>
