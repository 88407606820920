<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto -my-2 w-full">
      <div class="inline-block px-1 pb-1 w-full min-w-full align-middle">
        <div
          class="overflow-x-hidden rounded-lg border-b border-gray-200 shadow"
        >
          <div
            class="overflow-x-auto overflow-y-visible w-full"
            v-if="headerRows"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="flex sticky top-0 left-0 z-20 flex-col py-5 px-6 pr-3 pb-[22px] my-0 space-y-6 min-w-[130px] text-xs text-left bg-gray-100 border-r border-gray-200"
                  >
                    <small
                      class="flex justify-between items-center space-x-2 w-full whitespace-nowrap"
                      ><span class="block">Titre de la colonne</span>
                      <ChevronRightIcon
                        class="block w-3 h-3"
                        aria-hidden="true"
                      />
                    </small>
                    <small
                      class="flex justify-between items-center space-x-2 w-full whitespace-nowrap"
                      ><span class="block">Usage sur Phedone</span>
                      <ChevronRightIcon
                        class="block w-3 h-3"
                        aria-hidden="true"
                      />
                    </small>
                  </th>
                  <th
                    scope="col"
                    :class="[
                      colIndex === 0 ? 'pl-6' : '',

                      colIndex === headerRows.length - 1 ? 'pr-6' : '',
                      'px-3 py-3 pt-4 w-[220px] text-left',
                    ]"
                    v-for="(colTitle, colIndex) in headerRows"
                    :key="colIndex"
                  >
                    <simple-select
                      :items="availableColumns"
                      :modelValue="getKeyFromColumnTitle(colTitle)"
                      @update:modelValue="setColumnTitleToKey($event, colTitle)"
                      :none-label="$filters.i18n('Not used')"
                      class="w-[170px] min-w-full"
                      can-add-new-options
                      @add-option="addAndSelectNewOption($event, colTitle)"
                    >
                      <span
                        class="block text-xs font-medium tracking-wider text-gray-900 uppercase truncate lg:text-base lg:font-semibold"
                        :title="colTitle"
                        >{{ colTitle }}</span
                      >
                    </simple-select>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, rowId) in entries.slice(0, 6)"
                  :key="rowId"
                  :class="rowId % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="flex sticky top-0 left-0 z-10 justify-center items-center"
                    :class="rowId % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <b
                      class="py-4 px-3 max-w-[80px] text-sm text-center text-gray-500 truncate whitespace-nowrap"
                    >
                      {{ rowId + 1 }}
                    </b>
                    <hr
                      class="absolute inset-y-0 right-0 h-full bg-none border-t-0 border-r border-gray-200"
                    />
                  </td>
                  <td
                    :class="[
                      i === 0 ? 'pl-6' : '',
                      i === Object.values(row).length - 1 ? 'pr-6' : '',
                      'px-3 py-4 whitespace-nowrap text-sm text-gray-500 truncate max-w-[80px]',
                    ]"
                    v-for="(val, i) in Object.values(row)"
                    :key="i"
                  >
                    <span
                      v-if="
                        Object.prototype.toString.call(val) ===
                        '[object String]'
                      "
                    >
                      {{ val.slice(0, 30) }}{{ val.length > 30 ? "..." : "" }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="flex justify-center py-4 px-6 w-full text-sm text-center text-gray-500 truncate whitespace-nowrap border-t border-gray-200"
          >
            <p>
              + {{ entries.length - 4 }}
              {{ $filters.i18n("more rows") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleSelect from "../DesignSystem/Inputs/SimpleSelect";
import { useStore } from "vuex";
import { inject, ref, computed, watch, toRefs } from "vue";
import { mapValues, find, snakeCase } from "lodash";

import { ChevronRightIcon } from "@heroicons/vue/outline";

export default {
  name: "RowsPickers",
  components: { SimpleSelect, ChevronRightIcon },
  props: {
    entries: { type: Array },
    additionalColumns: { type: Array },
    columnsInUse: { type: Array },
  },
  emits: [
    "update:additionalColumns",
    "update:pickedKeyByColumns",
    "update:columnsInUse",
    "picked-rows",
  ],
  computed: {
    headerRows() {
      return this.entries && this.entries[0] && Object.keys(this.entries[0]);
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const i18n = inject("i18n");
    const { additionalColumns } = toRefs(props);

    const availableColumns = computed(() => {
      store.state.locale;

      return [
        { key: null, title: i18n("Not used") },
        { key: "title", title: `${i18n("Title")}*` },
        { key: "description", title: `${i18n("Description")}` },
        { key: "contributor", title: `${i18n("Contributor")}*` },
        { key: "date", title: i18n("date_with_format") },
        ...additionalColumns.value,
      ];
    });

    const keyByColumns = ref(
      props.entries &&
        props.entries[0] &&
        Object.fromEntries(
          availableColumns.value
            .filter((entry) => entry.key !== null)
            .map((column) => [column.key, null])
        )
    );

    watch(
      keyByColumns,
      (newVal) => {
        emit("update:pickedKeyByColumns", newVal);
      },
      {
        deep: true,
      }
    );

    const getKeyFromColumnTitle = (searchedColTitle) => {
      const keys = Object.keys(keyByColumns.value);
      let col = null;

      Object.values(keyByColumns.value).forEach((colTitle, i) => {
        if (colTitle === searchedColTitle) {
          col = find(
            availableColumns.value,
            (column) => column.key === keys[i]
          );

          col = col ? { ...col } : null;
        }
      });
      return col;
    };

    const setColumnTitleToKey = (ev, colTitle) => {
      if (ev === undefined) {
        return;
      }

      Object.values(keyByColumns.value).map((val, i) => {
        if (val === colTitle) {
          const previousKeyToUnset = Object.keys(keyByColumns.value)[i];

          if (previousKeyToUnset)
            keyByColumns.value = {
              ...keyByColumns.value,
              [previousKeyToUnset]: null,
            };
        }
      });
      if (ev.key !== null) {
        keyByColumns.value = {
          ...keyByColumns.value,
          [ev.key]: colTitle,
        };
      }
    };

    const updatePickedRows = () => {
      this.$emit("picked-rows", this.rows);
    };
    const formatKeyByColumns = (keyByColumns) => {
      return mapValues(keyByColumns.value, (v) => (v && v.key) || null);
    };

    const addAndSelectNewOption = (newOptionTitle, colTitle) => {
      console.log({ newOptionTitle, colTitle });
      const newCol = {
        key: snakeCase(newOptionTitle),
        title: newOptionTitle,
      };

      emit("update:additionalColumns", [...additionalColumns.value, newCol]);

      setColumnTitleToKey({ key: newCol.key }, colTitle);
    };

    const columnsInUse = computed(() => {
      const columnsKeysInUse = Object.entries(keyByColumns.value)
        .filter((c) => c[1] != null)
        .map((c) => c[0]);

      return availableColumns.value
        .filter((c) => c.key !== null && columnsKeysInUse.includes(c.key))
        .map((c) => c.key);
    });

    watch(columnsInUse, (newVal) => emit("update:columnsInUse", newVal));

    return {
      keyByColumns,
      availableColumns,
      getKeyFromColumnTitle,
      setColumnTitleToKey,
      updatePickedRows,
      formatKeyByColumns,
      addAndSelectNewOption,
    };
  },
  watch: {
    rows: {
      handler(rows) {
        this.updatePickedRows(rows);
      },
      deep: true,
    },
  },
};
</script>
