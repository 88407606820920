const exportChartToPng = (chartRef, fileName = null) => {
  let a = document.createElement("a");
  a.href = chartRef.chartJSState.chart.toBase64Image("image/png", 1);
  a.download = `${fileName || "phedone-chart-export.png"}`;
  a.click();
  a = null;
};

const exportElementToPng = (elementRef, fileName = null) => {
  let a = document.createElement("a");
  console.log(elementRef.$el);
  a.href = elementRef.$el.getContext("2d").toDataURL("image/png");
  a.download = `${fileName || "phedone-chart-export.png"}`;
  a.click();
  a = null;
};

export const useChartJsHelpers = {
  exportChartToPng: exportChartToPng,
  exportElementToPng: exportElementToPng,
};
