<template>
  <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
    <dt class="text-base font-medium text-gray-900">{{ title }}</dt>
    <dd
      class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
      :class="additionalRowClass"
    >
      <slot name="content"></slot>
    </dd>
  </div>
</template>

<script>
export default {
  name: "DetailsGridRow",
  props: {
    title: String,
    additionalRowClass: String,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
