/* eslint-disable */

import gql from "graphql-tag";

export const listSessions = gql(`
  query listSessions(
    $filter: ModelSessionFilterInput
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: 100, nextToken: $nextToken) {
      items {
        id
        title
        updatedAt
      }
    }
  }
`);

export const getSession = gql(`
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      title
      analysed
      createdAt
    }
  }
`);

export const getDetailledSession = gql(`
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      title
      analysed
      createdAt
      authors {
        items {
          id
          ideas {
            items {
              title
              description
              date
              originality
              polarity
              commentsNb
              likesNb
              createdAtRaw
              relativeness
              keywords
              author {
                id
                name
                companyRole
              }
            }
          }
        }
      }
    }
  }
`);

export const getSessionPaginatingItems = gql(`
  query getSessionPaginatingItems($id: ID!, $nextToken: String) {
    getSession(id: $id) {
      id
      title
      analysed
      createdAt
      authors (limit: 200, nextToken : $nextToken) {
        items {
          id
          name
          ideas {
            items {
              id
              title
              description
              date
              originality
              polarity
              commentsNb
              likesNb
              additionalColumns
              createdAtRaw
              cluster {
                id
              }
              relativeness
              keywords
              author {
                id
                name
                companyRole
              }
            }
          }
        }
        nextToken
      }
      clusters {
        items {
            id
            themes
            density
            polarityMean
            polarityMedian
            backgroundColor
            textColor
            title
        }
      }
    }
  }
`);
export const getSessionPaginatingItemsAuthors = gql(`
  query getSessionPaginatingItems($id: ID!, $nextToken: String) {
    getSession(id: $id) {
      id
      authors (limit: 200, nextToken : $nextToken) {
        items {
          id
          name
          ideas {
            items {
              id
              title
              description
              date
              originality
              polarity
              commentsNb
              createdAtRaw
              additionalColumns
              likesNb
              cluster {
                id
              }
              relativeness
              keywords
              author {
                id
                name
                companyRole
              }
            }
          }
        }
        nextToken
      }
    }
  }
`);

export const listAuthors = gql(`
  query listAuthors(
    $filter: ModelAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        companyRole
      }
    }
  }
`);

export const listIdeas = gql(`
  query listIdeas(
    $filter: ModelIdeaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIdeas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        date
        originality
        polarity
        commentsNb
        likesNb
        createdAtRaw
        relativeness
        keywords
        
        author {
          id
          name
          companyRole
        }
      }
    }
  }
`);
