<template>
  <label class="flex items-center space-x-3 font-medium text-gray-700">
    <input
      type="checkbox"
      class="w-4 h-4 text-primary rounded border-gray-300 focus:ring-primary cursor-pointer"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />

    <span class="cursor-pointer select-none"><slot /></span>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    modelValue: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
