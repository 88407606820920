<template>
  <section class="flex flex-col flex-grow" :class="scrollable ? 'h-full' : ''">
    <div
      class="inline-flex px-6 border-b bordered"
      :class="
        (smallPadding ? 'py-3' : 'py-5') +
        ' ' +
        (darkened ? 'darkened' : '') +
        ' ' +
        additionalHeaderClass
      "
    >
      <h2 class="text-base font-medium text-gray-900">
        {{ title }}
      </h2>
      <span class="mx-1 text-base text-gray-900">•</span
      ><span class="text-base text-gray-900">{{
        loading ? "..." : length || (contributions && contributions.length)
      }}</span>

      <slot name="header" />
    </div>
    <display-more-list
      :button-title="$filters.i18n('View more contributions')"
      :entries="orderedContributions"
      :limit="limit"
      @scroll="$emit('scroll', $event)"
      :class="scrollable ? 'flex-grow overflow-x-hidden overflow-y-auto' : ''"
    >
      <template #default="{ entry }">
        <div id="step-3-insight">
          <small-contribution-card
            :title="entry.title"
            :cluster-color="entry.cluster && entry.cluster.color"
            :cluster-title="entry.cluster && entry.cluster.title"
            :color-hex="entry.cluster && entry.cluster.backgroundColor"
            :title-color-hex="entry.cluster && entry.cluster.textColor"
            :details="entry"
            @entry-clicked="entryClicked(entry)"
            @view-cluster-clicked="
              clusterClicked(entry.cluster && entry.cluster.id)
            "
            @edit-cluster-clicked="showDropdown = ['edit', entry]"
            @remove-cluster-clicked="() => {}"
            @add-cluster-clicked="showDropdown = ['add', entry]"
            :clusters-actions="clustersActions"
            :cluster-updated="
              Array.isArray(contributionsWithUpdatedClusters) &&
              contributionsWithUpdatedClusters.includes(entry.id)
            "
          />
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <contribution-update-cluster-dropdown
              v-if="
                showDropdown != null &&
                showDropdown[1] &&
                showDropdown[1] === entry
              "
              :contribution-id="entry.id"
              :entries="availableFormattedClusters"
              @done="
                contributionsWithUpdatedClusters.push($event);
                showDropdown = null;
              "
              @close="showDropdown = null"
              :editing="
                showDropdown != null &&
                showDropdown[0] &&
                showDropdown[0] === 'edit'
              "
            />
          </transition>
        </div>
      </template>
    </display-more-list>
  </section>
</template>

<script>
import SmallContributionCard from "@/components/Contributions/SmallContributionCard";
import DisplayMoreList from "@/components/Lists/DisplayMoreList";
import _ from "lodash";
import { computed, inject, ref } from "vue";
import { useContributionsModals } from "@/logic/use-modals";
import ContributionUpdateClusterDropdown from "@/components/Contributions/ContributionUpdateClusterDropdown";
import { capitalize } from "lodash";
import { useClustersModals } from "../../logic/use-modals";

export default {
  name: "SmallContributionsList",
  components: {
    ContributionUpdateClusterDropdown,
    DisplayMoreList,
    SmallContributionCard,
  },
  setup(props) {
    const i18n = inject("i18n");

    let showDropdown = ref(null);
    let passDetailsToModal = () => {
      console.error(
        `[SmallContributionsList] Can't passDetailsToModal, modalsInstanceId seems unset : ${props.modalsInstanceId}`
      );
    };

    let passDetailsToClusterModal = () => {
      console.error(
        `[SmallContributionsList] Can't passDetailsToClusterModal, modalsInstanceId seems unset : ${props.modalsInstanceId}`
      );
    };

    if (props.modalsInstanceId != null) {
      passDetailsToModal = useContributionsModals(props.modalsInstanceId)
        .passDetailsToModal;

      passDetailsToClusterModal = useClustersModals(props.modalsInstanceId)
        .passDetailsToModal;
    }

    const orderedContributions = computed(() => {
      return _.orderBy(props.contributions, "score", "desc");
    });

    const entryClicked = (entry) => {
      passDetailsToModal(entry);
    };

    const clusterClicked = (cluster) => {
      passDetailsToClusterModal(cluster);
    };

    const availableClusters = inject("availableClusters");

    const activeContributionClusterId = computed(
      () => showDropdown.value[1] && showDropdown.value[1].cluster?.id
    );

    const availableFormattedClusters = computed(() => [
      { title: i18n("none_masculine"), key: null },
      ...availableClusters.value.map((c) => ({
        title: capitalize(c.name),
        key: c.id,
        active: c.id === activeContributionClusterId.value,
      })),
    ]);

    const contributionsWithUpdatedClusters = ref([]);

    return {
      passDetailsToModal,
      orderedContributions,
      entryClicked,
      showDropdown,
      availableFormattedClusters,
      activeContributionClusterId,
      clusterClicked,
      contributionsWithUpdatedClusters,
    };
  },
  props: {
    contributions: Array,
    limit: {
      type: Number,
      default: 0,
    },
    clustersActions: Boolean,
    modalsInstanceId: {
      type: String,
    },
    smallPadding: Boolean,
    additionalHeaderClass: { type: String, default: "" },
    title: {
      type: String,
      default: "Contributions",
    },
    darkened: {
      type: Boolean,
      default: true,
    },
    length: Number,
    scrollable: Boolean,
    loading: Boolean,
  },
};
</script>

<style scoped lang="scss"></style>
