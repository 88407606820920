<template>
  <RadioGroup v-bind="$attrs">
    <RadioGroupLabel><slot></slot></RadioGroupLabel>
    <div class="mt-4 space-y-3">
      <RadioGroupOption
        as="template"
        v-for="option in options"
        :key="option"
        :value="option"
      >
        <div
          :class="[
            'relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-3 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary',
          ]"
        >
          <div class="flex items-center">
            <div class="text-sm">
              <RadioGroupLabel as="p" class="font-medium text-gray-900">
                {{ option }}
              </RadioGroupLabel>
            </div>
          </div>
          <div
            :class="[
              modelValue === option ? 'border-primary' : 'border-transparent',
              'absolute -inset-px rounded-lg border-2 pointer-events-none',
            ]"
            aria-hidden="true"
          />
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";

export default {
  name: "StackedRadioCards",
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [Object, String],
    },
  },
};
</script>
