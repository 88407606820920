<template>
  <ul class="leading-8">
    <tag v-for="tag in tags" :key="tag" :title="tag" />
  </ul>
</template>

<script>
import Tag from "@/components/DesignSystem/Tags/Tag";
export default {
  name: "TagsList",
  components: { Tag },
  data() {
    return {};
  },
  props: {
    tags: Array,
  },
};
</script>

<style scoped lang="scss"></style>
