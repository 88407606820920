import { json2excel } from "js2excel";
import _ from "lodash";

export const useCsvExport = {
  exportContributionsToXlsx: (
    contributionsWithFormattedClusters,
    fileTitle,
    i18n
  ) => {
    try {
      let data = _.map(contributionsWithFormattedClusters, (contribution) => ({
        [i18n("Contributor")]: contribution.author.name,
        [i18n("Title")]: contribution.title,
        [i18n("Description")]: contribution.description,
        [i18n("Originality")]:
          Math.round(((contribution.originality || 0) / 10) * 100) + "%",
        [i18n("Polarity")]: _.upperFirst(
          i18n([
            "negative",
            "middly negative",
            "neutral",
            "middly positive",
            "positive",
          ])[Math.max(0, Math.round(2.5 + contribution.polarity * 2.5) - 1)]
        ),
        [i18n("Theme")]: _.upperFirst(
          contribution.clusters &&
            contribution.clusters[0] &&
            contribution.clusters[0].title
        ),
        [i18n("Keywords")]: (
          (contribution.keywords && typeof contribution.keywords === "string"
            ? JSON.parse(contribution.keywords)
            : contribution.keywords) || []
        )
          .slice(0, 2)
          .join(", "),
        ...(contribution?.additionalColumns &&
          Array.isArray(contribution?.additionalColumns) &&
          contribution?.additionalColumns.length > 0 &&
          Object.fromEntries(
            contribution?.additionalColumns.map((column) => [
              column.title,
              column.value,
            ])
          )),
      }));

      json2excel({
        data,
        name: _.kebabCase("contributions_" + fileTitle || "phedone").slice(
          0,
          25
        ),
      });
    } catch (e) {
      console.error(e);
      console.error("export error");
    }
  },
  exportClustersToXlsx: (
    transformedClusters,
    contributionsLength,
    fileTitle,
    i18n
  ) => {
    try {
      let data = _.map(transformedClusters, (cluster) => ({
        [i18n("Title")]: _.upperFirst(cluster.title),
        [i18n("Contributions")]: cluster.children.length,
      }));

      data.push({
        [i18n("Title")]: i18n("Other"),
        [i18n("Contributions")]:
          contributionsLength - _.sumBy(data, i18n("Contributions")),
      });

      json2excel({
        data,
        name: _.kebabCase("clusters_" + (fileTitle || "phedone")).slice(0, 25),
      });
    } catch (e) {
      console.error(e);
      console.error("export error");
    }
  },
};
