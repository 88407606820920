<template>
  <page-body>
    <template #mainBody>
      <inserting-steps-container />
    </template>
  </page-body>
</template>

<script>
import InsertingStepsContainer from "@/components/InsertingSteps/InsertingStepsContainer";
import PageBody from "@/components/Layout/PageBody";
export default {
  name: "OnBoarding",
  metaInfo() {
    return {
      title: this.$filters.i18n("New analysis"),
    };
  },
  components: {
    PageBody,
    InsertingStepsContainer,
  },
  data() {
    return {
      generateKeyPair: false,
    };
  },
};
</script>

<style scoped lang="scss"></style>
