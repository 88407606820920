<template>
  <dropdown :visible="visible" v-bind="$attrs">
    <template #content>
      <div class="divide-y divide-gray-100">
        <div class="py-3 px-4">
          <p class="text-sm">{{ $filters.i18n("Signed in as") }}</p>
          <p class="text-sm font-medium text-gray-900 truncate">
            {{ user?.attributes?.email }}
          </p>
        </div>

        <!--        <div class="py-3 px-4">
          <p class="text-sm">{{ $filters.i18n("user_dropdown_api_key") }}</p>
          <p class="text-sm font-medium text-gray-900 truncate">phedoneApi</p>
        </div>-->

        <div
          class="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <!--          <a
            href="#"
            class="block interactive px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 w-full text-left"
            role="menuitem"
            @click.prevent="openWorkInProgressModal()"
          >
            {{ $filters.i18n("Account settings") }}
          </a>-->
          <a
            class="block py-2 px-4 w-full text-sm text-left text-gray-700 hover:bg-gray-100 focus:bg-gray-100 interactive"
            role="menuitem"
            :href="`mailto:contact@phedone.com?subject=${$filters.i18n(
              `clientNeedHelpMail_title`
            )}`"
          >
            {{ $filters.i18n("Help") }}
          </a>
        </div>
        <div class="flex py-1">
          <Checkbox
            v-model:modelValue="tutorialVisibility"
            class="block py-2 px-4 w-full text-sm text-left text-gray-700 hover:bg-gray-100 focus:bg-gray-100 interactive"
          >
            {{ $filters.i18n("checkbox_activate_tutorial") }}
          </Checkbox>
        </div>
        <div class="py-1">
          <button
            class="block py-2 px-4 w-full text-sm text-left text-gray-700 hover:bg-gray-100 focus:bg-gray-100 interactive"
            role="menuitem"
            @click="signOut"
          >
            {{ $filters.i18n("Sign Out") }}
          </button>
        </div>
      </div>
    </template>
  </dropdown>
</template>

<script>
import { Auth } from "aws-amplify";
import Dropdown from "@/components/DesignSystem/Dropdown/Dropdown";
import Checkbox from "@/components/DesignSystem/Inputs/Checkbox";
import { inject } from "vue";

const { mapState } = require("vuex");

export default {
  name: "UserDropdown",
  components: { Checkbox, Dropdown },
  data() {
    return {};
  },
  setup() {
    const tutorialVisibility = inject("tutorialVisibility");

    return {
      tutorialVisibility,
    };
  },

  props: {
    visible: Boolean,
  },
  computed: {
    ...mapState(["user"]),
  },
  inject: ["openWorkInProgressModal"],
  methods: {
    signOut() {
      Auth.signOut()
        .then(() => {
          document.location.reload();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
