import { computed, watchEffect } from "vue";
import Fuse from "fuse.js";

export function useSetupFuseSearch(input, keys) {
  const searchOptions = {
    isCaseSensitive: false,
    includeScore: true,
    includeMatches: true,
    shouldSort: true,
    minMatchCharLength: 1,
    findAllMatches: true,
    location: 0,
    threshold: 0.3,
    distance: 50,
    useExtendedSearch: false,
    ignoreLocation: false,
    ignoreFieldNorm: false,
    keys,
  };

  const index = computed(() => {
    return Fuse.createIndex(searchOptions.keys, input);
  });

  return new Fuse(input, searchOptions, index.value);
}

export function useFuseSearch(fuse, searchTerm) {
  let results = [];

  watchEffect(() => (results = fuse.search(searchTerm)));

  console.log(results);

  return results;
}
