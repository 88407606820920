<template>
  <header class="flex relative z-30 flex-shrink-0 items-center h-16 bg-white">
    <div
      class="absolute inset-y-0 left-0 bg-primary md:static md:flex-shrink-0"
    >
      <router-link
        to="/"
        class="flex justify-center items-center w-16 h-16 hover:bg-white focus:bg-white hover:bg-opacity-20 focus:bg-opacity-20 transition focus:outline-none md:w-20"
      >
        <img
          src="../../assets/logo_blanc.svg"
          class="w-auto h-11"
          alt="Logo Phedone"
        />
      </router-link>
    </div>

    <!-- Picker area -->
    <div class="mx-auto md:hidden">
      <div class="relative">
        <label for="inbox-select" class="sr-only">Choose inbox</label>
        <select
          id="inbox-select"
          class="pr-8 pl-3 text-base font-medium text-gray-900 bg-none rounded-md border-0 focus:ring-2 focus:ring-primary"
        >
          <option>Open</option>

          <option>Archive</option>

          <option>Customers</option>

          <option>Flagged</option>

          <option>Spam</option>

          <option>Drafts</option>
        </select>
        <div
          class="flex absolute inset-y-0 right-0 justify-center items-center pr-2 pointer-events-none"
        >
          <!-- Heroicon name: solid/chevron-down -->
          <svg
            class="w-5 h-5 text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- Menu button area -->
    <div
      class="flex absolute inset-y-0 right-0 items-center pr-4 sm:pr-6 md:hidden"
    >
      <!-- Mobile menu button -->
      <button
        type="button"
        class="inline-flex justify-center items-center p-2 -mr-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded-md focus:ring-2 focus:ring-inset focus:ring-primary focus:outline-none"
        aria-expanded="false"
      >
        <span class="sr-only">Open main menu</span>
        <!-- Heroicon name: outline/menu -->
        <svg
          class="block w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <!-- Desktop nav area -->
    <div
      class="hidden md:flex md:flex-1 md:justify-between md:items-center md:min-w-0"
    >
      <div class="flex-1 min-w-0">
        <div
          class="relative max-w-2xl text-gray-400 focus-within:text-gray-500"
        >
          <label for="search" class="sr-only">{{
            $filters.i18n("Quick access")
          }}</label>
          <input
            type="search"
            :placeholder="$filters.i18n('Quick access')"
            class="block pl-12 w-full placeholder-gray-500 border-transparent focus:border-transparent focus:ring-0 sm:text-sm"
          />
          <div
            class="flex absolute inset-y-0 left-0 justify-center items-center pl-4 pointer-events-none"
          >
            <!-- Heroicon name: solid/search -->
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="flex flex-shrink-0 items-center pr-4 ml-10 space-x-10">
        <nav aria-label="Global" class="flex relative space-x-10">
          <div
            class="absolute inset-0 z-10"
            @click="openWorkInProgressModal()"
          ></div>
          <!--          <a href="#" class="text-sm font-medium text-gray-900">{{
            $filters.i18n("Analysis")
          }}</a>
          <a href="#" class="text-sm font-medium text-gray-900">{{
            $filters.i18n("Reporting")
          }}</a>
          <a href="#" class="text-sm font-medium text-gray-900">{{
            $filters.i18n("Settings")
          }}</a>-->
        </nav>
        <div class="flex items-center space-x-8">
          <div class="relative">
            <language-picker />
          </div>

          <!--          <span class="inline-flex">
            <a
              href="#"
              class="-mx-1 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500"
              @click.prevent="openWorkInProgressModal()"
            >
              <span class="sr-only">{{
                $filters.i18n("View notifications")
              }}</span>
              &lt;!&ndash; Heroicon name: outline/bell &ndash;&gt;
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </a>
          </span>-->

          <div class="inline-block relative text-left">
            <user-popover v-if="signedIn" />
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide this `div` based on menu open/closed state -->
    <div class="hidden fixed inset-0 z-40 md:hidden">
      <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div
        class="hidden sm:block sm:fixed sm:inset-0 md:hidden"
        aria-hidden="true"
      >
        <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
      </div>

      <!--
        Mobile menu, toggle classes based on menu state.

        Entering: "transition ease-out duration-150 sm:ease-in-out sm:duration-300"
          From: "transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
          To: "transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
        Leaving: "transition ease-in duration-150 sm:ease-in-out sm:duration-300"
          From: "transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
          To: "transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
      -->
      <nav
        class="fixed inset-0 z-40 w-full h-full bg-white sm:inset-y-0 sm:right-0 sm:left-auto sm:w-full sm:max-w-sm sm:shadow-lg md:hidden"
        aria-label="Global"
      >
        <div class="flex justify-between items-center px-4 h-16 sm:px-6">
          <a href="#">
            <svg
              class="block w-auto h-10"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 69.17 69.17"
            >
              <title>logo</title>
              <g id="logo-NB-vertical">
                <g
                  id="_-chouette-seule_logo-phedone-horizontal_logo-phedone-vertical"
                  data-name="-chouette-seule_logo-phedone-horizontal_logo-phedone-vertical"
                >
                  <g id="Group" fill="#FB453F">
                    <path
                      id="Path"
                      d="M54.53,32.09a16.32,16.32,0,0,0-1.2-5.3,14.17,14.17,0,0,0-.8-1.8,17.56,17.56,0,0,0-4.6-5.5c-2.3-1.9-4.7-3.7-6.2-6.2A12.83,12.83,0,0,1,40,8.19c-.1-.7-.2-4.7.5-5.1a38.7,38.7,0,0,0-3.4,2.1,72.48,72.48,0,0,0-8.3,6.3,17.11,17.11,0,0,0-4.2,4.7,9.64,9.64,0,0,0-1.2,5.6,9.85,9.85,0,0,0,.2,1.7,12.34,12.34,0,0,1-.7-4.2,12,12,0,0,1,1-3.7c-1.6,1.4-1.6,1.4-2.2,1.9a38.33,38.33,0,0,0-2.9,2.8,23.48,23.48,0,0,0-1.9,2.5,14,14,0,0,0-2.3,8.2,1.33,1.33,0,0,0,.6,1l6.5,3.8a21,21,0,0,0,2.2,1.5,31,31,0,0,1,4.3,3.9,28.1,28.1,0,0,1,6.7,14.1,4.9,4.9,0,0,0-1.7,1.8,6.2,6.2,0,0,0-.8,4.6,6.27,6.27,0,0,0,2.6,4.1l.6.3a6.37,6.37,0,0,0,3.2-6.8,6,6,0,0,0-2.7-4,27.12,27.12,0,0,1,7.1-14.1,25.23,25.23,0,0,1,5.4-4.9,30.71,30.71,0,0,1,3.1-1.7,10.48,10.48,0,0,0,2.6-1.3A1.8,1.8,0,0,0,54.53,32.09Z"
                    />
                    <path
                      id="Path-2"
                      data-name="Path"
                      d="M21.13,46.89a6.55,6.55,0,0,0-3.1-.1,6.3,6.3,0,0,0,7.4,8.3c.1-.2.1-.4.2-.6a7.37,7.37,0,0,0-.8-4.8A6.2,6.2,0,0,0,21.13,46.89Z"
                    />
                    <path
                      id="Path-3"
                      data-name="Path"
                      d="M49.63,46.89a6.61,6.61,0,0,0-4.5,7.7c.1.2.1.4.2.6a6.32,6.32,0,0,0,6.9-2.9,6.42,6.42,0,0,0,.5-5.4A5.45,5.45,0,0,0,49.63,46.89Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <button
            type="button"
            class="inline-flex justify-center items-center p-2 -mr-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded-md focus:ring-2 focus:ring-inset focus:ring-primary focus:outline-none"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <!-- Heroicon name: outline/x -->
            <svg
              class="block w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="px-4 mx-auto mt-2 sm:px-6">
          <div class="relative text-gray-400 focus-within:text-gray-500">
            <label for="search" class="sr-only">{{
              $filters.i18n("Quick access")
            }}</label>
            <input
              id="search"
              type="search"
              :placeholder="$filters.i18n('Quick access')"
              class="block pl-10 w-full placeholder-gray-500 rounded-md border-gray-300 focus:border-primary focus:ring-primary"
            />
            <div
              class="flex absolute inset-y-0 left-0 justify-center items-center pl-3"
            >
              <!-- Heroicon name: solid/search -->
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="py-3 px-2 mx-auto sm:px-4">
          <a
            href="#"
            class="block py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100 rounded-md"
            >Inboxes</a
          >

          <a
            href="#"
            class="block py-2 pr-3 pl-5 text-base font-medium text-gray-500 hover:bg-gray-100 rounded-md"
            >Technical Support</a
          >

          <a
            href="#"
            class="block py-2 pr-3 pl-5 text-base font-medium text-gray-500 hover:bg-gray-100 rounded-md"
            >Sales</a
          >

          <a
            href="#"
            class="block py-2 pr-3 pl-5 text-base font-medium text-gray-500 hover:bg-gray-100 rounded-md"
            >General</a
          >

          <a
            href="#"
            class="block py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100 rounded-md"
            >Reporting</a
          >

          <a
            href="#"
            class="block py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100 rounded-md"
            >Settings</a
          >
        </div>
        <div class="pt-4 pb-3 border-t border-gray-200">
          <div class="flex items-center px-4 mx-auto sm:px-6">
            <div class="flex-shrink-0">
              <img
                class="w-10 h-10 rounded-full"
                src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                alt=""
              />
            </div>
            <div class="flex-1 ml-3 min-w-0">
              <div class="text-base font-medium text-gray-800 truncate">
                Jonathan PHEDONE
              </div>
              <div class="text-sm font-medium text-gray-500 truncate">
                jonathan@phedone.com
              </div>
            </div>
            <a
              href="#"
              class="flex-shrink-0 p-2 ml-auto text-gray-400 hover:text-gray-500 bg-white"
              @click.prevent="openWorkInProgressModal()"
            >
              <span class="sr-only">View notifications</span>
              <!-- Heroicon name: outline/bell -->
              <svg
                class="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </a>
          </div>
          <div class="px-2 mx-auto mt-3 space-y-1 sm:px-4">
            <a
              href="#"
              class="block py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50 rounded-md"
              >Your Profile</a
            >

            <a
              href="#"
              class="block py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50 rounded-md"
              >Sign out</a
            >
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import router from "../../router/router";
import UserPopover from "@/components/User/UserPopover";
import LanguagePicker from "@/components/Language/LanguagePicker";

export default {
  name: "Header",
  components: { LanguagePicker, UserPopover },
  inject: ["openWorkInProgressModal"],
  data() {
    return {
      userDropdownVisible: false,
    };
  },
  setup() {
    return {};
  },
  methods: {
    logIn() {
      router.push({ path: "/auth" });
    },
  },
  computed: {
    ...mapState(["signedIn", "locale"]),
  },
};
</script>

<style scoped lang="scss">
.logo {
  filter: invert(100%);
}

.v-app-bar {
  z-index: 101 !important;
}
</style>
