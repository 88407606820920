<template>
  <modal :visible="visible" @close="close" :key="ownModalInstanceId">
    <template #content v-if="details != null">
      <header
        class="flex flex-wrap justify-between items-center py-5 px-4 -mt-4 -ml-4 sm:flex-nowrap sm:px-6"
      >
        <div class="mt-4 ml-4">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            {{ details.title }}
          </h3>
        </div>
        <div
          class="flex flex-wrap flex-shrink-0 mt-4 ml-4 space-y-3 sm:space-y-0 sm:space-x-3"
        >
          <add-to-favorites-button
            :toggled="favorited"
            @toggle="favorited = !favorited"
            class="flex-1"
          />
          <button
            class="inline-flex relative items-center button button-primary"
            v-if="
              false // TODO set it available when ready
            "
          >
            <svg
              class="mr-2 -ml-1 w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
              ></path>
            </svg>
            <span>{{ $filters.i18n("Edit") }}</span>
          </button>
          <span class="flex flex-col justify-center">
            <button class="lg:ml-2 icon-button" @click="close()">
              <span class="sr-only">{{ $filters.i18n("Close") }}</span>
              <svg
                class="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg></button
          ></span>
        </div>
      </header>
      <main class="overflow-y-auto py-5 px-4 h-auto border-t sm:p-0 bordered">
        <details-grid>
          <template #rows>
            <span id="step-4-insight">
              <details-grid-row :title="$filters.i18n('Contributor')">
                <template #content>
                  <large-contributor-card
                    :name="contributorDetails?.name"
                    :contributions-count="
                      contributorDetails?.relatedContributions?.entries
                        ?.length || 0
                    "
                    :datas="contributorDetails"
                    @click="ContributorSlidePanel = contributorDetails"
                  />
                </template>
              </details-grid-row>
              <details-grid-row
                :title="$filters.i18n('Description')"
                v-if="details.description"
              >
                <template #content>
                  {{ details.description }}
                </template>
              </details-grid-row>
            </span>
            <span id="step-5-insight">
              <details-grid-row
                :title="$filters.i18n('Indexes')"
                additional-row-class="grid grid-cols-3"
              >
                <template #content>
                  <details-grid-sub-col
                    :title="$filters.i18n('Tonality')"
                    v-if="details?.polarity != null"
                  >
                    <template #content>
                      <tonality-badge :score="details?.polarity" />
                    </template>
                  </details-grid-sub-col>
                  <details-grid-sub-col
                    :title="$filters.i18n('Originality')"
                    v-if="details?.originality != null"
                  >
                    <template #content>
                      <percentage-badge
                        :percentage="details?.originality / 10"
                      />
                    </template>
                  </details-grid-sub-col>
                  <details-grid-sub-col
                    :title="$filters.i18n('Relevance')"
                    v-if="
                      details.accuracy != null && details.accuracy !== undefined
                    "
                  >
                    <template #content>
                      <percentage-badge :percentage="0.3" />
                    </template>
                  </details-grid-sub-col>
                </template>
              </details-grid-row>
              <details-grid-row
                :title="$filters.i18n('Clustering')"
                additional-row-class="grid grid-cols-2 space-x-3"
                v-if="
                  contributionDetails.clusters &&
                  contributionDetails.clusters.length > 0
                "
              >
                <template #content>
                  <details-grid-sub-col :title="$filters.i18n('Theme')">
                    <template #content>
                      <SmallClusterCard
                        v-if="contributionDetails.clusters[0]"
                        @click="
                          clusterModal = contributionDetails.clusters[0].id
                        "
                        :title="contributionDetails.clusters[0].name"
                        :contributions-count="
                          contributionDetails.clusters[0].children &&
                          contributionDetails.clusters[0].children.length
                        "
                        :polarity="contributionDetails.clusters[0].polarityMean"
                        :color-hex="
                          contributionDetails.clusters[0].backgroundColor
                        "
                        :title-color-hex="
                          contributionDetails.clusters[0].textColor
                        "
                      />
                    </template>
                  </details-grid-sub-col>
                  <details-grid-sub-col :title="$filters.i18n('Keywords')">
                    <template #content>
                      <tags-list :tags="formattedTags" class="-mt-1" />
                    </template>
                  </details-grid-sub-col>
                </template>
              </details-grid-row>
              <details-grid-row
                :title="$filters.i18n('Additional data')"
                additional-row-class="grid grid-cols-1 gap-2"
                v-if="
                  details?.additionalColumns &&
                  Array.isArray(details?.additionalColumns) &&
                  details?.additionalColumns.length > 0
                "
              >
                <template #content>
                  <details-grid-sub-col
                    :title="col.title"
                    :key="col.key"
                    v-for="col in details?.additionalColumns"
                  >
                    <template #content>
                      <span class="inline-block -mt-2 max-w-full truncate">{{
                        col.value
                      }}</span>
                    </template>
                  </details-grid-sub-col>
                </template>
              </details-grid-row>

              <section class="grid grid-cols-1 lg:grid-cols-2">
                <div
                  class="inline-flex col-span-2 justify-center py-4 px-6 border-b bordered darkened"
                >
                  <h2 class="text-base font-medium text-gray-900">
                    {{
                      relatedContributions.length > 0 &&
                      formattedRelatedContributors.length > 0
                        ? $filters.i18n("Related")
                        : $filters.i18n(
                            "No related contributions or contributors"
                          )
                    }}
                  </h2>
                </div>
                <template
                  v-if="
                    relatedContributions.length > 0 &&
                    formattedRelatedContributors.length > 0
                  "
                >
                  <small-contributions-list
                    :title="$filters.i18n('Contributions')"
                    :limit="4"
                    :contributions="relatedContributions"
                    class="border-b md:border-r bordered"
                    :darkened="false"
                    additional-header-class="justify-center"
                    :small-padding="true"
                    :modals-instance-id="ownModalInstanceId"
                  />

                  <small-contributors-list
                    :title="$filters.i18n('Contributors')"
                    :limit="4"
                    :contributors="formattedRelatedContributors"
                    class="border-b bordered"
                    :darkened="false"
                    additional-header-class="justify-center"
                    :small-padding="true"
                    :modals-instance-id="ownModalInstanceId"
                  />
                </template>
              </section>
            </span>
          </template>
        </details-grid>

        <ClusterModal
          :external-contributors="externalContributors"
          :external-contributions="externalContributions"
          :external-clusters="availableClusters"
          :active-cluster-id="clusterModal"
          :modal-instance-id="ownModalInstanceId"
          v-if="clusterModal"
          :key="`cluster_${ownModalInstanceId}`"
        />

        <ContributorSlidePanel
          :external-contributors="externalContributors"
          :external-contributions="externalContributions"
          :external-clusters="availableClusters"
          :details="ContributorSlidePanel"
          :modal-instance-id="ownModalInstanceId"
          v-if="ContributorSlidePanel"
          :key="`contributor_${ownModalInstanceId}`"
        />

        <contribution-modal
          :visible="contributionModal != null"
          :external-contributors="availableContributors"
          :external-contributions="availableContributions"
          :details="contributionModal"
          :modal-instance-id="ownModalInstanceId"
          :key="`contribution_${ownModalInstanceId}`"
          v-if="contributionModal"
        />
      </main>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Layout/Modal";
import AddToFavoritesButton from "@/components/DesignSystem/Buttons/AddToFavoritesButton";
import DetailsGrid from "@/components/DesignSystem/Layouts/DetailsGrid";
import DetailsGridRow from "@/components/DesignSystem/Layouts/DetailsGridRow";
import LargeContributorCard from "@/components/Contributors/LargeContributorCard";
import _ from "lodash";
import DetailsGridSubCol from "@/components/DesignSystem/Layouts/DetailsGridSubCol";
import PercentageBadge from "@/components/DesignSystem/Badges/PercentageBadge";
import SmallClusterCard from "@/components/Clusters/SmallClusterCard";
import TagsList from "@/components/DesignSystem/Tags/TagsList";
import SmallContributionsList from "@/components/Contributions/SmallContributionsList";
import SmallContributorsList from "@/components/Contributors/SmallContributorsList";
import TonalityBadge from "@/components/DesignSystem/Badges/TonalityBadge";
import { useInjectModals, useProvideModals } from "@/logic/use-modals";
import ClusterModal from "@/components/Clusters/ClusterModal";
import { inject, computed, ref } from "vue";
export default {
  name: "ContributionModal",
  components: {
    ClusterModal,
    TonalityBadge,
    SmallContributorsList,
    SmallContributionsList,
    TagsList,
    SmallClusterCard,
    PercentageBadge,
    DetailsGridSubCol,
    LargeContributorCard,
    DetailsGridRow,
    DetailsGrid,
    AddToFavoritesButton,
    Modal,
  },
  beforeCreate() {
    this.$options.components.ContributorSlidePanel = require("@/components/Contributors/ContributorSlidePanel.vue").default;
  },
  data() {
    return {
      favorited: false,
    };
  },
  props: {
    details: Object,
    externalContributors: Array,
    externalContributions: Array,
    modalInstanceId: String,
  },
  setup(props) {
    const modalVisible = ref(true);

    const ownModalInstanceId = computed(
      () => `contribution_${props.details?.id}`
    );

    const {
      clusterModal,
      contributionModal,
      ContributorSlidePanel,
    } = useProvideModals(ownModalInstanceId.value);

    const { updateContributionModal: closeModal } = useInjectModals(
      props.modalInstanceId,
      true,
      false,
      false
    );

    const [availableContributors, availableContributions, availableClusters] = [
      inject("availableContributors"),
      inject("availableContributions"),
      inject("availableClusters"),
    ];

    const close = () => {
      modalVisible.value = false;

      setTimeout(function () {
        closeModal();
        // TODO Make this work properly
      }, 1);
    };

    const visible = computed(() => modalVisible.value && props.details != null);

    const contributionDetails = computed(() => {
      return _.find(
        props.externalContributions,
        (c) => c.id == props.details?.id
      );
    });

    const contributorDetails = computed(() => {
      // TODO Refactor this, or at least check if it still make sens (hint : it doesnt)
      return _.find(
        props.externalContributors,
        (c) => c.id === props.details?.author?.id
      );
    });

    const relatedContributions = computed(() => {
      let relatedContributions = [];
      let relativeContributions = JSON.parse(
        contributionDetails.value.relativeness
      );

      for (let contribution of Object.values(relativeContributions || {})) {
        let contributionsCompletedInformations = _.find(
          props.externalContributions,
          (externalContribution) => externalContribution.id === contribution.id
        );

        relatedContributions.push({
          ...contribution,
          ...contributionsCompletedInformations,
        });
      }

      return _.filter(
        relatedContributions,
        (c) => c.score > 0.15 && c.author?.id !== contributionDetails.value.id
      );
    });

    const relatedContributorsWithContributions = computed(() => {
      return _.chain(relatedContributions.value)
        .filter(
          (c) =>
            c.score > 0.15 && c.author?.id !== props.details?.contributor?.id
        )
        .groupBy("author.id")
        .map((contributor) => {
          return {
            contributor: contributor[0] && contributor[0].author,
            contributions: contributor,
          };
        })
        .value();
    });

    const formattedRelatedContributors = computed(() => {
      return relatedContributorsWithContributions.value.map(
        (relatedContributorWithContributions) => ({
          id: relatedContributorWithContributions.contributor?.id,
          name: relatedContributorWithContributions.contributor?.name,
          datas: relatedContributorWithContributions.contributor,
          relatedContributions: {
            count: relatedContributorWithContributions?.contributions?.length,
            averageStrength: _.meanBy(
              relatedContributorWithContributions?.contributions,
              "score"
            ),
          },
        })
      );
    });

    const formattedTags = computed(() => {
      let tags = [];

      if (
        contributionDetails.value?.clusters &&
        contributionDetails.value.clusters[0]
      ) {
        try {
          tags = _.chain(contributionDetails.value.clusters[0].themes)
            .orderBy("score", "desc")
            .map("title")
            .value();
        } catch (e) {
          console.error(e);
        }
      }
      return tags;
    });

    const additionalColumns = computed(
      () => contributionDetails.value?.additionalColumns || []
    );

    return {
      ownModalInstanceId,
      close,
      clusterModal,
      availableContributors,
      availableContributions,
      availableClusters,
      visible,
      contributionModal,
      ContributorSlidePanel,
      contributionDetails,
      contributorDetails,
      relatedContributions,
      relatedContributorsWithContributions,
      formattedRelatedContributors,
      formattedTags,
      additionalColumns,
    };
  },
};
</script>
