<template>
  <div class="flex items-center hover:bg-gray-50">
    <div
      class="p-4 pr-2 sm:px-6 sm:pr-3"
      v-if="
        false // TODO CHANGE THIS WHEN READY
      "
    >
      <Checkbox v-model="selected" />
    </div>
    <div v-else class="p-4 px-1.5" />
    <div
      class="flex flex-wrap flex-1 items-center p-4 pl-2 cursor-pointer sm:px-6 sm:pl-3 md:space-x-4"
      @click="$emit('click')"
    >
      <div class="flex-1 min-w-full md:min-w-0">
        <p class="text-sm font-medium text-gray-900 truncate">
          {{ name }}
        </p>
        <div class="flex">
          <p class="text-sm text-gray-500 truncate">
            {{ relatedContributionsCount
            }}{{
              " " + (contributionHint || $filters.i18n("related contributions"))
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/DesignSystem/Inputs/Checkbox";

export default {
  name: "LightContributorCard",
  components: { Checkbox },
  emits: ["click"],
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    linkStrengthText() {
      if (this.linkStrength >= 0.67) {
        return "Strong link";
      }

      if (this.linkStrength >= 0.33) {
        return "Medium link";
      }

      return "Weak link";
    },
    linkStrengthColor() {
      if (this.linkStrength >= 0.67) {
        return "green";
      }

      if (this.linkStrength >= 0.33) {
        return "cyan";
      }

      return "red";
    },
  },
  props: {
    name: String,
    datas: Object,
    relatedContributionsCount: Number,
    linkStrength: Number,
    contributionHint: String,
  },
};
</script>

<style scoped lang="scss"></style>
