/* eslint-disable */
// this is an auto generated file. This will be overwritten

import gql from "graphql-tag";

export const onCreateSession = gql(`
  subscription onCreateSession($owner: String!) {
    onCreateSession(owner: $owner) {
      id
      title
    }
  }
`);

export const onCreateAuthor = gql(`
  subscription onCreateAuthor($owner: String!) {
    onCreateAuthor(owner: $owner) {
      id
      name
      companyRole
    }
  }
`);

export const onCreateIdea = gql(`
  subscription onCreateIdea($owner: String!) {
    onCreateIdea(owner: $owner) {
      id
      title
      owner
      description
      date
      originality
      polarity
      commentsNb
      likesNb
      
      author {
          id
          name
          companyRole
      }
    }
  }
`);

export const onUpdateSessionAnalysed = gql(`
  subscription OnUpdateSessionAnalysed($owner: String!) {
    onUpdateSession(owner: $owner) {
      id
      analysed
    }
  }
`);
