import _ from "lodash";
import chroma from "chroma-js";

export function useFormatBatchData() {
  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const extractContributionsFromContributors = (contributors) => {
    if (contributors) {
      return _.flatMap(contributors, (author) => {
        return author.ideas.items.map((contribution) => {
          let contributionWithClusters = Object.assign(
            { clusters: [] },
            contribution
          );

          if (
            contribution.cluster !== undefined &&
            contribution.cluster !== null
          ) {
            contributionWithClusters.clusters = [contribution.cluster];
          }

          try {
            contributionWithClusters.additionalColumns =
              contribution.additionalColumns != null
                ? JSON.parse(contribution.additionalColumns)
                : [];

            const columnsBinding =
              contributionWithClusters.additionalColumns
                ?.phedone_columnsBindings || null;

            if (columnsBinding) {
              const additionalColumnsValues = {};
              contributionWithClusters.additionalColumns = Object.entries(
                contributionWithClusters.additionalColumns
              )
                .filter((entry) => entry[0] !== "phedone_columnsBindings")
                .map((entry) => {
                  const title = columnsBinding.find((c) => c.key === entry[0])
                    ?.title;

                  const value = entry[1];
                  const key = entry[0];

                  additionalColumnsValues[key] = value;
                  return {
                    title,
                    value,
                    key,
                  };
                });

              contributionWithClusters = {
                ...contributionWithClusters,
                ...additionalColumnsValues,
              };
            }

            // Handle injecting title in the object
          } catch (e) {
            console.error(e);
          }

          return contributionWithClusters;
        });
      });
    }

    return [];
  };

  const filterContributionsWithFocusedClusterAndFocusedContribution = (
    contributions,
    focusedClusterIds,
    focusedContributionId
  ) => {
    if (
      (focusedClusterIds && focusedClusterIds.length > 0) ||
      focusedContributionId
    ) {
      return _.filter(contributions, (contribution) => {
        if (focusedContributionId) {
          return contribution.id === focusedContributionId;
        }
        return (
          contribution.cluster &&
          contribution.cluster.id &&
          focusedClusterIds.includes(contribution.cluster.id)
        );
      });
    }
    return contributions;
  };

  const getContributionsByClusterIds = (contributions, clusterId) => {
    return _.filter(contributions, (contribution) => {
      return (
        contribution &&
        contribution.cluster &&
        contribution.cluster.id === clusterId
      );
    });
  };

  const formatContributorsWithContributions = (
    contributorsWithContributions
  ) => {
    return _.map(
      { ...contributorsWithContributions },
      (contributorWithContributions) => ({
        id: contributorWithContributions.id,
        name: contributorWithContributions.name,
        relatedContributions: {
          count: contributorWithContributions.ideas.items.length,
          entries: contributorWithContributions.ideas.items,
        },
      })
    );
  };

  const formatClusters = (clusters, contributions) => {
    const colorScale = chroma.scale(["B71C1C", "FFEBEE", "4CAF50"]);

    return _.chain(clusters || [])
      .map((cluster) => {
        let name = cluster.title || "ND";
        let themes = [];
        let title = cluster.title || "ND";

        if (cluster.themes && isJson(cluster.themes)) {
          themes = _.uniqBy(JSON.parse(cluster.themes), "title");

          let validThemes = _.chain(themes)
            .filter((theme) => theme.score >= 0.275)
            .value();

          if (cluster.title == null) {
            if (validThemes.length > 0) {
              name = _.chain(validThemes)
                .merge()
                .sortBy("score")
                .reverse()
                .take(3)
                .map("title")
                .join(" ")
                .split(" ")
                .uniq()
                .join("\n   ")
                .value();

              title = _.chain(validThemes)
                .sortBy("score")
                .reverse()
                .take(1)
                .map("title")
                .join(" ")
                .split(" ")
                .uniq()
                .join("\n   ")
                .value();
            } else {
              name = "ND";
              title = name;
            }
          }
        }

        let children = [];
        children = _.chain(
          getContributionsByClusterIds(contributions, cluster.id) || []
        )
          .map((contribution) => {
            let keywordsJson =
              contribution.keywords &&
              contribution.keywords !== "" &&
              contribution.keywords !== "[]"
                ? contribution.keywords
                : "[]";
            let keywords = JSON.parse(keywordsJson);
            let firstWord = "ND";

            if (keywords.length >= 1) {
              firstWord = _.capitalize(keywords[0]) || "ND";
            } else {
              firstWord = contribution.title.replace(/^(.{5}[^\s]*).*/, "$1"); // 5 first characters + any subsequents without space
            }
            const perc = 0.5 + contribution.polarity;

            return {
              title: contribution.title,
              value: 1,
              first_word: firstWord, // 5 first characters + any subsequents without space
              id: contribution.id,
              bgColor: colorScale(perc).hex(),
              textColor: perc >= 0.35 && perc <= 0.65 ? "#000000" : "#FFFFFF",
            };
          })
          .value();

        let textColor;
        let backgroundColor;

        if (cluster.textColor && cluster.backgroundColor) {
          backgroundColor = cluster.backgroundColor;
          textColor = cluster.textColor;
        } else {
          // In case it's a legacy cluster with no color set
          let colors = this.generateMaterialColorFromClusterNameHash(name);
          textColor = colors.textColor;
          backgroundColor = colors.backgroundColor;
        }

        return {
          name: name,
          title: title,
          id: cluster.id,
          children: children,
          themes: themes,
          density: cluster.density,
          polarityMean: cluster.polarityMean,
          backgroundColor: backgroundColor,
          textColor: textColor,
        };
      })
      .filter((cluster) => cluster.title != "ND")
      .sortBy((cluster) => cluster.children.length)
      .reverse() // We want the cluster with the most children in first place !
      .value();
  };

  const formatContributionsWithFormattedClusters = (
    formattedClusters,
    contributions
  ) => {
    if (formattedClusters && contributions && contributions.length) {
      return _.map(contributions, (contribution) => {
        const finalContribution = { ...contribution };
        finalContribution.clusters = _.chain(finalContribution.clusters)
          .map((cluster) =>
            _.find(formattedClusters, (formattedCluster) => {
              return formattedCluster && formattedCluster.id === cluster.id;
            })
          )
          .filter((cluster) => cluster !== undefined && cluster !== null)
          .value();

        finalContribution.cluster = finalContribution.clusters[0];

        return finalContribution;
      });
    }

    return [];
  };

  const findContributorFromContributionId = (contributions, contributionId) => {
    return _.find(contributions || [], (idea) => idea.id === contributionId)
      .author;
  };

  const findContributorIdFromContributionId = (
    contributions,
    contributionId
  ) => {
    return findContributorFromContributionId(contributions, contributionId)?.id;
  };

  const findContributorNameFromContributionId = (
    contributions,
    contributionId
  ) => {
    return findContributorFromContributionId(contributions, contributionId)
      ?.name;
  };

  return {
    filterContributionsWithFocusedClusterAndFocusedContribution,
    getContributionsByClusterIds,
    formatClusters,
    formatContributionsWithFormattedClusters,
    extractContributionsFromContributors,
    formatContributorsWithContributions,
    findContributorIdFromContributionId,
    findContributorNameFromContributionId,
  };
}
