<template>
  <div
    class="flex overflow-hidden flex-col flex-1 justify-between items-center pt-8 carded"
  >
    <div
      class="flex flex-col justify-center items-center space-y-3 w-full max-w-[500px] h-full"
    >
      <div class="flex flex-col justify-center items-center">
        <ExclamationIcon class="w-20 h-20 text-primary-dark" />

        <b class="text-lg text-primary-dark">{{
          $filters.i18n("An error occurred")
        }}</b>
      </div>

      <a
        class="button button-primary-dark"
        :href="`mailto:support@phedone.com?subject=${encodeURIComponent(
          $filters.i18n('An error occurred')
        )}&body=${encodeURIComponent(
          $filters.i18n('Here are the logs : ')
        )}%0D%0A${encodeURIComponent(error)}%0D%0A%0D%0A${encodeURIComponent(
          $filters.i18n('Additional informations : ')
        )}%0D%0A`"
        >{{ $filters.i18n("Report the error to Phedone's team") }}</a
      >
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/outline";

export default {
  name: "InsightError",
  components: {
    ExclamationIcon,
  },
  props: {
    id: String,
    error: String,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
