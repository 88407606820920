<template>
  <page-body>
    <template #mainBody>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="mx-auto w-auto h-12"
          src="../../assets/logo.png"
          alt="Workflow"
        />
        <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          {{ $filters.i18n("auth_register_title") }}
        </h2>
        <p class="mt-2 text-sm text-center text-gray-600">
          {{ $filters.i18n("Have an account?") }}
          <router-link
            to="/auth/login"
            class="font-medium text-primary hover:text-primary-light"
          >
            {{ $filters.i18n("auth_register_subtitle") }}
          </router-link>
        </p>
        <form @submit.prevent="signUp">
          <div
            class="fixed top-[100vh] opacity-0 pointer-events-none"
            data-description="dummyPanel for Chrome auto-fill issue"
          >
            <input
              type="text"
              style="
                height: 0;
                background: transparent;
                color: transparent;
                border: none;
              "
              name="email"
              data-description="dummyUsername"
            />
            <input
              type="password"
              style="
                height: 0;
                background: transparent;
                color: transparent;
                border: none;
              "
              name="password"
              data-description="dummyPassword"
            />
          </div>
          <div class="py-8 px-10 mt-8 space-y-5 carded">
            <text-input
              v-model="username"
              type="email"
              autocomplete="email"
              required
              class="py-3 px-3 bg-white"
              >{{ $filters.i18n("Email*") }}</text-input
            >
            <text-input
              v-model="name"
              autocomplete="name"
              type="text"
              required
              class="py-3 px-3 bg-white"
              >{{ $filters.i18n("Firstname, Lastname *") }}</text-input
            >
            <text-input
              v-model="password"
              type="password"
              autocomplete="new-password"
              required
              class="py-3 px-3 bg-white"
              >{{ $filters.i18n("Password *") }}</text-input
            >
            <div class="grid grid-cols-2 grid-rows-1 grid-flow-col gap-4">
              <text-input
                v-model="corporation"
                type="text"
                class="py-3 px-3 bg-white"
                >{{ $filters.i18n("Corporation") }}</text-input
              >
              <text-input
                v-model="website"
                type="text"
                class="py-3 px-3 bg-white"
                >{{ $filters.i18n("Website") }}</text-input
              >
            </div>
            <button
              type="submit"
              :disabled="requestInProgress"
              class="w-full button button-primary"
            >
              <togglable-loading-state :loading="requestInProgress">
                {{ $filters.i18n("Sign Up") }}</togglable-loading-state
              >
            </button>
          </div>
        </form>
      </div>
    </template>
  </page-body>
</template>

<script>
import PageBody from "@/components/Layout/PageBody";
import TextInput from "@/components/DesignSystem/Inputs/TextInput";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import { Auth } from "aws-amplify";

export default {
  name: "Register.vue",
  components: { TogglableLoadingState, TextInput, PageBody },
  props: {
    presetEmail: String,
    presetName: String,
    presetCorporation: String,
  },
  mounted() {
    this.username = this.presetEmail;
    this.name = this.presetName;
    this.corporation = this.presetCorporation;
  },
  data() {
    return {
      requestInProgress: false,
      username: "",
      password: "",
      name: "",
      corporation: "",
      website: "",
    };
  },
  methods: {
    async signUp() {
      this.requestInProgress = true;
      try {
        const { user, userConfirmed } = await Auth.signUp({
          username: this.username,
          password: this.password,
          attributes: {
            "custom:corporation": this.corporation,
            website: this.website,
            name: this.name,
          },
        });

        console.log("info : ");
        console.log(user);

        if (userConfirmed === false) {
          await this.$router.push({
            path: "/auth/confirm/account",
            query: { email: this.username },
          });
        }
      } catch (error) {
        console.log("error signing up:", error);
      }
      this.requestInProgress = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
