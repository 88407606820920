<template>
  <cluster-merge-modal :cluster-id="clusterId" @on-done="$emit('on-merged')">
    <template #default="{ clickCallback, loading }">
      <button
        class="button"
        @click="clickCallback"
        :disabled="loading"
        v-bind="$attrs"
      >
        <slot />

        <togglable-loading-state :loading="loading">
          <span>{{ $filters.i18n("Merge") }}</span>
        </togglable-loading-state>
      </button>
    </template>
  </cluster-merge-modal>
</template>

<script>
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import ClusterMergeModal from "@/components/Clusters/ClusterMergeModal";

export default {
  name: "ClusterMergeButton",
  components: { ClusterMergeModal, TogglableLoadingState },
  props: {
    clusterId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      loading: false,
    };
  },
};
</script>
