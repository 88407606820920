<template>
  <div>
    <dismissable-alert v-if="error" v-model:visible="error">{{
      $filters.i18n("An error occured during the renaming")
    }}</dismissable-alert>

    <slot :clickCallback="() => (modalVisible = true)" :loading="loading" />

    <dialog-modal
      :show="modalVisible"
      @action-clicked="renameCluster(clusterId)"
      @close="modalVisible = false"
      type="action"
      full-width-content
      :action-disabled="newTitle.length < 1 && newTitle !== ''"
    >
      <template #action>
        <togglable-loading-state :loading="loading">
          {{ $filters.i18n("Rename the cluster") }}
        </togglable-loading-state>
      </template>
      <template #title>{{ $filters.i18n("renameClusterHint_title") }}</template>
      <template #content>
        <text-input v-model="newTitle" required class="w-full">{{
          $filters.i18n("renameClusterHint_label")
        }}</text-input>
      </template>
    </dialog-modal>
  </div>
</template>

<script>
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";
import DialogModal from "@/components/DesignSystem/Dialog/DialogModal";
import { ref, toRefs } from "vue";
import { useRenameCluster } from "@/logic/use-api-fetch";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import TextInput from "@/components/DesignSystem/Inputs/TextInput";

export default {
  name: "ClusterRenameModal",
  components: {
    TextInput,
    TogglableLoadingState,
    DialogModal,
    DismissableAlert,
  },
  inheritAttrs: false,
  setup(props, { emit }) {
    const error = ref(null);
    const modalVisible = ref(false);

    const {
      renameCluster,
      onDone,
      onError,
      loading,
      newTitle,
    } = useRenameCluster(props.clusterId);

    let { defaultTitle } = toRefs(props);

    newTitle.value = (defaultTitle.value || "").replace("\n", "");

    const close = () => {
      modalVisible.value = false;
    };

    onDone(() => {
      emit("on-done");
      close();
    });

    onError((err) => {
      error.value = err;
    });

    return {
      renameCluster,
      loading,
      error,
      modalVisible,
      newTitle,
    };
  },
  props: {
    clusterId: {
      type: String,
      required: true,
    },
    defaultTitle: {
      type: String,
      default: "",
    },
  },
};
</script>
