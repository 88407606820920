<template>
  <div>
    <div class="py-5 px-4 sm:p-6">
      <dt class="text-sm font-normal text-gray-900">{{ title }}</dt>
      <dd class="flex justify-between items-baseline mt-1 md:block lg:flex">
        <div
          class="flex items-baseline w-full text-xl font-semibold text-primary lg:flex-col"
        >
          <button
            class="font-semibold hover:text-primary-dark truncate focus:outline-none"
            @click.prevent="$emit('title-click')"
            v-if="clickable"
          >
            {{ value }}
          </button>
          <span class="truncate" v-else>{{ value }}</span>
          <span
            class="text-sm font-medium text-primary text-opacity-50"
            v-if="$slots.subtitle"
          >
            <slot name="subtitle" />
          </span>
        </div>

        <div
          v-if="positiveEvolution || negativeEvolution"
          class="inline-flex items-baseline py-0.5 px-2.5 text-sm font-medium rounded-full md:mt-2 lg:mt-0"
          :class="
            positiveEvolution
              ? 'bg-green-100 text-green-800'
              : 'bg-red-100 text-red-800'
          "
        >
          <svg
            class="flex-shrink-0 self-center mr-0.5 -ml-1 w-5 h-5"
            :class="positiveEvolution ? 'text-green-500' : 'text-red-500'"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path
              v-if="positiveEvolution"
              fill-rule="evenodd"
              d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
            <path
              v-else
              fill-rule="evenodd"
              d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only"
            >{{
              $filters.i18n(positiveEvolution ? "Increased by" : "Decreased by")
            }}
          </span>
          {{ evolution }}
        </div>
      </dd>
    </div>
  </div>
</template>

<script>
export default {
  name: "SharedBorderMetric",
  props: {
    title: { type: String, required: true },
    value: { required: true },
    subtitle: String,
    positiveEvolution: Boolean,
    negativeEvolution: Boolean,
    evolution: String,
    clickable: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
