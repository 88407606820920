<template>
  <page-body>
    <template #preBody>
      <insight-information-bar
        :title="getSession && getSession.title"
        :results-available="getSession && getSession.analysed"
        :date="getSession && getSession.createdAt"
        :sessionId="id"
        :can-delete="false"
        :can-export="false"
        is-dashboard
      />
    </template>
    <template #mainBody>
      <dashboard-insight-container
        v-if="
          formattedClusters &&
          formattedClusters.length > 0 &&
          contributionsWithFormattedClusters &&
          contributionsWithFormattedClusters.length > 0
        "
        :title="getSession && getSession.title"
        :key="`insight_dashboard_${$store.state.locale}`"
        :clusters="formattedClusters"
        :contributions="contributionsWithFormattedClusters"
        class="overflow-hidden"
      />

      <div
        class="flex flex-col justify-center items-center w-full h-full"
        v-else
      >
        <loading-spinner
          :hint="$filters.i18n('dashboard_insight_loading_hint')"
        />
      </div>
    </template>
  </page-body>
</template>

<script>
import PageBody from "@/components/Layout/PageBody";
import DashboardInsightContainer from "@/components/Dashboard/DashboardInsight";
import InsightInformationBar from "@/components/Insights/InsightInformationBar";
import { useApiFetch } from "@/logic/use-api-fetch";
import { computed, inject } from "vue";
import { useFormatBatchData } from "@/logic/use-format-batch-data";
import LoadingSpinner from "@/components/DesignSystem/Progress/LoadingSpinner";
import { isTourCompletedForPageInLS } from "@/logic/use-tour";

export default {
  name: "InsightDashboard",
  components: {
    LoadingSpinner,
    InsightInformationBar,
    DashboardInsightContainer,
    PageBody,
  },
  data() {
    return {};
  },
  props: {
    id: String,
  },
  setup(props) {
    const i18n = inject("i18n");
    const setTutorialVisible = inject("setTutorialVisible");
    const setupTourOnComponent = inject("setupTourOnComponent");
    setupTourOnComponent(i18n, "InsightDashboard", {});
    if (!isTourCompletedForPageInLS("InsightDashboard")) {
      setTutorialVisible();
    }

    const { getBatch } = useApiFetch;

    const {
      formatClusters,
      formatContributorsWithContributions,
      formatContributionsWithFormattedClusters,
      extractContributionsFromContributors,
    } = useFormatBatchData();

    let {
      loading,
      error,
      getSession,
      nextToken,
      clusters,
      contributors,
      loadedBatch,
    } = getBatch(props.id);

    const contributions = computed(() =>
      extractContributionsFromContributors(contributors.value)
    );

    const formattedClusters = computed(() =>
      formatClusters(clusters.value, contributions.value)
    );

    const formattedContributors = computed(() =>
      formatContributorsWithContributions(contributors.value)
    );

    const contributionsWithFormattedClusters = computed(() =>
      formatContributionsWithFormattedClusters(
        formattedClusters.value,
        contributions.value
      )
    );

    return {
      loading,
      error,
      getSession,
      nextToken,
      clusters,
      contributors,
      loadedBatch,
      contributions,
      formattedClusters,
      formattedContributors,
      contributionsWithFormattedClusters,
    };
  },
};
</script>

<style scoped lang="scss"></style>
