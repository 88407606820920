<template>
  <togglable-state-button
    :title="$filters.i18n('Add to favorites')"
    :toggle-title="$filters.i18n('Remove from favorites')"
    :toggled="toggled"
    v-bind="$attrs"
    v-if="
      false // TODO set it available when ready
    "
  >
    <template v-slot:icon>
      <svg
        class="mr-2 -ml-1 w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        stroke="currentColor"
        :fill="toggled ? 'currentColor' : 'transparent'"
        aria-hidden="true"
      >
        <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
      </svg>
    </template>
  </togglable-state-button>
</template>

<script>
import TogglableStateButton from "@/components/DesignSystem/Buttons/TogglableStateButton";
export default {
  name: "AddToFavoritesButton",
  components: { TogglableStateButton },
  props: {
    toggled: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
