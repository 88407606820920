<template>
  <div class="w-full max-w-full h-full max-h-full">
    <vue3-chart-js
      :data="radarGraph.data"
      :type="radarGraph.type"
      :plugins="radarGraph.plugins"
      :options="radarGraph.options"
      ref="chartRef"
      class="!w-full !max-w-full !h-full !max-h-full"
    />
  </div>
</template>

<script>
import { uniqueId } from "lodash";
import { onMounted, ref, watch } from "vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { useColorHelpers } from "@/logic/use-color-helpers";

export default {
  name: "RadarChart",
  data() {
    return {};
  },
  props: {
    dataSets: Array,
    labels: Array,
  },
  components: {
    Vue3ChartJs,
  },
  setup(props) {
    const chartRef = ref(`${uniqueId("radar_chart")}`);

    const gradientFromColor = (color, ctx) => {
      let gradient = ctx.createLinearGradient(0, 0, 0, 150);
      gradient.addColorStop(0, useColorHelpers.hex2rgba(color, 0.3));
      gradient.addColorStop(1, useColorHelpers.hex2rgba(color, 0.15));

      return gradient;
    };

    let shadowed = {
      beforeDatasetsDraw: function (chart) {
        chart.ctx.shadowColor = "rgba(0, 0, 0, 0.2)";
        chart.ctx.shadowBlur = 30;
      },
      afterDatasetsDraw: function (chart) {
        chart.ctx.shadowColor = "rgba(0, 0, 0, 0)";
        chart.ctx.shadowBlur = 0;
      },
    };
    let delayed;

    const radarGraph = {
      type: "radar",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        devicePixelRatio: 2,
        responsive: true,

        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        gridLines: {
          display: false,
        },
        scale: {
          ticks: {
            maxTicksLimit: 1,
            display: false,
          },
        },
        scales: {
          r: {
            pointLabels: {
              font: {
                size: 12,
              },
            },
            grid: {
              display: false,
            },
          },
        },
        animation: {
          onComplete: () => {
            delayed = true;
          },
          delay: (context) => {
            let delay = 0;
            if (
              context.type === "data" &&
              context.mode === "default" &&
              !delayed
            ) {
              delay = context.dataIndex * 300 + context.datasetIndex * 100;
            }
            return delay;
          },
        },
        layout: {
          padding: 15,
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            labels: {
              boxWidth: 20,
              padding: 10,
              font: {
                size: 12,
                lineHeight: 1.2,
                family:
                  'Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              },
            },
          },
        },
      },
      plugins: [shadowed],
    };

    onMounted(() => {
      watch(
        props,
        () => {
          const dataSets = props.dataSets || [];

          radarGraph.data.labels = props.labels || [];
          radarGraph.data.datasets = dataSets.slice(0, 20).map((dataset) => ({
            label: dataset.title,
            data: dataset.entries,
            fill: true,
            backgroundColor: function (context) {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return null;
              }

              return gradientFromColor(dataset.color, ctx);
            },
            borderColor: dataset.color,
            borderWidth: 1,
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            pointHoverBackgroundColor: "transparent",
            pointHoverBorderColor: "transparent",
            pointHitRadius: 50,
          }));
          chartRef.value.update(`${uniqueId("radar_chart")}`);
        },
        {
          immediate: true,
        }
      );
    });

    return { chartRef, radarGraph };
  },
};
</script>

<style scoped lang="scss"></style>
