<template>
  <div class="flex items-center">
    <button
      type="button"
      class="inline-flex relative flex-shrink-0 w-11 h-6 rounded-full border-2 border-transparent focus:ring-2 focus:ring-primary focus:ring-offset-2 transition-colors duration-200 ease-in-out cursor-pointer focus:outline-none"
      :class="modelValue ? 'bg-primary' : 'bg-gray-200'"
      :aria-pressed="modelValue"
      :aria-labelledby="label"
      @click="$emit('update:modelValue', !modelValue)"
    >
      <span class="sr-only">{{ label }}</span>
      <span
        class="inline-block relative w-5 h-5 bg-white rounded-full ring-0 shadow transition duration-200 ease-in-out transform pointer-events-none"
        :class="modelValue ? 'translate-x-5' : 'translate-x-0'"
      >
        <template v-if="icons">
          <span
            class="flex absolute inset-0 justify-center items-center w-full h-full opacity-100 transition-opacity duration-200 ease-in"
            :class="
              modelValue
                ? 'opacity-0 ease-out duration-100'
                : 'opacity-100 ease-in duration-200'
            "
            aria-hidden="true"
          >
            <svg
              class="w-3 h-3 text-gray-400 bg-white"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span
            class="flex absolute inset-0 justify-center items-center w-full h-full transition-opacity"
            aria-hidden="true"
            :class="
              modelValue
                ? 'opacity-100 ease-in duration-200'
                : 'opacity-0 ease-out duration-100'
            "
          >
            <svg
              class="w-3 h-3 text-primary bg-white"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path
                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
              />
            </svg>
          </span>
        </template>
      </span>
    </button>
    <span class="ml-3" :id="label">
      <slot name="label" v-if="!!this.$slots.label" />
      <span v-else class="text-sm font-medium text-gray-900">{{ label }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    label: String,
    modelValue: Boolean,
    icons: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
