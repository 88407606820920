<template>
  <div class="relative w-full h-full">
    <LoadingChartOverlay
      v-if="!chartReady"
      :hint="$filters.i18n(`Themes cloud loading in progress`)"
    />

    <toggle
      class="absolute top-5 left-5 z-40"
      v-model="displayOpinions"
      :label="$filters.i18n('Display opinions')"
      v-if="chartReady"
    />

    <div id="themesCloud" ref="chartHtmlRef" class="absolute inset-10 top-16" />

    <ClusterModal
      :external-contributors="availableContributors"
      :external-contributions="availableContributions"
      :external-clusters="availableClusters"
      :active-cluster-id="clusterModal"
      :modal-instance-id="ownModalInstanceId"
      v-if="clusterModal"
      :key="`cluster_${ownModalInstanceId}`"
    />
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import _ from "lodash";
import theme from "@amcharts/amcharts4/themes/material";
import LoadingChartOverlay from "./LoadingChartOverlay";
import { nextTick } from "node-forge/lib/util";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useReadyToUseSingleClusterModal } from "@/logic/use-modals";
import ClusterModal from "@/components/Clusters/ClusterModal";
import { useClustersScoreCalculations } from "@/logic/use-clusters-score-calculations";
import Toggle from "@/components/DesignSystem/Inputs/Toggle";
// Apply the themes
am4core.useTheme(theme);

am4core.options.queue = false;

export default {
  name: "ThemesCloud",
  components: { Toggle, ClusterModal, LoadingChartOverlay },
  props: {
    clusters: { type: Array, default: null, required: false },
    visible: Boolean,
  },
  setup(props) {
    const chartElementRef = ref(null);
    const chartHtmlRef = ref(null);
    const displayOpinions = ref(false);
    const chartReady = ref(false);
    const changingDisplay = ref(false);
    const ownModalInstanceId = computed(() => `themes-cloud_modal`); // Here the computed is not necessary, but could be use with variable key

    const clusterModalVariables = useReadyToUseSingleClusterModal(
      ownModalInstanceId
    );

    const { scoreToColor } = useClustersScoreCalculations;

    const themesFromClusters = computed(() => {
      const themes = [];

      for (const cluster of props.clusters) {
        for (const theme of cluster.themes) {
          themes.push({ ...theme, cluster });
        }
      }
      return _.uniqBy(themes, "title");
    });

    const formattedThemes = computed(() => {
      return _.map(themesFromClusters.value, (t) => ({
        tag: t.title,
        weight: t.score * 50,
        backgroundColor: t.cluster.backgroundColor,
        backgroundColorOpinion: scoreToColor(t.cluster.polarityMean ?? 0),
        clusterId: t.cluster.id,
      }));
    });

    const setupChart = () => {
      am4core.options.queue = false;

      let chart = am4core.create(
        chartHtmlRef.value,
        am4plugins_wordCloud.WordCloud
      );

      chart.svgContainer.autoResize = true;

      let series = chart.series.push(
        new am4plugins_wordCloud.WordCloudSeries()
      );

      series.data = formattedThemes.value;

      series.dataFields.word = "tag";
      series.dataFields.value = "weight";
      series.dataFields.fill = "backgroundColor";
      series.dataFields.textColor = "backgroundColor";
      series.dataFields.color = "backgroundColor";

      series.labels.template.adapter.add("fill", function (fill, target) {
        return displayOpinions.value
          ? target.dataItem?.dataContext?.backgroundColorOpinion
          : target.dataItem?.dataContext?.backgroundColor;
      });

      chartElementRef.value = chart;

      series.events.on("arrangeended", () => {
        console.log("Themeclouds  ready");

        chartReady.value = true;
      });

      series.labels.template.events.on("hit", function (ev) {
        clusterModalVariables.clusterModal.value =
          ev.target.dataItem?.dataContext?.clusterId || null;
      });

      chart.events.on("zIndexChanged", () => {
        series.labels.template.adapter.remove("fill");

        for (const target of series.labels) {
          if (!changingDisplay.value) {
            return;
          }

          target.fill = displayOpinions.value
            ? target.dataItem?.dataContext?.backgroundColorOpinion
            : target.dataItem?.dataContext?.backgroundColor;
        }
      });
    };

    watch(displayOpinions, () => {
      changingDisplay.value = true;
      chartElementRef.value.dispatchImmediately("zIndexChanged"); // It's the trick
    });

    onMounted(() => {
      setupChart();
    });

    onBeforeUnmount(() => {
      if (chartElementRef.value) {
        chartElementRef.value.dispose();
      }
    });

    watch(
      () => props.visible,
      (visible) => {
        if (visible) {
          console.log(visible);
          setTimeout(async () => {
            await nextTick();
            chartElementRef.value.svgContainer.autoResize = false;
          }, 1);
        }
      },
      {
        immediate: true,
      }
    );

    return {
      ...clusterModalVariables,
      ownModalInstanceId,
      formattedThemes,
      themesFromClusters,
      chartElementRef,
      chartReady,
      chartHtmlRef,
      displayOpinions,
    };
  },
};
</script>

<style lang="css">
#themesCloud svg text:hover {
  cursor: pointer;
  opacity: 0.6;
}
</style>
