<template>
  <!--  <TransitionRoot appear as="div" :show="visible">-->
  <Dialog
    as="div"
    static
    class="overflow-hidden absolute inset-0 z-50"
    @close="close()"
    :open="visible"
  >
    <div class="overflow-hidden absolute inset-0">
      <!--        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >-->
      <DialogOverlay
        class="absolute inset-0 bg-gray-600 bg-opacity-10 cursor-pointer"
      />
      <!--        </TransitionChild>-->

      <div
        class="flex absolute inset-y-0 right-0 pl-10 max-w-full sm:pl-16 lg:pl-0"
      >
        <!--          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >-->
        <div class="w-screen max-w-sm">
          <div class="flex flex-col h-full bg-white shadow-xl">
            <header>
              <div class="py-6 px-4 sm:px-6">
                <div class="flex justify-between items-center">
                  <h2
                    id="slide-over-heading"
                    class="text-lg font-medium text-gray-900"
                  >
                    {{ title }}
                  </h2>

                  <div class="flex items-center ml-3 h-7">
                    <button
                      class="text-gray-400 hover:text-gray-500 bg-white rounded-md focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:outline-none"
                      @click="close()"
                    >
                      <span class="sr-only">Close panel</span>
                      <XIcon class="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <slot name="header"></slot>
            </header>

            <main class="overflow-y-auto">
              <slot name="content"></slot>
            </main>

            <!--            <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                        <dl class="space-y-8 px-4 sm:px-6 sm:space-y-6">
                          <div>
                            <dt
                              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0"
                            >
                              Location
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                              New York, NY, USA
                            </dd>
                          </div>
                        </dl>
                      </div>-->
          </div>
        </div>
        <!--          </TransitionChild>-->
      </div>
    </div>
  </Dialog>
  <!--  </TransitionRoot>-->
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  /*  TransitionChild,
  TransitionRoot,*/
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
  name: "SlidePanel",
  components: {
    Dialog,
    /*    TransitionChild,
    TransitionRoot,*/
    DialogOverlay,
    XIcon,
  },
  data() {
    return {};
  },
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  props: {
    title: String,
    visible: Boolean,
  },
};
</script>

<style scoped lang="scss"></style>
