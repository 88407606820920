let dict = {
  en: {
    phedoneLocale: "en-US",
    public_private_key_explanation:
      "In addition to Phedone's strict security process, you can opt-in for end to end encryption, where your data will be encrypted on the cloud with a public key, that will be paired with a decryption key that only you detain",
    exportName: "Export",
    exportVerb: "Export",
    deleteAnalysisHint_title: "Are you certain to delete this analysis ?",
    deleteClusterHint_title: "Are you certain to delete this cluster ?",
    renameClusterHint_label: "New cluster name",
    mergeClusterHint_label: "Specify the title of the resulting merged cluster",
    renameClusterHint_title: "Please enter the new cluster name",
    mergeClusterHint_title: "Please specify the merge options",
    deleteAnalysisHint_content:
      "All the data related to this analysis will be erased. This action is irreversible.",
    deleteClusterHint_content:
      "The contributions within the cluster won't be deleted. This action is irreversible.",
    batchLoadingHint:
      "You will be notified by email when the results are available.\nIf you accepted to receive notifications via browser, please do not close this tab.",
    createNewBatch: "Create a new analysis",
    new_feminine: "New",
    yourAnalysis_hp_title: "Your analysis",
    insightLoading_title_processing:
      "Our algorithm is actively working on bringing you the most accurate insights",
    insightLoading_title_loading: "Your analysis data is loading",
    yourAnalysis_hp_searchHint: "Filter the analysis",
    workInProgressModal_title: "This feature is under development",
    workInProgressModal_message:
      "It will be released in a future version of Phedone.",
    workInProgressModal_button: "Ok",
    clientNeedHelpMail_title: "I need help on Phedone",
    export_clusters: "Clusters",
    export_contributions: "Contributions",
    encryption_steps_sending: "Public key upload",
    encryption_inputs_addPassword_label:
      "Add a pass phrase to secure the key pair ?",
    encryption_inputs_keyTitle_label: "Title of your key pair",
    encryption_privateKey_actions_copy: "Copy the private key",
    encryption_privateKey_actions_download: "Download the private key",
    encryption_privateKey_actions_copy_callback:
      "The private key has been copied to your clipboard",
    encryption_privateKey_liability_title: "Liability",
    encryption_privateKey_liability_hint:
      "I stored the private key in a safe place, I understand that, without it, my data will be unreadable",
    encryption_privateKey_sending_hint:
      "The generated public key is being sent to Phedone's Key Management Service",
    encryption_privateKey_sending_error:
      "An error occurred while sending the public key",
    encryption_privateKey_sending_error_email:
      "An error occured on client side public key sending process",

    dashboard_card_export_picture: "Export the chart",
    dashboard_card_about: "Learn more",

    dashboard_insight_filters_title: "Filters",
    dashboard_insight_themes_on_period_title:
      "Contributions in themes on period",
    dashboard_insight_themes_repartition_title: "Themes repartition",
    dashboard_insight_main_themes_metrics_title: "Main themes metrics",
    dashboard_insight_contributions_evolution_on_periods_title:
      "Contributions evolution on period",
    dashboard_insight_opinions_by_themes_title: "Opinion by theme",
    dashboard_insight_overall_opinion_title: "Overall opinion",
    dashboard_insight_loading_hint: "Dashboard data is loading...",
    insight_dataviz_contributors_links_label: "Links of {name}",
    time_unit: "Unit",
    filterable_histogram_reset: "Unzoom",
    insight_dashboard_link: "Go to dashboard",
    insight_exploration_link: "Go to exploration",
    period_days: "Days",
    period_months: "Months",
    clusterModal_sentiment_repartition: "Contributions sentiment segmentation",
    dashboard_insight_placeholded_dates_label:
      "As you didn't input dates to the analysis, the 3 dates-based charts are simulated, to demonstrate the full dashboard potential",
    date_with_format: "Date (mm/dd/yyyy)",
    mergeCluster_select_label: "Enter to select",
    mergeCluster_deselect_label: "Enter to deselect",
    mergeCluster_selected_label: "Selected",
    mergeCluster_clusters_placeholder: "You can type a cluster name",
    mergeCluster_clusters_label: "Select the clusters to merge",
    none_masculine: "None",
    auth_login_error_password: "Your password does not match",
    auth_name_error_password: "Your username does not match",
    auth_login_title: "Connect to your account",
    auth_login_subtitle: "create an account",
    auth_register_title: "Create a new account",
    auth_register_subtitle: "Log in ",
    auth_reset_subtitle: "back to Sign In",
    auth_password_label_verification: "Code verification",
    login_confirmation_successful_notice:
      "You account is verified ! You can login.",
    register_confirmation_notice:
      "An code was sent to you by email (check you spams if you don't receive it)",
    reset_password_confirm: "Your password is reset, you can now login",
    step_one_insight: "List of contributions and tickets",
    step_two_insight: "You can filter and sort",
    step_three_insight:
      "Each card shows the first information of a ticket, you can click on it for more information",
    step_four_insight: "Find the information ingested by Phedone",
    step_five_insight:
      "Discover the analyzes and information generated by Phedone",
    step_six_insight:
      "The bubbles represent each theme detected and named automatically by the tool",
    step_seven_insight: "Other graphics are available",
    step_height_insight: "The list of ticket authors is available here",
    step_nine_insight: "Switch to dashboard and time analysis",

    //dashboard
    step_one_dashboard: "Summary of tickets entered against dates",
    step_two_dashboard:
      "Distribution of topics detected and discussed according to time",
    step_three_dashboard: "Return to exploration",
    //header steps
    header_step_one: "Discovery of the interface — 1/9",
    header_step_two: "Discovery of the interface — 2/9",
    header_step_three: "Discovery of the interface — 3/9",
    header_step_four: "Discovery of the interface — 4/9",
    header_step_five: "Discovery of the interface — 5/9",
    header_step_six: "Discovery of the interface — 6/9",
    header_step_seven: "Discovery of the interface — 7/9",
    header_step_eight: "Discovery of the interface — 8/9",
    header_step_nine: "Discovery of the interface — 9/9",
    header_step_ten: "Discovery of the interface — 1/3",
    header_step_eleven: "Discovery of the interface — 2/3",
    header_step_twelve: "Discovery of the interface — 3/3",
    //btn
    end_of_stages: "end of stages",
    btn_tutorial: "Display the tutorial",
    btn_tutorial_not_display: "Not display the tutorial",
    btn_end_tutorial: "End of tutorial",
    checkbox_activate_tutorial: "Activate tutorial",

    //Onboarding
    step_five: "Excluded terms",
    blacklist: "Terms to exclude",
    blacklist_message_bottom: "Words must be separated by commas",
    blacklist_title: "Enter your list of words to exclude",
    blacklist_text:
      "This list corresponds to the words or groups of words to be excluded because they do not bring any added value for the understanding of the content to be analyzed",
    user_dropdown_api_key: "Your API key",
  },
  "fr-FR": {
    phedoneLocale: "fr-FR",
    NEW_PASSWORD_LABEL: "New pass",
    "Sign In": "Se connecter",
    "Send Code": "Envoyer le code",
    "Email Address": "Adresse email",
    "Enter your email address": "Entrez votre email",
    "Email Address *": "Adresse email *",
    "Enter your password": "Entrez votre mot de passe",
    "Enter your username": "Entrez votre email",
    "Reset password": "Réinitialiser votre mot de passe",
    "Enter new password": "Entrez un nouveau mot de passe",
    "New Password": "Nouveau mot de passe",
    "Back to Sign In": "Revenir à la connexion",
    "Confirm Sign Up": "Confirmez votre inscription",
    "Confirm Sign In": "Confirmez votre connexion",
  },
  fr: {
    phedoneLocale: "fr-FR",
    NEW_PASSWORD_LABEL: "New passsss",
    "Sign In": "Se connecter",
    "Send Code": "Envoyer le code",
    "Email Address": "Adresse email",
    "Enter your email address": "Entrez votre email",
    "Email Address *": "Adresse email *",
    "Enter your password": "Entrez votre mot de passe",
    "Enter your username": "Entrez votre email",
    "Reset password": "Réinitialiser votre mot de passe",
    "Enter new password": "Entrez un nouveau mot de passe",
    "New Password": "Nouveau mot de passe",
    "Back to Sign In": "Revenir à la connexion",
    "Confirm Sign Up": "Confirmez votre inscription",
    "Confirm Sign In": "Confirmez votre connexion",
    Submit: "Valider",
    "Sign Up": "S'inscrire",
    "Sign in": "Connexion",
    "Username *": "Adresse mail *",
    "Sign in to your account": "Connexion à votre compte Phedone",
    Username: "Adresse mail",
    Password: "Mot de passe",
    "Password *": "Mot de passe *",
    "Create a new account": "Créer un nouveau compte",
    "Have an account?": "Déjà enregistré ?",
    "Create account": "Créer un compte",
    "Create Account": "Créer un compte",
    "No account?": "Pas de compte ?",
    "Forgot your password?": "Mot de passe oublié ?",
    Confirm: "Confirmer",
    "Confirmation Code": "Code de confirmation",
    "Lost your code?": "Code perdu?",
    "Enter your code": "Entrez votre code",
    "Resend Code": "Renvoyer le code",
    "An account with the given email already exists.":
      "Il existe déjà un compte pour cette adresse mail.",
    "Incorrect username or password.": "Identifiant/Mot de passe invalide.",
    "Reset your password": "Réinitialiser votre mot de passe",
    Email: "Email",
    "Firstname, Lastname *": "Prénom, Nom *",
    Corporation: "Société",
    Website: "Site web",
    "Clusters loading in progress": "Chargement des clusters en cours",
    "Authors connections": "Connexions d'auteurs",
    "Authors connections loading in progress":
      "Chargement des connexions d'auteurs en cours",
    Originality: "Originalité",
    calculating: "en cours de calcul",
    less: "moins",
    more: "plus",
    Display: "Afficher",
    Description: "Description",
    Keywords: "Mots clés",
    contributions: "contributions",
    "Loading contributions": "Chargement des contributions",
    Search: "Rechercher",
    "Sort by": "Trier par",
    "No description set": "Aucune description définie",
    Page: "Page",
    on: "sur",
    Title: "Titre",
    Author: "Auteur",
    Delete: "Supprimer",
    Merge: "Fusionner",
    "Idea title": "Titre de l'idée",
    Date: "Date",
    "If you would like to analyse more than 100 ideas, contact us":
      "Si vous souhaitez analyser plus de 100 idées, merci de nous contacter",
    "Get in touch": "Prendre contact",
    "Increase maximum of ideas request":
      "Demande d'augmentation du palier d'analyse",
    "Title of your analysis*": "Titre de votre analyse*",
    "Drop a XSLX or CSV file": "Déposer un fichier XLSX ou CSV",
    "Or use an external source": "Ou utiliser une source tierce",
    "Feature in development": "Fonctionnalité en développement",
    "Data importation": "Import des données",
    "successfully imported": "importé avec succès",
    "Link the document columns with the data to use by Phedone":
      "Liez les colonnes du document aux données à utiliser par Phedone",
    "Change file": "Changer de fichier",
    Next: "Suivant",
    Previous: "Précédent",
    send: "envoyer",
    "Your datas are ready to use !": "Vos données sont prêtes à l'envoi !",
    "From the following headers columns picked, your results will look the following :":
      "A partir des colonnes d'entêtes sélectionnées précédemment, les résultats se présenteraient de telle manière :",
    "Change headers": "Changer d'entêtes",
    "Upload in progress...": "Envoi en cours, merci de patienter",
    "Import data": "Import des données",
    "Cells selection": "Choix des cellules",
    Launch: "Validation de l'envoi",
    "Forbidden action": "Action non autorisée",
    "The analysis & data-visualization will soon be availables, you'll be notified by email.":
      "Les analyses et visualisations seront bientôt disponibles, vous serez notifié par email.",
    "An error occured during the deletion":
      "Une erreur est survenue durant la suppression",
    "An error occured during the renaming":
      "Une erreur est survenue durant le renommage",
    "An error occured during the merge":
      "Une erreur est survenue durant la fusion",
    "Identified themes": "Thèmes identifiés",
    "submitted contributions": "contributions soumises",
    "Proactive author": "Auteur proactif",
    "matched contributions": "contributions matchées",
    "Analyse this author contributions":
      "Analyser les contributions de cet auteur",
    "Average ideas/contributor": "Moyenne d'idées/contributeur",
    "uniques contributors": "contributeurs uniques",
    "Prominent theme": "Thème proéminent",
    "Identified in": "Identifié dans",
    "Analyse the contributions in this theme":
      "Analyser les contributions dans ce thème",
    "Manage the analysis": "Gestion de l'analyse",
    Rename: "Renommer",
    "This feature will be available soon":
      "Cette fonctionnalité sera disponible très prochainement",
    Export: "Exporter",
    Share: "Partager",
    Login: "Connexion",
    "Analyze new data": "Analyser d'autres données",
    themes: "thèmes",
    Themes: "Thèmes",
    Validation: "Validation",
    Sending: "Envoi de l'analyse",
    blacklist: "Termes à exclure",
    blacklist_message_bottom: "Les mots doivent être séparés par des virgules",
    "Bonjour !": "Hi !",
    "Thanks for your interest in Phedone":
      "Merci de votre intérêt pour la plateforme Phedone.",
    "The platform is not yet optimized for mobile use, and we invite you to use it on a computer":
      "Cette dernière n'est pour l'instant pas optimisée pour un usage mobile, et nous vous invitons à vous y rendre depuis un PC",
    "Mandatory datas": "Données nécessaires",
    "Optional datas": "Données facultatives",
    "Idea description": "Description de l'idée",
    "Idea publication date": "Date de publication de l'idée",
    "Idea likes amount": "Nb. de likes de l'idée",
    "Idea comments amount": "Nb. de commentaires sur l'idée",
    "Phedone user": "Utilisateur Phedone",
    Logout: "Déconnexion",
    "Drop a XLSX or CSV file": "Déposez un fichier XLSX ou CSV",
    "Welcome on Phedone !": "Bienvenue sur Phedone !",
    Home: "Accueil",
    "Loading...": "Chargement...",
    "New analysis": "Nouvelle analyse",
    "Themes cloud": "Nuage de thèmes",
    "Themes cloud loading in progress":
      "Chargement du nuage de thèmes en cours",
    "Display opinions": "Intégrer les opinions",
    "Similarity threshold": "Minimum de ressemblance",
    recommended: "recommandé",
    "Encryption keys": "Clés d'encryption",
    "Encryption key for your analysis": "Clé d'encryption de votre analyse",
    None: "Aucune",
    none_masculine: "Aucun",
    "Click here to see more": "Cliquez ici pour en apprendre plus",
    "Add an additional security layer to your data":
      "Ajoutez une couche de sécurité à vos données",
    "Add a new key pair": "Ajouter une nouvelle paire de clés",
    encryption_inputs_keyTitle_label: "Titre de votre paire de clés",
    encryption_inputs_addPassword_label:
      "Ajouter un mot de passe pour sécuriser votre clé",
    "Define key pair parameters": "Définir les paramètres de la paire de clés",
    "Download the private key": "Télécharger la clé privée",
    "Generate the key pair": "Générer la paire de clés",
    "The keys were successfully generated !":
      "Les clés ont été générées avec succès",
    "The key pair will be generated with the following parameters":
      "La clé privée sera générée avec les paramètres suivants",
    No: "Non",
    Yes: "Oui",
    Or: "Ou",
    "Download the generated private key to your device":
      "Télécharger la clé privée sur votre appareil",
    "I stored the private key in a safe place":
      "J'ai stocké la clé privée en lieu sûr",
    "Upload the generated public key to Phedone":
      "Envoyer à Phedone la clé publique générée",
    "You can": "Vous pouvez",
    "copy it from below": "la copier ci-dessous",
    "Generated private key": "Clé privée générée",
    "The generated public key was sent to Phedone, you can start using this key pair !":
      "La clé publique générée a été envoyée à Phedone, vous pouvez commencer à utiliser cette paire de clés !",
    "Start analysing": "Commencer à analyser",
    "New key pair": "Nouvelle paire de clés",
    "Pass phrase": "Mot de passe",
    "You must keep the key in a safe place : if you were to lose it, your encrypted data in Phedone would be unrecoverable !":
      "Vous devez conserver cette clé en lieu sûr : si vous l'égarez, vos données chiffrées sur Phedone seront irrécupérables",
    public_private_key_explanation:
      "En plus du processus de sécurité Phedone, vous pouvez opter pour une encryption de bout en bout, vos données seront chiffrées sur le cloud par une clé publique, associée avec une clé de décryption dont vous serez seul propriétaire",
    "Hold your click to continuously change":
      "Maintenez votre clic pour un changement continu",
    Step: "Étape",
    "Your XLSX or CSV file": "Votre fichier XLSX ou CSV",
    "Upload a file": "Téléversez un fichier",
    "or drag and drop": "ou glissez-déposez",
    "XLSX, CSV up to 100MB": "XLSX ou CSV, jusqu'à 100MO",
    "You picked the following file :":
      "Vous avez sélectionné le fichier suivant :",
    "Data sheet to use": "Feuille à utiliser",
    "If necessary, you can edit the column delimiter of your CSV":
      "Si besoin, vous pouvez modifier le délimiteur de colonne de votre CSV",
    "more rows": "lignes supplémentaires",
    Contributor: "Contributeur",
    "Not used": "Pas utilisé",
    "Pick the document columns with the data to use by Phedone":
      "Sélectionnez les colonnes à utiliser et leur nature",
    "the results will only be decipherable with the subsequent private key : ":
      "les résultats ne seront déchiffrables qu'avec la clé privée nommée : ",
    "The following data will be securely sent to Phedone's servers, after being encrypted in your browser":
      "Les données suivantes seront envoyées de manière sécurisée aux serveurs de Phedone, après avoir été chiffrées dans votre navigateur",
    "Start the analysis": "Commencer l'analyse",
    "As you didn't pick an encryption key, the results won't be encrypted !":
      "Vous n'avez pas sélectionné de clé privée, par conséquent, les résultats ne seront pas chiffrés !",
    "Your analysis is currently running on Phedone's infrastructure":
      "Votre analyse est en cours sur l'infrastructure de Phedone",
    "Your encrypted data are being uploaded":
      "Vos données chiffrées sont en cours d'envoi",
    "Your data are being uploaded": "Vos données sont en cours d'envoi",
    "Your data are being encrypted": "Chiffrement de vos données en cours",
    "Your encrypted data are being processed before analysis on Phedone's infrastructure":
      "Vos données chiffrées sont en cours de préparation pour analyse sur l'infrastructure de Phedone",
    "Your data are being processed before analysis on Phedone's infrastructure":
      "Vos données sont en cours de préparation pour analyse sur l'infrastructure de Phedone",
    "An error occurred and your analysis was not sent":
      "Une erreur est survenue et votre analyse n'a pu être envoyée",
    "An error occurred on client side sending process":
      "Une erreur est survenue lors du processus d'envoi client",
    "An error occurred": "Une erreur est survenue",
    "Here are the logs : ": "Voici les logs : ",
    "Additional informations : ": "Informations supplémentaires",
    "Report the error to Phedone's team":
      "Remonter l'erreur à l'équipe Phedone",
    "View more contributors": "Voir plus de contributeurs",
    "View more contributions": "Voir plus de contributions",
    Tonality: "Tonalité",
    Other: "Autre",
    negative: "négative",
    Neutral: "Neutre",
    "middly negative": "plutôt négative",
    neutral: "neutre",
    "middly positive": "plutôt positive",
    positive: "positive",
    View: "Voir",
    Select: "Sélectionner",
    Contributors: "Contributeurs",
    Contributions: "Contributions",
    Density: "Densité",
    "Results available": "Résultats disponibles",
    Processing: "Calculs en cours",
    "Start typing...": "Rechercher...",
    Sort: "Ordre",
    "Quick access": "Accès rapide",
    Analysis: "Analyses",
    Reporting: "Reporting",
    Settings: "Paramètres",
    "Signed in as": "Connecté en tant que",
    "Account settings": "Paramètres du compte",
    Help: "Aide",
    "Sign Out": "Déconnexion",
    "Strong link": "Lien fort",
    "Medium link": "Lien moyen",
    "Weak link": "Lien faible",
    Cancel: "Annuler",
    Related: "Relatif",
    "No related contributions or contributors":
      "Pas de contribution/contributeur relatif",
    Indexes: "Indices",
    Clustering: "Thématisation",
    "Contributor's details": "Détails du contributeur",
    "Related contributors": "Contributeurs relatifs",
    "related contributions": "contributions relatives",
    "Key settings": "Paramètres de la clé",
    "Settings review": "Revue des paramètres",
    "Private key retrieval": "Récupération de la clé privée",
    exportName: "Export",
    exportVerb: "Exporter",
    "Select a language": "Choisir une langue",
    "Title, A to Z": "Titre, A à Z",
    "Title, Z to A": "Titre, Z à A",
    "Originality, Asc.": "Originalité, Asc.",
    "Originality, Desc.": "Originalité, Desc.",
    "Polarity, Asc.": "Polarité, Asc.",
    "Polarity, Desc.": "Polarité, Desc.",
    Theme: "Thème",
    Negative: "Négative",
    "Name, A to Z": "Nom, A à Z",
    "Name, Z to A": "Nom, Z à A",
    "Contributions Nb., Asc.": "Nb. Contributions, Asc.",
    "Contributions Nb., Desc.": "Nb. Contributions, Desc.",
    Polarity: "Polarité",
    "Contributions Nb.": "Nb. contributions",
    insightLoading_title_processing:
      "Nos algorithmes travaillent activement à vous apporter les analyses les plus précises",
    insightLoading_title_loading:
      "Les données de votre analyse sont en cours de chargement",
    "The analysis proccess seems to be stuck":
      "Le processus d'analyse semble bloqué",
    "Report an error": "Remonter une erreur",
    "If the process is taking way longer than usual or you think their might be an issue, please contact us !":
      "Si l'analyse est bien bien plus longue qu'à l'habitude ou que vous pensez qu'il y a un problème, merci de nous contacter !",
    "Click here to access to the results":
      "Cliquez pour consulter les résultats",
    "Delete the analysis": "Supprimer l'analyse",
    "Delete the cluster": "Supprimer le cluster",
    "Rename the cluster": "Renommer le cluster",
    "Merge clusters": "Fusionner des clusters",
    deleteAnalysisHint_title: "Êtes vous certain de supprimer l'analyse ?",
    deleteClusterHint_title: "Êtes vous certain de supprimer le cluster ?",
    renameClusterHint_label: "Nouveau nom du cluster",
    renameClusterHint_title: "Merci de définir le nouveau nom du cluster",
    mergeClusterHint_title: "Merci de paramétrer la fusion des clusters",
    deleteAnalysisHint_content:
      "L'intégralité des données liées à cette analyse seront supprimées. Cette action est irréversible.",
    deleteClusterHint_content:
      "Les contributions contenues dans ce cluster ne seront pas supprimées. Cette action est irréversible.",
    mergeClusterHint_label:
      "Spécifiez le nom du cluster résultant de la fusion",
    batchLoadingHint:
      "Vous serez notifié par email quand les résultats sont disponibles.\nSi vous avez accepté de recevoir des notifications via navigateur, merci de ne pas fermer cet onglet.",
    Clusters: "Clusters",
    Credentials: "Clés",
    createNewBatch: "Créer une nouvelle analyse",
    new_feminine: "Nouvelle",
    yourAnalysis_hp_title: "Vos analyses",
    "Creation Date, Desc.": "Créé le, Desc.",
    "Creation Date, Asc.": "Créé le, Asc.",
    "Nb of Clusters, Asc.": "Nb de Clusters, Asc.",
    "Nb of Clusters, Desc.": "Nb de Clusters, Desc.",
    yourAnalysis_hp_searchHint: "Filtrer les analyses",
    workInProgressModal_title: "Cette fonctionnalité est en développement.",
    workInProgressModal_message:
      "Elle sera disponible dans une prochaine version de Phedone.",
    workInProgressModal_button: "C'est noté",
    clientNeedHelpMail_title: "J'ai besoin d'aide sur Phedone",
    export_clusters: "Thèmes",
    export_contributions: "Contributions",
    Show: "Afficher",
    Hide: "Cacher",
    encryption_steps_sending: "Téléversement de la clé publique",
    encryption_privateKey_actions_copy: "Copier la clé privée",
    encryption_privateKey_actions_download: "Télécharger la clé privée",
    encryption_privateKey_actions_copy_callback:
      "La clé privée est désormais dans votre presse papier",
    encryption_privateKey_liability_title: "Responsabilité",
    encryption_privateKey_liability_hint:
      "J'ai stocké la clé privée en lieu sûr, je comprends que sans elle mes données sont inaccessibles",
    encryption_privateKey_sending_hint:
      "La clé publique générée est en cours d'envoi vers le service de gestion sécurisée des clés Phedone",
    encryption_privateKey_sending_error:
      "Une erreur est survenue durant l'envoi de la clé publique",
    encryption_privateKey_sending_error_email:
      "Une erreur est survenue côté client lors de l'envoi d'une clé publique",
    dashboard_card_export_picture: "Exporter le graphe en PNG",
    dashboard_card_about: "En savoir plus",

    dashboard_insight_filters_title: "Filtres",
    dashboard_insight_themes_on_period_title:
      "Contributions par thèmes par périodes",
    dashboard_insight_themes_repartition_title: "Répartition des thèmes",
    dashboard_insight_main_themes_metrics_title:
      "Métriques des thèmes principaux",
    dashboard_insight_contributions_evolution_on_periods_title:
      "Répartition des contributions au sein des thèmes par période",
    dashboard_insight_opinions_by_themes_title: "Opinion au sein des thèmes",
    dashboard_insight_overall_opinion_title: "Opinion globale",
    dashboard_insight_loading_hint:
      "Chargement des données du dashboard en cours...",
    insight_dataviz_contributors_links_label: "Liens de {name}",
    time_unit: "Unité",
    filterable_histogram_reset: "Dézoomer",
    insight_dashboard_link: "Accéder au dashboard",
    insight_exploration_link: "Accéder à l'exploration",
    period_days: "Jours",
    period_months: "Mois",

    clusterModal_sentiment_repartition:
      "Répartition des sentiments au sein des contributions",
    auth_login_error_password: "Votre mot de passe ne correspond pas",
    auth_name_error_password: "Votre nom d'utilisateur ne correspond pas",
    auth_login_title: "Connexion à votre compte",
    auth_login_subtitle: "créer un compte",
    auth_register_title: "Créer un nouveau compte",
    auth_register_subtitle: "Connectez-vous",
    auth_reset_subtitle: "revenir à la connexion",
    auth_password_label_verification: "Code de vérification",
    login_confirmation_successful_notice:
      "Votre compte est confirmé, vous pouvez désormais vous connecter",
    register_confirmation_notice:
      "Un code vous a été envoyé par email (merci de vérifier vos spams)",
    reset_password_confirm:
      "Votre mot de passe a été réinitialisé avec succès, vous pouvez désormais vous connecter",
    dashboard_insight_placeholded_dates_label:
      "Étant donné que vous n'avez pas défini de dates aux entrées de l'analyse, les 3 graphiques comportant des dates sont simulés afin vous démontrer le plein potentiel du dashboard",
    date_with_format: "Date (mm/jj/aaaa)",
    mergeCluster_select_label: "Touche entrée pour sélectionner",
    mergeCluster_deselect_label: "Touche entrée pour désélectionner",
    mergeCluster_selected_label: "Sélectionné",
    mergeCluster_clusters_placeholder: "Vous pouvez taper le nom d'un cluster",
    mergeCluster_clusters_label: "Sélectionnez les clusters à fusionner",
    "It will replace the current one": "L'actuel sera remplacé",
    "Update the theme": "Mise à jour du thème",
    "Reload the analysis": "Rafraîchir l'analyse",
    "Some data has been updated": "Certaines données ont été mises à jour",
    "Additional data": "Données additionnelles",
    "No cluster": " Aucun cluster ",
    "Add cluster": "Nouveau cluster",
    "Attach a theme": "Association d'un thème",
    "Cluster changed": "Cluster modifié",
    "Cluster detached": "Cluster dissocié",
    "Theme title, A to Z": "Titre du thème, A à Z",
    "Theme title, Z to A": "Titre du thème, Z à A",
    //insight
    step_one_insight: "Liste des contributions et tickets",
    step_two_insight: "Vous pouvez filtrer et trier",
    step_three_insight:
      "Chaque carte présente les premières informations d’un ticket, vous pouvez cliquer dessus pour plus d’informations",
    step_four_insight: "Retrouvez les informations ingérées par Phedone",
    step_five_insight:
      "Découvrez les analyses et les informations générées par Phedone",
    step_six_insight:
      " Les bulles représentent chaque thème détecté et nommé automatiquement par l’outil",
    step_seven_insight: "D’autres graphiques sont disponibles",
    step_height_insight: "La liste des auteurs de tickets est présente ici",
    step_nine_insight: "Passer au dashboard et aux analyses temporelles",
    //dashboard
    step_one_dashboard: "Résumé des tickets entrés par rapport aux dates",
    step_two_dashboard:
      "Répartition des sujets détectés et abordés en fonction du temps",
    step_three_dashboard: "Retour à l’exploration",
    //header steps
    header_step_one: "Découverte de l'interface — 1/9",
    header_step_two: "Découverte de l'interface — 2/9",
    header_step_three: "Découverte de l'interface — 3/9",
    header_step_four: "Découverte de l'interface — 4/9",
    header_step_five: "Découverte de l'interface — 5/9",
    header_step_six: "Découverte de l'interface — 6/9",
    header_step_seven: "Découverte de l'interface — 7/9",
    header_step_eight: "Découverte de l'interface — 8/9",
    header_step_nine: "Découverte de l'interface — 9/9",
    header_step_ten: "Découverte de l'interface — 1/3",
    header_step_eleven: "Découverte de l'interface — 2/3",
    header_step_twelve: "Découverte de l'interface — 3/3",
    //btn
    end_of_stages: "fin des étapes",
    btn_tutorial: "Afficher le tutoriel",
    btn_tutorial_not_display: "Cachez le tutoriel",
    btn_end_tutorial: "Fin du tutoriel",
    checkbox_activate_tutorial: "Activer le tutoriel",

    //Onboarding
    step_five: "Blacklist",
    blacklist_text:
      "Cette liste correspond aux mots ou groupes de mots à exclure car ils n'apportent pas de valeur ajoutée pour la compréhension du contenu à analyser",
    blacklist_title: "Liste des mots à exclure",
    user_dropdown_api_key: "Votre clé API",
  },
};

let dropzone = {
  fr: {
    dictDefaultMessage: "Déposez votre fiche",
    dictFallbackMessage:
      "Your browser does not support drag'n'drop file uploads.",
    dictFallbackText:
      "Please use the fallback form below to upload your files like in the olden days.",
    dictFileTooBig:
      "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",
    dictInvalidFileType: "You can't upload files of this type.",
    dictResponseError: "Server responded with {{statusCode}} code.",
    dictCancelUpload: "Cancel upload",
    dictCancelUploadConfirmation:
      "Are you sure you want to cancel this upload?",
    dictRemoveFile: "Remove file",
    dictMaxFilesExceeded: "You can not upload any more files.",
  },
  en: {
    dictDefaultMessage: "Drop your sheet",
  },
};

module.exports = {
  dict,
  dropzone,
};
