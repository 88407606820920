import { sortBy, reverse } from "lodash";
import { useAccentsNormalization } from "@/logic/use-accents-normalization";
import _ from "lodash";

export function useOrderList(list, orderableKeys, pickedOrderKey) {
  let results = sortBy(
    list,
    [
      (l) => {
        if (typeof l[orderableKeys[pickedOrderKey]] === "string") {
          return _.toLower(
            useAccentsNormalization(l[orderableKeys[pickedOrderKey]])
          ); // Here we remove the majuscules accentuées that mess up with the A-Z order
        }
      },
    ],
    orderableKeys[pickedOrderKey]
  );

  if (pickedOrderKey & 1) {
    return reverse(results);
  }

  return results;
}
