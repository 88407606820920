<template>
  <span
    :class="['relative z-20 inline-flex', icon ? '' : 'shadow-sm rounded-md']"
  >
    <Menu as="span" class="block relative -ml-px">
      <MenuButton
        :class="[
          icon
            ? 'interactive shadow-none -m-2 p-2 rounded flex items-center text-gray-400 hover:text-gray-600'
            : 'button button-white focus:z-10 space-x-1 pr-2',
        ]"
      >
        <span v-if="!icon"><slot /></span>
        <span class="sr-only"><slot /></span>
        <DotsVerticalIcon class="w-5 h-5" aria-hidden="true" v-if="icon" />
        <ChevronDownIcon class="w-5 h-5" aria-hidden="true" v-else />
      </MenuButton>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          :class="[
            'z-[1000] origin-top-right absolute right-1 mt-2 -mr-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
            large ? 'w-56' : 'w-40',
          ]"
        >
          <div class="py-1">
            <MenuItem
              v-for="action in actions"
              :key="action.title"
              v-slot="{ active }"
              as="div"
            >
              <button
                @click="action.callback"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                ]"
                class="block py-2 px-4 w-full text-sm text-left focus:outline-none"
              >
                {{ action.title }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </span>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { DotsVerticalIcon } from "@heroicons/vue/solid";

export default {
  name: "DropdownButton",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    DotsVerticalIcon,
  },
  props: {
    actions: { type: Array, required: true },
    icon: Boolean,
    large: Boolean,
  },
};
</script>
