import awsconfig from "./aws-exports";
import LogRocket from "logrocket";

initRocketLogging();

import { createApp, provide, h } from "vue";

import App from "./App.vue";
import { store } from "./store/store";
// import VueMeta from "vue-meta";
import VueClickAway from "vue3-click-away";
import {
  DefaultApolloClient,
  provideApolloClient,
} from "@vue/apollo-composable";

import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import Amplify from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { I18n } from "@aws-amplify/core";
import { Logger } from "aws-amplify";

import "./index.css";

const i18n = require("./i18n/i18n");

import router from "./router/router";
import AsyncComputed from "vue-async-computed";
import strings from "lodash/string";

import { InMemoryCache } from "@apollo/client";
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { createAuthLink } from "aws-appsync-auth-link";
import { ApolloLink } from "apollo-link";
import { AWSAppSyncClient } from "aws-appsync";

I18n.setLanguage("fr");

const finalConfig = {
  ...awsconfig,
  Auth: {
    region: awsconfig.aws_cognito_region,
    userPoolId: awsconfig.aws_user_pools_id,
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
  },
  ...{
    API: {
      auth: {
        type: "AMAZON_COGNITO_USER_POOLS",
        credentials: async () =>
          (await Auth.currentSession()).getAccessToken().getJwtToken(),
        jwtToken: async () =>
          (await Auth.currentSession()).getAccessToken().getJwtToken(),
        graphql_headers: async () => {
          const session = await Auth.currentSession();
          return {
            Authorization: session.getAccessToken().getJwtToken(),
          };
        },
      },
      graphql_headers: async () => {
        const session = await Auth.currentSession();
        return {
          Authorization: session.getAccessToken().getJwtToken(),
        };
      },
    },
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getAccessToken().getJwtToken(),
      };
    },
  },
};
console.log(finalConfig);
Amplify.configure(finalConfig);
Auth.configure(finalConfig);

const url = awsconfig.aws_appsync_graphqlEndpoint;
const region = awsconfig.aws_appsync_region;
const auth = {
  type: "AMAZON_COGNITO_USER_POOLS",
  credentials: async () =>
    (await Auth.currentSession()).getAccessToken().getJwtToken(),
  jwtToken: async () =>
    (await Auth.currentSession()).getAccessToken().getJwtToken(),
  graphql_headers: async () => {
    const session = await Auth.currentSession();
    return {
      Authorization: session.getAccessToken().getJwtToken(),
    };
  },
};

const appsyncClient = new AWSAppSyncClient({
  url,
  region,
  auth,
});

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createHttpLink({ uri: url }),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

Amplify.Logger.LOG_LEVEL = "INFO"; //"DEBUG";

const logger = new Logger("main");
Auth.currentAuthenticatedUser()
  .then((user) => {
    logger.debug(user);

    console.log(user.username);
    console.log(user.attributes.email);
    LogRocket.identify(user.username, {
      email: user.attributes.email,
      // Add your own custom user variables here, ie:
      subscriptionType: "by team",
    });
  })
  .catch((err) => logger.debug(err));

I18n.putVocabularies(i18n.dict);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp({
  setup() {
    provide(DefaultApolloClient, client);
    provide("appsyncClient", appsyncClient);
    provideApolloClient(client);
    provide("i18n", (key) => {
      I18n.setLanguage(store.state.locale);

      return I18n.get(key);
    });
  },
  render: () => h(App),
});

app.config.isCustomElement = (tag) => tag.startsWith("amplify-");
app.use(router);
app.use(store);
app.use(AsyncComputed);
app.use(VueClickAway);

/*app.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});*/

app.config.globalProperties.$filters = {
  euros(value) {
    if (!value) {
      value = 0;
    }
    return value.toLocaleString("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    });
  },

  truncate(value, length) {
    if (!value) {
      value = "";
    }

    return strings.truncate(value, { length: length, separator: "..." });
  },
  percentage(value) {
    if (!value) {
      value = 0;
    }

    return Math.round(value * 100) + " %";
  },

  i18n(value) {
    I18n.setLanguage(store.state.locale);

    return I18n.get(value);
  },
};

app.mount("#app");

function getEnvironment() {
  let env = "devstaging";

  try {
    const REGEX = /.*-(\w+)/;
    env = awsconfig.aws_content_delivery_bucket.match(REGEX)[1];
    console.log(awsconfig.aws_content_delivery_bucket);
    console.log(env);
  } catch (e) {
    console.error(e);
  }

  console.log(env);

  return env;
}

function initRocketLogging() {
  if (
    getEnvironment() !== "devstaging" &&
    location.hostname !== "localhost" &&
    location.hostname !== "127.0.0.1"
  ) {
    let environmentsLogRocketKeys = {
      master: "",
      staging: "-staging",
    };
    console.log(environmentsLogRocketKeys);
    LogRocket.init(
      "7ifyl5/phedone-b2b"
      // "7ifyl5/phedone-b2b" + environmentsLogRocketKeys[getEnvironment()]
    );
  } else {
    console.log("It's a local or dev environment, we don't record");
  }
}
