import { shuffle } from "lodash";

const colors = require("tailwindcss/colors");

const colorsArrayForTint = (tint = "500") => {
  return Object.values(colors)
    .map((color) => {
      if (typeof color === "object" && color !== null) {
        return color[tint];
      }

      return null;
    })
    .filter((c) => c !== null);
};

const asArray = colorsArrayForTint("500");
const shuffledArray = shuffle(asArray);
export const useTailwindColors = {
  asArray,
  asObject: colors,
  asShuffledArray: shuffledArray,
};
