<template>
  <badge
    :color="linkStrengthColor"
    :rounded="false"
    class="px-1.5 ml-2 text-xs whitespace-nowrap"
    >{{ $filters.i18n(linkStrengthText) }}</badge
  >
</template>

<script>
import Badge from "@/components/DesignSystem/Badges/Badge";
export default {
  name: "LinkStrengthBadge",
  components: { Badge },
  data() {
    return {};
  },
  computed: {
    linkStrengthText() {
      if (this.strength >= 0.67) {
        return "Strong link";
      }

      if (this.strength >= 0.33) {
        return "Medium link";
      }

      return "Weak link";
    },
    linkStrengthColor() {
      if (this.strength >= 0.67) {
        return "green";
      }

      if (this.strength >= 0.33) {
        return "cyan";
      }

      return "red";
    },
  },
  props: {
    strength: Number,
  },
};
</script>

<style scoped lang="scss"></style>
