<template>
  <page-body>
    <template #mainBody>
      <keys-pair-generation-container />
    </template>
  </page-body>
</template>

<script>
import PageBody from "@/components/Layout/PageBody";
import KeysPairGenerationContainer from "@/components/Encryption/KeysPairGenerationContainer";
export default {
  name: "NewKeys",
  metaInfo() {
    return {
      title: this.$filters.i18n("New key pair"),
    };
  },
  components: {
    KeysPairGenerationContainer,
    PageBody,
  },
  data() {
    return {
      generateKeyPair: false,
    };
  },
};
</script>

<style scoped lang="scss"></style>
