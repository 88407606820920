<template>
  <!-- eslint-disable -->
  <div id="auth" class="d-flex justify-center align-center">
    <div
      style="
        overflow: hidden;
        height: 0px;
        background: transparent;
        width: 0 !important;
      "
      data-description="dummyPanel for Chrome auto-fill issue"
    >
      <input
        type="text"
        style="
          height: 0;
          background: transparent;
          color: transparent;
          border: none;
        "
        data-description="dummyUsername"
      />
      <input
        type="password"
        style="
          height: 0;
          background: transparent;
          color: transparent;
          border: none;
        "
        data-description="dummyPassword"
      />
    </div>

    <amplify-auth-container :key="phedoneLocale">
      <amplify-authenticator username-alias="email" class="authContainer">
        <amplify-sign-up
          username-alias="email"
          slot="sign-up"
          :formFields.prop="signUpFields"
        >

        </amplify-sign-up>
        <amplify-sign-in
          username-alias="email"
          slot="sign-in"
          :hideSignUp="!authConfig.signInConfig.isSignUpDisplayed"
        ></amplify-sign-in>
      </amplify-authenticator>
    </amplify-auth-container>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useStore } from "vuex";
export default {
  name: "PhedoneAuth",
  metaInfo() {
    return {
      title: this.$filters.i18n("Login"),
    };
  },
  setup() {
    const i18nFilter = inject("i18n");
    const store = useStore();

    const phedoneLocale = computed(() => store.state.locale);

    const signUpFields = computed(() => [
      {
        label: i18nFilter("Email") + " *",
        key: "username",
        inputProps: {
          required: true,
          autocomplete: "email",
          displayOrder: 1,
          key: "username",
          hint: null,
        },
        required: true,
        placeholder: "",
        type: "email",
        displayOrder: 1,
        hint: null,
      },
      {
        label: i18nFilter("Firstname, Lastname") + " *",
        key: "name",
        required: true,
        type: "name",
        displayOrder: 2,
        hint: null,
        inputProps: {
          required: true,
          autocomplete: "name",
          displayOrder: 2,
          key: "name",
          hint: null,
        },
      },
      {
        label: i18nFilter("Password") + " *",
        key: "password",
        required: true,
        placeholder: "",
        type: "password",
        displayOrder: 3,
        hint: null,
        inputProps: {
          required: true,
          autocomplete: "password",
          key: "password",
          hint: null,
        },
      },
      {
        label: i18nFilter("Corporation"),
        key: "custom:corporation",
        required: false,
        type: "custom:corporation",
        displayOrder: 4,
        value: "ND",
        hint: null,
        inputProps: {
          required: false,
          autocomplete: "corporation",
          key: "custom:corporation",
          hint: null,
        },
      },
      {
        label: i18nFilter("Website"),
        key: "website",
        required: false,
        type: "website",
        hint: null,
        displayOrder: 5,
        value: "ND",
        inputProps: {
          required: false,
          key: "website",
          hint: null,
        },
      },
    ]);

    return { signUpFields, phedoneLocale };
  },
  data() {
    return {
      signUp: "sign-up",
      authConfig: {
        hideAllDefaults: false,
        signUpConfig: {
          hideAllDefaults: false,
          hiddenDefaults: ["phone_number", "email"],
        },
        signInConfig: {
          usernameAttributes: "email",
          isSignUpDisplayed: true,
        },
      },
      userPrefilledInfos: {},
    };
  },
  methods: {
    authentificatorMounted() {
      if (this.$route.query.email && this.$route.query.email.length > 2) {
        console.log(this.authConfig.signUpConfig.signUpFields);
        this.authConfig.signUpConfig.signUpFields[0].value = this.$route.query.email;
        this.authConfig.signUpConfig.signUpFields[1].value = this.$route.query.name;
        this.authConfig.signUpConfig.signUpFields[3].value =
          this.$route.query.corporation ?? " ";
        this.authConfig.signUpConfig.signUpFields[4].value =
          this.$route.query.site ?? " ";

        let checkRegisterButtonExists = setInterval(function () {
          if (
            document.querySelector('a[data-test="sign-in-create-account-link"]')
          ) {
            clearInterval(checkRegisterButtonExists);

            document
              .querySelector('a[data-test="sign-in-create-account-link"]')
              .click();
          }
        }, 100); // check every 100ms

        this.$router.replace({
          ...this.$router.currentRoute,
          query: null,
        });
      }
    },
  },
};
</script>

<style lang="scss">
:root {
  /* Colors */
  --footer-color: #fb453f !important;
  --amplify-primary-color: #fb453f !important;
  --amplify-primary-shade: #b8332d !important;
  --amplify-primary-tint: #b8332d !important;
  --lightAmazonOrange: rgba(251, 69, 63, 0.75) !important;
  --darkAmazonOrange: #c13934;
  --squidInk: #232f3e;
  --lightSquidInk: #31465f;
  --deepSquidInk: #152939;
  --grey: #828282;
  --lightGrey: #c4c4c4;
  --silver: #e1e4ea;
  --darkBlue: #31465f;
  --red: #dd3f5b;
  --white: #ffffff;
  --light-blue: #00a1c9;
}

#auth {
  .error {
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    color: white;
  }
}
</style>

<style scoped lang="scss">
#auth {
  position: fixed;
  top: 80px;
  right: 16px;
  bottom: 74px;
  left: 96px;
}
</style>

<style lang="scss">
.auth-fields {
  // TODO Lookup Bryan
  display: grid !important;
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.authContainer {
  max-width: 100vw;

  > div {
    padding-right: 3rem;
    padding-left: 3rem;
    @media (max-width: 980px) {
      margin-top: 56px;
      min-width: 0 !important;
    }
  }

  div {
    &[signupfield="custom:corporation"],
    &[signupfield="website"] {
      display: none;
    }
  }
}
</style>
