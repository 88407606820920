<template>
  <section
    v-if="allowed"
    class="flex flex-col m-auto space-y-4 w-10/12 max-w-screen-2xl"
  >
    <inserting-limited-alert v-if="isUserASelfSubscribed" />

    <div class="overflow-hidden py-6 px-4 space-y-6 sm:p-6 carded">
      <header
        class="p-6 -mx-6 -mt-6 bg-gray-50 bg-opacity-50 border-b border-gray-100"
      >
        <horizontal-steps
          id="insertingSteps"
          v-model="step"
          key="insertingStepsContainer"
          :items="steps"
          @step-clicked="goToStep"
        />
      </header>

      <inserting-step1
        @user-file-input-changed="sheetPicked"
        v-model:sessionName="sessionName"
        @public-key-updated="updatePublicKey"
        @show-key-pair-generation="showKeyPairGeneration"
        :csv-separator="csvSeparator"
        v-show="step === 1"
      />

      <inserting-step2
        :json="json"
        v-show="step === 2"
        v-model:csvSeparator="csvSeparator"
        v-model:pickedKeyByColumns="pickedKeyByColumns"
        v-model:additionalColumns="additionalColumns"
        v-model:columnsInUse="columnsInUse"
        v-model:pickedSheet="pickedSheet"
      />

      <inserting-step-black-list
        v-if="step === 3"
        v-model:blackList="blackList"
      />

      <!-- validation-->
      <inserting-step3
        :json="finalJson.contributions"
        v-if="step === 4 && !sending"
        :encryptionKey="publicKey"
        :additional-colums-titles="additionalColumnsTitles.map((c) => c.title)"
      />

      <!-- envoie de l'analyse-->
      <inserting-step4
        v-if="step === 5"
        :hint="actualSendingStepString"
        :sending-error="sendingError"
      />

      <footer class="px-6 pt-6 -m-6 border-t border-gray-100">
        <div class="flex justify-end items-center space-x-3">
          <button
            class="button button-soft"
            :disabled="!canGoPrev"
            v-if="step > 1"
            @click="updateStep(step - 1)"
          >
            {{ $filters.i18n("Previous") }}
          </button>

          <button
            class="button button-primary"
            :disabled="!canGoNext"
            @click="updateStep(step + 1)"
            v-if="step < 5"
          >
            {{ customNextButton || $filters.i18n("Next") }}
          </button>
        </div>
      </footer>
    </div>
  </section>
  <h1 v-else>{{ $filters.i18n("Forbidden action") }}</h1>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import InsertingStep1 from "./InsertingStep1";
import InsertingStep2 from "./InsertingStep2";
import InsertingStep3 from "./InsertingStep3";
import { mapGetters, mapMutations, mapState, useStore } from "vuex";
import { Storage } from "aws-amplify";
import _ from "lodash";
let uniqid = require("uniqid");

// eslint-disable-next-line no-unused-vars
import router from "../../router/router";
import InsertingLimitedAlert from "@/components/InsertingSteps/InsertingLimitedAlert";
import HorizontalSteps from "@/components/DesignSystem/Steps/HorizontalSteps";
import InsertingStepsOngoing from "./InsertingStep4";
import InsertingStep4 from "./InsertingStep4";
import { useWatchNewBatch } from "@/logic/use-api-fetch";
import { inject, ref, watchEffect } from "vue";
import anyAscii from "any-ascii";
import InsertingStepBlackList from "@/components/InsertingSteps/InsertingStepBlackList";

export default {
  name: "InsertingStepsContainer",
  components: {
    InsertingStepBlackList,
    InsertingStep4,
    InsertingStepsOngoing,
    HorizontalSteps,
    InsertingLimitedAlert,
    InsertingStep3,
    InsertingStep2,
    InsertingStep1,
  },
  data() {
    return {
      step: 1, // For dev purposes
      sessionName: null,
      publicKey: null,
      csvSeparator: ";",
      json: null,
      pickedKeyByColumns: null,
      pickedSheet: null,
      fileName: null,
      rows: null,
      sending: false,
      actualSendingStepString: null,
      sendingError: null,
      encryptionEnabled: false,
      additionalColumns: [],
      columnsInUse: [],
      blackList: [],
    };
  },
  setup() {
    const watchingSessionTitle = ref(null);
    const store = useStore();
    const i18n = inject("i18n");

    useWatchNewBatch((newBatchData, newBatchSubscriptionObserver) => {
      console.log(
        "New batch received:",
        newBatchData.data.onCreateSession.title
      );

      if (
        newBatchData?.data?.onCreateSession?.title ===
        watchingSessionTitle.value
      ) {
        newBatchSubscriptionObserver.unsubscribe();
        router.push("/insights/" + newBatchData.data.onCreateSession.id);
      }
    });

    const rawSteps = [
      "Data importation",
      "Cells selection",
      "blacklist",
      "Validation",
      "Sending",
    ];

    const steps = ref([]);

    watchEffect(() => {
      store.state.locale;
      steps.value = rawSteps.map((s) => i18n(s));
    });

    return {
      watchingSessionTitle,
      steps,
    };
  },
  computed: {
    editable() {
      return !this.sending && this.step > 2;
    },
    canGoNext() {
      let conditionsForSteps = {
        1: this.sessionName && this.sessionName.length > 0 && this.json != null,
        2:
          this.pickedKeyByColumns &&
          this.pickedKeyByColumns.title &&
          this.pickedKeyByColumns.contributor &&
          this.pickedSheet &&
          this.json &&
          this.json[this.pickedSheet] &&
          Object.keys(this.json[this.pickedSheet][1]).includes(
            this.pickedKeyByColumns.title
          ) &&
          Object.keys(this.json[this.pickedSheet][1]).includes(
            this.pickedKeyByColumns.contributor
          ),
        3: true,
        4:
          this.pickedKeyByColumns &&
          this.pickedKeyByColumns.title &&
          this.pickedKeyByColumns.contributor &&
          this.pickedSheet &&
          this.json &&
          this.json[this.pickedSheet] &&
          Object.keys(this.json[this.pickedSheet][1]).includes(
            this.pickedKeyByColumns.title
          ) &&
          Object.keys(this.json[this.pickedSheet][1]).includes(
            this.pickedKeyByColumns.contributor
          ),
      };

      return conditionsForSteps[this.step];
    },
    canGoPrev() {
      let conditionsForSteps = {
        1: false,
        2: true,
        3: true,
        4: true,
        5: !this.sending && this.sendingError && this.sendingError.length > 0,
      };

      return conditionsForSteps[this.step];
    },
    customNextButton() {
      if (this.step === 4) {
        return this.$filters.i18n("Start the analysis");
      }

      return null;
    },
    ...mapState(["user", "userRoles"]),
    ...mapGetters(["isUserAPilot", "isUserASelfSubscribed"]),
    allowed() {
      return this.user && !this.isUserAPilot;
    },
    additionalColumnsTitles() {
      return this.additionalColumns.filter((c) =>
        this.columnsInUse.includes(c.key)
      );
    },
    finalJson() {
      const columnsInUse = this.columnsInUse;
      let finalJsonContributions = [];
      let i = 0;

      for (const row of this.json[this.pickedSheet]) {
        if (typeof row === "object" && row !== null) {
          let additionalColumns = {};
          this.additionalColumns
            .filter((c) => columnsInUse.includes(c.key))
            .forEach(
              (c) =>
                (additionalColumns[c.key] = row[this.pickedKeyByColumns[c.key]])
            );

          finalJsonContributions.push({
            title: row[this.pickedKeyByColumns.title],
            author: row[this.pickedKeyByColumns.contributor],
            ...(this.pickedKeyByColumns.description !== null && {
              description: row[this.pickedKeyByColumns.description],
            }),
            ...(this.pickedKeyByColumns.date !== null && {
              date: row[this.pickedKeyByColumns.date],
            }),
            additionalColumns: JSON.stringify({
              ...additionalColumns,
              phedone_columnsBindings: this.additionalColumnsTitles,
            }),
          });

          i++;

          if (i >= 100 && this.isUserASelfSubscribed) {
            break;
          }
        }
      }

      return {
        contributions: finalJsonContributions,
        blackList: this.blackList,
      };
    },
  },
  methods: {
    ...mapMutations(["setPageTitle"]),
    updateStep(step) {
      if (
        (this.canGoNext && step === this.step + 1) ||
        (this.canGoPrev && step === this.step - 1)
      ) {
        this.step = step;
        return true;
      }
      return false;
    },
    goToStep(step) {
      if (step > this.step) {
        while (this.step < step) {
          let canTravel = this.updateStep(this.step + 1);
          if (!canTravel) break;
        }
      }

      if (step < this.step) {
        while (this.step > step) {
          let canTravel = this.updateStep(this.step - 1);
          if (!canTravel) break;
        }
      }
    },
    updateFileName(pickedFileName) {
      this.fileName = pickedFileName;
    },
    updatePublicKey(publicKey) {
      this.publicKey = publicKey;
    },
    updateJson(json) {
      this.json = json;

      if (json == null) {
        this.updateFileName(null);
      }
    },
    updatePickedRows(rows) {
      this.rows = rows;
    },
    showKeyPairGeneration() {
      let routeData = this.$router.resolve({ name: "NewKeys" });
      window.open(routeData.href, "_blank");
    },
    sheetPicked(args) {
      this.updateFileName(args.name);
      this.updateJson(args.json);
      this.updatePickedRows(null);
    },
    sendJsonToAws: async function () {
      this.sendingError = null;
      this.sending = true;
      let formattedJson = this.finalJson;
      await this.pushIdeas(formattedJson, this.sessionName);
    },
    simulateSendingError: async function () {
      await new Promise((r) => setTimeout(r, 3000));

      this.sending = false;
      this.sendingError =
        "{error : 's3 upload failed because of your network :('}";
    },
    pushIdeas: async function (json, sessionName) {
      sessionName = anyAscii(sessionName);
      if (this.encryptionEnabled) {
        this.actualSendingStepString = this.$filters.i18n(
          "Your data are being encrypted"
        );

        await new Promise((r) => setTimeout(r, 3000)); // Todo Remove this after merge, it's for visual purpose...

        // await this.simulateSendingError();

        this.actualSendingStepString = this.$filters.i18n(
          "Your encrypted data are being uploaded"
        );
      }

      this.actualSendingStepString = this.$filters.i18n(
        "Your data are being uploaded"
      );

      try {
        this.watchingSessionTitle = sessionName;
        await Storage.put(
          uniqid(_.snakeCase(sessionName) + "_"),

          json,
          {
            level: "private",
            contentType: "application/json",
            metadata: {
              sessionName: sessionName,
              user: this.user.username,
              email: this.user.attributes.email,
            },
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            // TODO SET ENCRYPTION => https://docs.amplify.aws/lib/storage/upload/q/platform/js#encrypted-uploads
          }
        );

        this.actualSendingStepString = this.$filters.i18n(
          this.encryptionEnabled
            ? "Your encrypted data are being processed before analysis on Phedone's infrastructure"
            : "Your data are being processed before analysis on Phedone's infrastructure"
        );
      } catch (err) {
        console.log(err);
        this.sendingError = JSON.stringify(
          err,
          Object.getOwnPropertyNames(err)
        );
        this.sending = false;
      }
    },
  },
  watch: {
    step(step) {
      if (step === 5) {
        this.sendJsonToAws();
      }
    },
  },
};
</script>
