<template>
  <vertical-tabs :tabs="tabs" v-model:activeTab="activeTab">
    <template #tabsContent>
      <div
        id="step-6-insight"
        class="relative w-full h-full"
        @mouseleave="chartsContainerClass = '!pointer-events-none'"
      >
        <collapsible-blobs
          :clusters="clusters"
          ref="blobs"
          v-show="activeTab === 0"
          :visible="activeTab === 0"
          :class="activeTab !== 0 ? 'hidden' : ''"
        />

        <connections-links
          :contributors="contributors"
          :contributions="contributions"
          v-if="activeTab === 1"
          :visible="activeTab === 1"
          :class="activeTab !== 1 ? 'hidden' : ''"
          :key="phedoneLocale"
        />

        <themes-cloud
          :clusters="clusters"
          v-show="activeTab === 2"
          :visible="activeTab === 2"
          :class="activeTab !== 2 ? 'hidden' : ''"
        />
      </div>
    </template>
  </vertical-tabs>
</template>

<script>
import ConnectionsLinks from "../DataVizualisation/ConnectionsLinks";
import ThemesCloud from "@/components/DataVizualisation/ThemesCloud";
import VerticalTabs from "@/components/DesignSystem/Tabs/VerticalTabs";
import CollapsibleBlobs from "@/components/DataVizualisation/CollapsibleBlobs";
import { inject, ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "InsightPane",
  components: {
    CollapsibleBlobs,
    VerticalTabs,
    /*ClustersTreeMap,*/ ThemesCloud,
    ConnectionsLinks,
  },
  props: {
    contributors: { type: Array, default: null, required: false },
    contributions: { type: Array, default: null, required: false },
    clusters: {
      type: Array,
    },
  },
  setup() {
    const i18n = inject("i18n");

    const store = useStore();
    const phedoneLocale = computed(() => store.state.locale);

    const tabsItems = [
      {
        title: i18n("Themes"),
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" x="0px" y="0px">
                <path d="M7.87,12.17,6.48,13.22a1.94,1.94,0,1,1-.55-.8l1.42-1.07A3.41,3.41,0,0,1,9.09,6.86a3.45,3.45,0,0,1,2.71,0l.48-1.09a2,2,0,1,1,.91.34l-.56,1.26A3.4,3.4,0,0,1,13,12.19l1.51,1.86a1.94,1.94,0,1,1,1.58,3.55,1.94,1.94,0,0,1-2.34-2.94l-1.48-1.83a3.33,3.33,0,0,1-1.85.55A3.36,3.36,0,0,1,7.87,12.17Z"></path>
              </svg>`,
      },
      {
        title: i18n("Authors connections"),
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" x="0px" y="0px">
                <circle cx="8.00049" cy="35.00049" r="8"></circle>
                <circle cx="58.00049" cy="104.00049" r="8"></circle>
                <circle cx="52.00049" cy="62.00049" r="8"></circle>
                <circle cx="84.00049" cy="82.00049" r="8"></circle>
                <circle cx="111.00049" cy="98.00049" r="8"></circle>
                <circle cx="16.00049" cy="91.00049" r="8"></circle>
                <circle cx="120.00049" cy="50.00049" r="8"></circle>
                <circle cx="96.00049" cy="24.00049" r="8"></circle>
                <path d="M93.20764,89.6828l6.60052,4.02344a12.0003,12.0003,0,0,1,2.0354-3.44421l-6.62549-4.03821A11.99707,11.99707,0,0,1,93.20764,89.6828Z"></path>
                <path d="M109.76971,56.2403,105.22559,60.189,100.264,35.20337a11.91177,11.91177,0,0,1-3.9234.77942L101.748,63.21143,91.17194,72.40179a12.09051,12.09051,0,0,1,2.77264,2.88959l8.70093-7.56091,3.80713,19.17072a11.91626,11.91626,0,0,1,3.90527-.86859L106.123,64.7085l6.26221-5.44183A12.08378,12.08378,0,0,1,109.76971,56.2403Z"></path>
                <path d="M68.18109,97.67926,75.981,90.90137a12.0662,12.0662,0,0,1-2.49628-3.1297l-7.9458,6.90479A12.08056,12.08056,0,0,1,68.18109,97.67926Z"></path>
                <path d="M85.70776,30.13843l-26.326,22.42041a12.08039,12.08039,0,0,1,2.69891,2.9552L88.2962,33.18793A12.07781,12.07781,0,0,1,85.70776,30.13843Z"></path>
                <path d="M75.1109,73.968,63.0697,66.62848a12.01133,12.01133,0,0,1-2.15143,3.37262l12.02734,7.33105A12.01744,12.01744,0,0,1,75.1109,73.968Z"></path>
                <path d="M16.81262,43.11737,40.797,57.73682a12.001,12.001,0,0,1,2.02435-3.45026L19.00592,39.7699A12.01941,12.01941,0,0,1,16.81262,43.11737Z"></path>
                <path d="M33.81934,74.3291,24.3468,82.39655a12.05082,12.05082,0,0,1,2.37012,3.23566l9.46179-8.058,13.175,18.12671a12.0524,12.0524,0,0,1,3.21118-2.38513L39.23242,74.97266l4.76416-4.05707a12.06564,12.06564,0,0,1-2.50006-3.12445l-4.6225,3.9364L16.39874,43.55682a12.04311,12.04311,0,0,1-3.28583,2.28363Z"></path>
              </svg>`,
      },
      {
        title: i18n("Themes cloud"),
        icon: `<svg xmlns="http://www.w3.org/2000/svg"x="0px" y="0px" viewBox="0 0 16 16">
                <path d="M10,8c0,0.552-0.447,1-1,1H4C3.448,9,3,8.552,3,8l0,0c0-0.552,0.448-1,1-1h5C9.553,7,10,7.448,10,8L10,8z"></path>
                <path d="M16,5c0,0.552-0.447,1-1,1h-3c-0.553,0-1-0.448-1-1l0,0c0-0.552,0.447-1,1-1h3C15.553,4,16,4.447,16,5L16,5z"></path>
                <path d="M0,5c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1l0,0c0-0.552-0.448-1-1-1H1C0.448,4,0,4.447,0,5L0,5z"></path>
                <path d="M14,11c0,0.553-0.447,1-1,1h-3c-0.553,0-1-0.447-1-1l0,0c0-0.553,0.447-1,1-1h3C13.553,10,14,10.447,14,11L14,11z"></path>
                <path d="M10,14c0,0.553-0.447,1-1,1H4c-0.552,0-1-0.447-1-1l0,0c0-0.553,0.448-1,1-1h5C9.553,13,10,13.447,10,14L10,14z"></path>
                <path d="M10,2.5C10,2.776,9.776,3,9.5,3h-4C5.224,3,5,2.776,5,2.5l0,0C5,2.223,5.224,2,5.5,2h4C9.776,2,10,2.223,10,2.5L10,2.5z"></path>
                <path d="M15,1.5C15,1.776,14.776,2,14.5,2h-3C11.224,2,11,1.776,11,1.5l0,0C11,1.223,11.224,1,11.5,1h3C14.776,1,15,1.223,15,1.5  L15,1.5z"></path>
                <path d="M16,7.5C16,7.776,15.776,8,15.5,8h-3C12.224,8,12,7.776,12,7.5l0,0C12,7.224,12.224,7,12.5,7h3C15.776,7,16,7.224,16,7.5  L16,7.5z"></path>
                <path d="M4,1.5C4,1.776,3.777,2,3.5,2h-2C1.224,2,1,1.776,1,1.5l0,0C1,1.223,1.224,1,1.5,1h2C3.777,1,4,1.223,4,1.5L4,1.5z"></path>
                <path d="M7,10.5C7,10.776,6.777,11,6.5,11h-5C1.224,11,1,10.776,1,10.5l0,0C1,10.224,1.224,10,1.5,10h5C6.777,10,7,10.224,7,10.5  L7,10.5z"></path>
                <path d="M9,5.5C9,5.776,8.776,6,8.5,6h-3C5.224,6,5,5.776,5,5.5l0,0C5,5.223,5.224,5,5.5,5h3C8.776,5,9,5.223,9,5.5L9,5.5z"></path>
              </svg>`,
      },
      /*        i18n("Themes TreeMap")*/
    ];

    const activeTab = ref(0);
    const tabs = computed(() => {
      return tabsItems.map((tab, i) => ({
        ...tab,
        active: i === activeTab.value,
      }));
    });

    return { tabs, activeTab, phedoneLocale };
  },
  data() {
    return {
      chartsContainerClass: "!pointer-events-none",
    };
  },
  methods: {
    setActiveTab(index) {
      this.activeTab.value = index;
    },
    log(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss">
.insightPane .v-window__container {
  height: 100%;
}
</style>
