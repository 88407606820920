<template>
  <page-body>
    <template #preBody>
      <dismissable-alert
        type="success"
        v-model:visible="confirmationMessage"
        v-if="confirmationMessage"
      >
        {{ $filters.i18n("login_confirmation_successful_notice") }}
      </dismissable-alert>
      <dismissable-alert
        type="success"
        v-model:visible="resetMessage"
        v-if="resetMessage"
      >
        {{ $filters.i18n("reset_password_confirm") }}
      </dismissable-alert>
      <dismissable-alert v-model:visible="errorIsVisible" v-if="errorIsVisible">
        {{ $filters.i18n("auth_login_error_password") }}
      </dismissable-alert>
      <dismissable-alert
        v-model:visible="errorIsVisibleUserNotExist"
        v-if="errorIsVisibleUserNotExist"
      >
        {{ $filters.i18n("auth_name_error_password") }}
      </dismissable-alert>
    </template>
    <template #mainBody>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="mx-auto w-auto h-12"
          src="../../assets/logo.png"
          alt="Workflow"
        />
        <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          {{ $filters.i18n("auth_login_title") }}
        </h2>
        <p class="mt-2 text-sm text-center text-gray-600">
          {{ $filters.i18n("Or") }}
          <router-link
            to="/auth/register"
            class="font-medium text-primary hover:text-primary-light"
          >
            {{ $filters.i18n("auth_login_subtitle") }}
          </router-link>
        </p>
        <form @submit.prevent="signIn">
          <div
            style="
              overflow: hidden;
              height: 0px;
              background: transparent;
              width: 0 !important;
            "
            data-description="dummyPanel for Chrome auto-fill issue"
          >
            <input
              type="text"
              style="
                height: 0;
                background: transparent;
                color: transparent;
                border: none;
              "
              data-description="dummyUsername"
            />
            <input
              type="password"
              style="
                height: 0;
                background: transparent;
                color: transparent;
                border: none;
              "
              data-description="dummyPassword"
            />
          </div>

          <div class="py-8 px-10 mt-8 space-y-5 carded">
            <text-input
              v-model="username"
              type="email"
              autocomplete="email"
              required
              class="py-3 px-3 bg-white"
              >{{ $filters.i18n("Email Address *") }}</text-input
            >
            <text-input
              v-model="password"
              type="password"
              autocomplete="password"
              required
              aria-autocomplete="current-password"
              class="py-3 px-3"
              >{{ $filters.i18n("Password *") }}</text-input
            >
            <div class="flex justify-end">
              <div class="text-sm text-right">
                <router-link
                  to="/auth/reset/password"
                  class="font-medium text-primary hover:text-primary-light"
                  >{{ $filters.i18n("Forgot your password?") }}
                </router-link>
              </div>
            </div>
            <button
              type="submit"
              :disabled="requestInProgress"
              class="w-full button button-primary"
            >
              <togglable-loading-state :loading="requestInProgress">
                {{ $filters.i18n("Sign In") }}
              </togglable-loading-state>
            </button>
          </div>
        </form>
      </div>
    </template>
  </page-body>
</template>

<script>
import PageBody from "@/components/Layout/PageBody";
import TextInput from "@/components/DesignSystem/Inputs/TextInput";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import { Auth } from "aws-amplify";
import DismissableAlert from "@/components/DesignSystem/Alerts/DismissableAlert";

export default {
  name: "Login",
  components: { DismissableAlert, TogglableLoadingState, TextInput, PageBody },
  props: {
    confirmationSuccessful: Boolean,
    resetSuccessful: Boolean,
    email: String,
  },
  mounted() {
    this.username = this.email;
    this.confirmationMessage = this.confirmationSuccessful === "true";
    this.resetMessage = this.resetSuccessful === "true";
  },
  data() {
    return {
      requestInProgress: false,
      username: "",
      password: "",
      confirmationMessage: false,
      resetMessage: false,
      errorIsVisible: false,
      errorIsVisibleUserNotExist: false,
    };
  },
  methods: {
    async signIn() {
      if (this.requestInProgress) {
        return;
      }

      this.requestInProgress = true;

      try {
        const user = await Auth.signIn(this.username, this.password);
        console.log(user);
      } catch (error) {
        console.log("error signing in", error);
        if (error.message == "Incorrect username or password.") {
          this.requestInProgress = false;
          return (this.errorIsVisible = true);
        } else if (error.message == "User does not exist.") {
          this.requestInProgress = false;
          return (this.errorIsVisibleUserNotExist = true);
        }
      }

      this.requestInProgress = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
