<template>
  <cluster-delete-modal :cluster-id="clusterId" @on-done="$emit('on-deleted')">
    <template #default="{ clickCallback, loading }">
      <button
        class="button"
        @click="clickCallback"
        :disabled="loading"
        v-bind="$attrs"
      >
        <slot />

        <togglable-loading-state :loading="loading">
          <span>{{ $filters.i18n("Delete") }}</span>
        </togglable-loading-state>
      </button>
    </template>
  </cluster-delete-modal>
</template>

<script>
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import ClusterDeleteModal from "@/components/Clusters/ClusterDeleteModal";

export default {
  name: "ClusterDeleteButton",
  components: { TogglableLoadingState, ClusterDeleteModal },
  props: {
    clusterId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      loading: false,
    };
  },
};
</script>
