const worker = new Worker(new URL("./chartsWorker.js", import.meta.url), {
  type: "module",
});

const send = (message) => {
  worker.postMessage(JSON.parse(JSON.stringify({ message })));
};

export default {
  worker,
  send,
};
