<template>
  <div
    class="flex absolute inset-0 z-[45] flex-col justify-center items-center bg-white"
  >
    <loading-spinner :hint="hint" />
  </div>
</template>

<script>
import LoadingSpinner from "../DesignSystem/Progress/LoadingSpinner";
export default {
  name: "LoadingChartOverlay",
  components: { LoadingSpinner },
  data() {
    return {};
  },
  props: {
    hint: { type: String },
  },
};
</script>

<style scoped lang="scss">
.chartOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 900;
}
</style>
