<template>
  <main class="flex flex-col justify-center items-center w-full min-h-[300px]">
    <loading-spinner
      :hint="$filters.i18n(`encryption_privateKey_sending_hint`)"
      v-if="!sendingError"
    />
    <template v-else>
      <ExclamationCircleIcon class="w-16 h-16 text-primary-dark" />
      <h3 class="block mt-3 mb-4 text-primary-dark">
        {{ $filters.i18n("encryption_privateKey_sending_error") }}
      </h3>
      <a
        class="button button-primary-dark"
        :href="`mailto:support@phedone.com?subject=${encodeURIComponent(
          $filters.i18n('encryption_privateKey_sending_error_email')
        )}&body=${encodeURIComponent(
          $filters.i18n('Here are the logs : ')
        )}%0D%0A${encodeURIComponent(
          sendingError
        )}%0D%0A%0D%0A${encodeURIComponent(
          $filters.i18n('Additional informations : ')
        )}%0D%0A`"
        >{{ $filters.i18n("Report the error to Phedone's team") }}</a
      >
    </template>
  </main>
</template>

<script>
import LoadingSpinner from "../DesignSystem/Progress/LoadingSpinner";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

export default {
  name: "KeysPairGenerationSending",
  components: { LoadingSpinner, ExclamationCircleIcon },
  metaInfo: {
    title: "InsertingStepsOngoing",
  },
  props: {
    sending: Boolean,
    sendingError: String,
  },
};
</script>
