<template>
  <cluster-rename-modal
    :cluster-id="clusterId"
    @on-done="$emit('on-renamed')"
    :default-title="defaultTitle"
  >
    <template #default="{ clickCallback, loading }">
      <button
        class="button"
        @click="clickCallback"
        :disabled="loading"
        v-bind="$attrs"
      >
        <slot />

        <togglable-loading-state :loading="loading">
          <span>{{ $filters.i18n("Rename") }}</span>
        </togglable-loading-state>
      </button>
    </template>
  </cluster-rename-modal>
</template>

<script>
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";
import ClusterRenameModal from "@/components/Clusters/ClusterRenameModal";

export default {
  name: "ClusterRenameButton",
  components: { ClusterRenameModal, TogglableLoadingState },
  props: {
    clusterId: {
      type: String,
      required: true,
    },
    defaultTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modalVisible: false,
      loading: false,
    };
  },
};
</script>
