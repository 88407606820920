import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import chartsWorker from "@/charts-worker";

import theme from "@amcharts/amcharts4/themes/material";
import { ref, markRaw, inject } from "vue";
import { range } from "lodash";
// Apply the themes
am4core.useTheme(theme);

am4core.options.queue = true;

export function useConnectionsLinksChart(chartRef, chartElementRef) {
  const i18n = inject("i18n");

  const chartReady = ref(false);
  const linksBetweenContributorsByPrecision = ref(markRaw([]));

  const setupConnectionsLinksChart = (linksCollection) => {
    let chart = am4core.create(chartElementRef.value, am4charts.ChordDiagram);

    chart.svgContainer.autoResize = true;

    chart.colors.saturation = 0.45;
    chart.colors.step = 3;
    let colors = {
      Rachel: chart.colors.next(),
      Monica: chart.colors.next(),
      Phoebe: chart.colors.next(),
      Ross: chart.colors.next(),
      Joey: chart.colors.next(),
      Chandler: chart.colors.next(),
    };

    chart.colors.saturation = 0.45;
    chart.colors.step = 3;

    chart.data = markRaw(linksCollection);

    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";

    chart.nodePadding = 0.5;
    chart.minNodeSize = 0.01;
    chart.startAngle = 80;
    chart.endAngle = chart.startAngle + 360;
    chart.sortBy = "value";
    chart.fontSize = 10;

    let nodeTemplate = chart.nodes.template;
    nodeTemplate.readerTitle =
      "Cliquer pour afficher/cacher et maintenez pour réarranger";
    nodeTemplate.showSystemTooltip = true;
    nodeTemplate.propertyFields.fill = "color";
    nodeTemplate.tooltipText = i18n("insight_dataviz_contributors_links_label");

    // when rolled over the node, make all the links rolled-over
    nodeTemplate.events.on("over", function (event) {
      let node = event.target;
      node.outgoingDataItems.each(function (dataItem) {
        if (dataItem.toNode) {
          dataItem.link.isHover = true;
          dataItem.toNode.label.isHover = true;
        }
      });
      node.incomingDataItems.each(function (dataItem) {
        if (dataItem.fromNode) {
          dataItem.link.isHover = true;
          dataItem.fromNode.label.isHover = true;
        }
      });

      node.label.isHover = true;
    });

    // when rolled out from the node, make all the links rolled-out
    nodeTemplate.events.on("out", function (event) {
      let node = event.target;
      node.outgoingDataItems.each(function (dataItem) {
        if (dataItem.toNode) {
          dataItem.link.isHover = false;
          dataItem.toNode.label.isHover = false;
        }
      });
      node.incomingDataItems.each(function (dataItem) {
        if (dataItem.fromNode) {
          dataItem.link.isHover = false;
          dataItem.fromNode.label.isHover = false;
        }
      });

      node.label.isHover = false;
    });

    let label = nodeTemplate.label;
    label.relativeRotation = 90;

    label.fillOpacity = 0.4;
    let labelHS = label.states.create("hover");
    labelHS.properties.fillOpacity = 1;

    nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    // this adapter makes non-main character nodes to be filled with color of the main character which he/she kissed most
    nodeTemplate.adapter.add("fill", function (fill, target) {
      let node = target;
      let counters = {};
      let mainChar = false;
      node.incomingDataItems.each(function (dataItem) {
        if (colors[dataItem.toName]) {
          mainChar = true;
        }

        if (isNaN(counters[dataItem.fromName])) {
          counters[dataItem.fromName] = dataItem.value;
        } else {
          counters[dataItem.fromName] += dataItem.value;
        }
      });
      if (mainChar) {
        return fill;
      }

      let biggest = 0;
      let biggestName;

      for (var name in counters) {
        if (counters[name] > biggest) {
          biggestName = name;
          biggest = counters[name];
        }
      }
      if (colors[biggestName]) {
        fill = colors[biggestName];
      }

      return fill;
    });

    let linkTemplate = chart.links.template;
    linkTemplate.strokeOpacity = 0;
    linkTemplate.fillOpacity = 0.15;
    linkTemplate.tooltipText = "{fromName} & {toName}";

    let hoverState = linkTemplate.states.create("hover");
    hoverState.properties.fillOpacity = 0.7;
    hoverState.properties.strokeOpacity = 0.7;

    chartRef.value = chart;

    chart.events.on("ready", () => {
      chartReady.value = true;
    });
  };

  const setupChartWithActualPrecision = (linksPrecision) => {
    if (
      linksBetweenContributorsByPrecision.value &&
      linksPrecision &&
      linksBetweenContributorsByPrecision.value[linksPrecision]
    ) {
      if (chartRef.value) {
        try {
          chartRef.value.dispose();
        } catch (e) {
          console.error(e);
        }
      }

      setupConnectionsLinksChart(
        linksBetweenContributorsByPrecision.value[linksPrecision]
      );
    } else {
      console.error(
        `      linksBetweenContributorsByPrecision.value &&
      linksPrecision &&
      linksBetweenContributorsByPrecision.value[linksPrecision]`,
        linksBetweenContributorsByPrecision.value &&
          linksPrecision &&
          linksBetweenContributorsByPrecision.value[linksPrecision]
      );
    }
  };

  const startGeneratingLinksBetweenContributorsByPrecision = (
    linksPrecision,
    contributors,
    contributions,
    contributionsWithContributorName
  ) => {
    const remainingPrecisionsToCompute = ref(range(5, 30).map((v) => v / 100));

    chartsWorker.worker.onmessage = (event) => {
      if (event.data.error === true) {
        console.error(event.data.e);
      } else if (event.data.linksBetweenContributorsByPrecision) {
        linksBetweenContributorsByPrecision.value[
          event.data.linksBetweenContributorsByPrecision.precision
        ] = event.data.linksBetweenContributorsByPrecision.links;

        remainingPrecisionsToCompute.value = remainingPrecisionsToCompute.value.filter(
          (precision) =>
            precision !==
            event.data.linksBetweenContributorsByPrecision.precision
        );

        if (remainingPrecisionsToCompute.value.length > 0) {
          chartsWorker.send({
            type: "generateLinksBetweenContributorsForPrecision",
            precision: remainingPrecisionsToCompute.value[0],
            contributors: contributors,
            contributions: contributions,
            contributionsWithContributorName,
          });
        }

        if (
          event.data.linksBetweenContributorsByPrecision.precision ===
          linksPrecision
        ) {
          setupChartWithActualPrecision(linksPrecision);
        }
      }
    };

    chartsWorker.send({
      type: "generateLinksBetweenContributorsForPrecision",
      precision: linksPrecision,
      contributors: contributors,
      contributions: contributions,
      contributionsWithContributorName,
    });
  };

  return {
    setupChartWithActualPrecision,
    startGeneratingLinksBetweenContributorsByPrecision,
    chartReady,
  };
}
