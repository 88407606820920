<template>
  <div class="block hover:bg-gray-50 cursor-pointer">
    <div
      class="flex relative flex-col justify-center py-4 px-4 min-h-[50px] sm:px-6"
    >
      <div class="flex flex-col">
        <p
          class="text-sm text-gray-500 truncate"
          v-if="details.author && details.author.name"
        >
          {{ details.author.name }}
        </p>
        <div class="flex flex-wrap items-center">
          <p class="text-sm font-medium line-clamp-2">
            <span>{{ title }}</span>
          </p>
          <link-strength-badge
            v-if="
              details && details.score !== null && details.score != undefined
            "
            :strength="details.score"
          />
        </div>
      </div>
      <div class="flex items-center space-x-2">
        <div class="relative">
          <div
            class="z-20 mt-2 sm:flex sm:justify-between"
            v-if="clusterTitle && !clusterDetached && !clusterUpdated"
          >
            <div class="relative sm:flex">
              <span
                class="inline-flex justify-center items-center py-0.5 px-2.5 min-w-[70px] text-xs font-medium rounded-full"
                :class="`bg-${clusterColor}-100 text-${clusterColor}-800`"
                :style="
                  colorHex
                    ? `background: ${colorHex}; color: ${titleColorHex}`
                    : ''
                "
              >
                <span class="flex justify-around items-center w-full">
                  <svg
                    class="mr-1.5 -ml-0.5 w-2 h-2"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                    v-if="colorHex"
                    :style="colorHex ? `color: ${titleColorHex}` : ''"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  <span class="flex-1 text-center">{{ clusterTitle }}</span>
                </span>
              </span>
              <div
                :style="
                  colorHex
                    ? `background: ${colorHex}; color: ${titleColorHex}`
                    : ''
                "
                class="inline-flex absolute z-30 justify-between items-center py-0.5 px-1.5 w-full h-full text-xs font-medium rounded-full opacity-0 hover:opacity-100 duration-300 !cursor-default"
                v-if="clustersActions"
              >
                <button
                  @click="$emit('view-cluster-clicked')"
                  class="p-0.5 pl-0 hover:opacity-80 interactive"
                >
                  <EyeIcon class="w-3 h-3"> </EyeIcon>
                </button>
                <button
                  @click="$emit('edit-cluster-clicked')"
                  class="p-0.5 hover:opacity-80 interactive"
                >
                  <PencilIcon class="w-3 h-3"> </PencilIcon>
                </button>
                <button
                  @click="callContributionClusterUpdate(null)"
                  class="flex p-0.5 pr-0 hover:opacity-80 interactive"
                >
                  <togglable-loading-state
                    class="block mb-[-0.05rem]"
                    :white="false"
                    :loading="loadingClusterRemoval"
                    ><MinusIcon class="w-3 h-3"> </MinusIcon
                  ></togglable-loading-state>
                </button>
              </div>
            </div>
          </div>
          <span
            v-else
            class="group inline-flex relative z-20 items-center py-0.5 px-2.5 mt-2 text-xs font-medium rounded-full"
            :class="`bg-white text-gray-800 text-opacity-70 border border-gray-800 border-opacity-20 shadow-sm`"
          >
            <svg
              class="mr-1.5 -ml-0.5 w-2 h-2 opacity-100"
              :class="
                clustersActions && !clusterDetached && !clusterUpdated
                  ? 'group-hover:opacity-0'
                  : ''
              "
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx="4" cy="4" r="3" />
            </svg>
            <span
              class="min-w-[85px] opacity-100"
              :class="[
                clustersActions && !clusterUpdated && !clusterDetached
                  ? 'group-hover:opacity-0'
                  : '',
                clusterUpdated || clusterDetached ? 'text-[0.7rem]' : '',
              ]"
              >{{
                $filters.i18n(
                  clusterDetached
                    ? "Cluster detached"
                    : clusterUpdated
                    ? "Cluster changed"
                    : "No cluster"
                )
              }}</span
            >
            <span v-if="clustersActions && !clusterDetached && !clusterUpdated">
              <button
                class="flex absolute inset-0 z-30 justify-center items-center space-x-0.5 w-full h-full text-center opacity-0 group-hover:opacity-100 focus:opacity-0"
                @click="$emit('add-cluster-clicked')"
              >
                <PlusIcon class="w-3 h-3" />
                <span class="text-[0.7rem]">{{
                  $filters.i18n("Add cluster")
                }}</span>
              </button>
            </span>
          </span>
        </div>
        <div
          class="mt-2 sm:flex sm:justify-between"
          v-if="details.originality != null"
          :title="`${$filters.i18n('Originality')} : ${
            details.originality * 10
          } %`"
        >
          <div class="sm:flex">
            <span
              class="inline-flex items-center py-0.5 px-2.5 text-xs font-medium rounded-full"
              :class="`bg-white text-gray-800 text-opacity-70 border border-gray-800 border-opacity-20 shadow-sm`"
            >
              <LightBulbIcon
                class="mt-[-0.05rem] mr-0.5 -ml-1 w-[0.89rem] h-[0.9rem] text-current"
              />
              {{ details.originality * 10 }} %
            </span>
          </div>
        </div>
        <tonality-badge
          :score="details.polarity"
          v-if="details.polarity != null"
          no-color
          emojis
          class="mt-2"
          ><EmojiHappyIcon
            class="mt-[-0.05rem] mr-1 -ml-1 w-[0.89rem] h-[0.9rem] text-current"
        /></tonality-badge>
      </div>
      <button
        class="absolute inset-0 z-10 w-full h-full interactive"
        @click="$emit('entry-clicked')"
      ></button>
    </div>
  </div>
</template>

<script>
import LinkStrengthBadge from "@/components/DesignSystem/Badges/LinkStrenthBadge";
import { LightBulbIcon, EmojiHappyIcon } from "@heroicons/vue/outline";
import TonalityBadge from "@/components/DesignSystem/Badges/TonalityBadge";
import { EyeIcon, PencilIcon, MinusIcon, PlusIcon } from "@heroicons/vue/solid";
import { useProvideModals } from "../../logic/use-modals";
import { inject, ref, toRefs } from "vue";
import { useUpdateContributionCluster } from "../../logic/use-api-fetch";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";

export default {
  name: "SmallContributionCard",
  components: {
    TogglableLoadingState,
    TonalityBadge,
    LinkStrengthBadge,
    LightBulbIcon,
    EmojiHappyIcon,
    EyeIcon,
    PencilIcon,
    MinusIcon,
    PlusIcon,
  },
  props: {
    url: String,
    details: Object,
    title: String,
    clusterTitle: String,
    colorHex: String,
    titleColorHex: String,
    clusterColor: { type: String, default: "gray" },
    clustersActions: Boolean,
    clusterUpdated: Boolean,
  },
  setup(props) {
    useProvideModals("insight");
    const globalReloadProposed = inject("globalReloadProposed");
    const globalError = inject("globalError");
    let { details } = toRefs(props);
    const clusterDetached = ref(false);

    const {
      updateContributionCluster,
      onDone,
      onError,
      loading: loadingClusterRemoval,
      contributionID,
      clusterID,
    } = useUpdateContributionCluster();

    onDone(() => {
      console.log("Done");
      globalReloadProposed.value = true;
      clusterDetached.value = true;
    });

    onError((err) => {
      globalError.value = err;
    });

    const callContributionClusterUpdate = async (newClusterID) => {
      clusterID.value = newClusterID;
      contributionID.value = details.value.id;
      await updateContributionCluster();
    };

    return {
      loadingClusterRemoval,
      callContributionClusterUpdate,
      clusterDetached,
    };
  },
};
</script>

<style scoped lang="scss"></style>
