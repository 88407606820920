<template>
  <shared-borders-metrics>
    <template #metrics>
      <shared-border-metric
        :title="$filters.i18n('Identified themes')"
        :value="clustersNb || '...'"
      >
        <!--        <template #subtitle>
          {{ ideasNb }} {{ $filters.i18n("submitted contributions") }}
        </template>-->
      </shared-border-metric>

      <shared-border-metric
        :title="$filters.i18n('Average ideas/contributor')"
        :value="averageIdeasByAuthor > 0 ? averageIdeasByAuthor : '...'"
      >
        <!--        <template #subtitle>
          {{ authorsLength }} {{ $filters.i18n("uniques contributors") }}
        </template>-->
      </shared-border-metric>

      <shared-border-metric
        :title="$filters.i18n('Prominent theme')"
        :value="(mainTheme && (mainTheme.title || mainTheme.name)) || '...'"
        @title-click="clusterModal = mainTheme?.id"
        clickable
      >
        <template
          #subtitle
          v-if="mainTheme && mainTheme.children && mainTheme.children.length"
        >
          ({{ mainTheme.children.length }} {{ $filters.i18n("contributions") }})
        </template>
      </shared-border-metric>

      <ClusterModal
        :external-contributors="availableContributors"
        :external-contributions="availableContributions"
        :external-clusters="availableClusters"
        :active-cluster-id="clusterModal"
        :modal-instance-id="ownModalInstanceId"
        v-if="clusterModal"
        :key="`cluster_${ownModalInstanceId}`"
      />
    </template>
  </shared-borders-metrics>
</template>

<script>
import SharedBorderMetric from "@/components/DesignSystem/Metrics/SharedBorderMetric";
import SharedBordersMetrics from "@/components/DesignSystem/Metrics/SharedBordersMetrics";
import { mapMutations } from "vuex";
import { computed } from "vue";
import { useReadyToUseSingleClusterModal } from "@/logic/use-modals";
import ClusterModal from "@/components/Clusters/ClusterModal";
export default {
  name: "InsightMetrics",
  components: { ClusterModal, SharedBordersMetrics, SharedBorderMetric },
  data() {
    return {
      notAvailableYetAlert: false,
    };
  },
  setup() {
    const ownModalInstanceId = computed(() => `metrics_modal`); // Here the computed is not necessary, but could be use with variable key
    const clusterModalVariables = useReadyToUseSingleClusterModal(
      ownModalInstanceId
    );
    return { ...clusterModalVariables, ownModalInstanceId };
  },
  props: {
    displayProefficientAuthor: {
      type: Boolean,
      default: false,
    },
    clustersNb: { type: Number, default: null, required: false },
    ideasNb: { type: Number, default: null, required: false },
    bestAuthor: { type: Object, default: null, required: false },
    mainTheme: { type: Object, default: null, required: false },
    authorsLength: { type: Number, default: 0 },
    loading: Boolean,
  },
  computed: {
    averageIdeasByAuthor() {
      return this.ideasNb > 0 && this.authorsLength
        ? this.round(this.ideasNb / this.authorsLength, 2)
        : 0;
    },
  },
  methods: {
    ...mapMutations(["setActiveCluster"]),
    round(number, decimals) {
      return +(Math.round(number + "e+" + decimals) + "e-" + decimals);
    },
  },
};
</script>

<style lang="scss">
.cardActions {
  .v-btn__content {
    font-size: 9px !important;
  }
}
</style>
