<template>
  <div
    class="relative px-6 pb-6 space-y-6 md:grid md:grid-cols-3 md:gap-6 md:space-y-0 lg:grid-cols-12"
  >
    <dashboard-card
      id="step-1-dashboard"
      class="bg-white md:col-span-12 md:h-[250px]"
      ref="histogram_chart"
      :actions="[]"
    >
      <template #title>{{
        $filters.i18n("dashboard_insight_filters_title")
      }}</template>

      <template #content>
        <filterable-histogram
          class="w-[95%]"
          :entries="everyContributionsDates"
        />
      </template>
    </dashboard-card>

    <dashboard-card
      :class="
        matrixHorizontalLabels.length > 50 ? 'md:col-span-12' : 'md:col-span-8'
      "
      @export-to-picture-clicked="
        exportChartToPng($refs.matrixChart.$refs.chartRef)
      "
    >
      <template #title>{{
        $filters.i18n("dashboard_insight_themes_on_period_title")
      }}</template>
      <template #content>
        <matrix-chart
          id="step-2-dashboard"
          :days="matrixData"
          :months="matrixDataMonth"
          :vertical-labels="matrixVerticalTitles"
          :horizontal-labels="matrixHorizontalLabels"
          :horizontal-labels-months="matrixHorizontalLabelsMonth"
          :style="`height: ${
            (matrixVerticalTitles || matrixVerticalTitles).length * 30
          }px`"
          ref="matrixChart"
          :key="`matrix_chart_${matrixDataUnitKey}`"
          v-model:unitKey="matrixDataUnitKey"
        />
      </template>
    </dashboard-card>

    <dashboard-card
      :class="
        matrixHorizontalLabels.length > 50 ? 'md:col-span-8' : 'md:col-span-4'
      "
      @export-to-picture-clicked="
        exportChartToPng($refs.donutChart.$refs.chartRef)
      "
    >
      <template #title>{{
        $filters.i18n("dashboard_insight_themes_repartition_title")
      }}</template>
      <template #content>
        <div class="-mb-4">
          <donut-chart
            :colors="clusterDonutColors"
            :labels="clusterDonutLabels"
            :data-sets="clusterDonutValues"
            ref="donutChart"
            class="overflow-hidden md:!max-h-[450px]"
          />
        </div>
      </template>
    </dashboard-card>

    <dashboard-card
      class="md:col-span-4"
      @export-to-picture-clicked="
        exportChartToPng($refs.radarChart.$refs.chartRef)
      "
    >
      <template #title>{{
        $filters.i18n("dashboard_insight_main_themes_metrics_title")
      }}</template>
      <template #content>
        <radar-chart
          :data-sets="indexesData"
          :labels="indexesTitles"
          ref="radarChart"
          :key="`dashboard_insight_main_themes_metrics_${$store.state.locale}`"
          class="overflow-hidden md:!max-h-[450px]"
        />
      </template>
    </dashboard-card>

    <dashboard-card
      :class="
        matrixHorizontalLabels.length > 50 ? 'md:col-span-12' : 'md:col-span-8'
      "
      @export-to-picture-clicked="
        exportChartToPng($refs.stackedBarChart.$refs.chartRef)
      "
    >
      <template #title>{{
        $filters.i18n(
          "dashboard_insight_contributions_evolution_on_periods_title"
        )
      }}</template>
      <template #content>
        <stacked-bar-chart
          :data-sets="stackedBarData"
          :labels="everyContributionLocalizedDates"
          class="md:!max-h-[450px]"
          ref="stackedBarChart"
        />
      </template>
    </dashboard-card>

    <dashboard-card
      class="md:col-span-3"
      @export-to-picture-clicked="
        exportChartToPng($refs.overallOpinionChart.$refs.chartRef)
      "
    >
      <template #title>{{
        $filters.i18n("dashboard_insight_overall_opinion_title")
      }}</template>
      <template #content>
        <div class="-mb-4">
          <donut-chart
            :colors="negativePositiveOpinionSplitColors"
            :labels="negativePositiveOpinionSplitLabels"
            :data-sets="negativePositiveOpinionSplitValues"
            ref="overallOpinionChart"
            class="overflow-hidden md:!max-h-[350px]"
            rounded-labels
            no-auto-sort
          />
        </div>
      </template>
    </dashboard-card>

    <dashboard-card
      class="md:col-span-9"
      @export-to-picture-clicked="
        exportChartToPng($refs.horizontalStackedBarChart.$refs.chartRef)
      "
    >
      <template #title>{{
        $filters.i18n("dashboard_insight_opinions_by_themes_title")
      }}</template>
      <template #content>
        <div class="-mb-4">
          <horizontal-stacked-bar-chart
            :labels="horizontalStackedBarLabels"
            :data-sets="horizontalStackedBarValues"
            ref="horizontalStackedBarChart"
          />
        </div>
      </template>
    </dashboard-card>

    <template v-if="!notPlaceholdingDates">
      <div class="w-full h-10"></div>
      <sticky-banner v-model="visiblePlaceholdingDatesBanner">{{
        $filters.i18n("dashboard_insight_placeholded_dates_label")
      }}</sticky-banner>
    </template>
  </div>
</template>

<script>
import DashboardCard from "@/components/DesignSystem/Cards/DashboardCard";
import RadarChart from "@/components/Graphs/RadarChart";
import { inject, ref, computed } from "vue";
import _, {
  groupBy,
  mapValues,
  maxBy,
  meanBy,
  sortBy,
  uniq,
  uniqBy,
} from "lodash";
import StackedBarChart from "@/components/Graphs/StackedBarChart";
import MatrixChart from "@/components/Graphs/MatrixChart";
import { useChartJsHelpers } from "@/logic/use-chartjs-helpers";
import DonutChart from "@/components/Graphs/DonutChart";
import HorizontalStackedBarChart from "@/components/Graphs/HorizontalStackedBarChart";
import { useClustersScoreCalculations } from "@/logic/use-clusters-score-calculations";
import { useTailwindColors } from "@/logic/use-tailwind-colors";
import { useDateHelpers } from "@/logic/use-date-helpers";
import FilterableHistogram from "@/components/Graphs/FilterableHistogram";
import { useStore } from "vuex";
import StickyBanner from "@/components/DesignSystem/Banners/StickyBanner";

export default {
  name: "DashboardInsightContainer",
  components: {
    StickyBanner,
    FilterableHistogram,
    HorizontalStackedBarChart,
    DonutChart,
    MatrixChart,
    StackedBarChart,
    RadarChart,
    DashboardCard,
  },
  props: {
    clusters: Array,
    contributions: Array,
    title: String,
  },
  data() {
    return {};
  },
  setup(props) {
    const i18n = inject("i18n");
    const store = useStore();

    const visiblePlaceholdingDatesBanner = ref(true);

    const histogramChart = ref(null);
    const matrixChart = ref(null);
    const radarChart = ref(null);
    const stackedBarChart = ref(null);
    const donutChart = ref(null);
    const horizontalStackedBarChart = ref(null);
    const overallOpinionChart = ref(null);

    const { exportChartToPng, exportElementToPng } = useChartJsHelpers;

    const indexesTitles = [
      "Opinion",
      "Originality",
      /*"Relevance",
      "Subjectivity",*/
      "Density",
      "Contributions Nb.",
    ].map((l) => i18n(l));

    const formattedClusters = (props.clusters || []).map((cluster) => {
      const contributions = props.contributions
        .filter((c) => c?.cluster?.id === cluster?.id)
        .map((c) => ({
          ...c,
          opinion: useClustersScoreCalculations.scoreOnFive(c.polarity),
        }));

      const originality =
        Math.round(meanBy(contributions, "originality") * 100) / 10;

      return {
        contributions,
        ...cluster,
        bgColor: cluster.backgroundColor,
        title: (cluster.title || cluster.name)
          .replaceAll("\n", "")
          .replaceAll("  ", ""),
        opinion: useClustersScoreCalculations.scoreOnFive(cluster.polarityMean),
        opinionPercentage: Math.round(50 + cluster.polarityMean * 50),
        originality,
        density: Math.round(cluster.density * 1000) / 10,
        contributionsNumber: contributions.length,
      };
    });

    const clustersTitles = uniq(
      // The Uniq here could mess up, as it's common (as for now the engine is not handling this) to have 2 clusters with the same title
      Object.values(formattedClusters).map((c) => c.title)
    );
    const faker = require("faker");

    const notPlaceholdingDates = props.contributions.reduce(
      (containsDates, contribution) =>
        containsDates ||
        (contribution.createdAtRaw && contribution.createdAtRaw.length > 0),
      false
    );

    let formattedContributions = props.contributions
      .filter((c) => c?.cluster?.name != null)
      .map((c) => ({
        ...c,
        clusterTitle: (c.cluster.title || c.cluster.name)
          .replaceAll("\n", "")
          .replaceAll("  ", ""),
        opinion: useClustersScoreCalculations.scoreOnFive(c.polarity),
        createdAt:
          c.createdAtRaw && c.createdAtRaw.length > 0
            ? new Date(c.createdAtRaw)
            : faker.date.between(new Date(2021, 4, 20), new Date()),
      }))
      .sort((a, b) => a.createdAt - b.createdAt);

    let formattedContributionsByMonthByYear = mapValues(
      groupBy(formattedContributions, (c) => c.createdAt.getFullYear()),
      (v) => groupBy(v, (u) => u.createdAt.getMonth())
    );

    let formattedContributionsByClusterByMonthByYear = mapValues(
      formattedContributionsByMonthByYear,
      (y) => mapValues(y, (c) => groupBy(c, (i) => i.clusterTitle))
    );

    let contributionsNbByClusterByMonthByYearWithLocalizedmonthAndYearTitle = [];
    // eslint-disable-next-line no-unused-vars
    let contributionsNbByClusterByMonthByYear = mapValues(
      formattedContributionsByClusterByMonthByYear,
      (months, year) => {
        return mapValues(months, (clustersInMonth, month) => {
          const monthAndYearTitle = new Date(year, month, 1).toLocaleDateString(
            store.state.locale,
            {
              month: "long",
              year: "numeric",
            }
          );

          return mapValues(clustersInMonth, (clusterEntries, clusterTitle) => {
            contributionsNbByClusterByMonthByYearWithLocalizedmonthAndYearTitle[
              monthAndYearTitle
            ] =
              contributionsNbByClusterByMonthByYearWithLocalizedmonthAndYearTitle[
                monthAndYearTitle
              ] || {};

            contributionsNbByClusterByMonthByYearWithLocalizedmonthAndYearTitle[
              monthAndYearTitle
            ][clusterTitle] = clusterEntries.length;

            return clusterEntries.length;
          });
        });
      }
    );

    let everyContributionsDates = formattedContributions.map(
      (c) => c.createdAt
    );

    const everyDatesBetweenFirstAndLastContribution = useDateHelpers
      .getDatesInRange(
        everyContributionsDates[0],
        everyContributionsDates[everyContributionsDates.length - 1]
      )
      .map((d) => ({
        date: d,
        localized: d.toLocaleDateString(store.state.locale),
      }));

    formattedContributions = formattedContributions
      .sort((a, b) => a.createdAt > b.createdAt)
      .map((c) => ({
        ...c,
        createdAt: c.createdAt.toLocaleDateString(store.state.locale),
      }));

    const contributionsByClusters = groupBy(
      formattedContributions,
      "clusterTitle"
    );

    const contributionsNbByClusters = mapValues(
      contributionsByClusters,
      (c) => c.length
    );

    let contributionsByClusterByDate = {};

    for (const [key, contributions] of Object.entries(
      contributionsByClusters
    )) {
      contributionsByClusterByDate[key] = _.groupBy(contributions, "createdAt");
    }

    let everyContributionLocalizedDates = uniq(
      formattedContributions.map((c) => c.createdAt)
    );

    let contributionsNbByClusterByLocalizedDate = {};
    let contributionsNbByClusterByRawDate = {};

    for (const [clusterIndex, clusterContributions] of Object.entries(
      contributionsByClusterByDate
    )) {
      contributionsNbByClusterByLocalizedDate[clusterIndex] = {};
      contributionsNbByClusterByRawDate[clusterIndex] = {};

      for (const dateObject of everyDatesBetweenFirstAndLastContribution) {
        const localizedDate = dateObject.localized;
        //  const date = dateObject.date;
        let contributionsForDate = 0;

        if (Object.keys(clusterContributions).includes(localizedDate)) {
          contributionsForDate = clusterContributions[localizedDate];
        }

        contributionsNbByClusterByLocalizedDate[clusterIndex][localizedDate] =
          (contributionsForDate && contributionsForDate.length) || 0;
        contributionsNbByClusterByRawDate[clusterIndex][dateObject.date] =
          (contributionsForDate && contributionsForDate.length) || 0;
      }
    }

    contributionsNbByClusterByLocalizedDate = Object.values(
      contributionsNbByClusterByLocalizedDate
    );

    contributionsNbByClusterByRawDate = Object.values(
      contributionsNbByClusterByRawDate
    );

    const stackedBarData = contributionsNbByClusterByLocalizedDate.map(
      (entry, i) => {
        const cluster = Object.values(formattedClusters)[i];
        const clusterKey = cluster?.title;
        const color = cluster?.bgColor;

        return {
          title: clusterKey,
          entriesRaw: entry,
          entries: Object.entries(entry)
            .sort((a, b) => a[0] > b[0])
            .map((a) => a[1]),
          color,
        };
      }
    );

    let indexesData = Object.values(formattedClusters)
      .sort((a, b) => a.contributionsNumber > b.contributionsNumber)
      .slice(0, 6);

    const maxIndexesDataOpinion = maxBy(indexesData, "opinionPercentage")
      .opinionPercentage;
    const maxIndexesDataOriginality = maxBy(indexesData, "originality")
      .originality;
    const maxIndexesDataDensity = maxBy(indexesData, "density").density;
    const maxIndexesClusters = maxBy(indexesData, "children").children.length;

    indexesData = indexesData.map((cluster) => {
      let object = {
        title: cluster?.title,
        color: cluster?.bgColor,
      };

      const opinion = (cluster.opinionPercentage / maxIndexesDataOpinion) * 95;

      const originality =
        (cluster?.originality / maxIndexesDataOriginality) * 95;
      const density = (cluster?.density / maxIndexesDataDensity) * 95;
      const nbOfContributions =
        (cluster?.children.length / maxIndexesClusters) * 95;

      const entries = [opinion, originality, density, nbOfContributions];

      return { ...object, entries };
    });

    const matrixVerticalTitles = Object.keys(contributionsByClusterByDate);

    const matrixData = sortBy(
      Object.entries(contributionsNbByClusterByRawDate).reduce(
        (accumulator, clusterEntry) => {
          const dates = clusterEntry[1];

          Object.entries(dates).map((entry) => {
            const nbOfContributions = entry[1];
            const timeStamp = useDateHelpers.dateToTimestamp(entry[0]);

            accumulator.push({
              x: timeStamp, //entry[0],
              formattedDate: entry[0],
              y: matrixVerticalTitles[clusterEntry[0]],
              value: nbOfContributions,
              timestamp: timeStamp,
            });
          });

          return accumulator;
        },
        []
      ),
      "timestamp"
    );

    const matrixDataMonth = sortBy(
      Object.entries(
        contributionsNbByClusterByMonthByYearWithLocalizedmonthAndYearTitle
      ).reduce((accumulator, clusterEntry) => {
        const monthAndYearTitle = clusterEntry[0];
        const contributionsNbByCluster = clusterEntry[1];

        Object.entries(contributionsNbByCluster).map((entry) => {
          const nbOfContributions = entry[1];
          const clusterTitle = entry[0];

          accumulator.push({
            x: monthAndYearTitle, //entry[0],
            formattedDate: monthAndYearTitle,
            y: clusterTitle,
            value: nbOfContributions,
            timestamp: null,
          });
        });

        return accumulator;
      }, []),
      "timestamp"
    );

    const matrixHorizontalLabels = sortBy(
      uniqBy(
        matrixData.map((c) => ({ timestamp: c.timestamp, title: c.x })),
        "timestamp"
      ),
      "timestamp"
    ).map((c) => c?.title);

    const matrixHorizontalLabelsMonth = uniq(
      matrixDataMonth.map((m) => m.formattedDate)
    );

    const matrixDataUnitKey = ref(
      matrixHorizontalLabelsMonth.length < 5 ? "day" : "month"
    ); // Rule to define default value to avoid visual emptiness in case of not enough months

    const clusterDonutData = mapValues(
      contributionsByClusters,
      (c) => c.length
    );

    let clusterDonutLabels = [];
    let clusterDonutValues = [];
    let clusterDonutColors = [];

    formattedClusters.map((cluster) => {
      clusterDonutLabels.push(cluster.title);
      clusterDonutValues.push(cluster.contributionsNumber);
      clusterDonutColors.push(cluster.bgColor);
    });

    const sentimentsLabels = computed(() =>
      [
        "negative",
        "middly negative",
        "neutral",
        "middly positive",
        "positive",
      ].map((s) => {
        return i18n(s);
      })
    );

    const clustersSentimentsRepartition = computed(() =>
      Object.fromEntries(
        Object.entries(contributionsByClusters).map((entry) => [
          entry[0],
          mapValues(groupBy(entry[1], "opinion"), (c) => c.length),
        ])
      )
    );

    let horizontalStackedBarValues = ref([]);

    for (let [sentimentIndex, sentiment] of sentimentsLabels.value.entries()) {
      sentimentIndex++;
      horizontalStackedBarValues.value.push({
        title: sentiment,
        color: useClustersScoreCalculations.scoreToColor(
          sentimentIndex / 5,
          true
        ),
        entries: Object.entries(clustersSentimentsRepartition.value).map(
          ([clusterTitle, sentimentRepartition]) => {
            const entriesForSentiment =
              sentimentRepartition[sentimentIndex] || 0;

            const percentage =
              entriesForSentiment /
              formattedClusters.find((c) => c.title === clusterTitle)
                .contributions.length;

            return {
              absoluteValue: entriesForSentiment,
              value: percentage * 100,
            };
          }
        ),
      });
    }

    const horizontalStackedBarLabels = computed(() =>
      Object.keys(clustersSentimentsRepartition.value).map((c) =>
        c === "Other" ? i18n("Other") : c
      )
    );

    const negativeOpinions = computed(
      () => formattedContributions.filter((c) => c.opinion < 3).length
    );

    const neutralOpinions = computed(
      () => formattedContributions.filter((c) => c.opinion === 3).length
    );

    const positiveOpinions = computed(
      () => formattedContributions.filter((c) => c.opinion > 3).length
    );

    const negativePositiveOpinionSplit = computed(() => [
      {
        title: i18n("Negative"),
        value: negativeOpinions.value,
        color: useTailwindColors.asObject.red["600"],
      },
      {
        title: i18n("Neutral"),
        value: neutralOpinions.value,
        color: useTailwindColors.asObject.gray["600"],
      },
      {
        title: i18n("Positive"),
        value: positiveOpinions.value,
        color: useTailwindColors.asObject.green["600"],
      },
    ]);

    const negativePositiveOpinionSplitLabels = computed(() =>
      negativePositiveOpinionSplit.value.map((c) => c.title)
    );
    const negativePositiveOpinionSplitColors = computed(() =>
      negativePositiveOpinionSplit.value.map((c) => c.color)
    );
    const negativePositiveOpinionSplitValues = computed(
      () =>
        negativePositiveOpinionSplit.value.map((c) => "v_" + c.value.toString()) // Here we add v_ and turn value to string so it doesn't auto reorder the array and tamper the dataviz
    );

    return {
      contributionsNbByClusters,
      everyContributionsDates,
      contributionsByClusters,
      formattedClusters,
      formattedContributions,
      clusterDonutLabels,
      clusterDonutValues,
      clusterDonutColors,
      clustersSentimentsRepartition,
      indexesData,
      indexesTitles,
      horizontalStackedBarValues,
      stackedBarData,
      matrixData,
      matrixDataUnitKey,
      everyDatesBetweenFirstAndLastContribution,
      contributionsNbByClusterByLocalizedDate,
      contributionsByClusterByDate,
      everyContributionLocalizedDates,
      matrixVerticalTitles,
      histogramChart,
      matrixChart,
      matrixDataMonth,
      radarChart,
      donutChart,
      horizontalStackedBarChart,
      stackedBarChart,
      exportChartToPng,
      exportElementToPng,
      clustersTitles,
      sentimentsLabels,
      negativePositiveOpinionSplitLabels,
      negativePositiveOpinionSplitColors,
      negativePositiveOpinionSplitValues,
      negativePositiveOpinionSplit,
      overallOpinionChart,
      matrixHorizontalLabels,
      matrixHorizontalLabelsMonth,
      formattedContributionsByClusterByMonthByYear,
      contributionsNbByClusterByMonthByYearWithLocalizedmonthAndYearTitle,
      clusterDonutData,
      notPlaceholdingDates,
      visiblePlaceholdingDatesBanner,
      horizontalStackedBarLabels,
    };
  },
};
</script>

<style scoped lang="scss"></style>
