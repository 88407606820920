<template>
  <div>
    <contributor-slide-panel
      :external-contributors="availableContributors"
      :external-contributions="availableContributions"
      :external-clusters="availableClusters"
      :details="ContributorSlidePanel"
      modal-instance-id="insight"
      v-if="ContributorSlidePanel"
    />
    <contribution-modal
      :visible="contributionModal != null"
      :external-contributors="availableContributors"
      :external-contributions="availableContributions"
      :details="contributionModal"
      modal-instance-id="insight"
      v-if="contributionModal"
    />
    <cluster-modal
      :visible="clusterModal != null"
      :external-contributors="availableContributors"
      :external-contributions="availableContributions"
      :external-clusters="availableClusters"
      :active-cluster-id="clusterModal"
      modal-instance-id="insight"
      v-if="clusterModal"
    />
  </div>
</template>

<script>
import ContributorSlidePanel from "@/components/Contributors/ContributorSlidePanel";
import ContributionModal from "@/components/Contributions/ContributionModal";
import ClusterModal from "@/components/Clusters/ClusterModal";
import { useInjectModals } from "@/logic/use-modals";
import { inject } from "vue";
export default {
  name: "InsightModals",
  components: { ClusterModal, ContributionModal, ContributorSlidePanel },
  setup() {
    const {
      contributionModal,
      ContributorSlidePanel,
      clusterModal,
    } = useInjectModals("insight");

    const [availableContributors, availableContributions, availableClusters] = [
      inject("availableContributors"),
      inject("availableContributions"),
      inject("availableClusters"),
    ];

    return {
      contributionModal,
      ContributorSlidePanel,
      clusterModal,
      availableContributors,
      availableContributions,
      availableClusters,
    };
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
