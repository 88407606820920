<template>
  <main class="flex flex-col -mx-6 md:flex-row md:space-y-0 md:space-x-6">
    <div class="-my-5 -mb-6 w-full">
      <div class="py-5 px-4 sm:px-6">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{
            $filters.i18n(
              "The key pair will be generated with the following parameters"
            )
          }}
        </h3>
      </div>
      <div class="py-5 px-4 border-t border-gray-100 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-100">
          <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              {{ $filters.i18n("Title") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {{ title }}
            </dd>
          </div>
          <div
            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
            v-if="passPhrase && passPhrase.length > 0"
          >
            <dt class="text-sm font-medium text-gray-500">
              {{ $filters.i18n("Pass phrase") }}
            </dt>
            <dd
              class="flex items-center mt-1 space-x-3 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
            >
              <span class="font-light">{{
                showPassPhrase ? passPhrase : "*".repeat(passPhrase.length)
              }}</span>
              <button
                class="text-xs tracking-wide interactive button button-small button-soft-visible"
                @click="showPassPhrase = !showPassPhrase"
              >
                {{ $filters.i18n(showPassPhrase ? "Hide" : "Show") }}
              </button>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "KeysPairGenerationSummary",
  data() {
    return {
      showPassPhrase: false,
    };
  },
  methods: {
    goNext() {
      this.$emit("generate-key-pair");
    },
  },
  props: {
    title: String,
    passPhrase: String,
  },
};
</script>

<style scoped lang="scss"></style>
