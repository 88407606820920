import gql from "graphql-tag";

export const deleteSession = /* GraphQL */ gql(`
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      owner
    }
  }
`);

export const deleteClusters = /* GraphQL */ gql(`
  mutation deleteMultipleClusters(
    $ids: [ID!]!  ) {
    deleteMultipleClusters(ids: $ids)
  }
`);

export const mergeClusters = /* GraphQL */ gql(`
  mutation mergeMultipleClusters(
    $ids: [ID!]!,
    $targetClusterId: ID,
    $newTitle: String  
    ) {
    mergeMultipleClusters(ids: $ids, targetClusterId : $targetClusterId, newTitle: $newTitle)
  }
`);

export const renameClusterMutation = /* GraphQL */ gql(`
  mutation renameCluster(
    $id: ID!,
    $title: String!
  ) {
      renameCluster(id: $id, title: $title)
  }
`);

export const updateIdeaClusterMutation = /* GraphQL */ gql(`
  mutation updateContributionCluster($contributionId: ID!, $clusterId : ID) {
    updateContributionCluster(contributionId: $contributionId, clusterId: $clusterId)
  }
`);
