<template>
  <insight-delete-modal :session-id="sessionId" @on-done="redirectToHome">
    <template #default="{ clickCallback, loading }">
      <button
        class="button button-primary"
        @click="clickCallback"
        :disabled="loading"
      >
        <togglable-loading-state :loading="loading">
          <span>{{ $filters.i18n("Delete the analysis") }}</span>
        </togglable-loading-state>
      </button>
    </template>
  </insight-delete-modal>
</template>

<script>
import router from "../../router/router";
import InsightDeleteModal from "@/components/Insights/InsightDeleteModal";
import TogglableLoadingState from "@/components/DesignSystem/Progress/TogglableLoadingState";

export default {
  name: "InsightDeleteButton",
  components: { TogglableLoadingState, InsightDeleteModal },
  setup() {
    const redirectToHome = () => {
      window.location.href = "/";
    };

    return {
      redirectToHome,
    };
  },
  props: {
    sessionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      loading: false,
    };
  },
  methods: {
    quitSession() {
      router.push("/onboarding");
    },
  },
};
</script>
