<template>
  <Listbox
    as="div"
    :model-value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :disabled="editing"
  >
    <ListboxLabel
      class="block mb-2 font-medium text-gray-700"
      v-if="!!$slots.default"
    >
      <slot />
    </ListboxLabel>
    <div class="relative">
      <ListboxButton
        class="relative pr-10 pl-3 w-full text-left bg-white rounded-md border border-gray-300 focus:border-primary focus:ring-1 focus:ring-primary shadow-sm cursor-default focus:outline-none sm:text-sm"
        :class="large ? 'py-4' : 'py-2'"
      >
        <span class="block truncate">{{
          modelValue ? modelValue.title : noneLabel || $filters.i18n("None")
        }}</span>
        <span
          class="flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none"
        >
          <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          :class="[
            'max-h-[300px] absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
          ]"
        >
          <ListboxOption
            v-for="item in items"
            :key="item.key"
            :model-value="item"
            :value="item"
            v-slot="{ active }"
            as="li"
            :disabled="editing"
          >
            <div
              :class="[
                active ? 'text-white bg-primary-dark' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-3 pr-9',
              ]"
            >
              <span
                :class="[
                  modelValue && modelValue.key === item.key
                    ? 'font-semibold'
                    : 'font-normal',
                  'block truncate',
                ]"
              >
                {{ item.title }}
              </span>

              <span
                v-if="modelValue && modelValue.key === item.key"
                :class="[
                  active ? 'text-white' : 'text-primary-dark',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="w-5 h-5" aria-hidden="true" />
              </span>
            </div>
          </ListboxOption>

          <div
            v-if="canAddNewOptions"
            :class="[
              'text-gray-900',
              'cursor-pointer select-none relative py-2 px-3',
              editing
                ? '!px-2 !pb-1'
                : 'hover:text-white hover:bg-primary-dark',
            ]"
          >
            <form @submit.prevent="addEditedOption" v-if="editing">
              <text-input
                v-model="titleToAdd"
                placeholder="Titre du champ (un seul mot)"
                autocomplete="off"
                name="someUnusualName"
                class="!block flex-1 !py-2 !mt-0 !w-full !text-xs"
                container-class="flex items-center flex-row-reverse"
                :id="`addEditedOption${uuid}`"
                type="text"
              >
                <button
                  class="block z-10 p-2 py-2 -ml-2 h-full text-white bg-primary rounded-md rounded-l-none disabled:cursor-not-allowed"
                  :disabled="!titleToAdd || titleToAdd.length < 1"
                  type="submit"
                >
                  Ok
                </button>
              </text-input>
            </form>

            <span
              :class="['block truncate font-semibold']"
              v-else
              @click.prevent.capture.stop="editing = true"
            >
              Ajouter un champ
            </span>
          </div>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { nextTick, ref, watchEffect } from "vue";
import TextInput from "@/components/DesignSystem/Inputs/TextInput";

export default {
  components: {
    TextInput,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    modelValue: { required: true },
    items: { type: Array, required: true },
    noneLabel: { type: String, default: null },
    large: Boolean,
    canAddNewOptions: Boolean,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const editing = ref(false);
    const titleToAdd = ref("");
    const uuidv4 = () => {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    };

    const uuid = uuidv4();

    watchEffect(() => {
      if (editing.value === true) {
        nextTick(() =>
          document.getElementById(`addEditedOption${uuid}`).focus()
        );
      }
    });

    const resetEditedOption = () => {
      editing.value = false;
      titleToAdd.value = "";
    };

    const addEditedOption = () => {
      emit("add-option", titleToAdd.value);
      resetEditedOption();
    };

    return {
      editing,
      titleToAdd,
      addEditedOption,
      uuid,
      resetEditedOption,
    };
  },
};
</script>
